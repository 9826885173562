@import "src/styles/mixins";

app-minigame-catch-bananas-gui {
  .ow-dialog {
    .decoration {
      &.decoration-1 {
        left: -60px;
        top: 140px;
      }

      &.decoration-4 {
        right: -40px;
        top: 270px;
      }

      &.decoration-5 {
        top: -5px;
        left: 22px;
      }
    }

    m-ui-close-button {
      position: absolute;
      right: -40px;
      top: 30px;
    }
  }

  .minigame-container {
    --border-radius: 20px;

    width: 1000px;
    height: 570px;
    position: relative;
    border: 2px solid black;
    border-radius: var(--border-radius);

    .game-container, .background-container {
      width: 100%;
      height: 100%;
      border-radius: var(--border-radius);
      overflow: hidden;
    }

    .background-container {
      background-image: url("../../../../../../assets/minigames/catch-bananas/background.png");
      background-size: cover;
      background-position: bottom;
      position: absolute;
      top: 0;
      left: 0;
    }

    .logo {
      background-image: url("../../../../../../assets/minigames/catch-bananas/ui/logo.png");
      background-size: contain;

      &.gui {
        @include absolute-center-horizontal;
        top: -32px;
        width: 250px;
        height: 200px;
      }

      &.result, &.pause, &.load {
        width: 197px;
        height: 158px;
      }

      &.result {
        margin-top: -20px;
      }
    }

    .icon {
      &.point {
        width: 60px;
        height: 50px;

        &.banana {
          background-image: url("../../../../../../assets/minigames/catch-bananas/ui/banana.png");
        }

        &.banana-rotten {
          background-image: url("../../../../../../assets/minigames/catch-bananas/ui/banana_rotten.png");
        }

        &.box {
          background-image: url("../../../../../../assets/minigames/riverride/ui/box.png");
        }
      }
    }

    .bottom-menu {
      width: 850px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;

      .back {
        @include absolute-center-vertical;
        left: 0;
      }

      &, button {
        color: #FFFFFF;
        font-size: 18px;
        @include mikado;
        font-weight: bold;
      }

      button {
        margin: 0 12px;
        text-transform: initial;
      }
    }
  }

  .results-container, .pause-container, .load-container {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;

    .ow-dialog {
      &.c-window-a {
        width: 400px;
        height: 504px;
        display: flex;
        flex-direction: column;
        align-items: center;
        background: var(--asset--ui-window_small-png);
        background-repeat: no-repeat;
      }
    }

    .title, .summary {
      @include mikado;
      font-weight: bold;
      text-transform: initial;
    }

    .title {
      font-size: 30px;
    }

    table {
      &.results {
        @include mikado;
        font-weight: bold;
        border-spacing: 5px 10px;
        font-size: 20px;

        td {
          &.point-per-one {
            text-align: right;
          }
        }
      }
    }

    .separator {
      width: 300px;
      height: 2px;
      background: #000000;
    }

    .summary {
      margin: 5px 0 2px;
      display: flex;
      align-items: center;
      font-size: 22px;

      m-ui-product {
        margin-left: 6px;
      }
    }
  }

  button {
    margin-bottom: 15px;
  }

  .stock-minigame-catch-banana {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row-reverse;
    margin-left: 4px;

    .icon {
      width: 38px;
      height: 38px;
      margin: 0 0 4px 4px;
    }

    .value {
      @include mikado();
      font-size: 22px;
      font-weight: 900;
      text-align: center;
      color: #000000;
      text-shadow: none;
    }
  }

  .pause-container, .load-container {
    .ow-dialog {
      &.c-window-a {
        justify-content: space-evenly;
      }
    }
  }
}
