app-custom-building-special {
  @import "../styles/style";

  app-unlocked-parameters {
    .parameters-container {
      flex-wrap: wrap;
    }
  }

  .buttons-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    position: absolute;
    bottom: 45px;
    transform: translateX(-50%);
    left: 50%;

    .buttons {
      position: unset;
      left: unset;
      transform: unset;
      margin: 0;
    }
  }
}
