app-custom-qa-summary {
  width: 80%;

  p {
    margin: 0 0 24px 0;
    text-align: center;
    font-size: 18px;
    font-weight: 900;
    text-transform: uppercase;
  }

  .stock-layout-a {
    flex-direction: column !important;

    .icon {
      margin-right: 0 !important;
      margin-bottom: 10px;
    }
  }

  .container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 100%;

    &.qa-prizes-divide {
      & > * {
        width: 33%;
      }
    }

    & > * {
      padding: 0 26px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
    }
  }

  .prizes {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    & > * {
      margin: 0 20px;
    }
  }

  m-ui-separator {
    margin: 36px auto 38px auto;

    span {
      font-size: 16px !important;
      @include archivo-narrow();
    }
  }

  .stock-layout-a {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .icon {
      width: 50px;
      height: 50px;
      margin-bottom: 4px;
    }

    .value {
      @include mikado();
      font-size: 14px;
      font-weight: 900;
      text-align: center;
      color: #000000;
      text-shadow: none;
    }
  }
}




