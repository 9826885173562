app-san-building-efficiency {
  @import "../styles/style";

  .ow-dialog {
    padding: 50px 115px 106px;
  }

  .levels-container {
    position: absolute;
    left: 350px;
    bottom: -3px;
  }

  .container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    button {
      &.maintenance {
        width: 214px;
        @include absolute-center-horizontal;
        bottom: 50px;
      }
    }

    .right-column {
      p {
        margin: 0;

        &.title {
          @include mikado();
          font-size: 20px;
          font-weight: bold;
          letter-spacing: 0;
          text-transform: uppercase;
        }

        &.description {
          @include archivo-narrow();
          font-size: 18px;
          letter-spacing: 0;
          max-width: 430px;
          line-height: 24px;
        }
      }
    }
  }

  .top {
    .right-column {
      margin-left: 46px;
    }
  }

  m-ui-separator {
    .separator {
      margin-bottom: 20px;
    }
  }
}
