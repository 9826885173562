.bookmarks {
  width: 100%;
  box-sizing: border-box;
  position: absolute;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  &.spread {
    &-4 {
      gap: 4px;
    }

    &-8 {
      gap: 8px;
    }

    &-10 {
      gap: 10px;
    }

    &-12 {
      gap: 12px;
    }
  }

  &.center {
    justify-content: center;
  }

  .overlap {
    position: relative;

    &.active {
      z-index: 1 !important;

      &::after {
        position: absolute;
        content: "";
        width: 26px;
        height: 26px;
        border-left: solid 3px #000000;
        border-bottom: solid 3px #000000;
        left: 50%;
        transform: rotate(-45deg) translateX(-50%);
        bottom: -2px;
        z-index: -5;
      }

      &.green {
        &::after {
          background-color: #7CDF4F;
        }
      }

      &.yellow {
        &::after {
          background-color: #F3CB32;
        }
      }

      &.blue-light {
        &::after {
          background-color: #71B7FE;
        }
      }

      &.red {
        &::after {
          background-color: #FF3D3D;
        }
      }

      &.purple {
        &::after {
          background-color: #E567DD;
        }
      }

      &.orange {
        &::after {
          background-color: #FF732A;
        }
      }
    }
  }

  .bookmark {
    @include mikado();
    min-width: 186px;
    height: 34px;
    font-size: 20px;
    font-weight: bold;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    padding: 0 4px;

    &:not(:first-child) {
      margin-left: 26px;
    }

    &:before,
    &:after {
      background-repeat: no-repeat;
      background-size: contain;
      content: '';
      width: 32px;
      height: 100%;
      position: absolute;
      top: 0;
    }

    &.adjust-size {
      min-width: unset;
    }

    &.blue-dark {
      background-image: var(--asset--ui-bookmark_blue_dark-svg);

      &:before,
      &:after {
        background-image: var(--asset--ui-bookmark_end_blue_dark-svg);
      }
    }

    &.blue-light {
      background-image: var(--asset--ui-bookmark_blue_light-svg);

      &:before,
      &:after {
        background-image: var(--asset--ui-bookmark_end_blue_light-svg);
      }
    }

    &.green {
      background-image: var(--asset--ui-bookmark_green-svg);

      &:before,
      &:after {
        background-image: var(--asset--ui-bookmark_end_green-svg);
      }
    }

    &.orange {
      background-image: var(--asset--ui-bookmark_orange-svg);

      &:before,
      &:after {
        background-image: var(--asset--ui-bookmark_end_orange-svg);
      }
    }

    &.purple {
      background-image: var(--asset--ui-bookmark_purple-svg);

      &:before,
      &:after {
        background-image: var(--asset--ui-bookmark_end_purple-svg);
      }
    }

    &.red {
      background-image: var(--asset--ui-bookmark_red-svg);

      &:before,
      &:after {
        background-image: var(--asset--ui-bookmark_end_red-svg);
      }
    }

    &.yellow {
      background-image: var(--asset--ui-bookmark_yellow-svg);

      &:before,
      &:after {
        background-image: var(--asset--ui-bookmark_end_yellow-svg);
      }
    }

    &:after {
      -webkit-transform: scaleX(-1);
      transform: scaleX(-1);
      right: -16px;
    }

    &:before {
      left: -16px;
    }
  }

  .source-data-date-tab {
    @include archivo-narrow();
    font-weight: bold;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 18px;
    height: 36px;
    border: 3px solid black;
    background-color: #B8F2FF;
    padding: 0 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    box-sizing: border-box;
  }
}

.c-window-long, c-window-long-wf {
  .bookmarks {
    top: -12px;
    padding: 0 120px;

    .source-data-date-tab {
      right: 72px;
      bottom: -3px;
    }
  }
}

.c-window-short {
  .bookmarks {
    top: -20px;
    padding: 0 140px;
  }
}
