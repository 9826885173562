@import "src/styles/functions";
@import "src/styles/variables/core/variables";
@import "src/styles/mixins";

app-core-warehouse-details {
  @import "variables";

  .window-a {
    padding: multiply-size(22px) multiply-size(33px) multiply-size(12px) multiply-size(33px);
  }

  .product-details {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    height: 100%;

    .product-info {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;

      .product-in-glow-box {
        width: multiply-size(58px);
        height: multiply-size(55px);
        margin: 0 multiply-size(5px) multiply-size(6px) multiply-size(5px);
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;

        .product-icon {
          width: multiply-size(38px);
          height: multiply-size(38px);
          object-fit: contain;
        }

        .product-balance {
          position: absolute;
          bottom: multiply-size(-8px);
          font-size: multiply-size(10px);
          @include myriad-cond;
          font-weight: bold;
          left: 0;
          text-align: center;
          width: 100%;
        }

        .clip-path {
          position: absolute;
          top: multiply-size(-1px);
          left: multiply-size(-2px);
          height: 100%;
          width: 100%;
          border: multiply-size(2px) solid $clip-path-color;
          border-radius: multiply-size(10px);
          clip-path: polygon(
            -3% 107.06%,
            -3% -2%,
            106.72% -3%,
            104.03% 109.89%,
            76.01% 109.32%,
            75.74% 98.35%,
            86.16% 96.93%,
            92% 11%,
            7% 11%,
            12.85% 96.65%,
            24.42% 98.06%,
            26.3% 108.48%
          );
        }
      }

      .description {
        margin-top: multiply-size(6px);
        font-size: multiply-size(12px);
        text-align: center;
        @include myriad-cond;
        font-weight: bold;
        max-height: multiply-size(45px);
      }
    }

    .clip-path-horizontal {
      height: multiply-size(2px);
      width: multiply-size(324px);
      position: absolute;
      top: 50%;
      pointer-events: none;
      left: 50%;
      transform: translateX(-50%);
      background-color: $clip-path-color;
      clip-path: polygon(50% -100%, 102.31% 60%, 98.61% 85%, 50% 206%, 5% 77%, 0.77% 52%);
    }

    .clip-path-vertical {
      position: absolute;
      height: multiply-size(130px);
      width: multiply-size(2px);
      left: 50%;
      transform: translateX(-50%);
      bottom: multiply-size(8px);

      &:before,
      &:after {
        content: "";
        width: 100%;
        position: absolute;
        background-color: $clip-path-color;
        clip-path: polygon(0% 0%, 100% 0%, 60% 80%, 50% 70%);
      }

      &:before {
        height: 50%;
        top: 0;
      }

      &:after {
        bottom: 0;
        height: 50%;
        transform: rotate(180deg);
      }
    }

    .transaction-container {
      display: flex;
      justify-content: center;

      .each-transaction {
        margin: 0 multiply-size(16px);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;

        .summary {
          margin-top: multiply-size(6px);
          height: multiply-size(20px);
          display: flex;
          align-items: center;
          @include myriad-cond;
          font-weight: bold;
          font-size: multiply-size(10px);
          @include text-stroke();
          color: $summary-color;

          m-ui-product,
          m-ui-currency {
            margin-left: multiply-size(4px);
            margin-right: multiply-size(1px);
          }
        }

        .no-transation {
          font-size: multiply-size(10px);
          @include myriad;
          font-weight: bold;
          margin-bottom: multiply-size(20px);
        }
      }
    }
  }

  form {
    text-align: center;

    input {
      background: $color-I;
      font-family: MyriadPro, sans-serif;
      border-radius: multiply-size(7px);
      height: multiply-size(32px);
      width: multiply-size(132px);
      border: multiply-size(2px) solid $color-K;
    }
  }

  button.primary.base {
    margin: multiply-size(7px) 0;
  }

  @import "custom/style";
}
