app-custom-hud-menu {
  .main-buttons-container {
    display: flex;

    .main-button {
      height: 56px;
      width: 50px;
      margin-right: 10px;
      pointer-events: all;

      .app-notification {
        right: 0;
        bottom: 0;
      }

      &:hover {
        filter: var(--filter-hover);
      }

      &.anim {
        transform: translateY(-115%) scale3d(1, 0, 1);
        pointer-events: none;
        opacity: 0;

        &.active {
          opacity: 1;
          pointer-events: initial;
          transform: translateX(0) scale3d(1, 1, 1);
        }
      }

      ow-object-svg {
        width: 100%;
        height: 100%;
      }
    }

    .buttons-horizontal > .buttons-horizontal {
      .main-button {
        transition: 0.5s;
        margin-top: 5px;
      }
    }

    .buttons-horizontal {
      &.dropdown {
        position: relative;
        display: inline-block;

        &:hover {
          .dropdown-btn {
            display: block;
          }
        }

        .dropdown-btn {
          display: none;
          position: absolute;
          z-index: 1;
        }
      }
    }
  }
}
