cart {
  @import "../../shop-assets";

  & {
    width: 100%;
    overflow: auto;

    button {
      &.c-base.history {
        width: 214px !important;
      }

      &.c-base.order,
      &.c-base.accept {
        width: 186px !important;
        height: 46px !important;
        font-size: 14px !important;
      }
    }

    button {
      &.clear-basket {
        width: 200px;

        i {
          margin-right: 10px;
        }

        span {
          @include mplus1c-rounded;
          font-weight: 900;
          text-decoration: underline;
          letter-spacing: 0.28px;
        }
      }

      i {
        font-size: 20px;

        &.fa-times-circle,
        &.fa-minus-circle,
        &.fa-plus-circle {
          font-weight: 400;
        }

        &.remove {
          font-weight: bold;
        }
      }

      &.back {
        @include mplus1c-rounded;
        font-weight: 900;
        display: flex;
        align-items: baseline;
        padding-top: 12px;

        i {
          margin-right: 10px;
          font-size: unset !important;
        }

        span {
          text-decoration: underline;
        }
      }

      &.order {
        img {
          margin-left: 8px;
          width: 24px;
          height: 24px;
        }
      }
    }

    ow-perfect-scrollbar {
      min-width: 1300px;
    }

    .shop-container {
      padding-bottom: 50px;
    }

    .table-cart {
      button.square {
        border: 2px solid #588500;
        color: #588500;
        border-radius: 50px;
        box-sizing: border-box;
        width: 25px;
        min-width: 25px;
        height: 25px;
        min-height: 25px;
        font-size: 12px;
        cursor: pointer;
        padding: 0;

        i {
          font-size: 12px;
        }
      }

      thead {
        tr {
          th {
            text-align: center;

            &:last-child {
              text-align: right;
            }

            &:first-child {
              text-align: left;
            }

            &.small {
              font-size: 14px;
            }
          }
        }
      }

      tbody {
        tr {
          td {
            @include roboto-cond;
            font-weight: bold;
            padding-top: 8px;
            padding-bottom: 8px;
            box-sizing: border-box;

            .flex-center {
              justify-content: flex-end;

              &.center {
                justify-content: center !important;
              }

              img {
                margin-right: 0;
                margin-left: 10px;
                width: 22px;
                height: 22px;
              }
            }

            &.td-remove {
              width: 80px;
            }

            &.td-image {
              width: 90px;
              padding-left: 0;
              padding-right: 0;

              .image-container {
                width: 90px;
                height: 90px;

                img {
                  width: 100%;
                }
              }
            }

            &.td-amount {
              text-align: center;
            }

            &.td-price-sum, &.td-price-cash {
              text-align: right;
              width: 160px;
            }

            &.td-amount {
              width: 180px;

              span {
                display: inline-block;
                width: 80px;
                text-align: center;
              }
            }

            &.td-summary {
              text-align: left;
              font-size: 18px;
              font-weight: normal;

              .code-name {
                font-weight: bold;
              }

              .flex-center {
                font-size: 18px;
                letter-spacing: 0;
                width: 170px;
                display: inline-flex;
              }

              &.discount {
                text-transform: none;

                &.bold {
                  font-weight: bold;
                }

                .discount-icon {
                  font-size: 22px;
                  color: #00767a;
                  transform: translateY(2px);
                }
              }
            }
          }

          &.tr-title {
            font-size: 16px;
            @include roboto;

            td {
              background: #E5F2FF;
              font-size: 15px;

              span {
                font-size: 16px;
                text-transform: uppercase;
                font-weight: bold;
              }

              &.xsmall {
                font-size: 14px;
                text-align: right;
                font-weight: normal;
              }
            }
          }

          &.tr-product {
            font-weight: bold;
          }

          &.tr-summary {
            height: 30px;
          }


          &.tr-promo {
            height: 100px;

            td {
              height: 40px;
              text-align: right;

              div {
                display: flex;
                align-items: center;
                justify-content: flex-end;
              }

              img {
                margin-right: 20px;
              }

              input {
                width: 306px;
                height: 46px;
                border-radius: 15px;
                margin: 0 10px 0 0;
                @include roboto-cond;
                font-size: 16px;
                text-align: left;
                text-transform: none;
                font-weight: normal;
              }
            }
          }

          &.tr-empty {
            height: 40px;
            border-bottom: 1px solid rgba(0, 63, 84, 0.5);
          }
        }
      }
    }

    .empty {
      height: 30px;
    }

    .empty-top {
      border-top: 1px solid rgba(0, 63, 84, 0.5);
      height: 60px;
    }

    .button-container {
      padding: 0 16px;
      margin: 56px 0 120px 0;
      box-sizing: border-box;
      display: flex;
      align-items: flex-end;
      justify-content: space-between;

      button {
        margin: 2px 5px;
      }
    }

    .disallow-order-error {
      @include roboto-cond;
      color: #FF0421;
      font-weight: bold;
      font-size: 14px;
      margin-top: 10px;
      padding-bottom: 10px;
      padding-right: 11px;
    }

    .right {
      display: flex;
    }

    .right-buttons {
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      .order-buttons {
        margin-left: 20px;
      }
    }
  }

  .mobile & {
    .shop-container {
      overflow: hidden;
    }

    .table-cart {
      width: 1200px;

      tbody {
        tr {
          &.tr-promo {
            &, td {
              text-align: left;
            }
          }
        }
      }
    }

    .button-container {
      button {
        &.order {
          float: none;
        }
      }
    }
  }

  .bxs {
    box-sizing: content-box;
  }

  .fz-16 {
    font-size: 16px;
  }

  .no-wrap {
    white-space: nowrap;
  }
}
