@import "src/styles/mixins";

products-list {
  @import "../../shop-assets";

  & {
    width: 100%;
    display: flex;
    flex-direction: column;

    button {
      &.c-base {
        width: 176px !important;
        height: 42px !important;
        font-size: 14px !important;
      }
    }

    .shop-container {
      padding-bottom: 50px;
    }

    #productsList {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      align-items: flex-start;
      align-content: flex-start;

      .product-item {
        width: 200px;
        margin: 0 10px;
        box-sizing: border-box;
        text-align: center;

        &:nth-child(6n+1) {
          margin-left: 0;
        }

        &:nth-child(6n+6) {
          margin-right: 0;
        }

        .product-grey-bg {
          background: #E5F2FF;
          height: 136px;
          padding: 14px 4px;
          box-sizing: border-box;
          display: flex;
          align-items: center;
          flex-direction: column;
          justify-content: space-between;
        }

        .product-image {
          width: 200px;
          height: 200px;
          overflow: hidden;
          position: relative;
          cursor: pointer;
          color: #000000;

          img {
            width: 100%;
          }

          .product-ribbon {
            position: absolute;
            bottom: 1px;

            &.label-long {
              left: 50%;
              transform: translateX(-50%);
            }

            &.sparkle {
              left: 0;
              display: flex;
              align-items: center;

              img {
                width: 36px;
                height: 30px;
              }

              .label-name {
                display: none;
              }
            }

            &.promo {
              top: 0;
              right: 0;

              img {
                width: 50px;
                height: 50px;
                object-fit: contain;
              }
            }
          }

          .new-product {
            @include mplus1c-rounded;
            font-size: 14px;
            color: $color-I;
            text-transform: uppercase;
            text-align: center;
            border-radius: 10px 10px 0 0;
            background-color: #000000;
            height: 30px;
            width: 104px;
            @include absolute-center-horizontal;
            bottom: 1px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: 900;
            letter-spacing: 0;
          }
        }

        .product-name {
          font-size: 14px;
          font-weight: bold;
          @include roboto;
          line-height: 16px;
          display: inline-block;
          height: 32px;
          overflow: hidden;
          color: #000000 !important;
        }

        .product-price {
          @include roboto-cond;
          font-size: 12px;
          font-weight: 200;
          text-transform: uppercase;
          display: flex;
          align-items: center;
          color: #000000;
          margin: 0;

          .price {
            font-size: 16px;
            font-weight: bold;
            position: relative;
            margin-left: 8px;
            display: flex;
            align-items: center;

            img {
              width: 21px;
              height: 22px;
              margin-left: 4px;
            }
          }
        }

        &.innerAnimation {
          @keyframes innerAnimation {
            0% {
              opacity: 0;
              transform: translateY(25px);
            }
            100% {
              opacity: 1;
              transform: translateY(0);
            }
          }

          //animation: innerAnimation 1s;
        }
      }

      .button-center-bottom {
        margin-top: 20px;
      }

      .no-products-text {
        text-align: center;
      }
    }
  }

  p {
    &.center {
      max-width: 1300px;
      margin: 0 auto 60px auto;
      text-align: center;
      color: #000000;
      font-weight: 900;
      font-size: 16px;
    }
  }

  .desc-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 26px;

    .img.discount {
      background-image: var(--asset--ui-discount-png);
      background-repeat: no-repeat;
      background-size: contain;
      width: 20px;
      height: 20px;
      margin: 0 6px 0 3px;
    }
  }
}
