@import "src/styles/functions";
@import "src/styles/mixins";
@import "src/styles/variables/core/variables";

app-custom-currency-exchange {
  .c-window-short {
    padding: 124px 100px 128px 100px;
  }

  .bookmarks {
    .bookmark {
      min-width: 100px;
    }
  }

  .container {
    height: 100%;
    display: flex;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;

    .exchange {
      display: flex;
      justify-content: center;
      margin: 0 auto 24px;
      gap: 20px;
      width: 100%;
      align-items: center;

      .exchange-from,
      .exchange-to {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
      }
    }

    .summary {
      display: flex;
      align-items: center;
      justify-content: center;
      @include mikado;
      margin-top: 52px;
      margin-bottom: 32px;
      word-spacing: 2px;
      font-size: 18px;
      min-height: 30px;
    }
  }

  .tooltip-exchange-from,
  .tooltip-exchange-to {
    display: flex;
    position: absolute;
    padding: 4px 6px;
    border-radius: 4px;
    z-index: 1;

    m-ui-currency {
      margin: 6px;
      cursor: pointer;
    }
  }

  .tooltip-exchange-from {
    top: 0;
    margin-top: -7px;
    transform: translateY(-100%);
  }

  .tooltip-exchange-to {
    bottom: 0;
    margin-bottom: -7px;
    transform: translateY(100%);
  }

  .tooltip-exchange-from,
  .tooltip-exchange-to {
    &:after {
      content: '';
      width: 7px;
      height: 4px;
      position: absolute;
      transform: translate(50%);
      right: 50%;
    }
  }

  .spread {
    @include mikado;
    font-size: 20px;
  }

  .disabled {
    opacity: 0.5;
  }

  .form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .default-field {
      margin: 0;

      input {
        width: 190px;
        height: 32px;
        border-radius: 15px;
        @include archivo-narrow;

        &::placeholder {
          color: black;
          font-size: 16px;
          @include archivo-narrow;
        }

        &:focus {
          font-weight: normal;
        }
      }
    }

    .sell-all {
      margin-top: 16px;
      font-size: 16px;
      @include archivo-narrow();
      text-decoration: underline;
    }
  }

  .flex-center {
    font-size: 18px;
    line-height: 24px;
    flex-direction: column;

    m-ui-currency {
      .stock-layout-z {
        .icon {
          margin-left: 2px;
        }
      }
    }
  }
}
