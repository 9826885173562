app-gallery-header {
  .gallery-header {
    background: var(--asset--ui-background-png);
    background-position: center center;
    background-size: cover;
    height: 424px;

    .gallery-container {
      .logo {
        margin-top: 35px;
        background-image: var(--asset--ui-logo-png);
        width: 250px;
        height: 140px;
      }
    }

    .exit-button, .back-button {
      color: inherit;

      &.exit-button {
        font-size: 16px;
      }

      &.back-button {
        font-size: 20px;
      }
    }
  }
}
