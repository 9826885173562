app-custom-registration {
  @import "../../../styles/custom/style.scss";

  .auth-form-wrapper {
    padding-top: 42px;
    height: 580px;
  }

  .choose-wrapper .text-wrapper {
    @include archivo-narrow();
    font-size: 16px;
  }

  .form-title {
    margin: 0 0 35px 0;
  }

  .buttons {
    margin-top: 27px;
  }

  .m-top-35 {
    margin-top: 35px;
  }

  .m-top-45 {
    margin-top: 45px;
  }

  .m-top-55 {
    margin-top: 55px;
  }

  .m-top-10 {
    margin-top: 10px;
  }

  .m-top-15 {
    margin-top: 15px;
  }

  .m-bottom-100 {
    margin-bottom: 100px;
  }

  .m-bottom-55 {
    margin-bottom: 55px;
  }

  .m-bottom-20 {
    margin-bottom: 20px;
  }
}
