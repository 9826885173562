@import "../variables";
@import "../mixins";

$colors: (
  $font-stroke: transparent,
  $border: (
    $normal: #000000,
    $hover: #000000,
  ),
  $background: (
    $normal: #E33932,
    $active: #A92822,
    $hover: #e5443e,
  ),
  $box-shadow: (
    $normal: #99231E,
    $active: #6f0808,
    $hover: #a12e28,
  ),
  $drop-shadow: (
    $normal: transparent,
    $hover: transparent,
  ),
  $font: (
    $normal: #000000,
    $active: #000000,
    $hover: #000000,
  )
);

&.tertiary {
  @include generate-schema-button-color($colors)
}
