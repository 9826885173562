@import "src/styles/variables/core/variables";
@import "src/styles/mixins";

app-building-list {
  .exit-bar {
    right: -80px;
    top: 32px;
    i {
      color: $color-I;
    }
  }

  .navigation-bar {
    position: absolute;
    @include absolute-center-vertical;

    &.prev {
      left: -60px !important;
    }

    &.next {
      right: -60px !important;
    }
  }

  .swiper-slide {
    overflow: unset !important;
  }

  .swiper-wrapper {
    align-items: center !important;
  }

  m-ui-slider-dots {
    .dot {
      background-color: #EBE5D5;

      &.active {
        width: 18px !important;
        height: 18px !important;
        background-color: #77CC4F;
      }

      &.sibling {
        width: 10px;
        height: 10px;
      }

      &.siblin-far {
        width: 8px;
        height: 8px;
      }
    }
  }

  .navigation-bar {
    position: absolute;
    @include absolute-center-vertical;

    &.prev {
      left: -60px;
    }

    &.next {
      right: -60px;
    }
  }

  .swiper-slide {
    overflow: unset !important;
    display: flex;
    justify-content: center;
  }

  .costs-container {
    & > * {
      margin: 0 14px 0 0 !important;
    }

    & > *:last-child {
      margin: 0 !important;
    }
  }


  .global-dialog {
    width: 1154px;
    height: 528px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  swiper {
    height: 100%;
  }

  .buildings-list-container {
    @include mikado;
    display: flex;
    justify-content: center;
    width: 362px !important;
    height: 430px !important;
    margin: 35px 12px 0;
    background-color: #EBE5D5;
    border: 3px solid #000000;
    box-sizing: border-box;
    padding: 20px 30px 0 30px;

    .c-window-b {
      display: flex;
      flex-direction: column;
      align-items: center;
      cursor: pointer;
      position: relative;

      .active-shadow {
        width: 100%;
        height: 100%;
        z-index: -1;
        box-shadow: 0 0 12px #b3ff6e;
        position: absolute;
        top: 0;
        opacity: 0;
        transition: all 0.3s;
      }

      &:hover {
        .active-shadow {
          opacity: 1;
        }
      }

      .icon-container {
        display: flex;
        align-items: flex-end;
        width: 300px;
        height: 300px;
        margin-bottom: 10px;

        img {
          width: 100%;
          object-fit: contain;
        }
      }

      .name {
        text-align: center;
        font-size: 20px;
        text-transform: unset;
        @include mikado;
        font-weight: 900;
        color: #000000;
        margin: 0 0 15px 0;
        display: flex;
        align-items: center;
      }

      .frame {
        border-radius: 12px;
        display: flex;
        font-size: 20;
        justify-content: center;
        align-items: center;
        width: 100%;
        color: #000000;
      }
    }
  }

  m-ui-group-count, .building-lock {
    transition: 0.3s;
    position: absolute;
    @include absolute-center-horizontal;
    top: 1px;
    opacity: 1;
  }

  .building-lock {
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 2px;
    color: #ffffff !important;
    -webkit-text-stroke: 1px #000000;

    i {
      font-size: 16px;
      margin-right: 8px;
    }

    span {
      font-size: 18px;
      text-transform: uppercase;
      font-weight: 900;
      @include mikado;
    }
  }
}
