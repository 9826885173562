@import "../variables";
@import "../mixins";

$colors: (
  $font-stroke: transparent,
  $border: (
    $normal: #000000,
    $hover: #000000,
  ),
  $background: (
    $normal: #77CC4F,
    $active: #62B23C,
    $hover: #8DF25E,
  ),
  $box-shadow: (
    $normal: #346E18,
    $active: #77CC4F,
    $hover: #346E18,
  ),
  $drop-shadow: (
    $normal: transparent,
    $hover: transparent,
  ),
  $font: (
    $normal: #000000,
    $active: #000000,
    $hover: #000000,
  )
);

&.c-primary {
  @include generate-schema-button-color($colors)
}
