@import "src/styles/variables/core/variables";
@import "src/styles/mixins";

app-custom-chest {
  .ow-dialog {
    @include mikado;
    padding: 30px 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .exit-bar {
      top: 36px;
      right: 10px;
    }
  }

  .message-image {
    width: 572px;
    height: 264px;
    margin-bottom: 20px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;

    &.default {
      background: var(--asset--ilustration-generic_chest-png);
    }

    &.chest-blue {
      background: var(--asset--ilustration-generic_chest_blue-png);
    }
  }

  .title {
    font-size: 22px;
    line-height: 30px;
    @include mikado;
    text-align: center;
    margin-bottom: 12px;
  }

  .description {
    margin: 0 0 30px;
    @include archivo-narrow;
    font-weight: normal;
    font-size: 16px;
    text-align: center;
    //padding: 0 220px;
    width: 700px;
  }

  .accept {
    margin-bottom: 16px;
  }
}
