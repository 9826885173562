@import "../../../../../../../../../styles/mixins";
@import "src/styles/functions";
@import "src/styles/mixins";

app-media {
  .ow-dialog {
    padding: calc(20px * var(--multiply)) calc(30px * var(--multiply));
    @include archivo-narrow;
  }

  .main-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }

  .media-container {
    .type-select {
      display: flex;
      margin-bottom: calc(15px * var(--multiply));

      ng-select {
        min-width: calc(150px * var(--multiply));
      }
    }

    .media-list {
      .media-row {
        display: flex;
        box-sizing: border-box;
        padding: calc(6px * var(--multiply)) 0 calc(9px * var(--multiply));
        border-top: calc(1px * var(--multiply)) solid black;
        background-color: white;
        position: relative;

        .left {

          .icon {
            height: calc(30px * var(--multiply));
            width: calc(30px * var(--multiply));
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
          }
        }

        .right {
          flex: 1 0 0;
          display: flex;
          margin-left: calc(8px * var(--multiply));

          .heading {
            flex: 1;

            .title {
              @include mikado;
              font-weight: bold;
              font-size:calc(10px * var(--multiply));
              line-height: calc(10px * var(--multiply));
              text-align: left;
              margin-bottom: calc(3px * var(--multiply));
              width: auto;

              a {
                color: #000000;
                text-decoration: none;
                text-underline: none;
                display: inline;
              }
            }

            .description {
              @include archivo-narrow;
              font-size: calc(10px * var(--multiply));
              line-height: calc(10px * var(--multiply));
              color: black;
            }
          }

          .terms {
            text-align: right;
            margin-left: calc(40px * var(--multiply));
            white-space: nowrap;
            line-height: calc(14px * var(--multiply));
            font-weight: bold;
            font-size: calc(7px * var(--multiply));
            position: absolute;
            right: 0;
            top: calc(4px * var(--multiply));
            color: black;
          }
        }
      }
    }
  }

  .ng-dropdown-wrapper {
    width: calc(220px * var(--multiply));
    padding: calc(4px * var(--multiply)) calc(8px * var(--multiply));
  }

  .pagination {
    display: flex;
    align-items: center;
    justify-content: center;

    button {
      display: inline-block;
      padding: calc(3px * var(--multiply)) calc(5px * var(--multiply));
      cursor: pointer;
      font-size: calc(16px * var(--multiply));
      margin: 0 calc(20px * var(--multiply));

      &.square {
        padding: 0 calc(4px * var(--multiply));
        width: calc(24px * var(--multiply));
        height: calc(24px * var(--multiply));
        display: block;

        i {
          font-size: calc(12px * var(--multiply));
          display: block;
        }
      }
    }

    .value {
      @include archivo-narrow;
      font-weight: 900;
      font-size: calc(16px * var(--multiply));
    }
  }

  @import "custom/style";
}
