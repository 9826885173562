@import "../variables";
@import "../mixins";

$colors: (
        $font-stroke: #4C0008,
        $border: (
                $normal: #4C0008,
                $hover: #4C0008,
        ),
        $background: (
                $normal: #E33932,
                $active: #A92822,
                $hover: #e5453e,
        ),
        $box-shadow: (
                $normal: #99231E,
                $active: #6f0808,
                $hover: #a42e29,
        ),
        $drop-shadow: (
                $normal: transparent,
                $hover: transparent,
        ),
        $font: (
                $normal: #ffffff,
                $active: #ffffff,
                $hover: #ffffff,
        )
);

&.c-danger {
  @include generate-schema-button-color($colors)
}
