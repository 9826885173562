@import "src/styles/functions";
@import "src/styles/mixins";
@import "src/styles/variables/core/variables";

m-ui-requirements {
  .texts-container {
    margin-top: multiply-size(2px);
    text-align: center;
    line-height: 0.1;

    .text {
      @include myriad;
      font-weight: bold;
      font-size: multiply-size(10px);
      text-transform: uppercase;
      margin: 0 multiply-size(2px);
      white-space: nowrap;
      display: inline-block;
    }
  }

  m-ui-costs {
    margin-top: multiply-size(8px);
    display: block;
  }
}
