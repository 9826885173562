@import "src/styles/mixins";

app-micro-league-max-prizes {
  .ow-dialog {
    max-width: 806px;
    background: #EBE5D5;
    border: 3px solid #000000;
    min-width: 806px;
    min-height: 620px;
    padding: 20px 72px;
    display: flex !important;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    m-ui-product {
      justify-self: start;

      .stock-j {
        flex-direction: row-reverse;
        gap: 4px;

        .value {
          font-size: 22px;
          @include mikado();
        }

        .icon {
          width: 50px;
          height: 50px;
        }
      }
    }

    m-ui-currency {
      justify-self: start;

      .stock-j {
        flex-direction: row-reverse;
        gap: 4px;

        .value {
          font-size: 22px;
          @include mikado();
        }

        .icon {
          width: 50px;
          height: 50px;
        }
      }
    }

    m-ui-close-button {
      position: absolute;
      top: 5px;
      right: -42px;
    }

    .title {
      margin: 32px 0 40px 0;
      font-size: 20px;
      @include mikado();
      font-weight: bold;
      text-align: center;
      }
    }
    .prizes-wrapper {
      display: flex;
      flex-direction: column;
      gap: 30px;

      .prizes {
        display: flex;
        gap: 20px;
        margin: 0;
        justify-content: center;
        align-self: flex-start;

        span {
          margin-right: 10px;
          @include mikado();
          font-weight: bold;
          display: flex;
          font-size: 20px;
          align-items: center;
        }
      }
    }

    button {
      &.c-base {
        margin-top: 20px;
      }
    }
  }
