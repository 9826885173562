@import "src/styles/mixins";

.lack {
  .icon {
    filter: none !important;
  }

  .value {
    color: #FF2929 !important;
  }

  .icon-lack {
    visibility: visible;
  }
}

.stock-card-details {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row-reverse;

  .icon {
    width: 20px;
    height: 20px;
  }

  .value {
    @include archivo-narrow();
    font-size: 14px;
    text-shadow: none !important;
    color: #000000;
    padding-top: 2px;
    margin-right: 3px;
  }
}

.stock-a,
.stock-d,
.stock-e,
.stock-g {
  .icon {
    width: 25px;
    height: 25px;

    .icon-lack {
      width: 13px;
      height: 23px;
      right: -2px;
      bottom: 1px;
      left: initial;
    }
  }
}

.stock-b,
.stock-c,
.stock-f,
.stock-i {
  .icon {
    width: 38px;
    height: 38px;
  }
}

.stock-a,
.stock-b,
.stock-c,
.stock-e,
.stock-j {
  .value {
    font-size: 10px;
  }
}

.stock-f,
.stock-i {
  .value {
    font-size: 14px;
  }
}

.stock-san-a {
  display: flex;
  align-items: center;
  flex-direction: column;

  .icon {
    width: 50px;
    height: 50px;
  }

  .value {
    @include mikado;
    font-size: 14px;
    text-shadow: none !important;
    color: #000000;
    padding-top: 4px;
  }
}

.stock-san-b {
  display: flex;
  align-items: center;
  flex-direction: column;

  .icon {
    width: 70px;
    height: 70px;
  }

  .value {
    @include mikado;
    font-size: 14px;
    text-shadow: none !important;
    color: #000000;
    padding-top: 5px;
  }
}

.stock-san-c {
  display: flex;
  align-items: center;
  flex-direction: column;

  &.increased-size {
    .value {
      font-size: 18px;
      line-height: 24px;
      font-weight: bold;
    }
  }

  .icon {
    width: 76px;
    height: 76px;
  }

  .value {
    @include mikado;
    font-size: 14px;
    text-shadow: none !important;
    color: #000000;
    padding-top: 4px;
  }
}

& {
  &.new, &.max {
    .icon {
      position: relative;

      &:after {
        position: absolute;
        bottom: 0;
        right: 0;
        background: #000000;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 2px 3px;
        @include mikado;
        font-size: 12px;
        font-weight: bold;
        color: white;
      }
    }
  }

  &.new {
    .icon {
      position: relative;

      &:after {
        content: 'nowe';
      }
    }
  }

  &.max {
    .icon {
      position: relative;

      &:after {
        content: 'max';
      }
    }
  }
}

.stock-layout-b, .stock-layout-d {
  .value {
    @include mikado;
    font-size: 20px;
    font-weight: 900;
    text-shadow: none;
    text-align: center;
    color: #000000;
  }
}

.stock-layout-b {
  align-items: center;
  display: flex;
  flex-direction: row;

  .icon {
    width: 29px;
    height: 29px;
    margin-right: 4px;
  }
}

.stock-layout-d {
  align-items: center;
  display: flex;
  flex-direction: row;

  .icon {
    width: 29px;
    height: 29px;
    margin-right: 4px;

    .icon-lack {
      width: 14px !important;
      height: 24px !important;
      top: -2px !important;
      right: -4px;
    }
  }
}

.stock-layout-e {
  align-items: center;
  display: flex;
  flex-direction: row;

  .icon {
    width: 40px;
    height: 40px;
    margin-right: 4px;

    //.icon-lack {
    //  width: 14px !important;
    //  height: 24px !important;
    //  top: -2px !important;
    //  right: -4px;
    //}
  }
}

.stock-production {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .icon {
    width: 40px;
    height: 40px;
  }


  .value {
    font-size: 16px;
    font-weight: 900;
    text-align: center;
  }
}

.stock-layout-c {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: center;

  .icon {
    width: 60px;
    height: 60px;
  }

  .value {
    font-size: 22px;
    font-weight: 900;
    text-align: center;
  }
}

.value {
  color: $color-H;
  font-weight: 700;
  @include myriad;
  white-space: nowrap;
  text-shadow: none;
}

.stock-layout-z {
  align-items: center;
  display: flex;
  flex-direction: row;

  .icon {
    width: 28px;
    height: 28px;
    margin-left: 4px;
  }
}

.stock-layout-exchange {
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;

  .icon {
    width: 76px;
    height: 76px;
    margin-bottom: 2px;
  }

  .value {
    font-size: 20px;
    font-weight: 900;
    text-align: center;
    @include mikado;
  }
}

.icon-lack {
  width: 16px;
  height: 32px;
  right: 3px;
  background: var(--asset--ui-notification_small-png);
  background-size: contain;
  background-repeat: no-repeat;

  &:before {
    content: '' !important;
  }
}
