#ow-logger {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  max-height: 50%;
  color: black;
  background: white;
  font-size: 12px;
  overflow: auto;
  font-family: "Lucida Console", Monaco, monospace;
  z-index: 10000;
  padding: 5px;
  display: none;
  user-select: text;
}

#ow-logger .ow-logger-date {
  font-weight: bold;
}

#ow-logger.show {
  display: block;
}
