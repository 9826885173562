@import "src/styles/functions";

m-ui-costs {
  .costs-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    & > * {
      margin: multiply-size(3px);
    }
  }

  @import "custom/style";
}

