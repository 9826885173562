@import "src/styles/mixins";

wf-rank {
  width: 100%;
  height: 100%;

  table {
    @include archivo-narrow;
    border-collapse: collapse;
    font-size: 20px;
    width: 100%;

    tbody {
      tr:nth-child(odd) {
        background-color: #F9F4E4;
      }

      td {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: inherit;
        font-weight: bold;

        img {
          width: 38px;
          height: 38px;
        }
      }
    }

    tr {
      height: 60px;
    }

    td {
      cursor: pointer;
      position: relative;

      &.max-width {
        width: 100%;
      }

      &:first-child {
        padding-left: 15px;
      }

      &:last-child {
        padding-right: 15px;
      }
    }
  }

  .filters {
    display: flex;
    align-items: center;
    margin: 16px 0 16px 14px;
    box-sizing: border-box;

    .ow-radio-container {
      display: flex;
      align-items: center;

      &:not(:last-child) {
        margin-right: 20px;
      }

      .text-wrapper {
        @include mikado();
        font-weight: 900;
        font-size: 14px;
      }
    }
  }
}
