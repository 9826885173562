@import "../../../../../../../../../../../styles/variables/core/variables";
@import "../../../../../../../../../../../styles/mixins";


app-rank-business-base-cc {
  @import "../../styles/style";

  .content {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    overflow: hidden;

    .frame {
      padding-right: 24px;
      width: 100%;
      height: 384px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
    }
  }

  .top {
    display: flex;
    margin-bottom: 16px;
    padding-left: 10px;

    .left {
      width: 78%;

      p {
        margin: 0;
        font-size: 16px;
        max-height: 24px;
        @include archivo-narrow;

        &.sub-title {
          font-weight: 900;
          margin-bottom: 10px;
        }

        &.description {
          @include roboto-cond;
          font-weight: 400;
        }
      }
    }

    .right {
      flex: 1;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-right: 40px;

      .default-field {
        @include mikado;
        margin: 0;

        input {
          text-align: center;
          font-weight: normal;
          font-size: 15px;
          width: 210px;
          height: 36px;

          &:focus + label {
            background-color: #C6F4FF;
          }
        }
      }
    }
  }

  .box {
    display: flex;
    justify-content: space-between;
  }

  .filters {
    display: flex;
    align-items: center;
    margin: 16px 0 16px 14px;
    box-sizing: border-box;
  }

  .ow-radio-container {
    display: flex;
    align-items: center;

    &:not(:last-child) {
      margin-right: 20px;
    }

    .text-wrapper {
      @include mikado;
      font-weight: 900;
      font-size: 14px;
      text-transform: uppercase;
    }
  }

  .show-position {
    text-decoration: underline;
    font-size: 14px;
    font-weight: 900;
    display: flex;
    align-items: center;
    padding-right: 14px;

    a {
      color: #000000;
    }
  }

  table {
    @include roboto-cond;
    border-collapse: collapse;
    font-size: 14px;
    width: 100%;

    &.first-three-places {
      border-bottom: 2px solid #F4EBCD;
      width: calc(100% - 6px);

      tbody {
        tr {
          border-bottom: 1px solid #F4EBCD;
          background-color: #F8F2DE;
        }
      }
    }

    &.custom {
      tbody {
        tr {
          &:nth-child(even) {
            background-color: #F9F4E4;
          }
        }
      }
    }

    tr {
      height: 38px;
    }

    td {
      font-weight: bold;
      position: relative;

      &.td-position {
        min-width: 38px;
      }

      &.td-name {
        width: 100%;
        text-align: left;
      }

      &.td-value {
        min-width: 86px;
        white-space: nowrap;

        img {
          margin-right: 2px;
          margin-left: 6px;
        }
      }

      &.td-min-valid {
        min-width: 120px;
        padding-left: 6px;
        padding-right: 10px;
      }

      &.td-prize-1 {
        min-width: 70px;
        @include text-ellipsis;
      }

      &.td-prize-2 {
        min-width: 65px;
        @include text-ellipsis;
      }

      &.td-prize-3 {
        min-width: 54px;
        @include text-ellipsis;
      }

      &.td-prize-4 {
        min-width: 70px;
        @include text-ellipsis;
      }

      &.td-prize-1,
      &.td-prize-2,
      &.td-prize-3,
      &.td-prize-4 {
        padding-left: 4px;
      }

      &.max-width {
        width: 100%;
      }

      &:first-child {
        @include mikado;
        font-weight: 900;
        padding-left: 15px;
      }

      &:last-child {
        padding-right: 6px;
      }

      img {
        margin-right: 8px;
        vertical-align: bottom;
        width: 17px;
        height: 18px;
      }
    }
  }
}
