@import "src/styles/mixins";


.wf-piece-pairing-container {
  width: 400px;
  min-height: 400px;
  border: 3px solid black;
  background-color: #EBE5D5;
  display: flex;
  justify-content: center;
  align-items: center;

  m-ui-close-button {
    position: absolute;
    right: -43px;
    top: 15px;
  }

  .content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 40px;
    box-sizing: border-box;

    &.blocked {
      img {
        &:hover {
          filter: brightness(1);
          cursor: unset;
        }
      }
    }

    img {
      display: block;
      margin-bottom: 44px;

      &:hover {
        cursor: pointer;
        filter: brightness(1.1);
      }
    }

    button {
      padding: 0 22px 4px;
      width: auto;
      font-size: 18px;
      line-height: 24px;
      text-transform: none;
      font-weight: bold;
    }
  }
}
