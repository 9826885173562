@import "../variables";
@import "../mixins";

$colors: (
  $font-stroke: #114c00,
  $border: #144b00,
  $background: (
    $normal: #94d312,
    $active: #84ba16,
  ),
  $box-shadow: (
    $normal: #3E7103,
    $active: #27860e,
  ),
  $font: (
    $normal: #ffffff,
    $active: #b9dbb5,
  )
);

&.secondary {
  @include generate-schema-button-color($colors)
}
