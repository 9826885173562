app-san-player-choose {
  @import "../../../styles/custom/style.scss";

  .a-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .close-button {
    position: relative;

    .exit-bar {
      position: absolute;
      right: -50px;
      top: 26px;
    }
  }

  .auth {
    font-size: 16px;
    color: #000;
  }

  .inner {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
}
