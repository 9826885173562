@import "src/styles/variables/core/variables";
@import "src/styles/mixins";

app-message-details-chest-failed {
  .ow-dialog {
    padding: 30px 114px 54px 114px;
    display: flex;
    align-items: center;
    justify-content: center;

    m-ui-close-button {
      .exit-bar {
        top: 36px !important;
        right: 10px !important;
      }
    }
  }

  .chest-success-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .message-chest-image {
      display: flex;
      justify-content: center;
      margin-bottom: 26px;

      img {
        max-width: 100%;
        object-fit: contain;
      }
    }

    .teaser {
      @include mikado;
      font-size: 24px;
      font-weight: 900;
      margin-bottom: 8px;
    }

    .inner-html-description {
      color: #000000;
      margin: 10px 0 24px 0;
      @include archivo-narrow;
      font-weight: normal;
      font-size: 18px;
      text-align: center;
      padding: 0 100px;
    }

    .parameters {
      display: flex;
      justify-content: center;

      m-ui-product, m-ui-currency {
        margin: 0 10px;
      }
    }
  }

  button {
    position: absolute;
    bottom: 12px;
    right: 12px;
    border: none !important;
  }

  .stock-production {
    flex-direction: unset !important;

    .icon {
      margin-right: 8px;
    }
  }

  .trash {
    position: absolute;
    bottom: 28px;
    right: 70px;
    border: none;

    i {
      font-size: 16px;
      position: unset !important;
    }
  }
}
