app-new-edition {
  .new-edition-background {
    width: 100vw;
    height: 100vh;
    background-image: var(--asset--ui-background_2-png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;

    .edenred {
      width: 50px;
      height: 50px;
      background: var(--asset--ui-menu-prepaid-png);
      background-size: contain;
      position: absolute;
      top: 66px;
      right: 150px;
      cursor: pointer;
      background-repeat: no-repeat;
      background-position: center;
    }

    .logout {
      position: absolute;
      top: 75px;
      right: 75px;
    }
  }
}
