@import "src/styles/functions";
@import "src/styles/variables/core/variables";
@import "src/styles/mixins";

m-building-allow-production {
  @import "variables";

  .production-container-allow {
    width: 100%;
    position: relative;
    z-index: 0;

    .allow-production-title {
      font-size: multiply-size(11px);
      font-weight: bold;
      margin: 0 0 multiply-size(8px) 0;
      @include archivo-narrow;
    }

    .production {
      display: flex;
      pointer-events: none;

      .product-container {
        pointer-events: all;
        margin-right: multiply-size(17px);
        display: flex;
        position: relative;

        m-ui-stock-other,
        m-ui-currency,
        m-ui-product,
        m-ui-parameter {
          margin-top: 0;
        }

        & > span {
          cursor: pointer;
        }

        .bullet {
          position: absolute;
          background: $bgc-tooltip;
          width: multiply-size(11px);
          height: multiply-size(6px);
          border: multiply-size(1px) solid $border-color;
          border-top: none;
          border-bottom-left-radius: multiply-size(90px);
          border-bottom-right-radius: multiply-size(90px);
          box-sizing: border-box;
          top: multiply-size(-9px);
          left: multiply-size(7px);
        }

        m-ui-product {
          pointer-events: none;
        }
      }
    }

    .tooltip-production {
      position: absolute;
      border: multiply-size(1px) solid $border-color;
      background: $bgc-tooltip;
      box-sizing: border-box;
      border-radius: multiply-size(8px);
      padding: multiply-size(12px) multiply-size(6px) multiply-size(6px) multiply-size(6px);
      bottom: multiply-size(35px);
      left: multiply-size(-25px);
      right: 0;
      height: multiply-size(64px);
      z-index: 0;
    }

    .background-tooltip-hide {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
      pointer-events: none;

      &.active {
        pointer-events: all;
      }
    }
  }

  .details-container {
    margin-bottom: 0;
  }

  @import "custom/style";
}
