&.c-square {
  border: 2px solid;

  &.small {
    width: 40px;
    height: 40px;
  }

  &.x-small {
    width: 32px;
    height: 32px;
  }
}
