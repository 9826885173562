@import "src/styles/mixins";

.ow-dialog {
  padding: 32px 115px 106px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  color: #000000;

  .top-info {
    position: absolute;
    top: -13px;
    right: 90px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    z-index: 10;

    m-ui-group-count {
      box-sizing: border-box;
      border: 3px solid black;
      padding: 0 10px;
    }

    .experience {
      margin-right: 31px;
      position: relative;

      img {
        @include absolute-center-vertical;
        right: -20px;
        width: 40px;
        height: 40px;
        object-fit: contain;
        z-index: 2;
      }

      .inner-wrapper {
        min-width: 60px;
        box-sizing: border-box;
        border: 3px solid black;
        height: 30px;
        padding: 4px 25px 3px 8px;
        background-color: #eae4d4;

        p {
          text-align: right;
          font-size: 16px;
          line-height: 21px;
          margin: 0;
          color: black;
          @include mikado;
        }
      }
    }
  }

  .description {
    @include archivo-narrow;
    font-size: 16px;
    line-height: 18px;
  }

  .top {
    width: 100%;
    display: flex;

    .icon-container {
      min-width: 300px;
      width: 300px;
      height: 300px;
      position: relative;

      img {
        width: 100%;
      }

      .levels-container {
        position: absolute;
        bottom: 0;
        left: 42px;
      }
    }

    .right-column {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      margin-left: 35px;
      width: 100%;

      .elements {
        display: flex;
        align-items: flex-start;
        margin-top: 24px;
        width: 100%;
        overflow: hidden;

        &.not-show {
          .min-title {
            margin-right: 0 !important;
            border: none !important;
          }

          .box {
            display: flex;
            & > * {
              &:not(:last-child) {
                margin-right: 0 !important;
                border: none !important;
              }
            }
          }
        }


        & > * {
          height: 100%;

          &:not(:last-child) {
            border-right: 1px solid #9c9583;
            padding-right: 25px;
            margin-right: 25px;
          }
        }
      }
    }
  }

  .bottom {
    margin-top: 26px;
    width: 100%;
  }

  .min-title {
    position: absolute;
    top: 0;
    left: 0;
    @include archivo-narrow;
    font-size: 12px;
    white-space: nowrap;
  }

  .levels-container {
    display: flex;
    align-items: center;
    @include mikado;
    font-weight: bold;

    .lvl-box {
      background-color: #EBE5D5;
      border: 3px solid black;
      height: 30px;
      box-sizing: border-box;
      width: 103px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-left: 8px;

      span {
        font-size: 16px;
        padding-top: 3px;
      }

      .level {
        width: 30px;
        height: 24px;
      }
    }

    .level {
      background: #000000;
      font-size: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #ffffff;
      box-sizing: border-box;
      margin: 0;

      &.next {
        margin-left: 16px;
        color: #A6EB1D;
        position: relative;
        width: 36px;
        height: 30px;

        &:before {
          content: '';
          position: absolute;
          background: var(--asset--ui-arrow-png);
          width: 32px;
          height: 30px;
          @include absolute-center-vertical;
          left: -24px;
        }
      }
    }
  }

  .buttons {
    margin: 0 auto;
    display: flex;
    justify-content: center;
    @include absolute-center-horizontal;
    bottom: 45px;

    button {
      margin: 0 10px;
    }
  }

  .parameters-outcome {
    margin-top: 40px;
    display: flex;
    justify-content: center;
    align-items: center;

    & > * {
      margin: 0 5px;
    }
  }

  .other-container-diff {
    .building-upgrade-diff-parameter {
      .upgrade-container {
        .upgrade-label > span {
          padding-top: 4px !important;

        }
      }
    }
  }
}
