.icon-league {
  background-repeat: no-repeat;
  background-size: contain;

  &.group {
    &.big {
      width: 40px;
      height: 40px;

      &.aqua, &.icon-1 {
        background-image: var(--asset--league-basic-agua-png);
      }

      &.fuego, &.icon-2 {
        background-image: var(--asset--league-basic-fuego-png);
      }

      &.aire, &.icon-3 {
        background-image: var(--asset--league-basic-aire-png);
      }

      &.tierra, &.icon-4 {
        background-image: var(--asset--league-basic-tierra-png);
      }
    }

    &.small {
      width: 28px;
      height: 28px;

      &.aqua, &.icon-1 {
        background-image: var(--asset--league-small-agua-png);
      }

      &.fuego, &.icon-2 {
        background-image: var(--asset--league-small-fuego-png);
      }

      &.aire, &.icon-3 {
        background-image: var(--asset--league-small-aire-png);
      }

      &.tierra, &.icon-4 {
        background-image: var(--asset--league-small-tierra-png);
      }
    }
  }

  &.league-1 {
    background-image: var(--asset--league-basic-league_silver-png);
  }

  &.league-2 {
    background-image: var(--asset--league-basic-league_gold-png);
  }

  &.league-3 {
    background-image: var(--asset--league-basic-league_diamond-png);
  }

  &.league-4 {
    background-image: var(--asset--league-basic-league_brilliance-png);
  }

  &.league-5 {
    background-image: var(--asset--league-basic-league_pearl-png);
  }

  &.logo {
    background-image: var(--asset--league-basic-logo-png);
  }
}
