@import "src/styles/mixins";

@mixin material-title {
  margin: 0 0 8px;
  font-size: 12px;
  line-height: 14px;
  @include archivo-narrow;
}

@mixin material-box {
  padding-right: 20px;

  &:last-child {
    padding-right: 0;
    padding-left: 20px;
  }

  .title {
    @include material-title;
  }

  .wrapper {
    display: flex;
    gap: 12px;
  }
}
