// ****
// New Colors

$color-A: #fff02f;
$color-B: #a7ec1d;
$color-C: #fbbb2b;
$color-D: #22daec;
$color-E: #3dd00d;
$color-F: #d0d0d0;
$color-G: #e2001a;
$color-H: #000000;
$color-I: #ffffff;
$color-J: #FECD3D;
$color-K: #003366;
$color-L: #6699CC;
$color-M: #336699;
$color-N: #A4C0DB;
$color-O: #173b23;
$color-P: #d7d6d6;
$color-R: #F50705;
$color-S: #94d312;
$color-T: #eeeae4;
$color-U: #d5d9df;
$color-W: #6AFBFC;


// COLORS
$color-base: #000000;
$color-primary: #000000;
$color-secondary: #a7ec1d;
$color-tertiary: #173b23;
$color-quinary: #fff02f;
$color-senary: #000000;
$color-septenary: #eeeae4;
$color-octonary: #e2001a;
$color-nonary: #000000;
$color-denary: #ffffff;
$color-duodenary: #696969;
$color-vigenary: #004400;

// next colors -> duodenary, vigenary

// HOST CONTEXT
$context-desktop: 'body.desktop';
$context-mobile: 'body.mobile';
$context-tablet: 'body.tablet';

// COLORS BUTTONS
$color-button-base-primary: #5c4500;
$color-button-base-primary-h: #bf8f01;
$color-button-base-primary-d: #bda252;

$color-button-base-secondary: #314501;
$color-button-base-secondary-h: #7bae01;

$color-button-base-tertiary: #04453d;
$color-button-base-tertiary-h: #1a9687;

$color-button-base-quinary: #04453d;
$color-button-base-quinary-h: #0f8274;

$color-button-base-octonary: #610a0a;
$color-button-base-octonary-h: #d74040;

$color-yellow-accent: #d1b55a;

$color-text-green: #7eb204;

// MOBILE DIALOG
$color-dialog-mobile-primary: #ffcd33;
$color-dialog-mobile-secondary: #acdb40;
$color-dialog-mobile-tertiary: #7eccc3;
$color-dialog-mobile-quinary: #3e99a3;

$font-futura-medium: 'Futura Medium';
$font-futura-heavy: 'Futura Heavy';
$font-conthrax: 'Conthrax';
$color-error: #dd5551;

@import "../custom/variables";
