@import "src/styles/mixins";

app-san-hud-resources {
  .cash-container {
    position: absolute;
    left: 10px;
    top: 125px;

    .position-relative {
      position: relative;
    }

    .cash-block {
      width: 90px;
      height: 26px;
      background: #0E1107B3;
      border: 2px solid #000000;
      border-radius: 20px;
      margin-bottom: 22px;
      position: relative;
      box-sizing: border-box;
      cursor: pointer;
      transform: translateX(20px);

      &.initial-cursor {
        cursor: initial !important;
      }

      &.shop-btn {
        width: 105px;
        padding-right: 14px;
      }

      .icon {
        width: 38px;
        height: 40px;
        position: absolute;
        left: -21px;
        @include absolute-center-vertical;
      }

      .number {
        @include mikado;
        font-weight: bold;
        color: #fff;
        font-size: 14px;
        width: 66px;
        margin-left: auto;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: right;
        padding-right: 12px;
        letter-spacing: initial;
      }
    }

    button {
      &.currency-icon {
        position: absolute;
        width: 28px;
        height: 28px;
        top: -3px;
        right: -8px;
        box-shadow: inset 0 -2px 0 0 #346E18 !important;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 !important;
        i {
          font-size: 12px;
        }
      }
    }
  }

  .fw-normal {
    font-weight: normal;
  }
}
