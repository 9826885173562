@import "src/styles/functions";
@import "src/styles/variables/core/variables";

&.window-b {
  width: multiply-size(150px);
  height: multiply-size(140px);
  margin: multiply-size(10px);
  border-radius: multiply-size(10px);
  box-shadow: 0 0 0 multiply-size(2px) $color-H;
  z-index: 0;
}
