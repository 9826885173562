@import "src/styles/variables/core/variables";
@import "src/styles/mixins";

app-custom-mission-details {
  @import "../styles/styles";

  $font-color: #000000;

  perfect-scrollbar {
    width: calc(100% + 10px) !important;

    .ps-content {
      padding-right: 17px;
      text-align: justify;
    }

    &.scroll-indicator::after {
      background: linear-gradient(0deg, #dbff92 0%, rgba(230, 255, 255, 0.354762) 100%);
    }
  }

  .ow-dialog {
    padding: 38px 80px 40px 96px;
    position: relative;
    display: flex;
    flex-direction: column;
    @include archivo-narrow;

    .icon-mission {
      width: 64px;
      height: 64px;
      margin: 0 5px 5px 0;
      position: absolute;
      top: -33px;
      left: 53px;

      img {
        width: 100%;
      }
    }

    .timer {
      @include mikado;
      font-size: 18px;
      position: absolute;
      top: 45px;
      right: 100px;
      font-weight: 900;
    }

    .mission-title {
      @include mikado;
      font-weight: bold;
      font-size: 20px;
      margin-bottom: 9px;
      text-transform: unset;
    }

    .inner-html-description {
      text-align: left;
      font-size: 16px;
      line-height: 22px;
      color: $font-color;
      min-height: 88px;
    }

    // Mission prizes
    .mission-prizes {
      margin-top: 12px;
      margin-bottom: 32px;
      display: flex;

      & > * {
        margin-right: 20px;

        &:last-child {
          margin-right: 0;
        }
      }
    }

    // Image

    .mark-window-left {
      position: absolute;
      right: 0;
      top: 72px;
      transform: translateX(127px);
      z-index: 1;
      pointer-events: none;
    }

    .frame {
      flex: 1;
      padding: 10px 88px 10px 4px;
      border-radius: 15px;
      overflow: hidden;

      .condition-title {
        @include mikado;
        width: 100%;
        text-align: left;
        font-weight: 900;
        color: $font-color;
        text-transform: uppercase;
        font-size: 14px;
        margin: 4px 0 10px 63px;
      }
    }

    .bottom-buttons {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 18px;

      .bar-button {
        margin: 0 10px;
      }
    }

    .bar-button {
      button {
        text-transform: capitalize !important;
        font-weight: 900 !important;
        @include mikado;
      }
    }
  }

  .conditions {
    tr {
      height: 38px;

      &:nth-child(odd) {
        background-color: #F9F4E4 !important;
      }

      td {
        border: none !important;

        &.td-name {
          @include archivo-narrow;
          font-weight: bold;
        }

        &.td-condition {
          @include mikado;
          font-weight: bold;
        }

        &.td-transfer > button {
          @include mikado;
          text-transform: capitalize;
        }
      }
    }
  }

  // GLOBAL DIALOG -> IF MISSION CONTRACT
  .width-max {
    width: 1282px;
  }

  // DESCRIPTION -> IF MISSION CONTRACT
  .padding-right {
    padding: 0 58px 0 15px !important;
  }
}
