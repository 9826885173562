@import "src/styles/functions";
@import "src/styles/variables/core/variables";

&.window-c {
  width: multiply-size(150px);
  height: multiply-size(190px);
  margin: multiply-size(21px) multiply-size(10px) multiply-size(10px) multiply-size(10px);
  padding: multiply-size(16px) multiply-size(10px) multiply-size(7px) multiply-size(10px);
  border-radius: multiply-size(10px);
  box-shadow: 0 0 0 multiply-size(2px) $color-H;
  z-index: 0;
}
