@import "@ng-select/ng-select/themes/default.theme.css";

.ng-select {
  display: block;
  position: relative;
  @include archivo-narrow;
}

.ng-select, .ng-select div, .ng-select input, .ng-select span {
  box-sizing: border-box
}

.ng-select [hidden] {
  display: none
}

.ng-select.ng-select-searchable .ng-select-container .ng-value-container .ng-input {
  opacity: 1
}

.ng-select.ng-select-opened .ng-select-container {
  z-index: 1001;

  background-color: #F9F2E0;
  border-color: black black transparent;
}

.ng-select.ng-select-disabled .ng-select-container .ng-value-container .ng-placeholder, .ng-select.ng-select-disabled .ng-select-container .ng-value-container .ng-value {
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  cursor: default;
  user-select: none
}

.ng-select.ng-select-disabled .ng-arrow-wrapper {
  display: none;
}

.ng-select.ng-select-disabled .ng-value-container {
  padding-left: 0;
}

.ng-select.ng-select-disabled .ng-value {
  width: 100%;
  text-align: center;
}

.ng-select.ng-select-filtered .ng-placeholder {
  display: none
}

.ng-select .ng-select-container {
  cursor: default;
  display: flex;
  outline: none;
  overflow: hidden;
  position: relative;
  width: 100%;

  background-color: #F9F2E0;
  padding: 4px 8px;
  color: black;
  border: 2px solid black;
  border-radius: 0;
}

.ng-select .ng-select-container .ng-value-container {
  display: flex;
  flex: 1
}

.ng-select .ng-select-container .ng-value-container .ng-input {
  opacity: 0
}

.ng-select .ng-select-container .ng-value-container .ng-input > input {
  background: none transparent;
  border: 0;
  box-shadow: none;
  box-sizing: content-box;
  cursor: default;
  outline: none;
  padding: 0;
  width: 100%
}

.ng-select .ng-select-container .ng-value-container .ng-input > input::-ms-clear {
  display: none
}

.ng-select .ng-select-container .ng-value-container .ng-input > input[readonly] {
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  padding: 0;
  user-select: none;
  width: 0
}

.ng-select.ng-select-single.ng-select-filtered .ng-select-container .ng-value-container .ng-value {
  visibility: hidden
}

.ng-select.ng-select-single .ng-select-container .ng-value-container, .ng-select.ng-select-single .ng-select-container .ng-value-container .ng-value {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  font-weight: 700;
}

.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-value .ng-value-icon {
  display: none
}

.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
  left: 0;
  position: absolute;
  width: 100%
}

.ng-select.ng-select-multiple.ng-select-disabled > .ng-select-container .ng-value-container .ng-value .ng-value-icon {
  display: none
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container {
  flex-wrap: wrap
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder {
  position: absolute
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
  white-space: nowrap
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value.ng-value-disabled .ng-value-icon {
  display: none
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon {
  cursor: pointer
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-input {
  flex: 1;
  z-index: 2
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder {
  z-index: 1
}

.ng-select .ng-clear-wrapper {
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  cursor: pointer;
  position: relative;
  user-select: none;
  width: 17px
}

.ng-select.ng-select-focused:not(.ng-select-opened) > .ng-select-container {
  border-color: unset;
  box-shadow: unset;

}

.ng-select .ng-clear-wrapper .ng-clear {
  display: inline-block;
  font-size: 18px;
  line-height: 1;
  pointer-events: none
}

.ng-select .ng-spinner-loader {
  -webkit-animation: load8 .8s linear infinite;
  animation: load8 .8s linear infinite;
  border: 2px solid rgba(66, 66, 66, .2);
  border-left-color: #424242;
  border-radius: 50%;
  font-size: 10px;
  height: 17px;
  margin-right: 5px;
  position: relative;
  text-indent: -9999em;
  transform: translateZ(0);
  width: 17px
}

.ng-select .ng-spinner-loader:after {
  border-radius: 50%;
  height: 17px;
  width: 17px
}

@-webkit-keyframes load8 {
  0% {
    transform: rotate(0deg)
  }
  to {
    transform: rotate(1turn)
  }
}

@keyframes load8 {
  0% {
    transform: rotate(0deg)
  }
  to {
    transform: rotate(1turn)
  }
}

.ng-select .ng-arrow-wrapper {
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  cursor: pointer;
  position: relative;
  text-align: center;
  user-select: none;
}

.ng-select .ng-arrow-wrapper .ng-arrow {
  display: inline-block;
  height: 0;
  pointer-events: none;
  position: relative;
  width: 0;
  background-color: #F9F2E0;
}

.ng-dropdown-panel {
  -webkit-overflow-scrolling: touch;
  box-sizing: border-box;
  opacity: 0;
  position: absolute;
  width: 100%;
  z-index: 1050;
  background-color: #F9F2E0;
}

.ng-dropdown-panel.ng-select-bottom {
  overflow: hidden;
  border-top: unset;
  border-width: 2px;
  border-color: black;
}

.ng-dropdown-panel .ng-dropdown-panel-items {
  box-sizing: border-box;
  display: block;
  height: auto;
  max-height: 240px;
  overflow-y: auto
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup, .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  box-sizing: border-box;
  cursor: pointer;
  display: block;

  background-color: #F9F2E0;
  padding-left: 19px;
  &.ng-option-marked,
  &:hover {
    background: #eee6d7;
  }

  &.ng-option-selected.ng-option-marked,
  &.ng-option-selected {
    background-color: #eee6d7;

    .ng-option-label {
      font-weight: normal;
    }
  }


}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option .highlighted {
  font-weight: 700;
  text-decoration: underline
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.disabled {
  cursor: default
}

.ng-dropdown-panel .scroll-host {
  -webkit-overflow-scrolling: touch;
  display: block;
  overflow: hidden;
  overflow-y: auto;
  position: relative
}

.ng-dropdown-panel .scrollable-content {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%
}

.ng-dropdown-panel .total-padding {
  opacity: 0;
  width: 1px
}

ng-select {
  &.ng-touched.ng-invalid {
    .ng-select-container {
      border-color: #dc3545 !important;
    }
  }
}
