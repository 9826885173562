@import "src/styles/mixins";

.tooltip {
  .tooltip-inner {
    font-weight: bold;
    @include archivo-narrow;
  }

  &.hud {
    .arrow {
      display: none;
    }

    .tooltip-inner {
      font-weight: 900;
      @include mikado;
      padding: 5px 12px;
      font-size: 12px;
    }

    &.hud-parameters {
      transform: translateY(22px);
    }
  }

  &.rank {
    .tooltip-inner {
      max-width: 300px;
    }
  }
}

.mdc-tooltip {
  padding-top: 6px;
  padding-bottom: 6px;

  &.hud {
    font-weight: 900 !important;
    @include mikado('!important');
    padding: 5px 12px !important;
    font-size: 12px !important;
    margin: 7px !important;
  }

  &.delayed {
    animation: delayedFadeIn 0.4s;
  }
}
