@import "src/styles/functions";
@import "src/styles/variables/core/variables";
@import "src/styles/mixins";

app-m-building-build {
  .window-a {
    padding: multiply-size(30px) multiply-size(38px) multiply-size(22px) multiply-size(38px);
  }

  .container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 5%;
  }

  .details-container {
    height: 100%;
    flex-direction: column;
  }

  .swiper {
    width: 100%;
    height: 100%;
  }

  .building-details-container {
    display: flex;
    align-items: center;

    .building-icon {
      width: multiply-size(100px);
      min-width: multiply-size(100px);
      position: relative;
      margin-right: multiply-size(25px);

      img {
        width: 100%;
        object-fit: contain;
      }

      m-ui-exp-up {
        @include absolute-center-horizontal;
        bottom: 0;
      }
    }

    .building-desc {
      font-size: multiply-size(12px);
      line-height: multiply-size(12px);
      max-height: multiply-size(38px);
      height: multiply-size(38px);
      @include myriad-cond;
    }
  }

  m-ui-stock-other,
  m-ui-currency,
  m-ui-product,
  m-ui-parameter {
    margin-top: multiply-size(10px);
    display: flex;
  }

  .title-small {
    font-size: multiply-size(11px);
    font-weight: bold;
    margin: 0 0 multiply-size(8px) 0;
    @include archivo-narrow;
  }

  .unlocked-buildings {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    m-building-thumbnail {
      margin-right: multiply-size(10px);

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .parameters-slide {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .parameters {
    display: flex;

    m-ui-stock-other,
    m-ui-currency,
    m-ui-product,
    m-ui-parameter {
      margin-right: multiply-size(20px);

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .arrows-slider {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    .arrow-blue-left,
    .arrow-blue-right {
      cursor: pointer;
      width: multiply-size(20px);
      height: multiply-size(20px);
      background-size: contain;
      background-repeat: no-repeat;

      &.hide {
        visibility: hidden;
      }
    }

    .arrow-blue-left {
      background-image: var(--asset--ui-arow-blue-left-png);
    }

    .arrow-blue-right {
      background-image: var(--asset--ui-arow-blue-right-png);
    }
  }

  .costs-container {
    justify-content: center;
  }

  m-ui-requirements {
    flex-direction: column;
  }

  .tooltip-production > m-ui-close-button > .exit-bar {
    top: multiply-size(-3px) !important;
    right: multiply-size(-8px) !important;
  }

  .hide {
    opacity: 0;
    pointer-events: none;
  }

  @import "custom/style";
}
