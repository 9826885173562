@import "src/styles/mixins";

app-quality-tasks-cc {

  m-ui-currency {
    .stock-j {
      align-items: center;
      justify-content: center;
      gap: 4px;

      .icon {
        width: 28px;
        height: 28px;
      }
      .value {
        @include archivo-narrow();
        font-size: 18px;
        font-weight: 700;
      }
    }
  }

  .c-window-long {
    padding: 48px 83px 60px 103px;
    display: flex;
    flex-flow: column;

    .bookmarks {
      .right {
        right: 72px;
        bottom: -3px;
        position: absolute;
        display: flex;
        gap: 30px;

        .source-data-date-tab {
          position: relative;
          display: flex;
          right: 0;
          bottom: 0;

          &.pagination {
            width: 261px;
            background: #EBE5D5;
            padding: 0 14px;
          }
        }
      }
    }

    .frame {
      flex: 1 0 auto;
      height: 100%;

      .description {
        @include archivo-narrow();
        font-size: 16px;
      }

      table {
        width: 100%;
        border-spacing: 4px;
        border-collapse: separate;
        margin-bottom: 0;

        td, th {
          padding: 0;
          min-width: 200px;

          &:first-child {
            width: 586px;
            text-align: left;
            padding: 0 39px;
          }

          &.score {
            min-width: 162px;
          }
        }

        thead {
          th {
            font-size: 12px;
            font-weight: normal;
            @include archivo-narrow();
            text-align: center;
            white-space: nowrap;
            padding-top: 4px;
            padding-bottom: 4px;
          }
        }

        tbody {
          tr {
            height: 38px;

            &:nth-child(odd) {
              background: #F9F4E4;
            }

            td {
              font-size: 18px;
              font-weight: bold;
              @include archivo-narrow();
              text-align: center;
            }
          }
        }

        &.static {
          td, th {
            padding: 0;
            width: 245px;

            &:first-child {
              width: 662px;
              text-align: left;
              padding: 0 39px;
            }
          }

          tbody {
            tr {
              td {
                font-size: 14px;
              }
            }
          }

          margin: 5px 0 10px;
        }
      }
    }
  }

  .mark-window-left {
    position: absolute;
    left: -8px;
    top: 160px;
    z-index: 1;
    width: 55px;
    height: 55px;
    border: 3px solid #000000;
    background-color: #EBE5D5;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  m-ui-separator {
    margin: 0;

    .separator {
      padding-left: 20px;

      span {
        color: #848670;
        font-size: 16px;

        &.padding {
          padding: 0 12px !important;
        }

        &:before, &:after {
          background: #848670;
        }

        &:after {
          width: 1056px;
        }
      }
    }
  }

  .no-data {
    font-size: 16px;
    @include mikado;
    font-weight: bold;
  }

  .navigation {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: auto;

    .nav-arrow {
      text-shadow: none !important;
      color: #000000;
      font-size: 20px;
    }

    .value {
      @include archivo-narrow;
      font-size: 16px;
      font-weight: bold;
      margin: 0 20px;
      width: 197px;
      text-align: center;
    }
  }
}
