@import "src/styles/mixins";
@import "src/styles/variables/core/variables";

event-shop-details {
  .ow-dialog {
    padding: 30px 69px 60px 105px;
    display: flex;
    position: relative;
  }

  .cursor-pointer {
    cursor: pointer;
  }

  .dates {
    padding-top: 4px;
  }

  .shop-details-info {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: -21px;
    right: 84px;
    background-color: #EBE5D5;
    border: 3px solid #000000;
    min-width: 433px;
    height: 46px;
    box-sizing: border-box;

    .item {
      display: flex;
      align-items: center;
      margin: 0 9px;
      position: relative;
      font-size: 18px;
      @include mikado;

      .icon-margin {
        margin-right: 15px;
      }

      .icon {
        width: 30px;
        height: 30px;
        margin-right: 4px;
      }

      .icon-container {
        display: flex;
        position: relative;

        .not-repeatable {
          position: absolute;
          right: -15px;
        }
      }
    }
  }

  .shop-details-container {
    @import '../styles';
    border: 2px solid #000000;
    width: 378px;
    height: 633px;
    box-sizing: border-box;
    padding: 4px;
    display: flex;
    flex-direction: column;
    position: relative;
    top: -44px;
    background-color: $default-background;

    .shop-title {
      @include mikado;
      font-size: 20px;
      justify-content: center;
      font-weight: bold;
      display: flex;
      width: 100%;
      min-height: 91px;
      text-align: center;
      position: relative;
      background-color: $yellow;

      span {
        padding-top: 8px;
      }

      .ranks {
        font-size: 20px;
        position: absolute;
        margin-left: 5px;
        right: 43px;
        bottom: -33px;
      }
    }

    .shop-details {
      background-color: #F9F4E4;
      padding: 71px 25px 11px 25px;
      border-top: none !important;
      display: flex;
      flex-direction: column;
      flex: 1;
      overflow: hidden;
      border-top: none;
    }

    ow-perfect-scrollbar {
      .ps__rail-y {
        width: 3px !important;
        border: none !important;
      }

      .ps__thumb-y {
        width: 3px !important;
        right: 0 !important;
      }
    }

    ow-perfect-scrollbar.scroll-indicator::after {
      height: 30px;
      background: transparent linear-gradient(180deg, #EBE5D500 0%, #F9F4E4 100%) 0 0 no-repeat padding-box;
    }

    .currency {
      @include mikado;
      display: flex;
      position: absolute;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-weight: bold;
      font-size: 20px;
      margin-bottom: 9px;
      top: 55px;
      left: 50%;
      transform: translateX(-50%);

      span {
        margin-top: 4px
      }

      .icon {
        width: 76px;
        height: 76px;
      }

      .icon-history {
        top: 50px;
        right: -36px;
        position: absolute;
        margin-right: -100px;
      }
    }

    .description {
      @include archivo-narrow;
      font-size: 16px;
      font-weight: normal;
      letter-spacing: 0;
      text-align: center;
    }

    .description-details {
      @include archivo-narrow;
      font-size: 14px;
      font-weight: bold;
      letter-spacing: 0;
      padding-top: 38px;
      text-align: center;
    }

    .separator {
      margin: 17px auto;
      width: 220px;
      border: 1px solid #EBE5D5;
    }

    .events {
      display: flex;
      flex-direction: column;

      .event {
        display: flex;
        align-items: center;
        font-size: 12px;

        &:not(:last-child) {
          margin-bottom: 12px;
        }

        .event-name {
          @include archivo-narrow();
          font-size: 14px;
          letter-spacing: 0;
          line-height: 12px;
        }

        .prize {
          @include mikado;
          font-size: 18px;
          font-weight: bold;
          display: flex;
          align-items: center;
          box-sizing: border-box;

          span {
            font-size: 20px;
            min-width: 46px;
            padding-top: 4px;
            text-align: right;
          }

          .icon {
            width: 28px;
            height: 28px;
            margin: 0 7px 0 4px;
          }
        }
      }
    }
  }

  .main-container {
    --fill-color: #F9F4E4;
    margin-top: 30px;
    display: flex;
    flex: 1;
    overflow: hidden;

    ow-perfect-scrollbar {
      flex: 1;
    }

    ow-perfect-scrollbar.scroll-indicator::after {
      height: 2px;
      margin-left: 30px;
      background-color: #9C9583;
      width: 750px;
    }

    .products-container {
      margin-left: 25px;
      display: flex;
      flex-wrap: wrap;

      .product-container {
        background: var(--fill-color);
        width: 180px;
        height: 214px;
        margin: 0 5px 12px 5px;
        padding: 14px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        transition: background .3s;
        position: relative;
        order: 1;

        .to-low-lvl-info, .to-low-star-info, .has-edenred-card-info {
          display: none;
        }

        .to-low-lvl-info, .to-low-star-info {
          font-size: 14px;
        }

        &.gadget {
          height: 239px;

          &.gadget-disabled {
            order: 2;
            opacity: 0.5;
          }

          .product-details {
            .available-amount {
              display: block;
            }
          }
        }

        &.gadget-shop-id-5 {
          &.gadget-disabled {
            order: 2;
            opacity: 0.5;
          }
        }

        &.to-low-lvl, &.to-low-star, &.has-edenred-card, &.no-edenred-card {
          .product-details {
            .icon {
              opacity: 0.2;
            }
          }
        }

        &.to-low-lvl > .to-low-lvl-info,
        &.to-low-star > .to-low-star-info,
        &.has-edenred-card > .has-edenred-card-info,
        &.no-edenred-card > .has-edenred-card-info {
          @include mikado;
          font-size: 14px;
          width: 100%;
          height: 100%;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          background-color: #F9F4E4cc;
          text-align: center;
          display: block;

          .has-edenred-card-info-padding {
            padding: 25px;
          }
        }

        &.to-low-lvl > .to-low-lvl-info,
        &.to-low-star > .to-low-star-info {
          span {
            text-transform: lowercase;
            position: relative;
            top: 25px;
          }
        }

        &:hover {
          background: #FFFBEF;
          cursor: pointer;
        }

        .product-details {
          display: flex;
          align-items: center;
          justify-content: space-between;
          flex-direction: column;
          flex: 1;

          .product-title {
            font-size: 14px;
            @include mikado;
            text-align: center;
          }

          .product-separator {
            width: 107px;
            height: 2px;
            background-color: #EBE5D5;
          }

          .available-amount {
            @include mikado;
            display: none;
            font-size: 14px;
            font-weight: bold;
            text-align: center;

            .fa {
              font-size: 9px;
            }
          }

          .icon {
            width: 76px;
            height: 76px;
            margin-bottom: 5px;
          }

          .cost {
            @include mikado;
            font-size: 18px;
            display: flex;
            align-items: center;
            justify-content: center;

            .icon-currency {
              width: 20px;
              height: 20px;
              margin-left: 4px;
            }
          }

          .limit {
            margin-top: 1px;
            font-size: 14px;
            @include mikado;
            font-weight: bold;
          }
        }
      }
    }
  }
}

