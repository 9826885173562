@import "src/styles/functions";
@import "src/styles/mixins";
@import "src/styles/variables/core/variables";

m-building-production-cycles {
  display: flex;
  align-items: center;
  @include text-stroke;
  color: $color-I;
  padding: multiply-size(2px);

  .wrapper {
    display: flex;
  }

  i {
    font-size: multiply-size(12px);
    margin-right: multiply-size(4px);
  }

  span {
    font-size: multiply-size(10px);
    line-height: multiply-size(10px);
    @include mikado-black;
  }
}
