app-micro-league-tabs {
  .ow-dialog {
    padding: 60px 85px 54px 105px;

    &.achievements {
      padding: 60px 78px 62px 95px;
    }
  }

  .branch-name {
    @include absolute-center-horizontal;
    @include archivo-narrow();
    font-size: 16px;
    min-width: 250px;
    padding: 0 10px;
    height: 31px;
    border: 3px solid #000;
    border-top: 1px solid #000;
    background: #FFD500;
    bottom: -3px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
  }

  .additional-data-tabs-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    box-sizing: border-box;
    height: 31px;
    right: 176px;
    top: -8px;
    gap: 14px;

    .source-data-date-tab {
      @include archivo-narrow();
      font-weight: 600;
      font-size: 16px;
      letter-spacing: 0;
      line-height: 18px;
      height: 31px;
      border: 3px solid black;
      background-color: #B8F2FF;
      padding: 0 34px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .trophy-data-date-tab {
      height: 31px;
      width: 36px;
      border: 3px solid black;
      background-color: #F2EDDF;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      .trophy {
        width: 30px;
        height: 30px;
        background-image: var(--asset--ui-trophy-png);
        background-size: cover;
      }
    }
  }

  .cursor {
    cursor: default;
  }

  .visibility {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    box-sizing: border-box;

    .item {
      background-size: contain;
      background-repeat: no-repeat;
      width: 188px;
      height: 188px;
      position: relative;
      cursor: pointer;
      margin-bottom: 30px;

      &:hover {
        opacity: 0.8;
      }

      &.individual {
        background-image: var(--asset--ui-achievements_individual-png);
      }

      &.branch {
        background-image: var(--asset--ui-achievements_team-png);
      }

      span {
        @include absolute-center-horizontal;
        bottom: -34px;
        @include mikado();
        font-size: 16px;
        letter-spacing: 0.32px;
        line-height: 15px;
        text-align: center;
      }
    }

    .separator {
      width: 1px;
      height: 260px;
      background-color: #9C9583;
      margin: 0 87px;
    }
  }
}
