@import "src/styles/mixins";

app-micro-league-achievements {
  @import "../../styles/styles";

  .date {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    padding-right: 22px;
    margin-bottom: 27px;
    box-sizing: border-box;

    span {
      @include archivo-narrow();
      font-size: 16px;
      letter-spacing: 0;
      font-weight: bold;
      margin-right: 20px;
    }

    i {
      font-size: 26px;
    }
  }

  .container {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
  }

  .achievement {
    @include archivo-narrow();
    font-size: 12px;
    width: 376px;
    height: 218px;
    border: 2px solid #959185;
    border-radius: 4px;
    background-color: #F9F4E4;
    float: left;
    margin: 50px 8px 30px;
    position: relative;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    transform-style: preserve-3d;
    transition: transform 1s;

    &.flipped {
      transform: rotateY(-180deg);
    }
  }

  figure {
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    background: #FAEFD8;
    top: 0;
    left: 0;
    padding: 62px 12px 8px;
    box-sizing: border-box;

    .flip-card {
      position: absolute;
      top: 2px;
      right: 2px;
      z-index: 1;
      width: 20px;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      backface-visibility: hidden;

      i {
        font-size: 14px;

        &.rotate {
          transform: rotateY(-180deg);
        }
      }
    }

    &.front {
      z-index: 1;
    }

    &.back {
      transform: rotateY(180deg);
      z-index: 2;
    }
  }

  .l-icon {
    @include absolute-center-horizontal;
    top: -50px;
    z-index: 10;
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .title {
    @include mikado();
    font-size: 14px;
    text-align: center;
    letter-spacing: 0.28px;
    text-transform: uppercase;
    margin-bottom: 8px;
    height: 35px;
  }

  .app-notification {
    top: 6px;
    left: 6px;
  }

  .desc {
    @include archivo-narrow();
    font-size: 14px;
    font-weight: normal;
    text-align: center;
  }

  .prizes-box {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .target {
    display: flex;
    align-items: center;
    justify-content: center;

    m-ui-currency {
      margin-left: 4px;
    }

    &.available {
      margin-top: -5px;
    }

    .details {
      width: 20px;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      i {
        font-size: 14px;
        margin-left: 4px;
      }
    }
  }

  .progress-container-after {
    position: relative;
    padding: 2px 0;
    width: 204px;
    height: 14px;
    margin-bottom: 5px;
    box-sizing: border-box;


    .progressbar {
      height: 14px;
      position: relative;
      background-color: #DED8C6;
      border: 2px solid #000000;
      border-radius: 6px;
      padding: 0 !important;
      box-sizing: border-box;

      .polygons {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;

        div {
          position: absolute;

          &:last-child {
            display: none;
          }

          &::after {
            display: block;
            position: absolute;
            content: "";
            width: 10px;
            height: 10px;
            background-color: #000000;
            transform: rotate(-45deg) translateX(-50%);
            bottom: 3px;
            z-index: 2;
          }

          &::before {
            display: block;
            position: absolute;
            content: "";
            width: 10px;
            height: 10px;
            background-color: #000000;
            transform: rotate(-45deg) translateX(-50%);
            top: 6px;
            z-index: 2;
          }
        }
      }

      .fill {
        border-radius: 4px 0 0 4px;
        border-right: 2px solid #000000;
        box-sizing: unset;
      }
    }
  }

  .stock-card-details {
    .value {
      @include mikado();
    }
  }

  .margin-v1 {
    margin-bottom: 12px;
  }

  .margin-v2 {
    margin: 8px 0 6px 0;
  }

  m-ui-separator {
    width: 180px;

    .separator {
      margin: 6px 0;
    }
  }

  .squares {
    position: relative;
    width: 20px;
    height: 20px;
    box-sizing: border-box;
    margin-left: 4px;

    i {
      position: absolute;

      &.contour {
        top: 2px;
        left: 0;
        z-index: 1;
      }

      &.color {
        top: 2px;
        left: 0;
        font-size: 16px;
      }
    }
  }

  i {
    font-size: 16px;

    &.fa-check-square.green {
      color: $green;
    }

    &.fa-minus-square.red {
      color: $red;
    }
  }

  .prizes-box {
    .target {
      margin-right: 20px;

      img {
        width: 24px;
        height: 24px;
        object-fit: contain;
        cursor: pointer;
        margin-left: 2px;
      }
    }
  }

  .blocked {
    opacity: 0.5;
  }

  .visibility-hidden {
    visibility: hidden;
  }

  perfect-scrollbar {
    .ps-content {
      padding-right: 24px !important;
    }
  }
}
