

app-match3-minigame {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #0007;
  display: flex;
  justify-content: center;
  align-items: center;

  * {
    box-sizing: border-box;
  }

  &:before {
    content: ' ';
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    //background-image: var(--asset--minigames-match3-ui-logo-png);
    background-size: cover;
    z-index: -1;
  }

  #match3-target {
    @media screen and (max-width: 1000px) {
      transform: scale(0.5);
    }
  }


  .ow-dialog {
    m-ui-close-button {
      position: absolute;
      right: -40px;
      top: 30px;
    }

    .minigame-container {
      background-image: url("../../../../../../assets/minigames/match-three/ui/background.png");
      background-size: cover;
      background-position: bottom;
      width: 1100px;
      height: 570px;
      border: 2px solid #000000;
      border-radius: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  //
  //.show-debug-button {
  //  position: fixed;
  //  right: 10px;
  //  top: 10px;
  //}

  #match3-target {
    &.game-started {
      height: auto !important;
      background: rgba(255, 255, 255, 0.8);
      padding: 8px;
      border-radius: 20px;
      border: 2px solid #000000;
      //@media screen and (min-width: 1000px) {
      //  //transform: scale(2);
      //  border: 4px solid #000000;
      //  border-radius: 20px;
      //}
    }

    canvas {
      width: auto;
      height: auto;
      max-height: 450px;
      max-width: 870px;
    }
  }

  .game-ui {
    .side-button {
      position: absolute;
      right: 16px;
      bottom: 16px;
      display: flex;
      flex-direction: column;
      gap: 6px;
      z-index: 10;

      //@media screen and (min-width: 1000px) {
      //  transform: scale(2);
      //
      //  &.reset-board {
      //    bottom: 40px;
      //    right: 10px;
      //
      //    button {
      //      margin: 15px;
      //    }
      //  }
      //}

      .square {
        border-radius: 0;
        border: 2px solid black;
        background-color: #77CC4F;
        box-shadow: inset 0 calc(-4px * var(--multiply)) 0 0 #3E7103;
        box-sizing: border-box;
        width: 30px;
        padding-bottom: 3px;
        height: 30px;
        color: black;
        margin: 0;

        i {
          color: black;
          font-size: 14px;
        }
        //width: 23px;
        //height: 26px;
        //text-shadow: none;
        //
        //i {
        //  text-shadow: none;
        //  color: #000;
        //  font-size: 14px;
        //  font-weight: 500;
        //}
      }
    }

    .reset-board {
      right: 0;
      bottom: 0;

      button {
        margin: 20px;
      }
    }

    .exit {
      top: 20px;
      right: 20px;
    }


    .score-and-moves {
      position: absolute;
      top: 0;
      @include mikado;
      display: flex;
      flex-direction: row;
      justify-content: center;
      gap: 6px;
      width: 100%;
      left: 0;

      .score {
        display: flex;
        justify-content: center;
        align-items: center;
        // height: 30px;
        border-radius: 0 0 12px 12px;
        background-color: #8AE807;
        border: 2px solid #000000;
        font-size: 20px;
        padding: 0 14px;

        p {
          margin: 0;
          height: 27px;
          align-items: center;
          display: flex;
        }

        //background: #8AE807 0 0 no-repeat padding-box;
        //border: 2px solid #000000;
        //border-radius: 0 0 12px 12px;
        //text-align: center;
        //font-size: 14px;
        //max-height: 26px;
        //padding-right: 10px;
        //padding-left: 10px;
        //margin: 3.5px;
        //width: 136px;
      }

      .moves {
        display: flex;
        justify-content: center;
        align-items: center;
        // height: 30px;
        background-color: #00EEA5;
        border: 2px solid #000000;
        border-radius: 0 0 12px 12px;
        font-size: 20px;
        padding: 0 14px;
        //line-height: 27px;
        //padding: 1px 14px;

        p {
          margin: 0;
          height: 27px;
          align-items: center;
          display: flex;
        }
      }

      //
      //@media screen and (min-width: 1000px) {
      //  transform: scale(2);
      //  transform-origin: top center;
      //  top: -12px;
      //}
    }

  .box-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    position: absolute;
    right: 0;
    top: 0;
    margin: 20px;


    .box {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 64px;
      height: 64px;
      user-select: none;
      position: relative;

      &:before {
        content: "";
        position: absolute;
        width: 80px;
        height: 80px;
        background: var(--asset--ui-match_three-box_background-png);
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;

      }

      img {
        z-index: 1;
      }

      p {
        @include mikado;
        @include text-stroke;
        position: absolute;
        z-index: 2;
        font-size: 20px;
        line-height: 27px;
        color: white;
        bottom: 0;
        margin: 0;
      }
    }
  }
    .missions {
      position: absolute;
      left: 14px;
      top: 50%;
      width: 50px;
      transform: translateY(-50%);
      background: rgba(255, 255, 255, 0.8);
      border: 2px solid #000000;
      padding: 40px 0 25px;
      border-radius: 0 0 30px 30px;

      .level {
        background: var(--asset--ui-match_three-level-png);
        width: 60px;
        height: 60px;
        position: absolute;
        top: -30px;
        left: -7px;

        //.level-text {
        //  font-size: 12px;
        //  margin-top: 12px;
        //}

        .level-value {
          @include mikado;
          margin: 0;
          font-size: 26px;
          line-height: 35px;
          font-weight: bold;
          padding: 10px 15px 15px;
          text-align: center;

          //font-size: 24px;
          //margin-top: -5px;
        }
      }

      label {
        display: block;
        width: 100%;
        @include archivo-narrow;
        font-size: 12px;
        line-height: 14px;
        text-align: center;
        font-weight: bold;
        margin-bottom: 4px;
      }


      //transform-origin: bottom left;
      //position: fixed;
      //bottom: -2px;
      //left: -2px;
      //padding: 9px;
      //@include mikado;
      //font-size: 12px;
      //min-width: 60px;
      //@media screen and (min-width: 1000px) {
      //  transform: scale(2);
      //}
      //
      //background: rgba(255, 255, 255, 0.8);
      //border: 2px solid #000000;
      //border-radius: 0 12px 0 0;
      //z-index: 1;
      //
      //ul {
      //  padding: 0;
      //  font-size: 16px;
      //  margin: 0;
      //
      //  li {
      //    padding: 0;
      //    list-style: none;
      //    display: flex;
      //    align-items: center;
      //    margin-top: 10px;
      //
      //    &.bonus-color-label {
      //      font-size: 12px;
      //    }
      //
      //    &.bonus-color-icon {
      //      justify-content: center;
      //    }
      //
      //    img {
      //      margin-right: 4px;
      //      width: 25px;
      //    }
      //  }
      //}
    }

    .products-container {
      display: flex;
      flex-direction: column;
      gap: 4px;

      &:not(:last-of-type) {
        margin-bottom: 20px;
      }

      &.margin-less {
        margin-bottom: unset;
      }

      .game-ui-product {
        display: flex;
        flex-direction: column;
        align-items: center;

        img {
          width: 30px;
          height: 30px;
        }

        span {
          font-size: 14px;
          line-height: 19px;
          font-weight: bold;
          @include mikado;
        }
      }
    }

  }



  .missions-label {
    font-size: 14px;
    line-height: 19px;
    font-weight: bold;
    color: black;
    position: relative;
    text-align: center;
    width: 320px;
    height: 19px;
    //width: 160px;
    //margin-top: 10px;

    span {
      background: #f2eddf;
      padding-left: 10px;
      padding-right: 10px;
      position: relative;
    }

    &:before {
      width: 100%;
      height: 0;
      border-bottom: solid 1px #000;
      content: '';
      position: absolute;
      left: 0;
      top: 9px;
    }
  }

  .levels-navigation {
    display: flex;
    margin-top: 15px;
  }

  .end-game-btn {
    position: absolute;
    bottom: -15px;
    left: calc(50% - 62px);
  }

  .m3-dialog {

    //&.level-result {
    //  .level-circle {
    //    position: absolute;
    //    left: -30px;
    //    top: 15px;
    //  }
    //}
    //
    //&.next-level-info {
    //  .m3-dialog-content {
    //    height: 247px;
    //  }
    //
    //  .level-circle {
    //    position: absolute;
    //    top: 40px;
    //  }
    //
    //  .missions-label {
    //    margin-top: 75px;
    //  }
    //}

    .m3-dialog-content {
      width: 400px;
      height: 504px;
      display: flex;
      flex-direction: column;
      align-items: center;
      background: var(--asset--ui-window_small-png);
      background-repeat: no-repeat;

      position: relative;
      padding: 136px 30px 36px 26px;

      @include mikado;

      //&.with-logo {
      //  .level {
      //    margin-top: 50px;
      //  }
      //}

      .dialog-title {
        margin-bottom: 3px;
        min-height: 27px;

        h2 {
          @include mikado;
          font-size: 20px;
          line-height: 27px;
          font-weight: bold;
          margin: 0;
        }
      }

      .logo {
        top: -30px;
        width: 197px;
        height: 158px;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        position: absolute;
        background-image: var(--asset--ui-match_three-logo-png);

        //border: 2px solid black;
        //box-sizing: border-box;
        //background-color: white;
        //background-size: cover;
        //width: 120px;
        //height: 82px;
        //position: absolute;
        //top: -30px;
        //left: calc(50% - 60px);
      }

      .level {
        font-size: 30px;
        line-height: 40px;
        color: black;
        text-align: center;
        margin-bottom: 12px;
      }

      //.level-label {
      //  font-size: 16px;
      //  font-weight: bold;
      //}

      .finished-level-details {
        display: flex;
        flex-direction: column;
        gap: 6px;
        padding: 0 12px;
        width: 100%;
        height: 100%;

        table {
          table-layout: auto;
          border-spacing: 0 4px;
          width: 100%;
        }

        .separator {
          height: 2px;
          background-color: black;
          width: 100%;
        }

        .game-result {
          display: flex;
          align-items: center;
          justify-content: center;

          & > p {
            @include archivo-narrow;
            margin: 0;
            font-size: 14px;
            line-height: 16px;
            font-weight: bold;
            text-align: center;
          }

          .result {
            display: flex;
            justify-content: center;
            margin: 0 0 0 8px;
            font-size: 26px;
            line-height: 35px;
            text-align: center;
          }
        }

        .result-ingredient {
          //display: flex;
          //align-items: center;
          //justify-content: flex-end;

          & > p {
            margin: 0;
          }

          .description {
            font-size: 14px;
            line-height: 16px;
            width: auto;
            text-align: right;
            padding-right: 10px;
          }

          .value {
            font-size: 20px;
            line-height: 27px;
            margin-left: 10px;
            width: fit-content;
          }
        }

        button {
          width: 190px;
          margin: auto auto 0;
        }
      }

      //.finished-level-details {
      //  margin-top: 55px;
      //  width: 100%;
      //
      //
      //  //display: flex;
      //  div {
      //    display: flex;
      //    flex-direction: row;
      //    align-items: center;
      //    width: 100%;
      //    @include mikado;
      //    margin-left: 40px;
      //
      //    //&.previous-levels-points, &.level-points {
      //    margin-bottom: 6px;
      //    //}
      //
      //    span:first-child {
      //      @include archivo-narrow;
      //      font-size: 10px;
      //      text-align: right;
      //      margin-right: 10px;
      //      max-width: 100px;
      //      min-width: 100px;
      //      font-weight: normal;
      //    }
      //
      //    span:last-child {
      //      font-size: 24px;
      //      text-align: left;
      //    }
      //
      //    &.separator {
      //      position: relative;
      //      height: 2px;
      //      width: 178px;
      //      //margin-top: 12px;
      //
      //      &:after {
      //        top: 0;
      //        right: 0;
      //        width: 90px;
      //        border-bottom: solid 2px #000;
      //        content: '';
      //        position: absolute;
      //      }
      //    }
      //
      //    &.total-points {
      //      text-align: right;
      //      width: 100%;
      //
      //      span:first-child {
      //        @include mikado;
      //        font-size: 24px !important;
      //      }
      //    }
      //  }
      //}
    }
  }

  .missions-list {
    display: flex;
    flex-direction: row;
    justify-content: center;
    //width: 190px;
    //padding-left: 10px;
    flex-wrap: wrap;
    gap: 16px;
    padding: 12px 0 10px;
    max-width: 90%;
    width: auto;

    .mission-list-item {
      display: flex;
      align-items: center;
      flex-direction: row;

      .mission-product-img {
        width: 50px;
        margin-right: 4px;
      }

      .mission-text {
        font-size: 20px;
        line-height: 27px;
      }

      .mission-separator {
        margin-top: 5px;
      }

      &.bonus-mission {
        position: relative;
        margin-bottom: 17px;
        //width: 98px;
        //height: 16px;
        //background: #12870A;
        //border-radius: 5px;
        //color: #fff;
        //@include mikado;
        //justify-content: space-between;
        //padding-right: 0;
        //margin-top: 10px;

        p {
          padding: 6px 30px 5px 12px;
          background-color: #12870A;
          color: white;
          display: inline-block;
          border-radius: 5px 0 0 5px;
          line-height: 19px;
          font-size: 14px;
          margin: 0 28px 0 0;
          //font-size: 20px;
          //line-height: 27px;
          //font-size: 12px;
          //margin-left: 8px;
          //margin-top: 2px;
        }

        img {
          position: absolute;
          right: 0px;
          width: 50px;
          height: 50px;
          //margin-right: -5px;
        }
      }
    }
  }

  .start-game {
    margin-top: auto;
    display: block;
    //position: absolute;
    //bottom: 9px;
  }


  .level-circle {
    background: var(--asset--ui-match_three-level-png);
    width: 60px;
    height: 60px;
    background-size: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: "Mikado", sans-serif;
    line-height: 60px;
    font-size: 24px;
  }


  .mission-separator {
    width: 160px;
    border-bottom: solid 1px #000;
    content: '';
  }

  .stock-minigame-m3-result {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row-reverse;
    margin-left: 4px;

    .icon {
      width: 28px;
      height: 28px;
      margin: 0 0 0 4px;
    }

    .value {
      @include mikado();
      font-size: 26px;
      line-height: 35px;
      font-weight: 900;
      text-align: center;
      color: #000000;
      text-shadow: none;
    }
  }
}

