order-history {
  @import "../../shop-assets";

  & {
    width: 100%;
    overflow: auto;

    ow-perfect-scrollbar {
      min-width: 1300px;
    }

    .shop-container {
      padding-bottom: 50px;
    }


    .table-orders {
      tbody {
        @include roboto-cond;

        tr {
          td {
            &.td-date {
              padding: 8px 20px;
              font-size: 16px;
              text-transform: uppercase;
              font-weight: bold;
              background: #E5F2FF;
            }

            .delivery-address {
              text-decoration: underline;
              cursor: pointer;
            }
          }

          &.tr-summary {
            td {
              text-transform: uppercase;
              vertical-align: top;

              &.empty {
                width: 30%;
              }

              .flex-center {
                margin-bottom: 4px;
                justify-content: flex-start;

                img {
                  margin-left: 10px;
                  height: 22px;
                  width: 21px;
                }

                b {
                  margin-left: 3px;
                }

                &:last-child {
                  margin-bottom: 0
                }
              }
            }
          }

          &.tr-product-name {
            td {
              padding: 0 20px;
              font-size: 16px;

              p {
                margin: 10px 0 5px 0;
              }
            }
          }
        }
      }
    }

    .button-container {
      margin-left: 7px;
      display: flex;
      margin-top: 50px;

      button {
        margin: 5px;

        &.c-base {
          width: 186px !important;
          height: 46px !important;
          font-size: 14px !important;
        }

        &.back {
          @include mplus1c-rounded;
          font-weight: 900;
          display: flex;
          align-items: baseline;
          padding-top: 12px;
          margin-right: 20px;

          i {
            margin-right: 10px;
            font-size: unset !important;
          }

          span {
            text-decoration: underline;
          }
        }
      }
    }
  }

  .discount-icon {
    font-size: 22px;
    color: #00767a;
    transform: translateY(2px);
  }

  .mobile & {
    .shop-container {
      overflow: hidden;
    }
  }
}
