@import "src/styles/mixins";
@import "src/styles/variables/core/variables";

app-tracking-board {
  .global-dialog {
    .exit-bar {
      right: 17px;
      top: 65px;
    }
  }

  .ow-dialog {
    padding: 50px 183px; // 28px 114px 35px 114px;
    display: flex;
    flex-direction: column;
    width: 1366px;
    height: 690px;

    &.mountain {
      background-image: var(--asset--tracking-rock_pass-png);
    }

    &.savannah {
      background-image: var(--asset--tracking-savannah_1-png);
    }

    &.forest_1 {
      background-image: var(--asset--tracking-forest_1-png);
    }

    &.forest_2 {
      background-image: var(--asset--tracking-forest_2-png);
    }

    &.swamp {
      background-image: var(--asset--tracking-swamps-png);
    }
  }

  .board-pill {
    position: relative;
    background-color: #fcf9ef;
    border: 2px solid #000000;
    border-radius: 10px;
    min-width: 68px;
    height: 20px;

    &.long {
      width: 80px;
    }

    img {
      width: 28px;
      height: 28px;
      position: absolute;
      left: -14px;
      top: -4px;
    }

    p {
      @include mikado;
      font-size: 16px;
      line-height: 22px;
      text-align: right;
      margin: 0;
      padding-right: 6px;
    }
  }

  .bottom-hud,
  .top-hud {
    display: flex;
    flex-direction: column;
    gap: 16px;
    position: absolute;
    left: 80px;
  }

  .top-hud {
  }

  .bottom-hud {
    bottom: 38px;
  }

  .frame {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    position: relative;

    .board-wrapper {
      display: flex;
      flex-direction: column-reverse;
      align-items: center;
      justify-content: center;
      height: 100%;
      width: 100%;

      .row {
        display: flex;

        .cell {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          position: relative;
          width: 100px;
          height: 100px;

          &::after {
            position: absolute;
            display: block;
          }

          //&.loading {
          //  &::before {
          //    content: "Trwa\atropienie";
          //    white-space: pre;
          //    position: absolute;
          //    z-index: 2;
          //    color: white;
          //    font-size: 16px;
          //    text-align: center;
          //    animation-name: tracking;
          //    animation-duration: 2s;
          //    @include mikado;
          //    animation-direction: alternate-reverse;
          //    animation-iteration-count: infinite;
          //    text-shadow: 0 0 3px rgba(0, 0, 0, .6), 0 0 3px rgba(0, 0, 0, .6), 0 0 3px rgba(0, 0, 0, .6), 0 0 3px rgba(0, 0, 0, .6), 0 0 3px rgba(0, 0, 0, .6), 0 0 3px rgba(0, 0, 0, .6), 0 0 3px rgba(0, 0, 0, .6), 0 0 3px rgba(0, 0, 0, .6), 0 0 3px rgba(0, 0, 0, .6), 0 0 3px rgba(0, 0, 0, .6);
          //  }
          //}

          &.blocked {
            //cursor: not-allowed;
          }

          /* chess table */
          &.grid-a {
            background-image: var(--asset--tracking-grid_a-png);
          }

          &.grid-b {
            background-image: var(--asset--tracking-grid_b-png);
          }

          &.grid-c {
            background-image: var(--asset--tracking-grid_c-png);
          }

          // small version of the board
          //&.shrink {
          //  width: 68px;
          //  height: 68px;
          //
          //  &.grid-a {
          //    background-image: var(--asset--tracking-wf_grid_a-png);
          //  }
          //
          //  &.grid-b {
          //    background-image: var(--asset--tracking-wf_grid_b-png);
          //  }
          //
          //  &.grid-c {
          //    background-image: var(--asset--tracking-wf_grid_c-png);
          //  }
          //
          //  &.bush {
          //    &:not(.blocked):hover {
          //      filter: brightness(1.3);
          //    }
          //
          //    &::after {
          //      width: 96px;
          //      height: 96px;
          //      left: -14px;
          //      top: -14px;
          //    }
          //  }
          //
          //  &.clickable {
          //    filter: brightness(1.2);
          //
          //    &:not(.blocked) {
          //      .outdoor-1,
          //      .outdoor-2 {
          //        &:hover {
          //          filter: brightness(1.05);
          //          cursor: pointer;
          //        }
          //      }
          //    }
          //  }
          //}
          // end small version of the board

          /* bushes */
          &.bush {
            &:not(.blocked):hover {
              cursor: pointer;
              filter: brightness(120%);
            }

            &::after {
              content: "";
              width: 144px;
              height: 144px;
              left: -22px;
              top: -22px;
              pointer-events: none;
            }

            &.mountain {
              &::after {
                background-image: var(--asset--buildings-fog-1-png);
              }
            }

            &.savannah {
              &::after {
                background-image: var(--asset--buildings-grass-1-png);
              }
            }

            &.swamp {
              &::after {
                background-image: var(--asset--buildings-grass_swamps-1-png);
              }
            }

            &.forest_1 {
              &::after {
                background-image: var(--asset--buildings-forest_1-1-png);
              }
            }

            &.forest_2 {
              &::after {
                background-image: var(--asset--buildings-forest_2-1-png);
              }
            }
          }

          /* footprints */
          .footprint {
            width: 50px;
            height: 50px;
            z-index: 1;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;

            &.absolute {
              position: absolute;
            }

            &.opacity {
              &-100 {
                opacity: 100%;
              }
              &-80 {
                opacity: 80%;
              }
              &-60 {
                opacity: 60%;
              }
              &-40 {
                opacity: 40%;
              }
              &-20 {
                opacity: 20%;
              }
              &-10 {
                opacity: 10%;
              }
            }

            &.direction {
              &-top {
                transform: rotate(0deg);

                // push to the edge if there's also animal on the board
                &.absolute {
                  top: 0;
                }

                // previous sibling has type of "direction-top"; i'm also "direction-top" type
                &:has(+ .direction-top) {
                  top: 0;
                  left: 0;
                }

                // next sibling is of type "direction-top"; i'm also "direction-top" type
                & ~ .direction-top {
                  top: 0;
                  left: 50%;
                }
              }

              &-right {
                transform: rotate(90deg);

                &.absolute {
                  right: 0;
                }

                &:has(+ .direction-right) {
                  top: 0;
                  right: 0;
                }

                & ~ .direction-right {
                  top: 50%;
                  right: 0;
                }
              }
              &-bottom {
                transform: rotate(180deg);

                &.absolute {
                  bottom: 0;
                }

                &:has(+ .direction-bottom) {
                  bottom: 0;
                  left: 0;
                }

                & ~ .direction-bottom {
                  bottom: 0;
                  left: 50%;
                }
              }
              &-left {
                transform: rotate(270deg);

                &.absolute {
                  left: 0;
                }

                &:has(+ .direction-left) {
                  top: 0;
                  left: 0;
                }

                & ~ .direction-left {
                  top: 50%;
                  left: 0;
                }
              }
            }

            &.icon {
              background-size: contain;

              &-chain {
                background-image: var(--asset--tracking-map_chain-png);
              }

              &-poacher {
                background-image: var(--asset--tracking-map_track_poacher-png);
              }
              &-rhino {
                background-image: var(--asset--tracking-map_track_rhino-png);
              }
              &-snow_leopard {
                background-image: var(--asset--tracking-map_track_snow_leopard-png);
              }
              &-chemicals {
                background-image: var(--asset--tracking-map_track_chemicals-png);
              }
            }
          }

          /* creatures */
          .creature {
            width: 100%;
            height: 100%;
            z-index: 2;
            background-size: contain;

            &.knowledge,
            &.knowledge-1,
            &.knowledge-2 {
              background-image: var(--asset--buildings-scrolls_knowledge-1-png);
            }

            &.rock_pass,
            &.rock-pass-1,
            &.rock_pass-2 {
              background-image: var(--asset--buildings-rock_pass-1-png);
            }

            &.trash,
            &.trash-1,
            &.trash-2 {
              background-image: var(--asset--buildings-trash-1-png);
            }

            &.chest,
            &.chest-1,
            &.chest-2 {
              background-image: var(--asset--buildings-chest-1-png);
            }

            &.poacher,
            &.poacher-1,
            &.poacher-2 {
              background-image: var(--asset--buildings-map_poacher-1-png);
            }
            &.rhino,
            &.rhino-1,
            &.rhino-2 {
              background-image: var(--asset--buildings-map_rhino-1-png);
            }
            &.snow_leopard,
            &.snow_leopard-1,
            &.snow_leopard-2{
              background-image: var(--asset--buildings-map_snow_leopard-1-png);
            }
            &.chemicals,
            &.chemicals-1,
            &.chemicals-2 {
              background-image: var(--asset--buildings-map_chemicals-1-png);
            }
          }
        }
      }
    }
  }

  @keyframes tracking {
    0% {
      font-size: 16px;
    } // ; transform: rotate(-10deg) }
    //33% { transform: rotate(10deg) }
    //45% { transform: rotate(360deg) }
    //66% { transform: rotate(350deg) }
    100% {
      font-size: 20px;
    } // ; transform: rotate(370deg) }
  }
}
