// no-multiply-size-file

mock-dialogs-list {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100vh;

  .mock-dialog-list {
    background-color: #fff;
    display: flex;
    overflow: hidden;
    flex: 1;

    .mock-list {
      display: flex;
      flex-direction: column;
      justify-content: center;

      button {
        margin: 15px;
      }

      .list-group {
        list-style-type: none;
        display: flex;
        flex-direction: column;
        flex: 1;
        padding: 15px;
        overflow: auto;
        max-width: 300px;
        margin: 0;
        border-right: solid 1px #ccc;

        button {
          margin: 0 auto 15px auto;
        }

        li {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 8px 16px;
          cursor: pointer;

          &:hover {
            background-color: #ccc;
          }

          .circle {
            background-color: #3dd00d;
            border-radius: 50%;
            width: 8px;
            height: 8px;

            &.circle-disable {
              background-color: red;
            }
          }
        }
      }
    }

    .json-editor {
      display: flex;
      flex-direction: column;
      flex: 1;
      padding: 15px;

      .mock-checkbox {
        margin-left: auto;
        padding: 8px;
        z-index: 9999;
      }

      .store-button {
        margin-left: auto;
        z-index: 9999;

        &:hover {
          background-color: #ccc;
        }
      }

      textarea {
        flex: 1;
        resize: none;
      }

      .editor-container {
        display: flex;
        flex: 1;
        overflow: auto;
        justify-content: center;
        flex-wrap: wrap;

        .editor-container-response {
          display: flex;
          overflow: auto;
          width: 100%;
          max-height: 330px;
          height: 100%;
          margin: 15px;
          flex-direction: column;
        }
      }
    }
  }
}
