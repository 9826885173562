@import "src/styles/mixins";

app-contract-clock-prizes {
  .ow-dialog {
    max-width: 806px;
    background: #EBE5D5;
    border: 3px solid #000000;
    min-width: 806px;
    min-height: 362px;
    padding: 40px 35px;
    display: flex !important;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    m-ui-product {
      justify-self: start;

      .stock-j {
        flex-direction: row;
        gap: 4px;

        .value {
          font-size: 22px;
          @include mikado();
        }

        .icon {
          width: 50px;
          height: 50px;
        }
      }
    }

    m-ui-currency {
      justify-self: start;

      .stock-j {
        flex-direction: row;
        gap: 4px;

        .value {
          font-size: 22px;
          @include mikado();
        }

        .icon {
          width: 50px;
          height: 50px;
        }
      }
    }

    m-ui-close-button {
      position: absolute;
      top: 5px;
      right: -42px;
    }

    .description {
      @include archivo-narrow();
      font-size: 16px;
      text-align: center;
      width: 100%;
    }

    .title {
      margin-bottom: 20px;
      font-size: 20px;
      @include mikado();
      font-weight: bold;
      text-align: center;
      width: 100%;
      &.cta {
        margin: 40px 0;
      }
    }
    .subtitle {
      margin-bottom: 10px;
      font-size: 18px;
      @include mikado();
      font-weight: bold;
      text-align: center;
      width: 100%;
    }
  }
  .prizes {
    display: flex;
    gap: 20px;
    justify-content: center;
    align-self: center;

    .item {
      display: flex;
      flex-direction: column;
      gap: 4px;

      .rewards {
        display: flex;
      }

      .count {
        font-size: 14px;
        font-weight: bold;
        @include mikado();
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

  }

  button {
    &.c-base {
      margin-top: 20px;
    }
  }
}
