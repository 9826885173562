& {
  text-shadow: none;
  color: black;
  padding: 0 19px;
  background: #EBE5D5;
  border: 2px solid #3B3326;
  height: 30px;
  display: flex;
  align-items: center;

  i {
    font-size: 20px;
    margin-right: 3px;
  }

  span {
    font-size: 14px;
    line-height: 1;
  }
}
