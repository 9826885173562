app-minigame-river-ride {
  .rr-game-container {
    width: 1000px;
    height: 570px;
    margin: 0 auto;

    &.edit-mode {
      margin-right: 0;
      left: auto;
    }

    .minigame-river-ride, canvas {
      width: 100%;
      height: 100%;
    }
  }
}


app-rrconfig-editor {
  //position: fixed;
  //left: 0;
  //top: 0;
  //width: 400px;
  //height: 10vh;
}
