.content {
  height: 100%;

  &.type-3 {
    height: 476px;
  }
}

.card {
  width: 280px;
  height: 465px;
  border: 2px solid #959185;
  border-radius: 4px;
  box-sizing: border-box;
  padding: 22px 36px 12px;
  background-color: #F9F4E4;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  float: left;
  margin: 7px;
  cursor: pointer;
  position: relative;

  .ribbon {
    width: 60px;
    height: 60px;
    top: -4px;
    right: -4px;
    background-color: transparent !important;
    border: none !important;
  }

  .allegro-currency {
    width: 30px;
    height: 30px;
    bottom: 84px;
    right: -3px;
    background: #BAB4A4;
    border: 2px solid #716D62;
  }

  .allegro-currency, .ribbon {
    object-fit: contain;
    position: absolute;
  }

  img {
    width: 202px;
    height: 202px;
    object-fit: contain;
    border: 1px solid #707070;
    background-color: #fff;
    box-sizing: border-box;
  }

  .name {
    font-size: 18px;
    @include archivo-narrow();
    height: 52px;
    line-height: 16px;
    margin: 12px 0;
  }

  .sv-1 {
    font-size: 14px;
    @include archivo-narrow();
    width: 100%;
    letter-spacing: 0;
    line-height: 16px;
    cursor: default;
  }

  .box {
    height: 95px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 12px;
    border-bottom: 1px solid #959185;
    width: 100%;

    &.top {
      border-top: 1px solid #959185;
      margin-top: 10px;
    }
  }
}

.filter {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  box-sizing: border-box;
  padding-right: 26px;
  margin-bottom: 8px;

  .role-select {
    width: 206px;
  }
}

.allegro-currency-choose {
  width: 35px;
  min-width: 35px;
  height: 35px;
  border: 3px solid #000000;
  background-color: #FF3D3D;
  color: #ffffff;
  @include mikado();
  font-size: 26px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;

  &.v-card {
    position: absolute;
    bottom: 82px;
    right: -3px;
  }
}

.bottom {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  .item {
    @include archivo-narrow();
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0;
    text-align: left;
    border-right: 1px solid #959185;
    padding: 0 14px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: default;

    &.no-border {
      border-right: none !important;
    }

    &.no-padding {
      padding-right: 0 !important;
    }

    m-ui-currency {
      margin-left: 12px;
    }

    &:first-child {
      padding-left: 0 !important;
    }

    &:last-child {
      border-right: none !important;
    }
  }
}

.auction-id {
  position: absolute;
  bottom: 52px;
  right: 48px;
  width: 94px;
  height: 29px;
  border: 2px solid #000000;
  background-color: transparent;
  @include mikado();
  font-weight: bold;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
}

.bold {
  font-weight: bold;
}

.center {
  text-align: center;
}

.alert {
  color: #FF3D3D;

  &.green {
    color: #18AE29 !important;
  }

  &.black {
    color: #000000 !important;
  }
}

.m-top {
  margin-top: 8px;
}

m-ui-currency {
  margin-bottom: 6px;

  .stock-san-a {
    flex-direction: row-reverse !important;

    .icon {
      width: 40px !important;
      height: 40px !important;
    }

    .value {
      font-size: 22px !important;
      margin-right: 6px;
      line-height: 16px;
      letter-spacing: 0.44px;
      cursor: default;
    }
  }
}

.h-16 {
  height: 16px;
}
