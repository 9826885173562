@import "src/styles/functions";
@import "src/styles/variables/core/variables";
@import "src/styles/mixins";

app-custom-alert-image {
  .ow-dialog {
    max-width: 900px;
    max-height: 536px;
    background: #EBE5D5;
    border: 2px solid #000000;
    min-width: 100px;
    padding: 15px;
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &:after {
      content: '';
      position: absolute;
      top: -10px;
      left: -10px;
      bottom: -10px;
      right: -10px;
      background: $custom-dialog-outline;
      border: 2px solid #000000;
      z-index: -1;
    }

    perfect-scrollbar {
      max-height: 500px;
    }

    m-ui-close-button {
      position: absolute;
      top: 5px;
      right: -42px;
    }

    p {
      &.title {
        margin: 10px 0 10px 0;
        font-size: 22px;
        @include mikado;
        font-weight: bold;
        text-align: center;
        text-transform: uppercase;
      }
    }

    pinch-zoom {
      .pinch-zoom-content {
        height: 100%;

        img {
          max-height: 500px !important;
        }
      }
    }
  }
}
