@import "src/styles/mixins";

app-confirm-contract-alert {
  .ow-dialog {
    max-width: 600px;
    background: #EBE5D5;
    border: 3px solid #000000;
    min-width: 600px;
    min-height: 250px;
    padding: 70px 30px 50px 30px;
    display: flex !important;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    .description {
      @include mikado();
      font-size: 20px;
      font-weight: 700;
      margin-bottom: 38px;
      text-align: center;
      width: 100%;
    }

    .buttons-wrapper {
      display: flex;
      gap: 10px;
      justify-content: center;
      align-items: center;
      width: 100%;
    }

  }
}
