app-auction-details {
  @import "../../styles/styles";

  .ow-dialog {
    padding: 114px 154px;

    .content {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      height: 358px;

      .img {
        width: 348px;
        height: 358px;
        box-sizing: border-box;
        margin-right: 30px;

        img {
          width: 348px;
          height: 358px;
          object-fit: contain;
          object-position: right;
        }
      }

      .right {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        box-sizing: border-box;

        .title {
          @include mikado();
          font-size: 22px;
          width: 474px;
          line-height: 24px;
          letter-spacing: -0.22px;
          text-align: left;
          margin: 0 0 20px;
        }

        .desc {
          @include archivo-narrow();
          font-size: 16px;
          line-height: 18px;
          letter-spacing: 0;
          text-align: left;
          margin: 0 0 30px;
          height: 55px;
        }
      }
    }
  }

  .control-input {
    width: 100px;
    height: 36px;
    border: 2px solid #000000;
    margin-right: 4px;
    font-size: 22px;
    @include mikado();
    font-weight: bold;
    text-align: right;
    margin-left: 12px;
    border-radius: 15px;
  }

  .timer {
    margin: 0 0 0 4px;
  }

  //.warn {
  //  display: flex;
  //  align-items: center;
  //  justify-content: center;
  //
  //  m-ui-currency {
  //    .value {
  //      color: #E21900 !important;
  //    }
  //  }
  //}

  .offer {
    position: relative;

    .error {
      @include archivo-narrow();
      font-size: 12px;
      position: absolute;
      bottom: -16px;
      left: 15px;
    }
  }

  .choose-currency {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 38px;

    span {
      @include archivo-narrow();
      font-size: 16px;
      margin-left: 11px;

      &.fz-18 {
        font-size: 18px !important;
      }
    }
  }

  .separator {
    width: 1px;
    height: 40px;
    background: #959185;
    margin: 0 8px 0 20px;
  }

  .chose-btn {
    height: 34px !important;
    font-size: 16px;
    padding: 0;
    margin-left: 14px;

    &.pln {
      min-width: 112px !important;
      width: 112px !important;
    }

    &.zt {
      min-width: 140px !important;
      width: 140px !important;
    }
  }

  .auction-price {
    position: absolute;
    top: 48px;
    right: 76px;

    .stock-san-a {
      flex-direction: initial !important;

      .value {
        font-size: 20px !important;
        margin-right: 0 !important;
        margin-left: 6px;
      }

      .icon {
        width: 28px !important;
        height: 28px !important;
      }
    }
  }

  .bottom.v2 {
    flex-direction: column;
    align-items: flex-start;

    .item {
      padding: 0 !important;
    }
  }

  .txt {
    &.won {
      @include mikado();
      font-size: 20px;
      font-weight: bold;
      text-transform: uppercase;
      margin-bottom: 28px;
    }

    &.lost {
      @include archivo-narrow();
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 30px;
    }
  }

  m-ui-currency {
    margin-bottom: 0 !important;
  }

  .box {
    display: flex;
    flex-direction: column;
  }
}
