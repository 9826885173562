@import "src/styles/variables/core/variables";
@import "src/styles/mixins";

app-minigame-rank-details {
  .ow-dialog {
    padding: 17px 40px 40px 40px;
    position: relative;

    .title {
      margin-bottom: 24px;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    overflow: hidden;

    .frame {
      width: 100%;
      border-radius: 15px;
      padding: 16px 30px 28px 40px;
      height: 395px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
    }
  }

  .top {
    display: flex;
    margin-bottom: 16px;
    padding-left: 10px;

    .left {
      width: 50%;

      p {
        margin: 0;
        font-size: 16px;
        max-height: 24px;

        &.sub-title {
          font-weight: 900;
          margin-bottom: 10px;
        }

        &.description {
          @include roboto-cond;
          font-weight: 400;
        }
      }
    }

    .right {
      flex: 1;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      font-weight: bold;

      & > * {
        margin-left: 14px;
      }
    }
  }

  .box {
    display: flex;
    justify-content: space-between;
  }

  .filters {
    display: flex;
    align-items: center;
    margin: 16px 0 16px 14px;
    box-sizing: border-box;
  }

  .ow-radio-container {
    display: flex;
    align-items: center;

    &:not(:last-child) {
      margin-right: 20px;
    }

    .text-wrapper {
      @include mplus1c-rounded;
      font-weight: 900;
      font-size: 14px;
      text-transform: uppercase;
    }
  }

  .show-position {
    text-decoration: underline;
    font-size: 14px;
    font-weight: 900;
    display: flex;
    align-items: center;
    padding-right: 14px;

    a {
      color: #014859;
    }
  }

  table {
    @include roboto-cond;
    border-collapse: collapse;
    font-size: 14px;
    width: 100%;

    &.custom {
      tbody {
        tr {
          border-bottom: 1px solid rgba(0, 61, 82, 0.3);

          &:nth-child(even) {
            background-color: rgba(255, 255, 255, 1);
          }
        }
      }
    }

    tr {
      height: 34px;

      &.my-position {
        box-shadow: inset 0 0 0 2px rgba(0, 61, 82, 0.8);
      }
    }

    td {
      font-weight: bold;
      position: relative;

      &:first-child {
        @include mplus1c-rounded;
        font-weight: 900;
        padding-left: 15px;
      }

      &:last-child {
        padding-right: 6px;
      }
    }
  }

  :host::ng-deep {
    .pagination {
      position: absolute;
      top: 26px;
      right: 70px;
      display: flex;

      button {
        display: inline-block;
        padding: 3px 5px;
        cursor: pointer;

        i {
          font-size: 20px;
        }
      }

      .value {
        @include mplus1c-rounded;
        font-weight: 900;
        text-transform: uppercase;
        font-size: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 120px;

        .month {
          position: relative;
        }

        .date {
          position: relative;
        }
      }
    }
  }
}
