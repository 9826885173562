@import "src/styles/variables/core/variables";
@import "src/styles/mixins";

app-message-details-hidden-reward {
  .ow-dialog {
    padding: 50px 114px 50px 114px;
    display: flex;
    flex-direction: column;
    @include archivo-narrow;

    .back-bar {
      left: 72px !important;
      top: 128px !important;
    }

    .exit-bar {
      top: 42px !important;
      right: 10px !important;
    }

    .hidden-reward-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .hidden-reward-image {
        display: flex;
        justify-content: center;
        margin-bottom: 26px;

        img {
          max-width: 100%;
          object-fit: contain;
        }
      }

      .teaser {
        @include mikado;
        font-size: 24px;
        font-weight: 900;
        text-transform: uppercase;
        margin-bottom: 8px;
      }

      .inner-html-description {
        color: #000000;
        margin: 10px 0 24px 0;
        @include roboto-cond;
        font-weight: normal;
        font-size: 18px;
        text-align: center;
        padding: 0 100px;
      }

      .parameters {
        display: flex;
        justify-content: center;

        m-ui-product, m-ui-currency {
          margin: 0 10px;
        }
      }
    }

    button {
      position: absolute;
      bottom: 12px;
      right: 12px;
      border: none !important;
    }
    .trash {
      position: absolute;
      bottom: 28px;
      right: 70px;
      border: none;

      i {
        font-size: 16px;
        position: unset !important;
      }
    }
  }
}
