@import "src/styles/mixins";
@import "src/styles/variables/core/variables";

app-choose-task-cc {
  .ow-dialog {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 43px 80px 38px 100px;

    .frame {
      flex: 1;
      overflow: hidden;
    }
  }

  .description {
    @include archivo-narrow;
    font-size: 16px;
    margin: 6px 0 20px 0;
    padding: 0 50px;
    max-height: 88px;
    text-align: center;
  }

  perfect-scrollbar {
    width: 100% !important;
  }

  table {
    width: 100%;
    border-spacing: 0;

    tr {
      height: 42px;

      &:nth-child(odd) {
        background-color: #F9F4E4;
      }

      td {
        @include archivo-narrow;
        font-size: 14px;
        font-weight: bold;

        &:not(:first-child) {
          white-space: nowrap;
        }

        &:first-child {
          padding-left: 10px;
          text-align: left;
          width: 100%;
        }

        &:last-child {
          padding-right: 10px;
          text-align: right;

          button {
            display: inline-block;
            margin-left: 10px;

            i {
              font-size: 20px;
            }
          }
        }

        &.td-amount {
          font-size: 18px;
          padding-right: 10px;
        }

        &.task-title {
          width: 50%;
        }

        &.currency,
        &.product,
        &.emptyTd {
          width: 10%;
        }
      }
    }
  }
}
