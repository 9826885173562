@import "src/styles/mixins";

app-business-calendar-details {
  .ow-dialog {
    max-width: 484px;
    background: #EBE5D5;
    border: 3px solid #000000;
    min-width: 484px;
    min-height: 492px;
    padding: 20px 40px;
    display: flex !important;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    .file-description {
      @include absolute-center-horizontal();
      width: 100%;
      display: flex;
      bottom: 20px;
      @include archivo-narrow();
      font-size: 16px;

      span {
        width: 100%;
        text-align: center;
      }
    }

    m-ui-product {
      justify-self: start;

      .stock-j {
        flex-direction: row-reverse;
        gap: 4px;

        .value {
          font-size: 22px;
          @include mikado();
        }

        .icon {
          width: 50px;
          height: 50px;
        }
      }
    }

    m-ui-currency {
      justify-self: start;

      .stock-j {
        flex-direction: row-reverse;
        gap: 4px;

        .value {
          font-size: 22px;
          @include mikado();
        }

        .icon {
          width: 50px;
          height: 50px;
        }
      }
    }

    m-ui-close-button {
      position: absolute;
      top: 5px;
      right: -42px;
    }

    p {
      margin: 0;
      padding: 0;

      &.title {
        margin: 0 0 14px 0;
        font-size: 20px;
        @include mikado;
        font-weight: bold;
        text-align: left;
        text-transform: uppercase;
      }

      &.description {
        @include archivo-narrow;
        margin: 0;
        text-align: left;
        font-size: 16px;
        width: 100%;

        ul, ol {
          padding-left: 0;
          margin-left: 1em;
        }
      }
    }

    .prizes {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(2, 50px);
      gap: 20px;
      margin-top: 20px;
      justify-content: start;
      align-self: start;

      .item {
        text-align: left;
      }
    }

    button {
      &.c-base {
        margin-top: 20px;
      }
    }
  }
}
