@import "src/styles/mixins";
@import "src/styles/variables/core/variables";

app-star-missions-details {
  $arrow-size: 3px;

  .ow-dialog {
    padding: 52px 118px 40px 118px;
    display: flex;
    flex-direction: column;
    align-items: center;
    letter-spacing: 0;
    @include archivo-narrow;

    &.padding {
      padding-top: 80px;
    }

    .annie-image {
      position: absolute;
      top: 76px;
      right: -148px;
      z-index: 1;
    }

    .stars {
      display: flex;
      margin-bottom: 16px;

      .star {
        margin-right: 18px;
        height: 90px;
        width: 90px;
        background-size: contain;
        background-image: var(--asset--ui-star_empty-png);

        &.camp {
          background-image: var(--asset--ui-city_empty-png);

          &.half {
            background-image: var(--asset--ui-city_half-png);
          }

          &.full {
            background-image: var(--asset--ui-city_full-png);
          }
        }

        &.trail {
          background-image: var(--asset--ui-map_empty-png);

          &.half {
            background-image: var(--asset--ui-map_half-png);
          }

          &.full {
            background-image: var(--asset--ui-map_full-png);
          }
        }

        &.bulb {
          background-image: var(--asset--ui-scrolls_empty-png);

          &.half {
            background-image: var(--asset--ui-scrolls_half-png);
          }

          &.full {
            background-image: var(--asset--ui-scrolls_full-png);
          }
        }
      }
    }

    .title {
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 14px;
      margin-bottom: 12px;

      span {
        @include mikado;
        font-size: 22px;
        font-weight: bold;
        text-transform: uppercase;
      }
    }

    .description {
      font-size: 16px;
      width: 736px;
      text-align: center;
      margin-bottom: 14px;
    }

    .progress {
      display: flex;
      flex-direction: column;
      width: 100%;
      align-items: center;

      .progress-title {
        font-size: 14px;
        margin-bottom: 8px;
      }

      .tabs {
        align-items: center;
        position: relative;
        top: auto;
        width: 100%;
        height: 20px;
        border: solid 2px #000000;
        border-radius: 4px;
        margin-bottom: 14px;

        .tab {
          display: flex;
          flex: 1;
          height: 100%;
          position: relative;
          text-align: center;
          pointer-events: none;
          justify-content: center;
          align-items: center;
          background-color: #DED8C6;
          margin-left: 0 !important;
          margin-right: 0 !important;
          &:only-child {
            margin-right: 0;
          }

          &.active {
            background-color: #ffee00;
            box-shadow: inset 0 -4px 0 0 #FFBB00;
            &:after {
              background-color: #F9F4E4;
              position: absolute;
              top: 10px;
              left: 46%;
              z-index: 10;
              border: none;
            }
          }

          &.complete {
            &.green {
              background-color:#77CC4F ;
              box-shadow: inset 0 -4px 0 0 #53AA2A;
            }

            &.orange {
              background-color: #FF8C00;
              box-shadow: inset 0 -4px 0 0 #FF6600;
            }

            &.blue {
              background-color: #2EB0FE;
              box-shadow: inset 0 -4px 0 0 #058EDF;
            }
          }

          .tab-separator {
            display: flex;
            flex-direction: column;
            position: absolute;
            justify-content: space-between;
            right: -$arrow-size;
            z-index: 2;
            height: 100%;

            .separator-arrow {
              width: 0;
              height: 0;

              &.arrow-down {
                border-left: $arrow-size solid transparent;
                border-right: $arrow-size solid transparent;
                border-top: $arrow-size solid #000000;
              }

              &.arrow-up {
                border-left: $arrow-size solid transparent;
                border-right: $arrow-size solid transparent;
                border-bottom: $arrow-size solid black;
              }
            }
          }
        }
      }
    }

    .missions {
      display: flex;
      width: 100%;
      background-color: #F9F4E4;
      margin-bottom: 16px;
      padding: 6px 0;
      align-items: center;
      justify-content: center;
      height: auto;
      min-height: 124px;
      max-height: 150px;
      z-index: 11;
      box-sizing: border-box;

      .missions-info {
        flex: 1;
        font-size: 14px;
        padding-left: 21px;
      }

      .missions-separateor {
        width: 2px;
        height: 108px;
        background-color: #EBE5D5;
        margin: 0 13px;
      }

      .missions-stages {
        flex: 1;
        padding: 10px 16px 10px 0;

        .stage {
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-size: 14px;
          box-sizing: border-box;
          height: 20px;

          &:not(:last-child) {
            margin-bottom: 8px;
          }

          .stage-box {
            display: flex;
            align-items: center;

            &.stage-box-active {
              color: #18AE29;

              .box {
                &:after {
                  content: '';
                  background-image: var(--asset--ui-check_small-png);
                  width: 26px;
                  height: 26px;
                  position: absolute;
                }
              }
            }

            .box {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 20px;
              height: 20px;
              border: solid 2px #000000;
              border-radius: 4px;
              font-size: 14px;
              margin-right: 10px;
            }
          }

          .objective {
            min-width: 25px;
            text-align: right;
          }
        }
      }
    }
  }

  .bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    .mission-prizes {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
  }

  m-ui-separator {
    width: 100%;
    .separator {
      padding-left: 10px;
      justify-content: flex-start;
      margin: 0 0 19px 0;
      .padding{
        font-size: 12px !important;
        @include archivo-narrow();
        font-weight: normal !important;
        color: #9C9583 !important;
        &::after{
          width: 750px;
        }
      }
    }
  }

  m-ui-currency, m-ui-product {
    &:not(:last-child) {
      margin-right: 12px;
    }

    &:last-child {
      margin-right: 30px;
    }
  }

  .stock-san-a {
    flex-direction: row !important;

    .value {
      font-size: 16px;
      padding-top: 0;
      padding-left: 4px;
    }
  }

  .success {
    font-family: "Mikado", sans-serif;
    text-align: center;
    font-weight: bold;
    font-size: 20px;
  }

  .buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    button {
      margin: 0 5px;
    }
  }

  .exp {
    display: flex;
    align-items: center;
    margin-right: 30px;

    img {
      width: 50px;
      height: 50px;
      object-fit: contain;
    }

    .value {
      @include mikado;
      font-size: 16px;
      color: #000000;
      padding-left: 4px;
    }
  }
}
