@import "src/styles/mixins";

app-branch-preview {
  .ow-dialog {
    padding: 40px 96px 45px 84px;
    display: flex;
    flex-flow: column;

    perfect-scrollbar {
      .ps-content {
        padding-right: 42px !important;
      }
    }

    .frame {
      flex: 1;

      .table-wrapper {
        height: 406px;
        width: 100%;

        table {
          width: 100%;
          border-spacing: 2px;
          border-collapse: collapse;
          white-space: nowrap;

          tbody {

            tr {
              height: 60px;

              &:nth-child(odd) {
                background: #F9F4E4;
              }

              td {
                @include archivo-narrow;
                font-size: 16px;
                white-space: nowrap;
                padding: 0 10px;

                &:nth-child(1),
                &:nth-child(2) {
                  text-align: left;
                }

                .inner {
                  display: flex;
                  align-items: center;
                  justify-content: flex-start;
                }

                .number {
                  margin-left: 10px;
                  font-weight: 900;
                  @include mikado;
                }

                .default {
                  height: 34px;
                  width: 35px;
                  background-size: contain;
                  background-repeat: no-repeat;
                }

                &.avatar {
                  width: 44px;

                  .icon {
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;
                    background: #FFFFFF;
                    position: relative;
                    border: 1px solid #707070;

                    .default-avatar {
                      position: absolute;
                      top: 50%;
                      left: 50%;
                      transform: translate(-50%, -50%);
                      font-size: 30px;
                      color: #D1CAB9;
                    }

                    .custom-avatar {
                      width: 100%;
                      height: 100%;
                      z-index: 2;
                      position: absolute;
                      border-radius: 50%;
                      background-size: contain;
                    }
                  }
                }

                &.info {
                  font-size: 14px;

                  .name {
                    font-weight: bold;
                  }

                  &.allow-change-player {
                    &:hover {
                      cursor: pointer;
                      text-decoration: underline;
                    }
                  }
                }

                &.level {
                  width: 40px;
                  padding: 0 17px;
                }

                &.icon-td {
                  font-size: 16px;
                  font-weight: bold;
                  @include mikado();
                  letter-spacing: -0.16px;
                  box-sizing: border-box;
                  padding: 0;
                  width: 10%;

                  img {
                    width: 40px;
                    height: 40px;
                    margin-right: 4px
                  }

                  .inner {
                    width: 100%;
                    border-left: 2px solid #AAA495;
                    padding: 0 20px;
                    box-sizing: border-box;
                  }
                }

                .level-circle {
                  width: 40px;
                  height: 40px;
                  border: 3px solid #000000;
                  box-sizing: border-box;
                  background-color: #9BE222;
                  color: #006E0B;
                  font-size: 24px;
                  font-weight: bold;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  border-radius: 100px;
                }
              }
            }
          }
        }
      }
    }
  }
}
