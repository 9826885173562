@import "src/styles/mixins";

$wf_red: #E13636;

.wf-mahjong-container {
  width: 100%;
  height: 100%;

  // this one is set via ServiceLoader, override its behaviour
  &.prevent-loading-opacity {
    opacity: 1 !important;
    filter: unset !important;
  }

  .top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;

    &:has(:only-child) {
      justify-content: flex-end;
    }

    .points {
      display: flex;
      align-items: center;
      gap: 4px;
      user-select: none;

      p {
        font-size: 22px;
        line-height: 30px;
        margin: 0;
        @include mikado;
        font-weight: bold;
      }
    }

    .date {
      font-size: 16px;
      line-height: 21px;
      font-weight: bold;
      @include archivo-narrow;
      font-weight: bold;
    }
  }

  .center {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 50px;

    .mahjong-box {
      position: relative;
      border: 2px solid black;
      width: 340px;
      height: 340px;
      background-color: white;

      .decorations {
        z-index: 1;
        pointer-events: none;
        position: absolute;
        border: 4px solid $wf_red;
        width: calc(100% - 8px);
        height: calc(100% - 8px);

        .inner-box {
          position: absolute;
          border: 2px solid $wf_red;
          top: 4px;
          left: 4px;
          border-radius: 4px;
          width: calc(100% - 12px);
          height: calc(100% - 12px);
        }

        .corner-box {
          position: absolute;
          width: 17px;
          height: 17px;
          background-color: $wf_red;

          // fix subpixel rendering gap
          &.v-0 {
            top: -1px;
            left: -1px;
          }

          &.v-1 {
            top: -1px;
            right: -1px;
          }

          &.v-2 {
            bottom: -1px;
            left: -1px;
          }

          &.v-3 {
            bottom: -1px;
            right: -1px;
          }
        }
      }

      .content {
        position: relative;
        z-index: 0;
        margin-bottom: 4px;

        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        padding: 20px 40px;
        min-width: 340px;
        min-height: 340px;
        box-sizing: border-box;

        flex-direction: column;

        .piece {
          width: 42px;
          height: 52px;
          transition: 0.2ms ease-in-out;
          user-select: none;
          box-sizing: border-box;

          &:not(:empty) {
            cursor: pointer;
          }

          &.hidden {
            visibility: hidden;
            pointer-events: none;
            opacity: 0;
            cursor: unset;

            &:hover {
              filter: unset !important;
              cursor: unset !important;
            }
          }

          &.unclickable {
            pointer-events: none;

            &:hover {
              filter: unset;
              cursor: not-allowed;
            }

            &:empty {
              filter: unset;
              cursor: unset;
            }
          }

          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }

          &:hover:not(:empty) {
            filter: brightness(1.1);
            cursor: pointer;
          }
        }
      }

      .pieces-left {
        font-size: 16px;
        line-height: 20px;
        margin: 0;
        text-align: center;
        @include archivo-narrow;
        font-weight: bold;
      }
    }
  }

  .bottom {
    position: relative;
    width: 100%;
    max-width: 1144px;

    .pieces-container {
      background-color: #C0BAA4;
      border: 3px solid black;
      position: absolute;
      display: flex;
      justify-content: center;
      padding: 0 32px;
      gap: 4px;
      left: 50%;
      transform: translateX(-50%);
      height: 80px;
      top: 68px;
      min-width: 300px;

      .relative {
        position: relative;
        max-width: 1144px;
        overflow: hidden;

        swiper-container {
          height: 100%;

          transition: opacity 0.2ms ease-in-out;
        }

        .piece {
          max-width: 44px;
          transition: 0.2ms ease-in-out;
          user-select: none;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100%;

          &:hover {
            filter: brightness(1.1);
            cursor: pointer;
          }

          .new {
            border: 2px solid black;
            background-color: #7CDF4F;
            position: absolute;
            top: 2px;
            left: 50%;
            transform: translateX(-50%);
            font-size: 12px;
            line-height: 16px;
            @include mikado;
            font-weight: bold;
            width: 35px;
            height: 18px;
            box-sizing: border-box;
            text-align: center;

            &.show {
              display: block;
            }

            &.hide {
              display: none;
            }
          }

          .amount {
            background-image: var(--asset--ui-wf_badge-png);
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            width: 30px;
            height: 30px;
            justify-content: center;
            align-items: center;
            font-size: 16px;
            line-height: 20px;
            font-weight: bold;
            @include archivo-narrow;

            &.show {
              display: flex;
            }

            &.hide {
              display: none;
            }
          }
        }
      }

      .nav-btn {
        position: absolute;
        width: 76px;
        height: 66px;
        cursor: pointer;
        transition: 0.3s ease-in-out;

        &.disabled {
          filter: grayscale(1);
          cursor: not-allowed;
        }

        &.left {
          left: -78px;
          transform: rotate(180deg) scaleY(-1);
          top: 6px;
        }

        &.right {
          right: -78px;
          top: 6px;
        }
      }
    }
  }
}
