@import "src/styles/functions";

app-photo-like {
  @import "../../styles/style";

  .photo-like {
    display: flex;
    align-items: center;
    @include roboto-cond;
    font-size: multiply-size(16px);
    font-weight: normal;
    position: relative;
    color: $color-dark;
    user-select: none;

    &.is-liked {
      color: #0079ef;
      font-weight: bold;

      button {
        i {
          color: #0079ef;
        }
      }
    }

    button {
      width: multiply-size(34px);
      height: multiply-size(48px);
      background: transparent;
      position: relative;
      color: $color-dark;

      i {
        font-size: multiply-size(20px);
      }
    }

    span.likes-number {
      width: multiply-size(20px);
      height: multiply-size(48px);
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .likes-list {
      @include roboto-cond;
      position: absolute;
      bottom: multiply-size(40px);
      font-weight: normal;
      background-color: #ffffff;
      color: $color-dark;
      padding: multiply-size(6px);
      border: multiply-size(1px) solid $color-light;
      border-radius: multiply-size(4px);
      right: 0;
      z-index: 1;
      white-space: nowrap;
      font-size: multiply-size(13px);
    }
  }

  loading {
    .loading-content {
      top: 0 !important;
      left: 0 !important;
      right: 0 !important;
      bottom: 0 !important;
      position: relative !important;
    }
  }
}
