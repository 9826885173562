@import "src/styles/variables/core/variables";
@import "src/styles/mixins";
@import "src/styles/dialogs/core/mixins";
@import "src/styles/dialogs/core/variables";

app-star-missions {
  $bgc-overlap: $color-I;
  overflow-y: visible;
  .exit-bar {
    right: -60px;
    top: 90px;
    i {
      color: $color-I;
    }
  }

  .navigation-bar {
    position: absolute;
    @include absolute-center-vertical;

    &.prev {
      left: -65px !important;
    }

    &.next {
      right: -65px !important;
    }
  }

  .global-dialog {
    width: 960px;
    height: 600px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    swiper-container{
      width: 100%;
      height: 100%;
      .slide{
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow-y: visible;
        .mission{
          position: relative;
          height: 400px;
          width: 300px;
          background-color: #ebe5d5;
          border: 3px solid #000000;
          box-sizing: border-box;
          display: flex;
          justify-content: center;
          overflow-y: visible;
          margin-left: 10px;
          margin-right: 10px;
          .foto{
            position: absolute;
            top: -18px;
            height: 224px;
            width: 210px;
            background-position: center;
            background-repeat: no-repeat;
          }
          .title{
            @include mikado();
            margin-top: 280px;
            font-size: 20px;
            text-align: center;
          }
          .badge{
            position: absolute;
            top: -40px;
            width: 200px;
            height: 34px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #7CDF4F;
            border: 3px solid black;
            font-size: 20px;
            @include mikado();
          }
          &:hover{
            transition: all 0.3s;
            box-shadow: 0 0 12px #ffffff;
          }
        }
      }

    }
  }
}
