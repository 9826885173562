@import "src/styles/functions";
@import "src/styles/mixins";
@import "src/styles/variables/core/variables";

app-m-qa-finish {
  .ow-dialog {
    padding: multiply-size(20px);
  }

  .quiz-end {
    .title {
      margin-bottom: 0;
      max-height: multiply-size(36px);
    }

    .description {
      margin: multiply-size(10px);
      text-align: center;
      max-height: multiply-size(80px);
      overflow: hidden;
      @include archivo-narrow;
      font-size: multiply-size(12px);
    }
  }

  .wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
  }

  .title {
    width: 100%;
    text-align: center;
    @include text-stroke;
    @include mikado;
    font-size: multiply-size(14px);
    margin-bottom: multiply-size(15px);
    color: $color-I;
    max-height: multiply-size(18px);
    overflow: hidden;
  }

  @import "custom/style";
}
