@import "src/styles/functions";
@import "src/styles/mixins";

app-device-rotate {
  #landscape-screen {
    display: block;
    position: fixed;
    z-index: 2000;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: white;
    color: black;
    text-align: center;
    @include archivo-narrow;

    .flex-center {
      flex-direction: column;
      height: 100%;
      width: 100%;
    }

    .mobile-icon {
      font-size: multiply-size(50px);
      display: inline-block;
      transform: rotate(-90deg);
    }

    .land-title,
    .land-description {
      margin: multiply-size(2px);
    }

    .land-title {
      margin-top: multiply-size(20px);
      font-weight: bold;
      font-size: multiply-size(24px);
      text-transform: uppercase;
    }

    .land-description {
      font-size: multiply-size(18px);
    }
  }

  @import "custom/style";
}
