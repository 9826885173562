@import "src/styles/mixins";
@import "src/styles/variables/core/variables";

app-custom-qa-finish {
  .c-window-long {
    padding: 100px 103px 102px 103px;
    box-sizing: border-box;
  }

  .title {
    margin-bottom: 20px;
  }

  .frame {
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    overflow: hidden;

    p {
      @include archivo-narrow();
      font-size: 16px;
      text-align: center;
      padding: 0 100px;
      margin: 14px 0 0 0;
    }

    .inner-html-description {
      margin-bottom: 16px;
      max-height: 106px;
    }
  }

  .bottom {
    @include absolute-center-horizontal;
    bottom: 50px;

    button {
      margin: 0 auto;
    }
  }

  .quiz {
    margin-bottom: 20px;
    @include mikado();
    font-size: 20px;
    text-align: center;
  }

  .location-50 {
    .inner-html-description {
      font-size: 18px;
      text-align: center;
      padding: 0;
      text-transform: initial;
      @include archivo-narrow();
    }
  }
}
