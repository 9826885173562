@import "src/styles/mixins";
@import "src/styles/custom/mixins";

app-minigames {

  .swiper {
    overflow: unset;
  }

  // slider games
  .slider-games-container {
    position: relative;

    .navigation-bar {
      position: absolute;
      @include absolute-center-vertical;

      &.prev {
        left: -60px;
      }

      &.next {
        right: -60px;
      }
    }

    .global-dialog {
      max-width: 1148px;
      height: 430px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .game {
      display: flex;
      justify-content: center;
      padding: 70px 0 20px 0;

      //  Mission window
      .c-window-b {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        padding: 0 26px 22px 26px;
        width: 272px;
        height: 360px;
        background: var(--asset--ui-window_mini-png);
        background-repeat: no-repeat;
        background-position: bottom;
        margin: 0 8px;

        .stats {
          position: absolute;
          bottom: 12px;
          left: 7px;
          display: flex;
          align-items: center;

          img {
            margin-right: 4px;
            width: 30px;
            height: 30px;
            object-fit: contain;
          }
        }

        .rank {
          position: absolute;
          bottom: 14px;
          right: 10px;
          font-size: 22px;
        }

        .logo {
          margin-top: -70px;
          width: 220px;
          height: 176px;
          margin-bottom: 8px;
        }
      }
    }

    .costs-title {
      @include mikado;
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 8px;
    }

    .resources-container {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 24px;
      background: #F2EDDF;
      border: 2px solid #000000;
      border-radius: 20px;
      margin-bottom: 10px;

      & > * {
        margin: 0 9px;
      }
    }

    m-ui-costs {
      margin-bottom: 10px;
    }

    .play-button {
      margin-bottom: 12px;
    }

    m-ui-close-button {
      position: absolute;
      right: -60px;
      top: 0;

      //.exit-bar button {
      //  border-width: 0;
      //}
      //
      //&:before {
      //  content: "";
      //  height: 35px;
      //  display: block;
      //  width: 3px;
      //  position: absolute;
      //  background-color: black;
      //  z-index: 2;
      //  top: 8px;
      //}
    }

    .terms {
      @include archivo-narrow;
      font-size: 16px;
      text-decoration: underline;
      text-transform: initial;
      font-weight: bold;
    }
  }
}
