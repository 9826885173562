app-auth-info {
  .info-btn {
    position: absolute;
    top: 8px;
    right: 8px;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    i {
      color: #000;
    }
  }
}
