app-helpdesk-info {
  .helpdesk {
    position: absolute;
    bottom: 30px;
    right: 30px;
    font-size: 16px;
    @include mikado;
    color: #ffffff;
    text-shadow: 0 0 3px rgba(0, 0, 0, .6), 0 0 3px rgba(0, 0, 0, .6), 0 0 3px rgba(0, 0, 0, .6), 0 0 3px rgba(0, 0, 0, .6), 0 0 3px rgba(0, 0, 0, .6), 0 0 3px rgba(0, 0, 0, .6), 0 0 3px rgba(0, 0, 0, .6), 0 0 3px rgba(0, 0, 0, .6), 0 0 3px rgba(0, 0, 0, .6), 0 0 3px rgba(0, 0, 0, .6);
  }
}
