app-micro-league-details {
  @import "../../../styles/styles";

  .toggle-group {
    @include archivo-narrow();
    font-size: 12px;
    position: absolute;
    right: 70px;
    bottom: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    span {
      margin-right: 7px;
    }

    i {
      font-size: 20px;
    }
  }

  .description {
    padding-left: 20px;
    @include archivo-narrow();
    height: 40px;
    font-size: 16px;
    margin-bottom: 0;
  }

  .title {
    margin-bottom: 13px;
  }

  table {
    thead {
      tr {
        td {

          &.value1 {
            text-align: center;
          }

        }
      }
      .value1 {
        width: 14%;
        text-align: center;
        padding-right: 10px;
      }
    }
    tbody {
      tr {
        td {
          &.position {
            width: 4%;
            padding-left: 20px;
          }

          &.prize {
            width: 28.5% !important;
            padding-right: 20px;
          }

          &.value1 {
            width: 8.6% !important;
            text-align: right;
          }

          &.name {
            width: 59%;
          }
        }
      }
    }

    .prize {
      .my-prizes {
        m-ui-currency, m-ui-product {
          margin: 0 0 0 10px;
        }
      }
    }
  }
}
