app-edenred-card-history {
  @import '../styles/style';

  .topup-amount, .topup-date, .card-number, .topup-status {
    width: 20% !important;
  }

  .topup-amount, .topup-date {
    text-align: center !important;
  }

  .topup-status {
    text-align: left !important;
    padding-left: 20px !important;
  }
}
