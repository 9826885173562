@import "src/styles/variables/core/variables";
@import "src/styles/mixins";

app-basic-actions {
  $sticky-actions: $color-R;

  .no-data {
    @include absolute-center();
    @include archivo-narrow();
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: bold;
  }

  .branch-name {
    @include absolute-center-horizontal;
    @include archivo-narrow();
    font-size: 16px;
    min-width: 250px;
    padding: 0 10px;
    height: 31px;
    border: 3px solid #000;
    border-top: 1px solid #000;
    background: #FFD500;
    bottom: -11px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
  }

  .ow-dialog {
    display: flex;
    flex-direction: column;

    &.c-window-short {
      padding: 42px 114px 48px 86px;

      .frame {
        width: 100%;
        height: 434px;
        display: flex;
        flex-direction: column;

        &.current {
          height: 100% !important;
        }
      }
    }

    .top-right {
      position: absolute;
      right: 80px;
      top: 40px;
    }
  }

  .title, .title-2 {
    font-weight: 900;
    text-align: left;
    text-transform: uppercase;
  }

  .title {
    font-size: 24px;
  }

  .title-2 {
    font-size: 16px;
    @include mikado();
    padding-left: 14px;
  }

  .description {
    padding: 0 14px;
    @include archivo-narrow();
    font-size: 16px;
    margin: 6px 0 22px 0;
    text-align: left;
    cursor: pointer;
    max-height: 36px;
  }

  .navigate-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: bold;
    padding: 0 14px;

    .title-nav {
      @include mikado();
      font-weight: bold;
      font-size: 16px;
      text-transform: uppercase;
      text-align: left;
      margin-right: 40px;
    }

    .navigate-date {
      display: flex;

      .nav-arrow {
        text-shadow: none !important;
        color: #000000;
        font-size: 20px;
      }

      .current {
        @include archivo-narrow();
        font-size: 16px;
        font-weight: bold;
        margin: 0 20px;
      }
    }
  }

  perfect-scrollbar {
    margin-top: 8px;
  }

  table {
    @include archivo-narrow();
    border-collapse: collapse;

    thead {
      tr {
        height: 37px;
      }
    }

    tr {
      th, td {
        padding: 0 14px;

        &:first-child {
          text-align: left;
        }

        &.border-color {
          @include mikado;
          border-top-width: 0;
          border-bottom-width: 0;
          text-align: center;
          font-weight: 900;
          font-size: 14px;
        }
      }

      th {
        font-size: 12px;
        font-weight: normal;
        padding-top: 2px;
        padding-bottom: 2px;
      }

      td {
        font-size: 14px;
        font-weight: bold;
        height: 37px;

        .prizes {
          display: flex;
          justify-content: flex-end;

          .prize {
            font-weight: bold;
            font-size: 14px;
            justify-content: flex-end;

            &:not(:last-child) {
              margin-right: 12px;
            }

            .icon {
              margin-left: 3px;
            }
          }

          &:last-child {
            margin-right: 0;
          }
        }

        &.width-6 {
          width: 6%;
        }

        &.width-12 {
          width: 12%;
        }

        &.width-70 {
          width: 70%;
        }
      }

      &.summary {
        @include mikado;
        font-weight: 900;

        td {
          font-size: 14px;
          font-weight: 900;
          height: 38px;

          &:not(:last-child) {
            text-transform: uppercase;
          }

          .limit-info {
            font-size: 8px;
            font-weight: normal;
          }

          &:first-child {
            text-align: right;
          }

          &.border-color {
            @include mikado;
            border-left-width: 0;
            border-right-width: 0;
          }
        }
      }
    }

    tbody {
      tr {
        &:nth-child(odd):not(.summary) {
          background: #F9F4E4;
        }
      }
    }
  }

  .back-bar {
    top: 6px;
    left: 2px;

    button {
      border: none !important;

      i {
        font-size: 24px !important;
      }
    }
  }

  button.list {
    i {
      font-size: 16px;
    }
  }

  .stock-g {
    flex-direction: row-reverse;

    .icon {
      width: 29px;
      height: 29px;
    }

    .value {
      font-size: 16px !important;
      @include archivo-narrow();
      text-shadow: none !important;
      color: #000000;
      margin-left: 0 !important;
      margin-right: 4px;
    }
  }

  .line-h-10 {
    line-height: 10px;
  }
}
