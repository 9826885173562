@import "src/styles/mixins";
@import "src/styles/variables/core/variables";

app-building-upgrade-diff-production {

  .upgrade-container {
    display: flex;

    m-ui-currency, m-ui-product {
      margin-bottom: 4px;
    }

    .upgrade-label {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      span {
        @include mikado;
        font-size: 14px;
        color: #000000;
        font-weight: bold;

        &.quantity-diff {
          color: #18AE29;
        }

        &.quantity-diff-minus {
          color: $color-octonary;
        }
      }
    }

    .upgrade-value-container {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      &.has-normal {
        margin-top: 2px;
      }

      .upgrade-arrow {
        width: 18px;
        height: 16px;
        margin-right: 2px;
        background: var(--asset--ui-arrow_small-png);
        transform: rotate(90deg);
      }
    }
  }
}
