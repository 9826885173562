@import "src/styles/mixins";
@import "src/styles/variables/core/variables";

app-antyminer-board {
  .global-dialog {
    .exit-bar {
      right: 17px;
      top: 65px;
    }
  }

  .ow-dialog {
    padding: 50px 183px; // 28px 114px 35px 114px;
    display: flex;
    flex-direction: column;
    width: 1366px;
    height: 690px;

    &.field {
      background-image: var(--asset--antyminer-antyminer_field-png);
    }

    &.forest {
      background-image: var(--asset--antyminer-antyminer_forest-png);
    }

    &.river {
      background-image: var(--asset--antyminer-antyminer_river-png);
    }
  }

  .board-pill {
    position: relative;
    background-color: #fcf9ef;
    border: 2px solid #000000;
    border-radius: 10px;
    min-width: 68px;
    height: 20px;

    &.long {
      width: 80px;
    }

    img {
      width: 28px;
      height: 28px;
      position: absolute;
      left: -14px;
      top: -4px;
    }

    p {
      @include mikado;
      font-size: 16px;
      line-height: 22px;
      text-align: right;
      margin: 0;
      padding-right: 6px;
    }
  }

  .bottom-hud,
  .top-hud {
    display: flex;
    flex-direction: column;
    gap: 16px;
    position: absolute;
    left: 80px;
  }

  .top-hud {
  }

  .bottom-hud {
    bottom: 38px;
  }

  .frame {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    position: relative;

    .board-wrapper {
      display: flex;
      flex-direction: column-reverse;
      align-items: center;
      justify-content: center;
      height: 100%;
      width: 100%;

      .row {
        display: flex;

        //// set top rows in the middle
        //&:last-child {
        //  .cell {
        //    &.marked {
        //      .cross {
        //        background-position-y: 50%;
        //      }
        //    }
        //  }
        //}

        .cell {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          position: relative;
          width: 100px;
          height: 100px;

          &::after {
            position: absolute;
            display: block;
          }

          &.blocked {
            user-select: none;
            //cursor: not-allowed;
          }

          /* chess table */
          &.grid-a {
            background-image: var(--asset--tracking-grid_a-png);
          }

          &.grid-b {
            background-image: var(--asset--tracking-grid_b-png);
          }

          &.grid-c {
            background-image: var(--asset--tracking-grid_c-png);
          }

          /* bushes */
          &.bush {
            user-select: none;

            &.clickable {
              cursor: pointer;

              &:not(.blocked):hover {
                filter: brightness(90%);
              }
            }

            &.blocked {
              cursor: unset;
            }

            &::after {
              content: "";
              width: 144px;
              height: 144px;
              left: -22px;
              top: -22px;
              pointer-events: none;
            }

            &.field,
            &.forest,
            &.river {
              &::after {
                background-image: var(--asset--buildings-antyminer_cloud-1-png);
              }
            }
          }

          //// set X position of last element in a row
          //&:last-child {
          //  &.marked {
          //    .cross {
          //      background-position-x: 50%;
          //    }
          //  }
          //}

          &.bush.marked {
            .cross {
              display: block;
              position: absolute;
              width: 144px;
              height: 144px;
              left: -22px;
              top: -22px;
              pointer-events: none;
              z-index: 5;
              background-repeat: no-repeat;
              background-position: center;
              //background-position-x: 40%;
              //background-position-y: 60%;
              background-image: var(--asset--antyminer-antyminer_x-png);
            }
          }

          .cross {
            display: none;
          }

          .hint {
            width: 100%;
            height: 100%;
            z-index: 2;
            background-repeat: no-repeat;
            background-position: center;

            &.v-0 {
              background-image: var(--asset--antyminer-antyminer_0-png);
            }

            &.v-1 {
              background-image: var(--asset--antyminer-antyminer_1-png);
            }

            &.v-2 {
              background-image: var(--asset--antyminer-antyminer_2-png);
            }

            &.v-3 {
              background-image: var(--asset--antyminer-antyminer_3-png);
            }

            &.v-4 {
              background-image: var(--asset--antyminer-antyminer_4-png);
            }

            &.v-5 {
              background-image: var(--asset--antyminer-antyminer_5-png);
            }

            &.v-6 {
              background-image: var(--asset--antyminer-antyminer_6-png);
            }

            &.v-7 {
              background-image: var(--asset--antyminer-antyminer_7-png);
            }

            &.v-8 {
              background-image: var(--asset--antyminer-antyminer_8-png);
            }
          }

          /* items */
          .items {
            width: 100%;
            height: 100%;
            z-index: 2;
            background-size: contain;

            &.antyminer_chest_blue {
              background-image: var(--asset--buildings-antyminer_chest_blue-1-png);
            }

            &.antyminer_building_materials {
              background-image: var(--asset--buildings-antyminer_building_materials-1-png);
            }

            &.antyminer_chemicals {
              background-image: var(--asset--buildings-antyminer_chemicals-1-png);
            }

            &.antyminer_garbage {
              background-image: var(--asset--buildings-antyminer_garbage-1-png);
            }

            &.antyminer_natural_fertilizer {
              background-image: var(--asset--buildings-antyminer_natural_fertilizer-1-png);
            }
            &.antyminer_recycled_materials {
              background-image: var(--asset--buildings-antyminer_recycled_materials-1-png);
            }
            &.antyminer_wood {
              background-image: var(--asset--buildings-antyminer_wood-1-png);
            }
          }
        }
      }
    }
  }

  @keyframes tracking {
    0% {
      font-size: 16px;
    } // ; transform: rotate(-10deg) }
    //33% { transform: rotate(10deg) }
    //45% { transform: rotate(360deg) }
    //66% { transform: rotate(350deg) }
    100% {
      font-size: 20px;
    } // ; transform: rotate(370deg) }
  }
}
