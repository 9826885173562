app-micro-league {
  @import "../../styles/styles";

  .button-league {
    border: 2px solid #000000;
    width: 164px;
    height: 30px;
    box-sizing: border-box;
    cursor: pointer;
    @include mikado();
    font-weight: bold;
    font-size: 16px;
    background-color: #F1ECDE;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 2px;
  }

  .btn-tooltip {
    position: absolute;
    bottom: 60px;
    right: 104px;
    width: 164px;
    height: 30px;
  }

  table {

    .chest-button {
      background-image: var(--asset--ui-box-png);
      width: 29px;
      height: 29px;
      background-size: contain;
      background-repeat: no-repeat;
      margin-left: 5px;
    }

    .prize {
      .my-prizes {
        m-ui-currency, m-ui-product {
          margin-left: 10px;
        }
      }
    }

    tbody {
      .date, .name {
        vertical-align: top;
        padding-top: 10px;
      }

      .value1 {
        font-size: 14px;
        border-left: 2px solid #F2EDDF;
        border-right: 2px solid #F2EDDF;
        text-align: center;
      }

      .info, .chest {
        border-left: 2px solid #F2EDDF;
        padding: 0;
      }

      td {
        font-size: 16px;
      }
    }

    tr {
      cursor: pointer;
      height: 70px;
      border-top: 1px solid #CEC7B4;
      position: relative;
    }

    .date {
      width: 15.3%;
    }
    .name {
      width: 37.6%;
    }
    .value1 {
      width: 13.6%;
    }
    .prize {
      width: 30.1%;
      padding-right: 10px;
    }
    .info, .chest {
      width: 3.4% !important;
    }

    &.league-closed {
      .prize {
        width: 30.1% !important;
      }

      .info {
        padding: 0;
        text-align: center;
      }
    }

    thead {
      .value1 {
        font-size: 12px;
        padding: 0 0 0 12px;
        text-align: center;
      }
      .prize {
        text-align: right;
        padding: 0 0 0 20px;
      }
    }

    &.league-closed {
      thead {
      }
    }

  }

  .accept-rules {
    display: flex;
    align-items: center;
    font-size: 14px;
    cursor: pointer;
    position: absolute;
    left: 16px;
    top: 33px;
    padding: 0 4px;
    height: 28px;

    .box {
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 20px;
      height: 20px;
      border: solid 2px #000000;
      border-radius: 4px;
      font-size: 14px;
      margin-right: 14px;
    }

    &.cursor-default {
      cursor: default;
    }

    &.chest-background {
      background: #FFDC3E;
    }

    &.box-active {

      .box {
        &:after {
          content: '';
          background-image: var(--asset--ui-check_small-png);
          width: 26px;
          height: 26px;
          position: absolute;
        }
      }
    }
  }

  .cursor-default {
    cursor: default;
  }
  .chest-background {
    background: #FFDC3E;
  }

}
