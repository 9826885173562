@import "src/styles/mixins";

app-custom-messages {
  @import "../../styles/style";

  $message-color-read: rgba(#588500, 0.8);

  .ow-dialog {
    padding: 46px 95px 42px 102px !important;
    display: flex;
    flex-direction: column;
  }

  .title {
    margin-bottom: 26px;
  }

  .top,
  .left,
  .right {
    display: flex;
    align-items: center;
  }

  .top {
    margin-bottom: 16px;
    justify-content: space-between;
  }

  .archive {
    @include archivo-narrow;
    font-weight: 900;
    font-size: 16px;
    color: #000000;
    cursor: pointer;
    text-decoration: none;
    font-weight: bold;

    i {
      margin: 0 16px 0 8px;
    }
  }

  .frame {
    height: 457px;
    width: 100%;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
  }

  .navigate-pages {
    @include mikado;
    display: flex;
    align-items: center;
    position: absolute;
    text-transform: uppercase;
    font-weight: 900;
    font-size: 14px;
    bottom: 46px;
    left: 50%;
    transform: translateX(-50%);

    .small {
      width: 18px;
      height: 21px;
      border: 2px solid #000000;
      border-radius: 2px;
    }

    i {
      font-size: 20px;
    }

    .pages {
      text-transform: lowercase;
      margin: 0 20px;
    }
  }

  .ow-dialog {
    padding: 17px 40px 40px 40px;
  }

  .no-item {
    @include mikado;
    font-weight: bold;
    margin-top: 50px;
    text-align: center;
    font-size: 16px;
  }

  table {
    @include roboto-cond;
    border-collapse: collapse;
    font-size: 14px;
    width: 100%;

    thead {
      tr {
        height: 20px;

        th {
          @include archivo-narrow;
          font-weight: normal;
          padding-left: 15px;
          font-size: 12px;
          text-align: left;
        }
      }
    }

    tbody {
      tr:nth-child(odd) {
        background-color: #F9F4E4;
      }
    }

    tr {
      height: 38px;
      font-weight: bold;

      &.readed {
        font-weight: normal;
      }
    }

    td {
      cursor: pointer;
      position: relative;
      text-align: center;
      padding: 0 15px;
      font-size: 14px;

      &:first-child {
        padding-left: 15px;
      }

      &:last-child {
        padding-right: 15px;
      }

      &.td-category,
      &.td-sender,
      &.td-date,
      &.td-title {
        @include text-ellipsis;
      }

      &.td-title {
        padding-left: 20px;
        width: 100%;
        text-align: left;
      }

      &.td-sender {
        min-width: 150px;
        text-align: left;

        img {
          margin-right: 5px;
        }
      }

      &.td-category {
        min-width: 55px;
        text-align: left;
      }

      img {
        width: 34px;
        height: 34px;
        vertical-align: middle;
      }
    }
  }

  .role-select:not(:last-child) {
    margin-right: 10px;
  }

  .ng-dropdown-wrapper {
    width: 220px;
    height: 36px;
    padding: 4px 8px;
  }

  perfect-scrollbar.scroll-indicator::after {
    height: 50px;
  }
}
