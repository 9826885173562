@import "src/styles/mixins";
@import "src/styles/variables/core/variables";

app-card-task-details {
  .navigation-bar {
    &.prev {
      left: -16px !important;
    }
  }

  .bracket-task-weighted {
    text-transform: none !important;
    font-weight: normal !important;
    font-size: 12px;
  }

  m-ui-navigation-center {
    z-index: 2;
  }

  ow-perfect-scrollbar {
    width: 100% !important;

    .ps-content {
      padding-right: 35px;
    }
  }

  .ow-dialog {
    display: flex;
    flex-direction: column;
    padding: 33px 79px 70px 98px;
    align-items: center;

    &.c-window-short {
      .exit-bar {
        top: 26px;
      }
    }

    .cleared {
      right: unset;
      left: 94px;
      bottom: 14px;
    }
  }

  .title {
    @include mikado();
    font-size: 20px;
    font-weight: 900;
    text-transform: uppercase;
  }

  .prize-min {
    span {
      position: relative;

      i {
        position: absolute;
        font-size: 14px;
        top: -4px;
        right: -17px;
        cursor: pointer;
      }
    }

    .min-prizes {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      m-ui-currency,
      m-ui-product {
        margin-right: 6px;
      }
    }
  }

  .description {
    @include archivo-narrow();
    font-size: 16px;
    max-height: 20px;
    margin-bottom: 14px;
    margin-top: 8px;
    box-sizing: border-box;
    padding: 0 40px;
    text-align: center;
  }

  .branch-name {
    @include mikado();
    text-transform: uppercase;
    font-weight: 900;
    text-align: center;
    font-size: 16px;
    margin-bottom: 14px;
  }

  .content {
    width: 100%;
    box-sizing: border-box;
    overflow: hidden;
    flex: 1;
  }

  .prizes {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 23px;

    & > * {
      margin: 0 7px;

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }
    }

    .frame-white {
      background: #F9F4E4;
      border-radius: 6px;
      width: 286px;
      box-sizing: border-box;
      height: 44px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 2px solid #878273;

      &:not(:last-child) {
        margin-right: 14px;
      }

      m-ui-currency, m-ui-product {
        margin: 0 8px;
      }
    }

    .prize-container {
      display: flex;
      flex-direction: column;
      align-items: center;

      .percent {
        @include mikado();
        font-size: 16px;
        font-weight: 900;
        margin-bottom: 5px;
        letter-spacing: 0.32px;
      }

      .prize {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  .progressbar-container {
    margin-bottom: 18px;

    .box {
      display: flex;
      justify-content: space-between;

      .progressbar-title {
        padding-left: 20px;
        font-size: 14px;
        text-transform: uppercase;
      }

      .progressbar-value {
        font-size: 14px;
        padding-right: 20px;
        display: flex;
        align-items: flex-end;
      }
    }

    .progress-container-after {
      position: relative;
      padding: 2px 0;

      &:after {
        content: '';
        display: block;
        width: 4px;
        height: 100%;
        background-color: #EBE5D5;
        position: absolute;
        top: 0;
        right: 124px;
      }

      .progressbar {
        height: 14px;
        border-radius: 6px;
        position: relative;
        background-color: #DED8C6;
        border: 2px solid #878273;
        padding: 0 !important;

        .fill {
          border-radius: 2px 0 0 2px;
          border-right: 2px solid #878273;
        }
      }
    }
  }

  .info {
    font-size: 14px;
    padding: 0 30px;
    margin-bottom: 18px;
    @include archivo-narrow();
    text-align: left;
    font-weight: bold;
  }

  table {
    width: 100%;

    tr {
      height: 32px;

      &.border-bottom {
        box-shadow: 0 2px 1px -1px #878273;
      }

      &.summary {
        font-weight: bold;
      }
    }

    td {
      @include archivo-narrow();
      font-size: 14px;

      &.title {
        @include mikado;
        box-shadow: none;
        font-size: 16px;
        font-weight: 900;
        text-align: left;
        text-transform: uppercase;
        padding-left: 20px;
      }

      &.score {
        font-weight: bold;
      }

      &.text-right {
        text-align: right;
      }

      &:first-child {
        padding-left: 20px;
      }

      &:last-child {
        padding-right: 20px;
      }

      &:last-child {
        font-weight: bold;
      }
    }
  }

  .sanjungle-logo {
    //mask-image: var(--asset--ui-card-revers-svg);
    width: 255px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    height: 200px;
    display: block;
    //background: #8aa750;
    -webkit-mask-size: contain;
    -webkit-mask-repeat: no-repeat;
    -webkit-mask-position: center center;
  }

  .mark-window-left-container {
    position: absolute;
    left: 8px;
    top: 155px;
    z-index: 1;
    width: 55px;
    height: 55px;
    box-sizing: border-box;

    .mark-window-left {
      top: initial;
      left: initial;
      position: relative;
      margin-bottom: 10px;
      width: 55px;
      height: 55px;
      border: 3px solid #000000;
      background-color: #EBE5D5;
      display: flex;
      align-items: center;
      box-sizing: border-box;
      justify-content: center;

      &.custom {
        display: none;
      }
    }
  }

  .dialog-decoration {
    z-index: 1;

    .right {
      position: absolute;
      width: 136px;
      height: 522px;
      right: -44px;
      top: 72px;
      background-repeat: no-repeat;
    }

    .bottom {
      bottom: 0;
      width: 244px;
      height: 26px;
      right: 0;
      position: absolute;
      background-image: var(--asset--ui-sheet_orange_down-png);
    }
  }
}
