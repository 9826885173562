app-san-hud {
  .dor-background {
    width: 100%;
    height: 100%;
    background: var(--asset--ui-background-png) center no-repeat;
    background-size: cover;
    position: absolute;

    .logo {
      background: var(--asset--ui-logo-png) center no-repeat;
      width: 100%;
      height: 100%;
    }
  }

  .hud-menu-positioner {
    top: 10px;
    left: 118px;
    position: absolute;
    display: flex;
    align-items: center;
    pointer-events: none;

    &.pointer-events-active {
      pointer-events: unset;
    }
  }

  .animations {
    position: absolute;
    right: 0;
    top: 50%;
    display: flex;
    flex-direction: column;
    transform: translateY(-50%);

    .animations-buttons {
      padding: multiply-size(4px);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
}
