@import "src/styles/functions";
@import "src/styles/variables/core/variables";
@import "src/styles/mixins";

app-m-building-production {
  .window-a {
    padding: multiply-size(30px) multiply-size(38px) multiply-size(22px) multiply-size(38px);
  }

  .slider-container {
    height: 100%;
    overflow: hidden;
    display: flex;

    swiper {
      width: 100%;
    }
  }

  .each-production {
    display: flex;
    margin-top: multiply-size(5px);
    margin-left: multiply-size(2px);
    align-items: center;

    button.short,
    button.square.small {
      margin: 0 multiply-size(5px) multiply-size(10px) multiply-size(5px);
    }

    &:first-child {
      margin-top: multiply-size(8px);
    }
  }

  m-building-row-production {
    flex: 1;
    margin-right: multiply-size(2px);
  }

  .bar-button {
    width: multiply-size(150px);

    .actual-production-item {
      margin-right: multiply-size(5px);
    }

    .timer {
      padding-top: multiply-size(2px);
      font-size: multiply-size(10px);
      @include mikado-black;
      @include text-stroke;
      position: absolute;
      right: multiply-size(6px);
      @include absolute-center-vertical;
      color: $color-I;
    }

    .progress-container {
      $border-radius: multiply-size(6px);

      position: relative;
      width: 100%;
      height: multiply-size(18px);
      box-sizing: border-box;
      border: multiply-size(1px) solid $color-K;
      border-radius: $border-radius;

      .fill {
        border-radius: $border-radius;
      }
    }
  }

  i {
    &.arrow-pagination {
      @include absolute-center-horizontal;
      cursor: pointer;
      z-index: 10;
      animation: 1.5s infinite;
      animation-duration: 0.5s;
      animation-iteration-count: infinite;
      font-size: multiply-size(16px);
      padding: multiply-size(4px);

      &.up {
        top: multiply-size(-16px);
      }

      &.down {
        bottom: multiply-size(-12px);
      }
    }
  }

  @import "custom/style";
}
