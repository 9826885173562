event-shop-alert-confirm {
  @import "src/styles/variables/core/variables";
  @import "src/styles/mixins";

  .c-window-short {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    form {
      width: auto;
    }

    p {
      &.title {
        margin: 0 0 6px 0;
        font-size: 24px;
        @include mikado;
        font-weight: 900;
        text-align: center;
        text-transform: uppercase;
      }

      &.description {
        @include roboto-cond;
        margin: 0;
        text-align: center;
        font-size: 16px;
        padding: 0 0 10px 0;
        margin-bottom: 25px;

        & + m-ui-separator {
          margin-top: -10px;
        }
      }

      &.separator-title {
        font-size: 16px;
        text-transform: uppercase;
        margin: 8px 0 12px 0;
        @include mikado;
        font-weight: bold;
      }
    }
  }

  .buttons {
    margin-top: 20px;
    display: flex;
    justify-content: center;

    button {
      margin: 0 10px;
    }
  }

  .exit-bar {
    top: 14px !important;
    right: 8px !important;
  }

  .c-base {
    width: unset;
    min-width: 204px;
    padding-left: 16px;
    padding-right: 16px;
  }

  m-ui-separator {
    width: 100%;
  }

  m-ui-costs {
    margin: 0 auto 5px auto;
  }
}
