@import "src/styles/mixins";

app-message-details-level-up {
  @import "../../styles/style";

  .ow-dialog {
    padding: 50px 114px;
  }

  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  .level-up {
    position: relative;
    width: 572px;
    height: 264px;
    background-image: var(--asset--ilustration-level_up-png);
    background-repeat: no-repeat;
    background-size: contain;

    .level {
      position: absolute;
      left: calc(50% + 2px);
      transform: translateX(-50%);
      text-align: center;
      letter-spacing: 0;

      font-family: "Nuku", sans-serif;
      font-size: 60px;
      line-height: 64px;
      color: #FCC547;
      bottom: 36px;
    }
  }

  .title {
    margin: 26px 0 22px 0;
    @include mikado();
    font-weight: bold;
    font-size: 22px;
    text-transform: none !important;
  }

  .new-buildings {
    font-size: 14px;
    margin: 0 0 14px 0;
  }

  .new-buildings {
    font-weight: 900;
    text-transform: uppercase;
  }

  img {
    width: 110px;
    height: 110px;
  }

  .unlock {
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
  }

  .next,
  .prev {
    @include absolute-center-vertical;
    width: 76px;
    height: 66px;
    background: var(--asset--ui-chevron_big-svg);
    cursor: pointer;
    z-index: 20;
    border-left: 3px solid black;

    &.hide {
      visibility: hidden;
    }
  }

  .next {
    right: -12px;
  }

  .prev {
    left: -12px;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    top: 23%;
  }

  swiper-container {
    .page {
      display: flex;
      justify-content: center;
      align-items: flex-end;

      app-building-thumbnail {
        margin: 0 5px;

        img {
          width: 110px;
          height: 110px;
        }

        &:first-child {
          margin-left: 0;
        }

        &:last-child {
          margin-right: 0;
        }
      }

      m-ui-product {
        margin: 0 4px;
      }
    }
  }

  m-ui-separator {
    margin-bottom: 24px;

    .separator {
      margin: 0 !important;
    }
  }

  .trash {
    position: absolute;
    bottom: 34px;
    right: 74px;
    border: none;

    i {
      font-size: 16px;
      position: unset !important;
    }
  }
}
