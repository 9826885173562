@import "src/styles/mixins";
@import "src/styles/variables/core/variables";

app-custom-qa-details {
  .c-window-long {
    padding: 62px 103px 46px 103px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .timer {
      position: absolute;
      right: 101px;
      top: -13px;
      @include mikado();
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: bold;
      font-size: 18px;
      width: 188px;
      height: 34px;
      background-color: #EBE5D5;
      border: 2px solid #3B3326;
      border-radius: 10px 10px 0 0;

      i {
        font-size: 20px;
      }

      span {
        padding-top: 4px;
        margin-left: 4px;
      }
    }
  }

  .elem-flex {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .question {
    @include archivo-narrow();
    font-size: 16px;
    text-align: left;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 36px;

    p {
      max-height: 54px;
    }
  }

  .question-tag {
    @include mikado();
    font-size: 22px;
    font-weight: bold;
    width: 56px;
    height: 56px;
    padding-top: 4px;
    box-sizing: border-box;
    border: 2px solid #000000;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    background-color: #F9F4E4;
  }

  .checkbox-button {
    width: 26px;
    height: 26px;
    cursor: pointer;

    i {
      font-size: 20px;
    }
  }

  .answer {
    min-height: 30px;
    padding: 10px 18px;
    box-sizing: border-box;
    @include archivo-narrow();
    font-size: 14px;

    p {
      margin: 0;
      flex: 1;
      font-weight: bold;
    }

    &:nth-child(even) {
      background: #F9F4E4;
    }

    &.align {
      align-items: flex-start !important;
    }
  }

  textarea {
    background: transparent;
    border: 1px solid #9C9583;
    width: 80%;
    margin-left: 50px;
    min-height: 100px;
    color: #000000;
    @include archivo-narrow();
    font-size: 14px;
    font-weight: bold;
  }

  .image-container {
    width: 152px;
    height: 106px;
    box-sizing: border-box;
    border: 2px solid #000000;
    margin: 0 12px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &.qa {
      margin-left: 66px;
    }
  }

  .qa-container {
    height: 90%;
  }

  .navigation {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .correct {
    color: #18AE29;
  }

  .in-correct {
    color: #F25854;
  }

  .indeterminated {
    color: #2c7ae3
  }

  .hide {
    opacity: 0;
    pointer-events: none;
  }

  .cursor {
    cursor: default;
  }
}
