@import "src/styles/variables/core/variables";
@import "src/styles/mixins";

confirmation-delivery {
  @include archivo-narrow();

  button {
    display: inline-flex;
    margin: 0 10px;
  }

  .confirmation-delivery-container {
    position: relative;
    text-align: center;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    padding: 30px 0;
    background-color: #EBE5D5;

    perfect-scrollbar {
      padding: 0 40px;
      max-height: 100vh;
      box-sizing: border-box;
      width: 100% !important;
    }

    p {
      margin: 5px 0;

      &.title {
        @include mikado();
        font-size: 18px;
        font-weight: bold;
        text-transform: uppercase;
        text-align: center;
      }

      &.description {
        text-align: center;
        font-size: 16px;
        @include archivo-narrow;

        span {
          @include mikado();
        }
      }
    }

    .table-user {
      tr {
        &:nth-child(odd) {
          background: #F9F4E4;
        }
      }
    }

    .table-user, .table-prize {
      width: 100%;
      margin: 20px 0;
      text-align: left;
      border-spacing: 4px;
      border-collapse: separate;

      th {
        font-size: 12px;
        @include mikado;
      }

      td, th {
        padding: 5px 10px;
        box-sizing: border-box;
      }


      tbody {
        tr {
          td {
            font-size: 14px;
          }

          &:nth-child(odd) {
            background: #F9F4E4;
          }
        }
      }
    }

    .table-prize {
      tr {
        td {
          font-weight: bold;
        }
      }
    }

    .bottom-button {
      margin: 30px;
      text-align: center;

      table {
        width: 80%;
        margin: 0 auto;

        tr {
          td {
            font-size: 14px;
            font-weight: bold;
            padding: 5px 30px;
            text-align: center;

            button {
              font-size: 16px;
              text-transform: none;
            }
          }
        }
      }
    }

    .helpdesk {
      border: 3px solid #878273;
      font-size: 14px;
      font-weight: bold;
      text-align: center;
      padding: 15px;
      box-sizing: border-box;
    }

    .confirmation-template {
      .term-info {
        width: 500px;
        margin: 30px auto 15px auto;
        font-size: 14px;
        @include archivo-narrow;
        line-height: 22px;
        font-weight: bold;
      }

      .input-date {
        border: 2px solid #878273;
        background: #EBE5D5;
        padding: 5px;
        font-size: 14px;
        font-weight: bold;
        color: #878273;
        margin-bottom: 30px;
      }

      input[type=date]::-webkit-inner-spin-button, input[type=date]::-webkit-clear-button {
        -webkit-appearance: none;
        display: none;
      }
    }
  }

  button {
    &.c-base {
      width: unset !important;
      min-width: 204px;
      padding-left: 16px;
      padding-right: 16px;
    }
  }
}
