@import "src/styles/mixins";

& {
  width: 100%;

  &.left {
    .separator {
      justify-content: flex-start;

      span {
        &.padding {
          padding-left: 0;
        }
      }
    }
  }

  &.right {
    .separator {
      justify-content: flex-end;

      span {
        &.padding {
          padding-right: 0;
        }
      }
    }
  }
}

.separator {
  min-height: 2px;

  span {
    font-size: 12px;
    @include archivo-narrow;
    font-weight: normal;

    &:before, &:after {
      transform: none;
      width: 1000px;
    }
  }
}
