sparkles {
  @import "../../shop-assets";

  & {
    @include roboto-cond;
    font-size: 16px;

    .separator-2 {
      width: 100%;
      height: 1px;
      margin: 20px 0;
      background: #588500;
      opacity: 0.5;
    }

    .sparkles-container {
      margin-top: 65px;

      .table-sparkles {
        width: auto;

        tr {
          td {
            height: 20px;

            .flex-center {
              justify-content: flex-end;

              img {
                width: 20px;
                height: 20px;
                margin-left: 14px;
              }
            }

            &:first-child {
              width: 515px;
            }

            &:nth-child(2) {
              width: 100px;
            }

            &:nth-child(3) {
              width: 150px;
            }
          }
        }

        thead {
          tr {
            background: #E5F2FF;

            th {
              position: relative;
              height: 60px;
              box-sizing: border-box;

              .shop-ribbon-sparkles {
                width: 80px;
                height: 82px;
                position: absolute;
                top: -6px;
              }
            }
          }
        }

        tbody {
          .title-description {
            margin: 5px 0 30px 0;
          }
        }
      }
    }
  }

  .w-100 {
    width: 100%;
  }

  .actual-sum {
    font-size: 18px;
  }

  .m-top {
    margin-top: 30px;
  }
}


