@import "src/styles/functions";

app-reauthorize {
  @import "../../../styles/core/styles";
  display: flex;
  flex-direction: column;
  align-items: center;

  .description {
    display: block;
    margin: multiply-size(5px) 0;

    p {
      margin: 0;
    }

    &.logout {
      margin-top: multiply-size(25px);
    }
  }

  @import "custom/styles";
}
