auth-forgot-password {
  @import "../../../styles/core/styles";
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    &.description {
      text-align: center;
    }
  }

  @import "custom/styles";
}
