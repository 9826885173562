@import "src/styles/variables/core/variables";
@import "src/styles/mixins";

app-rank-league-results {
  @import "../rank-league-details.component";
  @import "../../styles/style";

  .left {
    gap: 10px;
  }

  .role-select {
    margin-right: 6px;
    width: 200px;
  }

  .top {
    margin-bottom: 18px;
  }

  .box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;

    .my-position-link {
      position: absolute;
      top: 58px;
      right: 380px;
    }

    .league-summary {
      @include archivo-narrow;
      display: flex;
      font-size: 14px;
      font-weight: bold;
      flex-direction: row-reverse;

      .flex-center {
        margin-right: 18px;

        .icon-league {
          margin: 0 8px 0 0;
        }
      }
    }
  }
}

