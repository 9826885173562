$default-background: #F9F4E4;

// COLORS
&.yellow {
  background-color: $yellow;

  .shop-title {
    background-color: $yellow;
  }

  .shop-details {
    border: none;
  }
}


&.gray {
  background-color: $gray;

  .shop-title {
    background-color: $gray;
  }

  .shop-details {
    border: none;
  }
}

&.orange-light {
  background-color: $orange-light;

  .shop-title {
    background-color: $orange-light;
  }

  .shop-details {
    border: none;
  }
}

&.blue-light {
  background-color: $blue-light;

  .shop-title {
    background-color: $blue-light;
  }

  .shop-details {
    border: none;
  }
}

&.red {
  background-color: $red;

  .shop-title {
    background-color: $red;
  }

  .shop-details {
    border: none;
  }
}

&.green {
  background-color: $green;

  .shop-title {
    background-color: $green;
  }

  .shop-details {
    border: none;
  }
}

&.orange {
  background-color: $orange;

  .shop-title {
    background-color: $orange;
  }

  .shop-details {
    border: none;
  }
}

&.green-light {
  background-color: $green-light;

  .shop-title {
    background-color: $green-light;
  }

  .shop-details {
    border: none;
  }
}

&.blue-pearl {
  background-color: $blue-pearl;

  .shop-title {
    background-color: $blue-pearl;
  }

  .shop-details {
    border: none;
  }
}

&.purple-strong {
  background-color: $purple-strong;

  .shop-title {
    background-color: $purple-strong;
  }

  .shop-details {
    border: none;
  }
}

&.yellow-dashed {
  background-color: $default-background;

  .shop-title {
    background-color: $yellow;
  }

  .shop-details {
    border-color: $yellow;
  }
}

&.gray-dashed {
  background-color: $default-background;

  .shop-title {
    background-color: $gray;
  }

  .shop-details {
    border-color: $gray;
  }
}

&.orange-light-dashed {
  background-color: $default-background;

  .shop-title {
    background-color: $orange-light;
  }

  .shop-details {
    border-color: $orange-light;
  }
}

&.blue-light-dashed {
  background-color: $default-background;

  .shop-title {
    background-color: $blue-light;
  }

  .shop-details {
    border-color: $blue-light;
  }
}

&.red-dashed {
  background-color: $default-background;

  .shop-title {
    background-color: $red;
  }

  .shop-details {
    border-color: $red;
  }
}

&.green-dashed {
  background-color: $default-background;

  .shop-title {
    background-color: $green;
  }

  .shop-details {
    border-color: $green;
  }
}

&.orange-dashed {
  background-color: $default-background;

  .shop-title {
    background-color: $orange;
  }

  .shop-details {
    border-color: $orange;
  }
}

&.blue-medium {
  background-color: $default-background;

  .shop-title {
    background-color: #94B8FF;
  }

  .shop-details {
    border-color: #94B8FF;
  }
}

&.blue-strong {
  background-color: $default-background;

  .shop-title {
    background-color: #829CFA;
  }

  .shop-details {
    border-color: #829CFA;
  }
}

&.teal {
  background-color: $default-background;

  .shop-title {
    background-color: #0BEBC1;
  }
}

&.blue {
  background-color: $default-background;

  .shop-title {
    background-color: #36B9FF;
  }
}

&.green-light-dashed {
  background-color: $default-background;

  .shop-title {
    background-color: $green-light;
  }

  .shop-details {
    border-color: $green-light;
  }
}

&.blue-pearl-dashed {
  background-color: $default-background;

  .shop-title {
    background-color: $blue-pearl;
  }

  .shop-details {
    border-color: $blue-pearl;
  }
}

&.purple-strong-dashed {
  background-color: $default-background;

  .shop-title {
    background-color: $purple-strong;
  }

  .shop-details {
    border-color: $purple-strong;
  }
}
