@import "src/styles/mixins";

app-league {
  .branch-name {
    @include absolute-center-horizontal;
    @include archivo-narrow();
    font-size: 16px;
    min-width: 250px;
    padding: 0 10px;
    height: 31px;
    border: 3px solid #000;
    border-top: 1px solid #000;
    background: #FFD500;
    bottom: -3px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
  }

  perfect-scrollbar {
    max-width: 100% !important;

    .ps-content {
      padding-right: 37px
    }
  }

  .league-container {
    padding: 42px 70px 78px 94px;
    display: flex;
    flex-direction: column;

    .summaries {
      margin-top: 20px;
      margin-right: 37px;

      .summary-row {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        background: #F9F4E4;
        border-radius: 18px;
        padding-right: 18px;

        button {
          i {
            font-size: 14px;
            color: #000000;
          }
        }

        .disallow-estimation {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 14px;
          font-weight: bold;
          @include archivo-narrow;
        }

        .summary-title {
          @include mikado;
          text-transform: uppercase;
          font-weight: 900;
          font-size: 14px;
          display: flex;
          width: 9%;
          align-items: center;
          justify-content: flex-start;
          padding-left: 30px;

          span {
            transform: translateY(1px);
          }
        }

        .summary-blocks {
          display: flex;
          width: 1000px;
          height: 36px;
          margin-left: auto;

          .block {
            display: flex;
            justify-content: center;
            position: relative;

            m-ui-currency {
              width: 60px;
            }

            .stock-g {
              .icon {
                width: 20px;
                height: 20px;
              }

              .value {
                font-size: 14px !important;
                @include archivo-narrow();
                text-shadow: none !important;
                color: #000000;
                margin-left: 4px !important;
              }
            }

            &:nth-child(1) {
              padding-left: 12px;

              .item {
                justify-content: flex-start;

                &:nth-child(1) {
                  width: 80px;
                }

                &:nth-child(2) {
                  width: 76px;
                }

                &:nth-child(3) {
                  width: 80px;
                }
              }
            }

            &:nth-child(2) {
              width: 16%;
              justify-content: flex-start;
              padding-left: 36px;
            }

            &:nth-child(3) {
              width: 50%;
              padding-left: 12px;
              justify-content: flex-start;

              .item {
                .txt {
                  white-space: nowrap;
                  font-weight: bold;
                }
              }
            }

            &:after {
              background: #EBE5D5;
              height: 100%;
              width: 2px;
              position: absolute;
              right: 0;
              top: 50%;
              transform: translateY(-50%);
              content: '';
            }

            &:last-child {
              &:after {
                display: none;
              }
            }

            .item {
              font-size: 14px;
              display: flex;
              align-items: center;
              justify-content: center;
              margin-right: 15px;

              m-ui-currency {
                margin: 0 8px;
              }

              .txt {
                @include archivo-narrow;
                margin-right: 5px;
              }

              .number {
                @include mikado;
                font-weight: 900;
              }

              .icon-league {
                width: 28px;
                height: 28px;
                border-radius: 50%;
                margin-right: 10px;
              }
            }
          }
        }
      }
    }

    .settings {
      margin: 12px 50px 12px 0;
      display: flex;

      .tabs-wrapper {

        &.tabs {
          width: auto;
        }
      }

      .default-settings {
        display: flex;
        margin-left: auto;

        .link {
          display: flex;
          align-items: center;
          cursor: pointer;

          span {
            @include mikado;
            font-weight: 900;
            text-transform: uppercase;
            text-decoration: underline;
            margin-right: 10px;
            font-size: 14px;
          }
        }
      }
    }

    .table-wrapper {
      overflow: hidden;
      width: 100%;

      .frame {
        height: 100%;
        width: 100%;

        table {
          width: 100%;
          border-spacing: 2px;
          border-collapse: collapse;
          white-space: nowrap;

          thead {
            th {
              font-size: 12px;
              @include archivo-narrow;
              text-align: center;
              white-space: nowrap;
              padding: 0 5px 5px 5px;
              font-weight: normal;


              &:first-child {
                width: 100%;
              }

              &:last-child {
                text-align: center;

                button {
                  width: 30px;
                  display: inline;
                  font-size: 14px;
                }
              }
            }
          }

          tbody {
            tr {
              height: 38px;

              &:first-child {
                border-top: none;
              }

              &:nth-child(odd) {
                background: #F9F4E4;
              }

              td {
                font-size: 14px;
                font-weight: bold;
                @include archivo-narrow;
                text-align: center;
                min-width: 90px;
                padding: 0 10px;
                cursor: default;

                 &.ow-slider-td {
                   min-width: 145px;
                 }

                &:first-child {
                  text-align: left;
                  padding-left: 15px;
                  width: 100%;
                }

                &:last-child {
                  min-width: 52px;
                  padding-left: 0;
                }

                &.bonus-task {
                  &.red {
                    color: #FF0600;
                  }

                  &.green {
                    color: #35A700;
                  }
                }
              }
            }
          }

          .progressbar-wrapper {
            display: flex;
            align-items: center;
            min-width: 105px;

            .progressbar {
              height: 10px;
              border-radius: 10px;
              background-color: #000000;
              width: 80px;

              .fill {
                width: 0;
                border-radius: 10px;

                &.fill-color-3 {
                  background-color: #6EC3D6;
                }
              }
            }

            .number {
              margin-left: 5px;
            }
          }
        }
      }
    }

    .pagination {
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: center;

      button {
        display: inline-block;
        cursor: pointer;
        font-size: 20px;

        &.chart,
        &.quality {
          position: absolute;
        }

        &.chart {
          right: 71px;
          top: 40px;
        }

        &.quality {
          right: 110px;
          top: 37px;

          img {
            width: 26px;
            height: 26px;
          }
        }
      }

      .value {
        @include archivo-narrow();
        font-weight: 900;
        font-size: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 20px;

        .month {
          position: relative;
        }

        .date {
          margin-left: 26px;
          position: relative;

          &:before {
            content: '';
            position: absolute;
            left: -13px;
            height: 80%;
            background: #000000;
            display: block;
            top: 0;
            width: 1px;
            @include absolute-center-vertical;
          }
        }
      }
    }

    .radio-buttons-wrapper {
      display: flex;
      align-items: center;
    }

    .control {
      margin-right: 20px;

      .choose-wrapper {
        display: flex;
        align-items: center;

        .checkbox-wrapper {
          margin-right: 5px;
          display: flex;
          align-items: center;
        }

        .text-wrapper {
          @include mikado;
          font-weight: 900;
          font-size: 14px;
        }
      }
    }

    .description {
      @include roboto;
      font-weight: bold;
      margin-top: 0;
      font-size: 16px;
      text-align: center;

      &.no-content {
        margin-top: 200px;
        font-size: 20px;
      }
    }
  }

  /**
  INPUT PERCETAGE
   */
  .input-percentage {
    border: 2px solid #000000;
    padding: 0 7px 0 3px;
    width: 65px;
    box-sizing: border-box;
    margin: 0 auto;
    border-radius: 50px;
    font-size: 12px;

    input {
      background: transparent;
      color: inherit;
      text-align: right;
      font-weight: bold;
      border: none;
      height: 20px;
      width: 40px;
      font-size: inherit;
    }
  }

  .no-item {
    @include archivo-narrow();
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: bold;
    height: 100%;
  }

  .prev-results {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    flex: 1;

    .desc {
      @include mikado();
      font-size: 20px;
      font-weight: bold;

      span {
        text-transform: lowercase;
      }
    }

    .logo-league {
      width: 300px;
      height: 284px;
      background: var(--asset--ui-league_logo_big-png) center no-repeat;
      background-size: contain;
    }
  }
}
