@import "variables";

@mixin generate-schema-button-color($colors) {
  $background-colors: map-get($colors, $background);
  $box-shadow-colors: map-get($colors, $box-shadow);
  $font-colors: map-get($colors, $font);
  $border-colors: map-get($colors, $border);
  $font-stroke-color: map-get($colors, $font-stroke);
  $drop-shadow-colors: map-get($colors, $drop-shadow);

  background: map-get($background-colors, $normal);
  color: map-get($font-colors, $normal);
  border-color: map-get($border-colors, $normal);
  @include text-stroke($font-stroke-color);


  &:after {
    box-shadow: 0 0 10px map-get($drop-shadow-colors, $normal);
  }

  &.c-square {
    &.normal {
      box-shadow: inset 0 -6px 0 0 map-get($box-shadow-colors, $normal);
      padding-bottom: 2px;
    }

    &.small {
      box-shadow: inset 0 -6px 0 0 map-get($box-shadow-colors, $normal);
      padding-bottom: 2px;
    }

    &.x-small {
      box-shadow: inset 0 -3px 0 0 map-get($box-shadow-colors, $normal);
      padding-bottom: 2px;
    }
  }

  &.c-base {
    box-shadow: inset 0 -6px 0 0 map-get($box-shadow-colors, $normal);
    padding-bottom: 5px;
  }

  &.c-long {
    box-shadow: inset 0 -6px 0 0 map-get($box-shadow-colors, $normal);
    padding-bottom: 2px;
  }

  &.c-short {
    box-shadow: inset 0 -6px 0 0 map-get($box-shadow-colors, $normal);
    padding-bottom: 1px;
  }

  &:hover {
    background: map-get($background-colors, $hover);
    color: map-get($font-colors, $hover);
    border-color: map-get($border-colors, $hover);

    &:after {
      box-shadow: 0 0 10px map-get($drop-shadow-colors, $hover);
    }

    &.c-square {
      &.normal {
        box-shadow: inset 0 -6px 0 0 map-get($box-shadow-colors, $hover);
      }

      &.small {
        box-shadow: inset 0 -6px 0 0 map-get($box-shadow-colors, $hover);
      }

      &.x-small {
        box-shadow: inset 0 -3px 0 0 map-get($box-shadow-colors, $hover);
      }
    }

    &.c-base {
      box-shadow: inset 0 -6px 0 0 map-get($box-shadow-colors, $hover);
    }

    &.c-long {
      box-shadow: inset 0 -6px 0 0 map-get($box-shadow-colors, $hover);
    }

    &.c-short {
      box-shadow: inset 0 -6px 0 0 map-get($box-shadow-colors, $hover);
    }
  }

  &:active {
    background: map-get($background-colors, $active);
    color: map-get($font-colors, $active);

    &.c-square {
      &.normal {
        box-shadow: inset 0 -2px 0 0 map-get($box-shadow-colors, $active);
        padding-bottom: 0;
      }

      &.small {
        box-shadow: inset 0 -2px 0 0 map-get($box-shadow-colors, $active);
        padding-bottom: 0;
      }

      &.x-small {
        box-shadow: inset 0 -1px 0 0 map-get($box-shadow-colors, $active);
        padding-bottom: 0;
      }
    }

    &.c-base {
      box-shadow: inset 0 -2px 0 0 map-get($box-shadow-colors, $active);
      padding-top: 5px;
    }

    &.c-long {
      box-shadow: inset 0 -3px 0 0 map-get($box-shadow-colors, $active);
      padding-top: 5px;
    }

    &.c-short {
      box-shadow: inset 0 -3px 0 0 map-get($box-shadow-colors, $active);
      padding-top: 4px;
    }
  }

  i {
    color: inherit;
    @include text-stroke($font-stroke-color);
  }
}

