@import "src/styles/mixins";

app-san-hud-player {
  .user-container {
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 1;

    .main-button {
      width: 36px;
      height: 36px;
      pointer-events: all;
      position: absolute;
      z-index: 1;
      top: -6px;
      left: -6px;

      &:hover {
        filter: var(--filter-hover);
      }

      img {
        width: 100%;
        height: 100%;
      }
    }

    .experience-wrapper {
      background-image: var(--asset--ui-main_user-png);
      width: 90px;
      height: 96px;
      background-size: contain;
      background-repeat: no-repeat;

      .avatar-wrapper {
        position: relative;
        height: 100%;
        width: 100%;

        .avatar-default {
          position: absolute;
          top: 28px;
          left: 28px;
          cursor: pointer;

          i {
            font-size: 34px;
            color: #000000;
          }
        }

        .avatar-custom {
          height: 51px;
          width: 51px;
          border-radius: 50%;
          position: absolute;
          top: 19px;
          left: 19px;
          pointer-events: none;
          background-size: cover;
          background-position: center center;

          &.dor {
            width: 62px;
            height: 62px;
            top: 14px;
            left: 14px;
          }
        }
      }

      &:before {
        content: '';
        display: block;
        height: 73px;
        width: 84px;
        background: transparent;
        position: absolute;
        z-index: -1;
        top: 10px;
        border-radius: 50%;
      }
    }

    circle#bar {
      stroke-width: 40px;
      stroke-linecap: butt;
    }

    svg#circle {
      right: 11px;
      position: absolute;
      top: 10px;
      z-index: -1;

      .stop-0 {
        stop-color: #9BE222;
        stop-opacity: 1;
      }

      .stop-100 {
        stop-color: #9BE222;
        stop-opacity: 1;
      }
    }

    .level-star {
      width: 29px;
      height: 29px;
      background: #9BE222;
      background-size: contain;
      position: absolute;
      @include mikado();
      font-weight: bold;
      color: #006E0B;
      @include absolute-center-horizontal;
      box-sizing: border-box;
      border-radius: 100px;
      bottom: 13px;
      font-size: 16px;
      border: 2px solid black;
    }

    .status {
      display: flex;
      @include absolute-center-horizontal;
      bottom: -16px;

      &.active {
        &:after {
          position: absolute;
          pointer-events: none;
          content: '';
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          background: var(--asset--ui-status_red-png);
          animation-name: status-animation;
          animation-duration: 1s;
          animation-iteration-count: infinite;
          animation-direction: alternate;

          @keyframes status-animation {
            0% {
              opacity: 0;
            }
            20% {
              opacity: 0;
            }
            100% {
              opacity: 1;
            }
          }
        }
      }
    }
  }
}
