app-building-build {
  @import "../styles/style";

  .elements {
    position: relative;

    &.prizes-build {
      .stock-san-a {
        .value {
          color: #18AE29;
        }
      }
    }
  }

  m-ui-parameter {
    &.red {
      .stock-san-a {
        .value {
          color: #E50404 !important;
        }
      }
    }

    &.green {
      .stock-san-a {
        .value {
          color: #18AE29 !important;
        }
      }
    }
  }
}
