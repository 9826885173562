@import "src/styles/mixins";

app-san-business-cc {
  bottom: 30px;
  @include absolute-center-horizontal;
  width: 100%;

  .dashboard {
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;
    @include absolute-center-horizontal;
    bottom: 0;

    &:hover {
      filter: var(--filter-hover);
    }

    .icon-business {
      width: 72px;
      height: 72px;
      position: absolute;
      z-index: 1;
      left: -30px;
      pointer-events: unset;
    }

    .dashboard-button {
      text-transform: initial;
      color: #000000;
      pointer-events: none;
      width: 224px;
      height: 42px;
      background: #FFFFFF;
      border: 2px solid #000000;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 20px;
      font-weight: bold;
      @include mikado;
      box-sizing: border-box;
      padding-left: 28px;

      &:after {
        content: '';
        position: absolute;
        top: -8px;
        left: -8px;
        bottom: -8px;
        right: -8px;
        background: $custom-dialog-outline;
        border: 2px solid #000000;
        z-index: -1;
      }
    }
  }

  .dashboard-prize {
    background: var(--asset--ui-notification_chest-png);
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    @include absolute-center-horizontal;
    bottom: -5px;
    left: calc(50% + 143px);
  }

  .notification-box {
    position: absolute;
    top: -12px;
    right: -2px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .app-notification {
    left: unset !important;
    position: unset !important;
  }

  .wf {
    width: 140px;
    height: 90px;
    box-sizing: border-box;
    position: absolute;
    bottom: -10px;
    right: 10px;
    cursor: pointer;

    &:hover {
      filter: var(--filter-hover);
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}
