@import "src/styles/variables/core/variables";
@import "src/styles/mixins";

app-cl-rank-details {
  .ow-dialog {
    padding: 42px 90px 40px 80px;
    position: relative;

    .title {
      margin-bottom: 24px;
    }
  }

  perfect-scrollbar .ps-content {
    padding-right: 46px;
  }

  .content {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    overflow: hidden;

    .frame {
      width: 100%;
      border-radius: 15px;
      padding: 16px 30px 28px 40px;
      height: 395px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
    }
  }

  .top {
    display: flex;
    margin-bottom: 16px;
    padding-left: 10px;

    .left {
      width: 50%;

      p {
        margin: 0;
        font-size: 16px;
        max-height: 24px;

        &.sub-title {
          @include mikado;
          font-weight: 900;
          margin-bottom: 10px;
        }

        &.description {
          @include archivo-narrow;
          font-weight: 400;
        }
      }
    }

    .right {
      @include mikado;
      flex: 1;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      font-weight: bold;

      & > * {
        margin-left: 14px;
      }
    }
  }

  .box {
    display: flex;
    justify-content: space-between;
  }

  .filters {
    display: flex;
    align-items: center;
    margin: 16px 0 16px 14px;
    box-sizing: border-box;
  }

  .ow-radio-container {
    display: flex;
    align-items: center;

    &:not(:last-child) {
      margin-right: 20px;
    }

    .text-wrapper {
      @include mikado;
      font-weight: 900;
      font-size: 14px;
      text-transform: uppercase;
    }
  }

  .show-position {
    text-decoration: underline;
    font-size: 14px;
    font-weight: 900;
    display: flex;
    align-items: center;
    padding-right: 14px;

    a {
      color: #000000;
    }
  }

  table {
    @include archivo-narrow;
    border-collapse: collapse;
    font-size: 14px;
    width: 100%;

    &.custom {
      tbody {
        tr {

          &:nth-child(even) {
            background-color: #F9F4E4;
          }
        }
      }
    }

    tr {
      height: 34px;

      &.my-position {
        border-bottom: none;
      }
    }

    td {
      font-weight: bold;
      position: relative;

      &:first-child {
        @include mikado;
        font-weight: 900;
        padding-left: 15px;
      }

      &:last-child {
        padding-right: 6px;
      }
    }
  }
}
