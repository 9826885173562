app-card-button-choose-cc {
  .buttons {
    display: flex;
    align-items: center;
    margin-top: 16px;

    button {
      margin: 0 10px;
      height: 32px;
    }
  }
}
