app-auction-bid {
  @import "../../styles/styles";

  .ow-dialog {
    padding: 112px 86px 60px;
  }

  .content {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  form, .control {
    width: 240px !important;
  }

  .control {
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;

    .alert-danger {
      @include absolute-center-horizontal();
      bottom: -20px;
      white-space: nowrap;
    }
  }

  .control-input {
    width: 194px !important;
    border-radius: 15px;
    height: 36px !important;
  }

  .control-input {
    border: 2px solid #000000;
  }

  m-ui-separator {
    width: 900px;

    .separator {
      margin: 14px 0 70px;;
    }
  }

  .sv-1 {
    font-size: 14px;
    @include archivo-narrow();
    width: 100%;
    letter-spacing: 0;
    line-height: 16px;
    cursor: default;
    text-align: center;

    &.number {
      margin-top: 28px;
    }
  }

  .bottom {
    margin-bottom: 64px;
  }

  .buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;

    button {
      margin: 0 8px;
    }
  }

  .label-flex {
    display: flex;
    margin-bottom: 6px;
  }

  .alert-danger {
    @include archivo-narrow();
    font-size: 12px;
  }
}
