app-unlocked-buildings {
  .building-unlocked {
    display: flex;

    & > * {
      &:not(:last-child) {
        margin-right: 10px;
      }
    }
  }
}
