app-world-info {
  li {
    margin-bottom: 10px;
  }

  li ul li:hover {
    color: red;
    cursor: pointer;
  }
}
