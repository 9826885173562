@import "src/styles/functions";
@import "src/styles/variables/core/variables";

&.window-d {
  $width: multiply-size(300px);

  min-width: $width;
  min-height: multiply-size(120px);
  max-width: $width;
  padding: multiply-size(25px);
  border-radius: multiply-size(10px);
  box-shadow: 0 0 0 multiply-size(2px) $color-H;
  z-index: 0;
}
