@import "src/styles/variables/core/variables";
@import "src/styles/mixins";

app-custom-mission-transfer {
  $font-color: #000000;
  $border-color: #6AAAB9;

  button {
    &.mobile-square {
      font-size: 12px;
      border: 1px solid $font-color;
      width: 40px;
      height: 40px;
    }

    .far {
      color: $font-color;
      text-shadow: none;
    }
  }

  m-ui-close-button {
    .exit-bar {
      right: -42px !important;
      top: 16px !important;
    }
  }

  m-ui-back-button {
    .back-bar {
      left: -33px !important;
      top: 50px !important;
    }
  }

  .c-window-c {
    @include mikado;
    color: #000000;
    width: 410px;
    height: 500px;
    border: 3px solid #000000;
    position: relative;
    background-color: #EBE5D5;
    box-sizing: border-box;
    padding: 50px 68px;

    .frame {
      display: flex;
      flex-direction: column;
      align-items: center;

      .product-in-glow-box {
        width: 80px;
        height: 80px;
        margin: 6px 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        padding: 4px;

        .product-icon {
          width: 100%;
          height: 100%;
          background-size: contain;
          background-repeat: no-repeat;
        }
      }

      .product-balance {
        text-align: center;
        width: 70%;
        margin: 0 0 20px 0;
        letter-spacing: 0.4px;
        font-size: 20px;
        font-weight: 900;
      }

      .product-name {
        text-transform: none;
        font-size: 20px;
        margin: 6px 0 10px 0;
        letter-spacing: 0.4px;
        font-weight: 900;
      }

      .default-field {
        @include mikado;
        width: 190px;
        height: 32px;
        margin: 0px auto 12px auto;

        input {
          text-align: center;
          padding: 7px !important;
        }

        label {
          @include archivo-narrow;
          letter-spacing: 0px;
          font-size: 16px;
          padding: 0;
          top: 4px;
          left: 50%;
          transform: translateX(-50%);
          transition: font-size 0.2s ease-in-out, top 0.2s ease-in-out;
        }
      }

      button {
        &.only-border {
          @include archivo-narrow;
          margin: 0 5px;
          text-decoration: underline;
          letter-spacing: 0px;
          font-size: 16px;
          width: 100%;
          font-weight: bold;
          padding: 0 10px;
          text-align: center;
          text-transform: none;
        }

        &.no-hover-blur {
          filter: none;
        }
      }

      .summary {
        margin: 66px 0 12px 0;
        font-size: 18px;
        letter-spacing: 0px;
        font-weight: 900;
      }

      .error {
        text-align: center;
        position: absolute;
        bottom: 20px;
      }
    }
  }
}
