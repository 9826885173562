@import "src/styles/mixins";


.wf-piece-details-container {
  width: 484px;
  min-height: 500px;
  border: 3px solid black;
  background-color: #EBE5D5;
  display: flex;
  justify-content: center;
  align-items: center;

  m-ui-close-button {
    position: absolute;
    right: -43px;
    top: 15px;
  }

  .content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 40px;
    box-sizing: border-box;

    img {
      display: block;
      margin-bottom: 44px;
    }

    p {
      text-align: center;
      margin: 0;
      font-size: 18px;
      line-height: 24px;
      @include archivo-narrow;
    }
  }
}
