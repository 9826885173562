@import "src/styles/variables/core/variables";
@import "src/styles/mixins";

app-rank-business-details {

  .branch-name {
    @include absolute-center-horizontal;
    @include archivo-narrow();
    font-size: 16px;
    min-width: 250px;
    padding: 0 10px;
    height: 31px;
    border: 3px solid #000;
    border-top: 1px solid #000;
    background: #FFD500;
    bottom: -3px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
  }

  .ow-dialog {
    padding: 52px 60px 72px 100px;
    position: relative;

    .title {
      @include archivo-narrow;
      margin-bottom: 24px;
    }
  }

  .ng-dropdown-wrapper {
    width: 210px;
    height: 36px;
    color: #000000;
    background: transparent;
    border: 2px solid #000000;
    border-radius: 15px;
    box-shadow: none !important;
    margin-right: 10px;
    display: flex !important;
    align-items: center !important;

    &.active {
      border-radius: 15px 15px 0 0;
      background-color: #F9F4E4;
    }

    input {
      @include archivo-narrow;
      font-weight: bold;
      font-size: 16px;
      color: #000000;
    }

    .ng-dropdown-menu {
      border: 2px solid #000000;
      border-top: none;
      top: 114% !important;
      left: -2px !important;
      right: -2px !important;

      li {
        border-top: 1px dashed #0000003d;
      }
    }

    .ng-dropdown-menu > * {
      background-color: #F9F4E4;
    }
  }


  .dialog-decoration {
    .right {
      position: absolute;
      width: 136px;
      height: 522px;
      right: -52px;
      top: 78px;
      background-repeat: no-repeat;
    }

    .bottom {
      position: absolute;
      bottom: 8px;
      width: 244px;
      height: 26px;
      right: 53px;
      background-image: var(--asset--ui-sheet_orange_down-png);
    }
  }
}
