app-event-business-list {
  .branch-name {
    @include absolute-center-horizontal;
    @include archivo-narrow();
    font-size: 16px;
    min-width: 250px;
    padding: 0 10px;
    height: 31px;
    border: 3px solid #000;
    border-top: 1px solid #000;
    background: #FFD500;
    bottom: -3px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
  }

  .ow-dialog {
    padding: 58px 82px 60px 100px;
    display: flex;
    flex-direction: column;
    @include archivo-narrow;
    letter-spacing: 0;

    .event-business-list {
      display: flex;
      flex-direction: column;
      padding-right: 20px;

      .event-business-card {
        display: flex;
        flex-direction: column;
        height: 172px;
        margin-bottom: 6px;
        background-color: #F9F4E4;
        border: 2px solid #000000;
        padding: 4px;
        box-sizing: border-box;

        &.deactived {
          opacity: 0.7;
        }

        .card-title {
          @include mikado;
          font-size: 16px;
          font-weight: bold;
          background-color: #FFD916;
          height: 30px;
          line-height: 28px;
          padding-left: 30px;
        }

        .card-description {
          display: flex;
          flex: 1;
          align-items: center;
          padding: 12px 15px 12px 28px;

          .description-image {
            width: 76px;
            height: 76px;
          }

          .description-separator {
            width: 2px;
            height: 100%;
            background-color: #EBE5D5;
            margin: 0 24px;
          }

          .description-text {
            flex: 1;
            @include archivo-narrow;
            font-size: 16px;
          }

          .right {
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            height: 100%;
            width: 332px;

            .right-info {
              display: flex;

              a {
                text-decoration: none;
              }

              .description-date {
                display: flex;
                align-items: center;
                margin-right: 20px;
                position: relative;

                .date-icon {
                  width: 30px;
                  height: 30px;
                  margin-right: 6px;

                  &.date-notification {
                    position: absolute;
                    z-index: 2;
                    left: -14px;
                  }
                }

                span {
                  width: 168px;
                  font-size: 18px;
                  font-weight: bold;
                }
              }

              .c-base {
                width: 38px;
                height: 38px;
                min-width: 38px;
                font-size: 16px;

                &:first-of-type {
                  margin-right: 16px;
                }
              }
            }
          }

        }
      }
    }

    perfect-scrollbar.scroll-indicator::after {
      height: 30px;
      background: transparent linear-gradient(180deg, #EBE5D500 0%, #EBE5D5 86%, #EBE5D5 100%) 0 0 no-repeat padding-box;
    }
  }

  .no-item {
    @include archivo-narrow();
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: bold;
    margin-top: 30px;
  }
}
