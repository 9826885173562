&.c-window-long-wf {
  background: var(--asset--ui-window_long-png);
  width: 1366px;
  height: 620px;

  .decoration {
    position: absolute;
    background-repeat: no-repeat;
    background-size: contain;

    &.decoration-1 {
      width: 108px;
      height: 212px;
      box-sizing: border-box;
      background: var(--asset--ui-wf_tail-png);
      right: -32px;
      top: 102px;
    }

    &.decoration-2 {
      width: 118px;
      height: 262px;
      box-sizing: border-box;
      background: var(--asset--ui-wf_lemur_head-png);
      left: -60px;
      bottom: 0;
    }
  }
}
