@import "src/styles/functions";
@import "variables/core/variables";

$important: "!Important";

@mixin text-ellipsis() {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

@mixin futura-heavy($_important:"") {
  font-family: $font-futura-heavy, sans-serif unquote($_important);
}

@mixin futura-medium($_important:"") {
  font-family: $font-futura-medium, sans-serif unquote($_important);
}

@mixin archivo-narrow($_important:"") {
  font-family: 'Archivo Narrow', sans-serif unquote($_important);
}

@mixin font-awesome($_important:"") {
  font-family: 'Font Awesome 5 Pro', sans-serif unquote($_important);
}

@mixin mikado($_important:"") {
  font-family: 'Mikado', sans-serif unquote($_important);
}

@mixin mikado-black($_important:"") {
  font-family: 'Mikado Black', sans-serif unquote($_important);
}

@mixin myriad($_important:"") {
  font-family: 'MyriadPro', sans-serif unquote($_important);
}

@mixin myriad-cond($_important:"") {
  font-family: 'MyriadPro-Cond', sans-serif unquote($_important);
}

@mixin vt323($_important:"") {
  font-family: 'VT323', sans-serif unquote($_important);
}

@mixin conthrax($_important:"") {
  font-family: 'Conthrax', sans-serif unquote($_important);
}

@mixin roboto($_important:"") {
  font-family: 'Roboto', sans-serif unquote($_important);
  letter-spacing: multiply-size(0.4px);
}

@mixin roboto-cond($_important:"") {
  font-family: 'RobotoCond', sans-serif unquote($_important);
  letter-spacing: multiply-size(0.4px);
}

@mixin preload-image($images...) {
  &:after {
    $url: null;
    @each $image in $images {
      $url: $url $image
    }
    content: $url;
    width: 0;
    height: 0;
    display: none;
  }
}

@mixin absolute-center-vertical {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

@mixin absolute-center-horizontal {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

@mixin absolute-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin outer-dialog {
  position: absolute;

  &.left {
    left: multiply-size(-4px);
    transform: translateX(-100%);
  }

  &.right {
    right: multiply-size(-4px);
    transform: translateX(100%);
  }
}

@mixin experience-badge {
  position: absolute;
  width: 71px;
  top: -16px;
  right: 134px;
  margin: auto;

  .inner-wrapper {
    position: relative;

    img {
      width: 40px;
      height: 40px;
      left: 70px;
      object-fit: cover;
      position: absolute;
      z-index: 2;
    }

    p {
      text-align: right;
      position: absolute;
      right: -20px;
      top: 6px;
      box-sizing: border-box;

      //width: 71px;
      height: 27px;
      font-size: 16px;
      line-height: 21px;
      margin: 0;
      padding: 3px 25px 3px 8px;
      border: 3px solid black;
      color: black;
      z-index: 1;
      background-color: #eae4d4;
      @include mikado;
    }
  }
}

@mixin building-image {
  background-position: center bottom;
  background-size: contain;
  background-repeat: no-repeat;
}

@mixin text-stroke($color: $color-K) {
  text-shadow: 0 0 multiply-size(2px) $color, 0 0 multiply-size(2px) $color, 0 0 multiply-size(1px) $color, 0 0 multiply-size(1px) $color, 0 0 multiply-size(1px) $color, 0 0 multiply-size(1px) $color, 0 0 multiply-size(1px) $color, 0 0 multiply-size(1px) $color, 0 0 multiply-size(1px) $color, 0 0 multiply-size(1px) $color, 0 0 multiply-size(1px) $color, 0 0 multiply-size(1px) $color, 0 0 multiply-size(1px) $color, 0 0 multiply-size(1px) $color, 0 0 multiply-size(1px) $color, 0 0 multiply-size(1px) $color, 0 0 multiply-size(1px) $color, 0 0 multiply-size(1px) $color, 0 0 multiply-size(1px) $color, 0 0 multiply-size(1px) $color, 0 0 multiply-size(1px) $color;
}

@mixin text-stroke-grey($color: $color-duodenary) {
  text-shadow: 0 0 multiply-size(2px) $color, 0 0 multiply-size(2px) $color, 0 0 multiply-size(1px) $color, 0 0 multiply-size(1px) $color, 0 0 multiply-size(1px) $color, 0 0 multiply-size(1px) $color, 0 0 multiply-size(1px) $color, 0 0 multiply-size(1px) $color, 0 0 multiply-size(1px) $color, 0 0 multiply-size(1px) $color, 0 0 multiply-size(1px) $color, 0 0 multiply-size(1px) $color, 0 0 multiply-size(1px) $color, 0 0 multiply-size(1px) $color, 0 0 multiply-size(1px) $color, 0 0 multiply-size(1px) $color, 0 0 multiply-size(1px) $color, 0 0 multiply-size(1px) $color, 0 0 multiply-size(1px) $color, 0 0 multiply-size(1px) $color, 0 0 multiply-size(1px) $color;
}

@mixin text-stroke-green($color: $color-vigenary) {
  text-shadow: 0 0 multiply-size(2px) $color, 0 0 multiply-size(2px) $color, 0 0 multiply-size(1px) $color, 0 0 multiply-size(1px) $color, 0 0 multiply-size(1px) $color, 0 0 multiply-size(1px) $color, 0 0 multiply-size(1px) $color, 0 0 multiply-size(1px) $color, 0 0 multiply-size(1px) $color, 0 0 multiply-size(1px) $color, 0 0 multiply-size(1px) $color, 0 0 multiply-size(1px) $color, 0 0 multiply-size(1px) $color, 0 0 multiply-size(1px) $color, 0 0 multiply-size(1px) $color, 0 0 multiply-size(1px) $color, 0 0 multiply-size(1px) $color, 0 0 multiply-size(1px) $color, 0 0 multiply-size(1px) $color, 0 0 multiply-size(1px) $color, 0 0 multiply-size(1px) $color;
}

@mixin drop-shadow($color: $color-primary, $sizes...) {
  $filter: null;
  $default-sizes: 1, 2, 4;

  @if length($sizes) == 0 {
    $sizes: $default-sizes;
  }

  @each $size in $sizes {
    $filter: $filter drop-shadow(0 0 unquote($size+'px') $color)
  }
  filter: $filter;
}

@mixin notificationBounce() {
  .icon {
    &.notification {
      position: absolute;
      right: 0;
      top: 0;
      animation: bounceCustom 1s ease-in-out;
      animation-iteration-count: infinite;
      font-size: multiply-size(32px);
      background-image: var(--asset--ui-notfication-png);
      width: multiply-size(13px);
      height: multiply-size(24px);
      background-size: contain;
      background-repeat: no-repeat;
      transform: none;

      @keyframes bounceCustom {
        0% {
          transform: translateY(multiply-size(0px));
        }
        50% {
          transform: translateY(multiply-size(-3px));
        }
        100% {
          transform: translateY(multiply-size(0px));
        }
      }
    }
  }
}

@import "custom/mixins";
