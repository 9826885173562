app-building-upgrade {
  @import "../styles/style";

  .bookmarks {
    top: -23px !important;
  }

  .levels-container {
    margin-top: 10px;
    margin-left: 26px;
  }

  .elements {
    position: relative;
  }
}
