@import "src/styles/mixins";
@import "src/styles/variables/core/variables";

app-business-preview-branches {

  .business-buttons {
    position: absolute;
    bottom: 37px;
    display: flex;
    gap: 20px;
    right: 84px;
  }

  perfect-scrollbar {
    max-width: 100% !important;

    .ps-content {
      padding-right: 30px;
    }
  }

  .c-window-long {
    @include archivo-narrow();
    padding: 50px 70px;
    flex-direction: column;

    .top-menu {
      width: 100%;
      flex: 1;
      display: flex;
      align-items: flex-end;
      justify-content: flex-start;
    }

    .search-bar {
      width: 496px;
      height: 36px;
      border: 2px solid #000;
      padding: 0 12px;
      color: #000;
      font-size: 16px;
      font-weight: 400;
      border-radius: 15px;
      @include archivo-narrow();
      text-align: left;
      margin: 10px 0 20px 30px;

      &::placeholder {
        color: #000;
      }

    }

    .main {
      width: 100%;
      height: 438px;
      box-sizing: border-box;
      padding: 0 0 0 30px;

      .branch {
        font-size: 16px;
        font-weight: 700;
        height: 38px;
        padding: 0 20px 0 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-top: 1px solid #CEC7B4;

        &:nth-child(even) {
          background-color: #F2EDDF;
        }

        &:nth-child(odd) {
          background-color: #F9F4E4;
        }

        span.cta {
          text-decoration: underline;
          cursor: pointer;
        }

        .italic {
          font-style: italic;
        }
      }
    }
  }

  .no-item {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: bold;
    margin-top: 30px;
  }

    img {
      margin-bottom: 20px;
    }
  }

  .cursor {
    cursor: pointer;
  }

  .pointer-events {
    pointer-events: none;
  }

