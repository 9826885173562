@import "src/styles/functions";
&.base {
  width: auto;
  min-width: multiply-size(124px);
  height: multiply-size(31px);
  @include myriad;
  font-weight: bold;
  font-size: multiply-size(10px);
  border-radius: multiply-size(8px);
  border: multiply-size(2px) solid;
  padding-left: multiply-size(10px);
  padding-right: multiply-size(10px);
}
