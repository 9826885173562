@import "src/styles/mixins";

input[type="text"], input[type="password"], input[type="number"], input[type="email"] {
  @include roboto-cond;
}

.default-field {
  position: relative;
  display: flex;
  flex-flow: column-reverse;
  margin-bottom: 25px;
  @include roboto-cond;

  .input-icon {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    color: #000;
    transition: 0.2s ease-in-out;
  }

  label {
    color: #000000;
    font-size: 16px;
    letter-spacing: 0.32px;
    display: inline-block;
    margin-right: auto;
    padding: 3px;
    position: absolute;
    top: 50%;
    left: 20px;
    transform: translateY(-50%);
    transition: font-size 0.2s ease-in-out, top 0.2s ease-in-out;
    @include roboto-cond;
  }

  input {
    font-size: 16px;
    border: 2px solid #000000;
    outline: none;
    box-shadow: none;
    padding: 14px;
    box-sizing: border-box;
    color: #000000;
    height: auto;
    background: #F9F2E0;
    text-align: left;

    &::placeholder {
      color: transparent;
    }

    &:focus {
      outline: none;
      font-weight: bold;
    }


    &:placeholder-shown + label {
      cursor: text;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      transform-origin: left bottom;
    }

    &:not(:placeholder-shown) + label,
    &:focus + label {
      transform: translateY(-50%);
      top: -3px;
      cursor: pointer;
      color: #000000;
      font-size: 12px;
      // todo:gutyo temporary hide
      opacity: 0;
    }

    &:disabled {
      cursor: not-allowed;
      background-color: #fff;
      opacity: 0.5;

      & + label {
        cursor: not-allowed;
      }
    }
  }

  &.error {
    input {
      border-color: #D11A00;

      &:not(:placeholder-shown) + label,
      &:focus + label {
        color: #D11A00;
      }
    }

    label {
      color: #D11A00;
    }
  }
}

.choose-wrapper {
  position: relative;
  display: flex;
  cursor: pointer;
  align-items: center;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;

    &:checked {
      & ~ .checkmark {
        //background-color: #FFA200;
        &:after {
          display: block;
        }
      }
    }
  }

  .checkbox-wrapper {
    margin-right: 12px;
    position: relative;

    .checkmark {
      display: inline-block;
      height: 21px;
      width: 21px;
      border-radius: 50%;
      background: #fff;
      border: 3px solid #000000;
      box-sizing: border-box;

      &:after {
        content: "";
        position: absolute;
        display: none;
        top: 7px;
        left: 7px;
        width: 7px;
        height: 7px;
        border-radius: 50%;
        background: #000000;
      }
    }
  }

  .text-wrapper {
    @include roboto-cond;
    color: inherit;
    letter-spacing: 0.4px;


    a {
      color: inherit;
    }
  }
}
