@import "src/styles/mixins";

app-league-achievement-details {
  .ow-dialog {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px 70px 60px 105px;
  }

  .icon-league {
    width: 100px;
    height: 100px;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .title {
    @include mikado();
    font-size: 18px;
    font-weight: bold;
    text-transform: uppercase;
    margin: 12px 0 6px 0;
  }

  .desc {
    @include archivo-narrow();
    font-size: 14px;
    font-weight: normal;

    &.middle {
      margin: 12px 0 24px 0;
      height: 32px;
      align-self: center;
    }
  }

  .list {
    width: 100%;
    height: 261px;
    margin-top: 8px;
  }

  table {
    width: 100%;
    border-spacing: 2px;
    border-collapse: collapse;
    white-space: nowrap;

    tbody {
      tr {
        height: 38px;

        &:first-child {
          width: 4%;
        }

        &:nth-child(odd) {
          background: #F9F4E4;
        }

        td {
          font-size: 14px;
          font-weight: bold;
          @include archivo-narrow;
          text-align: center;
          padding: 0 10px;
          cursor: default;

          &:nth-child(2),
          &:last-child {
            text-align: left;
            padding-left: 15px;
            width: 45%;
          }
        }
      }
    }
  }
}
