app-auctions {
  @import "../../styles/styles";

  .ow-dialog {
    padding: 54px 86px 60px;

    &.player {
      padding: 44px 86px 60px !important;
    }
  }

  .no-item {
    @include mikado();
    width: 100%;
    height: 80%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 22px;
  }

  .bookmark {
    span {
      padding-top: 4px !important;
    }
  }

  .tabs {
    left: 0 !important;
    padding-left: 95px;
    box-sizing: border-box;
  }

  .tabs {
    .tab {
      .bookmarks {
        .bookmark {
          min-width: 60px !important;
        }
      }
    }
  }
}
