@import "src/styles/mixins";
@import "src/styles/variables/core/variables";

.edenred-list-container {
  @include mikado;
  padding: 40px 88px 35px 88px;
  display: flex;
  flex-direction: column;

  .info {
    @include archivo-narrow();
    font-size: 16px;
    line-height: 18px;
    font-weight: 700;
    position: absolute;
    right: 112px;
    top: 42px;
  }

  .title {
    text-align: center;
    font-size: 24px;
  }

  .frame {
    padding: 25px 30px;
    border-radius: 15px;
    flex: 1 0 auto;

    .table-wrapper {
      height: 406px;
      width: 100%;

      table {
        width: 100%;
        border-spacing: 2px;
        border-collapse: collapse;
        white-space: nowrap;


        th {
          font-weight: normal !important;
          font-size: 14px !important;
          padding-bottom: 10px;

          &:last-child {
            padding-right: 36px !important;
          }
        }

        td, th {
          padding: 0 10px;

          &.card-number {
            width: 30%;
            font-weight: bold;
            font-size: 20px;
          }

          &.card-status {
            width: 26%;
            text-align: left;
          }

          &.card-history {
            width: 100%;
            display: flex;
            justify-content: flex-end;
            padding-right: 36px;
            box-sizing: border-box;

            button {
              width: 47px;
              height: 47px;
            }
          }
        }

        td, th {
          @include roboto-cond;
          font-size: 14px;
          font-weight: 400;
          text-align: left;

          &:first-child {
            padding-left: 20px !important;
          }
        }

        thead {
          th {
            padding: 0 0 10px 8px;

            &:last-child {
              text-align: right;
            }

          }
        }

        tbody {
          tr {
            height: 47px;

            &:nth-child(odd) {
              background-color: #F9F4E4;
            }
          }
        }
      }
    }
  }
}

button {
  &.c-square {
    font-size: 20px;
    border: none !important;

    i {
      font-weight: 400;
    }
  }
}
