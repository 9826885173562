ow-object-svg {
  display: flex;
  pointer-events: none;

  svg {
    width: 100%;
    height: 100%;

    path {
      color: inherit;
      paint-order: stroke;
    }
  }
}
