@import "src/styles/functions";

app-photos {
  @import "../../styles/style";

  background: #f6f8f9;
  padding-bottom: multiply-size(50px);
  display: block;
  min-height: 100vh;
  box-sizing: border-box;

  .inner-html-description {
    p:first-child {
      margin-top: 0;
    }

    p:last-child {
      margin-bottom: 0;
    }
  }

  p {
    &.photo-date {
      @include archivo-narrow;
      font-weight: bold;
      font-size: multiply-size(14px);
      margin: multiply-size(40px) 0 0 0;
      color: #008000;
    }

    &.invalid-add-photo-date {
      color: #ff0000 !important;
    }
  }

  .download-attachment {
    margin-top: multiply-size(15px);

    i {
      margin-right: multiply-size(8px);
      font-size: multiply-size(16px);
    }
  }

  .images-list-container {
    display: block;

    .each-image {
      .details-border {
        padding-bottom: multiply-size(12px);

        .each-image-author,
        .each-image-other-info,
        .each-image-date {
          color: $color-light;
          font-size: multiply-size(16px);
          @include roboto-cond;
          margin: 0;

          &.each-image-author,
          &.each-image-other-info {
            margin-bottom: multiply-size(8px);
          }
        }

        .each-image-title {
          margin: multiply-size(10px) 0 multiply-size(8px) 0;
        }

        .each-image-annotation {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
      }
    }
  }

  ngx-masonry {
    margin: 0 auto;
    display: block;
  }
}
