@import "src/styles/mixins";
@import "src/styles/variables/core/variables";

app-dashboard-cc {
  @mixin display-center {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  perfect-scrollbar {
    max-width: 100% !important;

    .ps-content {
      padding-right: 22px;
    }
  }

  .business-calendar-button {
    width: 50px;
    height: 54px;
    background: var(--asset--ui-calendar-png) center no-repeat;
    background-size: contain;
    position: absolute;
    bottom: 46px;
    right: -2px;
    cursor: pointer;
  }

  .c-window-long {
    @include archivo-narrow();
    @include display-center();
    padding: 50px 72px;
    flex-direction: column;

    .top-menu {
      width: 100%;
      flex: 1;
      display: flex;
      align-items: flex-start;
      justify-content: center;

      .option {
        display: flex;
        align-items: center;
        position: relative;

        &:not(:last-child) {
          margin-right: 24px;
        }

        button {
          i {
            text-shadow: none !important;
            font-size: 16px;
            color: #000000;
          }

          ow-object-svg {
            width: 16px;
            height: 16px;
          }
        }

        span {
          font-size: 14px;
          font-weight: bold;
          margin-left: 6px;

          &.no-wrap {
            white-space: nowrap;
          }
        }
      }
    }

    .main {
      width: 100%;
      height: 418px;
      box-sizing: border-box;
    }

    .navigation {
      @include display-center();
      align-items: flex-start !important;
      width: 100%;
      margin-top: 12px;

      .nav-arrow {
        text-shadow: none !important;
        color: #000000;
        font-size: 20px;
      }

      .value {
        font-size: 16px;
        font-weight: bold;
        margin: 0 20px;
      }
    }
  }

  .no-item {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: bold;
    margin-top: 30px;
  }

  .under-construction {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    font-weight: bold;
    margin-top: 40px;
    line-height: 24px;
    text-align: center;
    @include mikado;
    flex-direction: column;

    img {
      margin-bottom: 20px;
    }
  }

  .cursor {
    cursor: pointer;
  }

  .pointer-events {
    pointer-events: none;
  }

  .cleared {
    position: absolute;
    bottom: 44px;
    left: 51px;
    width: 200px;
    height: 40px;
    z-index: 1;
    background: var(--asset--ui-cleared-png);
    background-size: contain;
    background-repeat: no-repeat;
    display: unset !important;
  }

  @import "card-task-cc";
}
