app-business-challenges {
  .branch-name {
    @include absolute-center-horizontal;
    @include archivo-narrow();
    font-size: 16px;
    min-width: 250px;
    padding: 0 10px;
    height: 31px;
    border: 3px solid #000;
    border-top: 1px solid #000;
    background: #FFD500;
    bottom: -3px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
  }

  .container {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;

    .separator {
      height: 260px;
      width: 1px;
      background-color: #9C9583;
      margin: 0 86px;
      opacity: 0.6;
    }

    .box {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: relative;

      &.blocked {
        span, .icon {
          opacity: 0.3;
          pointer-events: none;
        }
      }

      .fa-info-square {
        position: absolute;
        top: -18px;
        right: -18px;
        color: #C0BBAD;
        font-size: 20px;
        cursor: pointer;
      }

      span {
        margin-top: 6px;
        @include mikado();
        font-size: 16px;
        font-weight: bold;
        line-height: 15px;
        letter-spacing: 0.32px;
      }

      .icon {
        width: 188px;
        height: 188px;
        box-sizing: border-box;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
        cursor: pointer;

        &:hover {
          opacity: 0.8;
        }

        &.contract-sale {
          background-image: var(--asset--ui-microleague-png);
        }

        &.micro-league {
          background-image: var(--asset--ui-contract_sales-png);
        }
      }
    }
  }

  .app-notification {
    left: unset !important;
    right: 34px;
    top: unset !important;
    bottom: 0;
  }
}
