@import "src/styles/mixins";

shop {
  @import "shop-assets";

  & {
    @include mplus1c-rounded;

    button {
      &.c-base {
        width: 168px !important;
        height: 40px !important;
        font-size: 14px !important;
      }

      &.c-primary.c-square.small {
        padding-bottom: 4px !important;
      }
    }

    .default-field input:not(:placeholder-shown) + label, .default-field input:focus + label {
      background-color: #ffffff;
    }

    .default-field label {
      color: #000000;
    }

    .default-field {
      margin-left: 8px;

      input {
        border: 2px solid #000000;
        border-radius: 15px;
        width: 182px;
        height: 36px;
      }
    }
  }

  #shop-header {
    position: fixed;
    top: 0;
    height: 157px;
    width: 100%;
    z-index: 10;
    padding-top: 20px;
    box-sizing: border-box;
    background: #ffffff;

    .logo-market {
      width: 251px;
      height: 138px;
      float: left;
      cursor: pointer;
    }

    .person-data {
      float: left;
      margin-top: 31px;
      margin-left: 20px;
      color: #000000;

      p {
        margin: 1px 0;
        font-size: 16px;
        line-height: 16px;

        &.name {
          margin-bottom: 10px;
          font-size: 16px;
          font-weight: 900;
        }

        &.branch {
          text-transform: uppercase;
        }
      }
    }

    #mobile-menu {
      display: none;
    }

    .button-menu {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      & > div, & > button {
        margin-left: 10px;
      }

      button {
        &.cart {
          i {
            margin-right: 2px;
            padding-bottom: 1px;
            font-size: 16px;
          }

          .cart-length {
            font-weight: 900;
            padding-bottom: 2px;
          }
        }

        &.back {
          font-size: 16px;
          padding-left: 2px;
        }
      }

      .golden-bar, .sparkle-bar, .discount-bar {
        position: relative;
        cursor: pointer;
        padding-left: 25px;
        padding-right: 16px;
        padding-bottom: 1px;
        box-sizing: border-box;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        font-size: 14px;
        color: $color-I;
        font-weight: 900;
        min-width: 88px;
        height: 26px;
        background: #000000;
        border: 2px solid #000000;
        border-radius: 20px;

        img {
          @include absolute-center-vertical;
          left: -20px;
        }
      }

      .golden-bar, .discount-bar {
        margin-right: 20px;
      }

      .sparkle-bar {
        margin-right: 10px;
      }
    }

    .filter-container {
      float: right;
      margin-top: 40px;
      display: flex;

      .filter-select {
        position: relative;

        i {
          position: absolute;
          top: 8px;
          right: 20px;
          font-size: 16px;
          color: #000000;
        }

        select {
          -webkit-appearance: none !important;
          -moz-appearance: none !important;
          appearance: none !important;
          border: 2px solid #000000;
          border-radius: 15px;
          height: 36px;
          letter-spacing: 0.32px;
          color: #000000;
          font-size: 16px;
          text-transform: none;
          text-align: center;
          cursor: pointer !important;
          padding: 0 10px;
        }
      }
    }
  }

  #shop-container {
    display: flex;
    flex-direction: row;
    margin-top: 200px;
    height: calc(100% - 157px);

    & > *:not(router-outlet) {
      flex: 1;
    }
  }

  #shopBlockScreen {
    position: fixed;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    top: 0;
    left: 0;
    z-index: 1000;

    span {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 26px;
      font-weight: bold;
    }
  }

  .mobile {
    #shop-header {
      padding: 0;
      height: 60px;
      border-bottom: 2px solid #553b24;
      box-sizing: border-box;
      background: #ffffff;

      .toggle-menu {
        position: fixed;
        border-bottom: 2px solid #553b24;
        box-sizing: border-box;
        top: -100%;
        transition: 0.3s;
        left: 0;
        right: 0;
        z-index: -1;
        background: #ffffff;

        &.toggle {
          top: 57px;
        }
      }
    }

    #mobile-menu {
      display: inline !important;
    }

    .logo-market, .person-data, .talar-bar {
      display: none;
    }

    .button-menu {
      display: flex;
      align-items: center;
      justify-content: flex-start !important;

      button.square {
        margin-left: 10px !important;
      }

      .button-shop {
        float: none !important;
      }
    }

    .filter-container {
      margin: 10px !important;
      float: none !important;

      select {
        width: 100%;
        margin: 10px 0;
        -webkit-appearance: none !important;
        -moz-appearance: none !important;
        appearance: none !important;
        cursor: pointer !important;
      }
    }

    #shop-container {
      height: calc(100vh - 50px) !important;
    }

    .mobile-menu {
      display: flex;
      padding: 5px 8px;
      box-sizing: border-box;
      justify-content: space-between;
      align-items: center;
      background: #ffffff;
    }
  }

  .default-field {
    position: relative;

    .search {
      position: absolute;
      top: 0;
      right: 0;
      width: 36px;
      height: 36px;
      z-index: 1;

      &.clear {
        right: -30px;
        font-size: 16px;
      }
    }
  }

  html {
    &.shop {
      overflow: hidden;

      body {
        margin-top: 157px;
      }

      &.mobile {
        body {
          margin-top: 60px;
        }
      }

      .scroll-x-element {
        overflow-x: auto;
      }
    }
  }

  ow-perfect-scrollbar {
    max-width: initial !important;
    width: 100% !important;
  }

  .m-left-0 {
    margin-left: 0
  }

  .bg-container {
    width: 100%;
    height: 100%;
  }

  .default-field input {
    background-color: #fff;
  }
}
