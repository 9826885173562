@import "src/styles/variables/core/variables";
@import "src/styles/mixins";

app-alert-confirm {
  background: #EBE5D5;
  border: 2px solid #000000;
  max-width: 840px;
  padding: 25px;
  position: relative;
  // MAX SIZE DEFAULT IN ALERT SERVICE
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &:after {
    content: '';
    position: absolute;
    top: -12px;
    left: -12px;
    bottom: -12px;
    right: -12px;
    background: $custom-dialog-outline;
    border: 2px solid #000000;
    z-index: -1;
  }

  p {
    &.title {
      margin: 0 0 10px 0;
      font-size: 22px;
      @include mikado;
      font-weight: 900;
      text-align: center;
      text-transform: uppercase;
    }

    &.description {
      @include archivo-narrow;
      margin: 0;
      text-align: center;
      font-size: 20px;
      padding: 0 0 10px 0;

      & + m-ui-separator {
        margin-top: -10px;
      }
    }

    &.separator-title {
      font-size: 16px;
      text-transform: uppercase;
      margin: 8px 0 12px 0;
      @include mikado;
      font-weight: bold;
      text-align: center;
    }
  }

  .buttons {
    margin-top: 20px;
    display: flex;
    justify-content: center;

    button {
      margin: 0 10px;
    }
  }

  .c-base {
    width: unset;
    min-width: 204px;
    padding-left: 16px;
    padding-right: 16px;
  }

  m-ui-separator {
    width: 100%;
  }

  m-ui-costs {
    margin: 0 auto 5px auto;
  }

  .stock-layout-a {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .icon {
      width: 50px;
      height: 50px;
      margin-bottom: 4px;
    }

    .value {
      @include mikado();
      font-size: 14px;
      font-weight: 900;
      text-align: center;
      color: #000000;
      text-shadow: none;
    }
  }
}
