@import "src/styles/variables/core/variables";
@import "src/styles/mixins";

wf-main {
  .ow-dialog {
    &.rules {
      padding: 68px 158px 68px 146px;
    }

    &.library {
      padding: 48px 103px;
    }

    &.media {
      padding: 48px 103px 84px;
    }

    &.ranks {
      padding: 50px 103px 84px;
    }

    &.episode {
      padding: 50px 123px 0;
    }
  }

  .tabs {
    left: 110px;
  }

  .frame {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
  }

  .content {
    height: 100%;
    width: 100%;
    box-sizing: border-box;

    &.rules {
      display: flex;
      align-items: center;

      .logo {
        width: 442px;
        height: 483px;
        margin-right: 40px;
        box-sizing: border-box;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }

      .info {
        flex: 1;

        .title {
          @include mikado();
          font-size: 20px;
          margin-bottom: 10px;
          padding-left: 0 !important;
        }

        .desc {
          @include archivo-narrow();
          font-size: 20px;
          text-align: left;
          letter-spacing: 0;
          line-height: 22px;
        }
      }
    }
  }

  .sticker {
    width: 128px;
    height: 158px;
    box-sizing: border-box;
    position: absolute;
    bottom: 81px;
    left: -36px;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .tabs {
    .tab {
      .bookmarks {
        .bookmark {
          min-width: auto;

          &.disabled {
            filter: grayscale(1);
          }
        }
      }
    }
  }
}


