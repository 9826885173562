@import "src/styles/mixins";

app-building-production {
  .c-window-short {
    padding: 40px 90px 40px 114px;
  }

  .building-production-container {
    display: flex;
    flex-direction: column;

    .title {
      margin-bottom: 18px;
    }

    .current-production-text {
      @include archivo-narrow();
      font-size: 14px;
      display: flex;
      justify-content: center;
      margin-right: 10px;
      letter-spacing: 0;
      font-weight: bold;
    }

    .current-production-progress {
      display: flex;
      align-items: center;
      justify-content: center;

      .progressbar {
        border-radius: 6px;
        height: 14px;
        width: 252px;
        margin-right: 12px;
        border: 2px solid #000000;
        background-color: #DED8C6;
        padding: 0;

        .fill {
          border-radius: 6px 0 0 6px;
          border-right: 2px solid $color-H;
        }
      }

      .time {
        @include mikado;
        font-size: 16px;
        font-weight: 900;
        padding-top: 6px;
      }
    }

    .row {
      height: 78px;
      display: flex;
      align-items: center;

      &:not(:last-child) {
        margin-bottom: 10px;
      }

      .buttons-wrapper {
        display: flex;
        margin-left: auto;

        button {
          width: 38px;
          height: 38px;
          box-sizing: border-box;

          &:not(:last-child) {
            margin-right: 8px;
          }

          &.production-play,
          &.production-stop {
            i {
              font-size: 12px;
            }
          }

          &.production-bolt {
            i {
              font-size: 16px;
            }
          }

          &.production-play {
            &.charity-dodo {
              i {
                display: none;
              }

              background-image: var(--asset--ui-star_button-png);
            }
          }
        }
      }
    }
  }

  .prod-progress {
    height: 30px !important;
    margin-top: 16px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  m-ui-multi-stock {
    cursor: pointer;

    .multi-products-container {
      background: transparent;
      top: -10px;
    }
  }

  .weight-900 {
    font-weight: 900;
  }

  perfect-scrollbar .ps-content {
    padding-right: 46px;
  }

  .h-static {
    height: 470px;
  }
}

