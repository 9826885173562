@import "src/styles/mixins";

app-custom-player-details {
  .c-window-short {
    padding: 78px 72px 67px 72px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .user-details-container {
    .logout {
      position: absolute;
      bottom: 60px;
      right: 98px;
      cursor: pointer;
      @include archivo-narrow();
      font-size: 14px;
      font-weight: bold;
      text-decoration: underline;
    }

    .title {
      margin-bottom: 15px;
      margin-top: 5px;
    }

    .info-wrapper {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-direction: column;
      height: 100%;
      box-sizing: border-box;

      .first-details-section {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .avatar-wrapper {
          width: 110px;
          height: 110px;
          box-sizing: border-box;
          background: var(--asset--ui-main-user-2-2-png);
          background-size: contain;
          background-repeat: no-repeat;
          position: relative;
          z-index: 2;
          border-radius: 50%;
          border: 2px solid #000000;

          &:hover {
            .avatar-default {
              z-index: 20;
              pointer-events: none;

              i {
                color: #fff;
                font-size: 38px;
                @include text-stroke(#000000);

                &:before {
                  content: '\f067';
                }
              }
            }

            .avatar-change {
              cursor: pointer;
            }
          }

          .avatar {
            position: absolute;
            left: 50%;
            transform: translate(-50%, -50%);
          }

          .avatar-default {
            top: 50%;
            z-index: 10;

            i {
              font-size: 74px;
              color: #959185;
            }
          }

          .avatar-custom {
            width: 100%;
            height: 100%;
            top: 53px;
            border-radius: 50%;
            background-size: cover;
            background-position: center;
            z-index: 15;
          }

          .avatar-change {
            width: 78px;
            height: 79px;
            position: absolute;
            top: 48%;
            left: 50%;
            transform: translate(-50%, -50%);
            border-radius: 50%;
            opacity: 0;
            z-index: 30;
          }
        }

        .name-wrapper {
          margin-top: 8px;
          @include mikado();
          font-weight: bold;
          font-size: 20px;
        }

        .position-wrapper {
          @include archivo-narrow();
          font-size: 16px;
          font-weight: bold;
          letter-spacing: 0.32px;
          margin-top: 4px;

          .position-name {
            margin-right: 22px;
            position: relative;

            &:after {
              position: absolute;
              content: '';
              width: 2px;
              height: 80%;
              background-color: #000000;
              display: block;
              right: -12px;
              top: 3px;
            }
          }

          .position-location {
            text-transform: uppercase;
          }
        }

        .role-wrapper {
          display: flex;
          flex-direction: column;
          align-items: center;
          font-size: 16px;
          margin-top: 22px;

          .role-title {
            @include archivo-narrow();
            font-weight: 900;
            font-size: 14px;
            margin-bottom: 12px;
          }

          .role-select {
            flex-grow: 1;
            width: 206px;
            height: 36px;
          }
        }
      }

      .second-details-section {
        .frame {
          height: 150px;
          margin-top: 48px;
          position: relative;
          width: 600px;
          border-top: 2px solid #959185;
        }

        .edit {
          position: absolute;
          right: 0;
          top: 4px;

          i {
            font-size: 14px;
            color: #959185;
          }
        }

        .person-data {
          @include absolute-center-horizontal;
          display: flex;
          flex-direction: column;
          top: 16px;

          .item {
            @include archivo-narrow();
            display: flex;
            font-size: 14px;
            font-weight: bold;

            &:not(:last-child) {
              margin-bottom: 6px;
            }

            .name {
              min-width: 200px;
              text-align: right;
              margin-right: 20px;
            }

            .value {
              font-weight: bold;
            }
          }
        }
      }
    }

    .edit-wrapper {
      padding-top: 42px;
      width: 768px;
      display: flex;
      align-items: center;
      justify-content: center;

      .form-wrapper {
        height: 323px;
        width: 45%;

        form {
          height: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
        }

        .frame {
          border-radius: 15px;
          height: 70%;

          .form-title {
            @include archivo-narrow();
            text-align: left;
            padding-left: 14px;
            margin-bottom: 16px;
          }

          .control {
            width: 304px;
            box-sizing: border-box;

            .default-field {
              input {
                padding: 6px 14px 8px 14px;
                border-radius: 15px;
              }

              label, input {
                @include archivo-narrow();
                font-size: 16px;
              }
            }
          }
        }

        .buttons {
          margin-top: 16px;
          width: 100%;
        }
      }
    }
  }

  .default-field {
    margin-bottom: 15px;
  }

  .ng-select.ng-select-disabled>.ng-select-container {
    background-color: #F9F2E0;
    padding: 6px 14px 8px 14px !important;
    border-radius: 16px;
    text-align: left;
  }

  .ng-value-container {
    font-size: 16px;
    @include archivo-narrow();
    text-align: left;
  }
}
