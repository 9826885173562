@import "mixins";
@import "variables/core/variables";

.mikado {
  @include mikado;
}

.mikado-black {
  @include mikado-black;
}

.text-ellipsis {
  @include text-ellipsis;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-transform-initial {
  text-transform: initial;
}

.text-bold {
  font-weight: bold;
}

.text-normal {
  font-weight: normal;
}

.text-center, .align-center, .ck-text-align-center {
  text-align: center !important;
}

.text-left, .align-left, .ck-text-align-left {
  text-align: left !important;
}

.text-right, .align-right, .ck-text-align-right {
  text-align: right !important;
}

.text-justify, .align-justify, .ck-text-align-justify {
  text-align: justify !important;
}

.text-error {
  color: $color-octonary !important;
}

.bold {
  font-weight: bold;
}

.relative {
  position: relative;
}

.flex {
  display: flex;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-center-height {
  display: flex;
  align-items: center;
}

.direction-column {
  flex-direction: column;
}

.overflow-hidden {
  overflow: hidden;
}

.pointer-events-none {
  pointer-events: none;
}

.display-none {
  display: none;
}

.opacity-0 {
  opacity: 0;
}

.opacity-1 {
  opacity: 1;
}

.hide-window {
  opacity: 0;
}

.pointer {
  cursor: pointer;
}

.height-100 {
  height: 100%;
}
