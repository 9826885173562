.container {
  width: 100%;
  height: 100%;
}

.title {
  margin-bottom: 20px;
  padding-left: 20px;
}

.title, .no-item {
  @include mikado();
  font-weight: bold;
  font-size: 16px;
  letter-spacing: 0.32px;
  line-height: 15px;
}

.table-header {
  width: calc(100% - 20px);
  margin-bottom: 7px;
}

.table-body {
  width: 100%;
  height: 394px;
}

.my-position-link {
  @include mikado;
  text-decoration: underline;
  font-size: 14px;
  font-weight: 900;
  display: flex;
  align-items: center;
  padding-right: 14px;
  margin: 0 0 16px auto;
  position: absolute;
  right: 96px;
  top: 110px;

  a {
    color: #000000;
  }
}

table {
  @include archivo-narrow();
  border-collapse: collapse;
  width: 100%;
  box-sizing: border-box;

  &.custom {
    tbody {
      tr {
        &:nth-child(odd) {
          background-color: #F9F4E4;
        }
      }
    }
  }

  tr {
    height: 38px;
    border-bottom: 1px solid #F4EBCD;
    letter-spacing: 0.28px;
    line-height: 15px;

    &.my-position {
      border: solid 2px #D0413B !important;
    }
  }

  td {
    font-weight: bold;
    font-size: 14px;
  }

  th {
    font-size: 12px;
    font-weight: normal;
  }

  &.league-closed {
    .prize {
      width: 11% !important;
    }

    .info {
      width: 4%;
      text-align: right;
      padding-right: 20px;
      font-size: 20px;
      cursor: pointer;
    }
  }

  .date {
    width: 20%;
    text-align: left;
    padding-left: 20px;
  }

  .name {
    width: 40%;
    text-align: left;
  }

  .value1 {
    width: 13%;
    text-align: right;
    padding-right: 10px;
  }

  .prize {
    width: 24%;
    text-align: right;
    padding-right: 20px;

    .my-prizes {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      .stock-g {
        flex-direction: row-reverse;

        i {
          width: 29px;
          height: 29px;
        }

        .value {
          @include archivo-narrow();
          font-size: 14px;
          margin-right: 4px;
          letter-spacing: 0.28px;
        }

      }

      m-ui-currency, m-ui-product {
        margin: 0 2px;
      }
    }
  }
}

perfect-scrollbar .ps-content {
  padding-right: 44px;
}

.no-item {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #9C9583;
}

m-ui-currency {
  .stock-g {
    flex-direction: row-reverse;

    .value {
      @include archivo-narrow();
      font-size: 14px;
      margin-left: 0;
      margin-right: 4px;
    }
  }
}

.disabled {
  user-select: none;
  opacity: 0.6;
  pointer-events: none;
}

