@import "src/styles/variables/core/variables";
@import "src/styles/mixins";

app-rank-league-details {

  .branch-name {
    @include absolute-center-horizontal;
    @include archivo-narrow();
    font-size: 16px;
    min-width: 250px;
    padding: 0 10px;
    height: 31px;
    border: 3px solid #000;
    border-top: 1px solid #000;
    background: #FFD500;
    bottom: -3px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
  }

  .ow-dialog {
    padding: 50px 100px;
    position: relative;

    .cleared {
      position: absolute;
      bottom: 42px;
      left: 46px;
      height: 40px;
      width: 200px;
      z-index: 2;
      padding-top: 0;
    }
  }

  table {
    @include archivo-narrow;
    border-collapse: collapse;
    font-size: 14px;
    width: 100%;

    &.league-quarter {
      .cleared {
        right: 6px;
      }
    }

    tr:nth-child(even) {
      background-color: #F8F2DE;
    }

    td {
      font-weight: bold;
    }

    tr {
      height: 38px;
    }

    td {
      position: relative;
      text-align: center;
      padding: 0 10px;

      .icon-league {
        width: 28px;
        height: 28px;
        margin: 0 6px 0 10px;
      }

      &:first-child {
        padding-left: 15px;
      }

      &:last-child {
        padding-right: 15px;
      }

      &.td-prize-array {
        height: 37px;
        align-items: center;
        display: flex;
        justify-content: center;
      }

      &.td-position {
        @include mikado;
        font-weight: 900;
        min-width: 32px;
        padding-right: 0;
      }

      &.td-badge {
        min-width: 32px;
        padding: 0;
      }

      &.td-name,
      &.td-region-name,
      &.td-league-name {
        @include text-ellipsis;
      }

      &.td-name {
        padding-left: 20px;
        width: 100%;
        text-align: left;
      }

      &.td-region-name {
        text-transform: capitalize;
      }

      &.td-league-name {
        img {
          vertical-align: bottom;
          margin-right: 2px;
        }
      }

      &.td-value {
        min-width: 82px;
        white-space: nowrap;
      }

      img {
        width: 17px;
        height: 18px;
      }
    }
  }

  .top,
  .left,
  .right {
    display: flex;
    align-items: center;
  }

  .top {
    margin-bottom: 32px;
    justify-content: space-between;
  }

  .content {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    overflow: hidden;
  }

  span {
    &.name {
      @include archivo-narrow;
      font-size: 16px;
      font-weight: 900;
      text-transform: uppercase;
      margin-right: 50px;
      margin-left: 6px;
    }
  }

  .frame {
    padding-right: 24px;
    width: 100%;
    height: 422px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
  }

  .ng-dropdown-wrapper {
    width: 212px;
    height: 36px;
    margin-right: 8px;
    display: flex !important;
    align-items: center !important;

  }

  input {
    padding: 0;
    font-size: 16px;
    width: 210px;
    height: 36px;
    color: #000000;
    border-radius: 15px;
  }

  .default-field {
    @include roboto-cond;
    margin: 0 auto;

    input {
      font-weight: normal;
      padding: 6px 14px;

      &:focus + label {
        background-color: #C6F4FF;
      }
    }
  }

  .stock-g {
    padding: 0 5px;

    .icon {
      margin-right: 2px !important;
    }
  }
}
