@import "src/styles/mixins";

mat-dialog-container {
  & > * {
    .desktop .cdk-overlay-pane:not(.no-scale) & {
      --multiply: 1;
    }

    .tablet .cdk-overlay-pane:not(.no-scale) & {
      --multiply: 1;
    }
  }

  // training track case
  app-building-return ~ app-unlocked-parameters {
    margin-left: 14px;
  }

  .building-upgrade-diff-parameter, .building-upgrade-diff-production, .parameters-container, .building-productions, .building-return-container {
    display: flex;
    gap: 14px;
  }

  .building-unlocked, .building-upgrade-diff-parameter, .building-upgrade-diff-production, .parameters-container, .building-productions, .building-return-container {
    position: relative;
    padding-top: 36px;

    &.min-100 {
      min-width: 100px;
    }

    &.min-65 {
      min-width: 65px;
    }

    .sub-title {
      position: absolute;
      top: 0;
      left: 0;
      @include archivo-narrow;
      font-size: 12px;
    }
  }
}

.cleared {
  width: 142px;
  height: 46px;
  z-index: 2;
  background-image: var(--asset--ui-cleared-png);
  background-repeat: no-repeat;
  background-size: contain;
  display: unset !important;
  padding-top: 0;
}

.ow-dialog {
  &.c-window-long, &.c-window-long-wf {
    .cleared {
      bottom: 22px;
      left: 85px;
      right: unset;
    }
  }
}

.app-notification {
  position: absolute;
  animation: shake-animation linear 3s;
  animation-iteration-count: infinite;
  background: var(--asset--ui-notification_small-png);
  width: 16px;
  height: 32px;
  pointer-events: none;

  &.dot-blue {
    background: var(--asset--ui-notification_dot_blue-png);
    top: 0;
  }

  &.dot-red {
    background: var(--asset--ui-notification_dot_red-png);
    bottom: 0;
  }

  &.dot-blue, &.dot-red {
    width: 20px !important;
    height: 20px !important;
    left: -10px;
  }
}

// WF STICKERS
.card {
  &.card-template-20 {
    box-shadow: 0 0 0 2px #ffbb00;
  }

  .wf-icon {
    width: 30px;
    height: 30px;
    box-sizing: border-box;
    position: relative;
    @include absolute-center-vertical;
    right: -10px;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}


@import "./tabs";
@import "./borders";
