@import "src/styles/mixins";

business-quality-task-info {
  .exit-bar {
    top: 24px !important;
    right: -28px !important;
  }

  .ow-dialog {
    border: 2px solid #000000;
    border-radius: 30px;
    @include archivo-narrow();
    font-size: 12px;
  }

  .frame {
    background-color: $custom-dialog-outline;
    padding: 10px;
    border-radius: 30px;

    .container {
      background-color: #EBE5D5;
      border: 2px solid #000000;
      border-radius: 20px;
      padding: 86px 36px;

      &.padding-top {
        padding-top: 36px;
      }

      .info {
        font-size: 14px;
        padding: 20px 0;
      }
    }
  }

  table {
    margin-top: 10px;
    width: 100%;
    border-spacing: 2px;

    th, td {
      text-align: center;
      white-space: nowrap;
    }

    th {
      font-size: 14px;
      font-weight: normal;
      letter-spacing: 0.28px;
      padding: 5px 0;
    }

    tr.th-main {
      th {
        border: 1px solid rgba(0, 0, 0, 0.3);
        background: #F9F4E4;
        padding: 10px 40px;
      }
    }

    tbody {
      tr {
        &:nth-child(odd) {
          td {
            background: #F9F4E4;
          }
        }

        td {
          font-size: 14px;
          font-weight: bold;
          padding: 10px 35px;
        }
      }
    }
  }
}
