perfect-scrollbar {
  .ps__rail-y {
    width: 12px !important;
    right: 0 !important;

  }

  .ps__rail-y {
    background: #DED8C6 !important;
    border: 2px solid #000000;
    border-radius: 6px !important;
    overflow: hidden;
    box-sizing: border-box;
    opacity: 1 !important;
  }

  .ps__thumb-y {
    width: 12px !important;
    background-color: #80796A !important;
    cursor: pointer;
    border-radius: 8px !important;
    right: -2px !important;
    border-bottom: 2px solid black;
  }

  &.scroll-indicator {
    &:after {
      background: #DED8C6;
      height: 2px;
      width: calc(100% - 27px);
    }
  }
}
