app-custom-qa-list-view {
  .c-window-long {
    padding: 62px 103px;
    box-sizing: border-box;
  }

  .container {
    width: 100%;
    height: 100%;
    box-sizing: border-box;

    table {
      width: 100%;
      border-spacing: 2px;
      border-collapse: collapse;

      tbody {
        tr {
          height: 38px;
          cursor: pointer;

          &:nth-child(odd) {
            background: #F9F4E4;
          }

          td {
            font-size: 14px;
            font-weight: bold;
            @include archivo-narrow();
            white-space: nowrap;

            &.valid {
              padding-right: 15px;
            }

            &.name {
              width: 100%;
              padding-left: 17px;
            }
          }
        }
      }
    }
  }
}
