@import "src/styles/functions";
@import "src/styles/mixins";
@import "src/styles/variables/core/variables";

m-ui-game-date {
  .game-date {
    @include text-stroke;
    @include myriad;
    background: $color-K;
    font-weight: bold;
    color: $color-I;
    padding: multiply-size(2px) multiply-size(3px) 0 multiply-size(3px);
    box-sizing: border-box;
    border-radius: multiply-size(4px);
    font-size: multiply-size(9px);
    width: multiply-size(36px);
    white-space: nowrap;
  }

  @import "custom/style";
}

