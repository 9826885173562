@import "src/styles/mixins";
@import "src/styles/variables/core/variables";

app-antyminer-continue {
  * > div {
    box-sizing: border-box;
  }

  .ow-dialog {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    background-color: #D1A86B;
    border: 2px solid black;
    width: 508px;
    padding: 8px;
    height: 524px;

    .exit-bar {
      right: -32px;
      top: 30px;
    }
  }

  .details {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 2px solid black;
    background-color: #EBE5D5;
    padding: 32px 0;
    width: 100%;
    height: 100%;

    .tracking-picture {
      width: 300px;
      height: 300px;
    }

    h2 {
      @include mikado;
      font-size: 30px;
      line-height: 40px;
      margin: 0 0 55px;
    }
  }
}
