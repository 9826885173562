@import "src/styles/mixins";

.container-bg {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: var(--asset--ui-background_logo-png) center no-repeat;
  background-size: cover;
  z-index: -1;
}

.auth-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 16px;
  color: #000;

  .inner {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
}

.logo-wrapper {
  background: none;
  height: 416px;
  width: 736px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  position: relative;
  margin-right: 87px;
  //filter: drop-shadow(0px 0px 144px #fff) drop-shadow(0px 0px 244px #fff) drop-shadow(0px 0px 356px #fff);

  &:before {
    position: absolute;
    width: 776px;
    height: 776px;
    content: '';
    display: block;
    background: transparent radial-gradient(closest-side at 50% 50%, #FFFFFF 0%, #FFFFFFB3 46%, #FFFFFF00 100%) 0% 0% no-repeat padding-box;
    z-index: -1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0.15;
  }
}

.auth-form-wrapper {
  height: 540px;
  width: 394px;
  background: #EBE5D5;
  border: 2px solid #000000;
  padding: 62px 35px 20px 35px;
  box-sizing: border-box;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    top: -12px;
    left: -12px;
    bottom: -12px;
    right: -12px;
    background: $custom-dialog-outline;
    border: 2px solid #000000;
    z-index: -2;
  }
}

.form-title {
  text-align: center;
  letter-spacing: 0;
  font-weight: 900;
  font-size: 22px;
  @include mikado;
  margin: 0 0 32px 0;
}

.container {
  width: 280px;
  margin: 0 auto;
}

.default-form-link {
  @include archivo-narrow();
  letter-spacing: 0.32px;
  color: #000000;
  font-size: 16px;
  margin-bottom: 16px;
  text-align: center;
  display: block;

  &:last-child {
    margin-bottom: 20px;
  }

  p {
    margin: 5px 0;
  }
}

.buttons {
  margin-top: 48px;
}

button {
  &.full-width {
    width: 100%;
    margin-bottom: 25px;
  }

  &.uppercase {
    text-transform: uppercase;
    letter-spacing: 0.4px;
  }
}

.auth-default-text {
  @include roboto-cond;
  text-align: center;
  padding: 5px 0;

  &.error {
    font-weight: bold;
  }
}

.auth-error-data {
  @include roboto-cond;
  padding: 15px 0;
  text-align: center;

  .item {
    font-weight: bold;
    padding: 5px 0;
    letter-spacing: 0.8px;
  }
}

.auth-contact-wrapper {
  padding: 25px 0;
  text-align: center;
  line-height: 1.6;

  .auth-contact-link {
    @include mikado;
    font-weight: bold;
    text-transform: uppercase;
  }
}

.auth-big-text {
  @include mikado;
  color: #994419;
  font-weight: 900;
  font-size: 36px;
  text-transform: uppercase;
  text-align: center;
}

.auth-medium-text {
  @include mikado;
  color: #994419;
  font-weight: 900;
  font-size: 24px;
  text-transform: uppercase;
  text-align: center;
}

.error {
  color: #E21900;
}

.invalid-password {
  @include roboto-cond;
  text-align: center;
  font-weight: bold;
}

button {
  &.c-base {
    height: 54px !important;
  }
}

.ng-select-container {
  height: 52px !important;
  padding: 12px 16px !important;
  border-radius: 15px !important;
}

input[type=text], input[type=password], input[type=number], input[type=email], .default-field label {
  @include archivo-narrow();
  border-radius: multiply-size(15px);
}
