order {
  @import "../../shop-assets";

  width: 100%;
  @include roboto-cond;

  form {
    margin-top: 40px;
  }

  .shop-container {
    padding-bottom: 130px;
  }

  .alert {
    font-size: 10px;

    &.alert-danger {
      color: red;
    }
  }

  .info {
    font-size: 12px;
    padding-left: 20px;
    margin: 30px 0;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;

    span {
      margin-right: 1px;
    }
  }

  .table-summary-order {
    width: auto;

    tbody {
      tr {
        td {
          &:first-child {
            text-transform: uppercase;
          }

          &:nth-child(2) {
            font-weight: bold;
            text-align: right;
            padding-right: 0;
          }

          .flex-center {
            justify-content: flex-end;

            .icon {
              height: 20px;
              width: 20px;
            }

            img {
              margin-left: 10px;
            }
          }
        }
      }
    }
  }

  .table-order {
    thead {
      @include roboto;
      background-color: #E5F2FF;
    }

    tbody {
      tr {
        border-bottom: 1px solid rgba(0, 63, 84, 0.5);

        td {
          font-size: 16px;
          height: 44px;
          overflow: hidden;
          position: relative;
          text-overflow: ellipsis;

          .alert {
            font-size: 12px;
          }

          input {
            &:not([type='radio']) {
              width: 80%;
              text-transform: none;
              height: 46px;
              border: 2px solid #6AAAB9;
              border-radius: 15px;
              margin: 0 10px 0 0;
              color: #6AAAB9;
              font-size: 16px;
              text-align: left;
              font-weight: normal;
            }
          }

          i.fas {
            color: #5E5E5F;
            font-size: 18px;

            &.fa-check {
              color: #00C136;
            }

            &.fa-close {
              color: #ff3335;
            }
          }

          .input-container {
            -webkit-transform: translateY(-80px);
            -moz-transform: translateY(-80px);
            -ms-transform: translateY(-80px);
            -o-transform: translateY(-80px);
            transform: translateY(-80px);
            position: absolute;
          }

          .hide {
            display: none;
          }

          &:first-child {
            max-width: 170px;
          }

          &:nth-child(2) {
            width: 65%;
          }

          &:last-child {
            text-align: right;
            width: 20%;
            padding-right: 6px;
          }

          @media screen and (max-width: 1300px) {
            min-width: 0 !important;
            height: auto;
            font-size: 14px;
            padding-left: 0;
            padding-right: 5px;

            &:first-child {
              width: 30%;
              padding-left: 20px;
            }

            &:nth-child(2) {
              width: 50%;
            }

            &:last-child {
              padding-right: 15px;
            }

            input {
              height: auto;
              font-size: 12px;
            }

            .input-flex {
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              justify-content: center;
            }
          }
        }

        @media screen and (max-width: 1300px) {
          display: flex;
        }
      }
    }
  }

  .button-container {
    padding: 0 16px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;

    .left {
      display: flex;
    }

    button {
      margin: 5px;

      &.c-base {
        width: 186px !important;
        height: 46px !important;
        font-size: 14px !important;
      }

      &.back {
        @include mplus1c-rounded;
        font-weight: 900;
        display: flex;
        align-items: baseline;
        padding-top: 12px;

        i {
          margin-right: 10px;
          font-size: unset !important;
        }

        span {
          text-decoration: underline;
        }
      }
    }
  }

  .mobile & {
    .button-container {
      button {
        &.order {
          float: none;
        }
      }
    }
  }

  label {
    &.radio {
      margin: 15px 0;
      display: flex;
      align-items: center;

      input {
        width: 20px;
        height: auto;
      }
    }
  }

  .margin {
    margin: 35px 0;
  }
}
