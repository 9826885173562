app-auction-info {
  @import "../../styles/styles";
  @include archivo-narrow;

  .main-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }

  .type-select {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;

    ng-select {
      min-width: 200px;
      cursor: pointer;
    }
  }

  .media-list {
    .media-row {
      height: 80px;
      display: flex;
      box-sizing: border-box;
      padding: 10px;

      &:nth-child(odd) {
        background: #F9F4E4;
      }

      .left {
        margin-right: 16px;

        .icon {
          height: 60px;
          width: 60px;
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
        }
      }

      .right {
        flex: 1 0 0;
        display: flex;

        .heading {
          flex: 1;

          .title {
            font-weight: bold;
            font-size: 16px;
            text-align: left;
            margin-bottom: 3px;
            width: auto;

            a {
              color: #000000;
              text-decoration: none;
              text-underline: none;
              display: inline;
            }
          }

          .description {
            font-size: 14px;
            line-height: 1.4;
          }
        }

        .terms {
          text-align: right;
          margin-left: 40px;
          white-space: nowrap;
          line-height: 1.3;
          font-weight: bold;
          font-size: 14px;
        }
      }
    }
  }

  .ng-dropdown-wrapper {
    width: 260px;
    padding: 4px 8px;
  }

  .pagination {
    display: flex;
    align-items: center;
    justify-content: center;

    button {
      display: inline-block;
      padding: 3px 5px;
      cursor: pointer;
      font-size: 18px;
      margin: 0 20px;
    }

    .value {
      @include archivo-narrow;
      font-weight: 900;
      font-size: 16px;
    }
  }
}
