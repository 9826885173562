.navigation-bar {
  z-index: 1;

  button {
    width: 76px;
    height: 66px;
    background: var(--asset--ui-chevron_big-svg);

    &, &:hover, &:active {
      box-shadow: none;
      padding: 0;
      border-radius: 0;
      border: none;
    }

    &:hover {
      filter: var(--filter-hover);
    }

    i {
      display: none;
    }
  }

  &.next {
    right: -15px;
  }

  &.prev {
    left: -15px;

    button {
      -webkit-transform: scaleX(-1);
      transform: scaleX(-1);
    }
  }
}
