@import "src/styles/functions";

.icon {
  &.square {
    width: multiply-size(40px);
    height: multiply-size(40px);
    background-size: 100%;
    background-repeat: no-repeat;

    img {
      width: multiply-size(40px);
      height: multiply-size(40px);
    }

    &.big {
      width: multiply-size(63px);
      height: multiply-size(63px);
    }
  }

  &.small, &.x-small, &.x20 {
    width: multiply-size(24px);
    height: multiply-size(24px);
    background: no-repeat;
    background-size: cover;
  }

  &.x-small {
    width: multiply-size(18px);
    height: multiply-size(18px);
  }

  &.x20 {
    width: multiply-size(20px);
    height: multiply-size(20px);
  }

  &.x28 {
    width: multiply-size(28px);
    height: multiply-size(28px);
  }

  &.big, &.square {
    width: multiply-size(40px);
    height: multiply-size(40px);
    background: no-repeat;
    background-size: cover;
  }
}

.resource-from-string-icon {
  vertical-align: bottom;

  &.small {
    height: 28px;
    width: 28px;
  }
}

@import "../custom/icons";
