@import "src/styles/functions";
@import "src/styles/mixins";
@import "src/styles/variables/core/variables";

app-player-details {
  font-size: multiply-size(12px);
  color: $color-K;
  @include myriad;
  font-weight: bold;
  height: 100%;
  position: relative;

  .window-a {
    padding: multiply-size(30px) multiply-size(38px) multiply-size(22px) multiply-size(38px);
  }

  app-hud-player {
    margin: multiply-size(3px) multiply-size(3px) 0 multiply-size(3px);
    width: multiply-size(50px);
    height: multiply-size(50px);

    .player-bar-container {
      .avatar,
      .user-options {
        width: multiply-size(32px);
        height: multiply-size(32px);
      }
    }
  }

  #icon-edit,
  #logout,
  #others {
    color: $color-K;
    cursor: pointer;
  }

  #logout,
  #others {
    position: absolute;
    bottom: multiply-size(12px);
    font-size: multiply-size(12px);
    z-index: 1;
    font-weight: bold;
  }

  #logout {
    right: multiply-size(14px);
  }

  #others {
    left: multiply-size(14px);
  }

  .player-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    position: relative;

    .experience {
      margin-top: multiply-size(5px);
      font-size: multiply-size(10px);
    }

    .nick-container {
      margin-top: multiply-size(17px);

      .nick {
        text-transform: uppercase;
      }

      #icon-edit {
        font-size: multiply-size(10px);
        cursor: pointer;
        margin-top: multiply-size(-4px);
        margin-left: multiply-size(10px);
      }
    }

    m-ui-separator {
      margin: multiply-size(13px) 0 multiply-size(15px) 0;
      width: multiply-size(150px);
      border-bottom: none;
    }
  }

  .others-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;

    button {
      margin: multiply-size(5px) 0;
    }
  }

  .bottom-bar {
    button {
      text-transform: initial;
    }
  }

  a {
    text-decoration: none;
  }

  .control {
    margin: multiply-size(8px) 0;
    color: inherit;
    display: flex;
    flex-direction: column;
    font-size: multiply-size(12px);
    font-weight: bold;
    @include archivo-narrow;

    label {
      padding-left: multiply-size(8px);
    }

    &.control-checkbox {
      flex-direction: row;
      align-items: center;
      justify-content: center;

      input {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        margin: 0;
        opacity: 0;

        &:checked + .custom-checkbox-fill {
          display: block;
        }
      }

      .custom-checkbox {
        min-width: multiply-size(22px);
        max-width: multiply-size(22px);
        min-height: multiply-size(22px);
        max-height: multiply-size(22px);
        border: multiply-size(2px) solid #000000;
        color: #000000;
        border-radius: multiply-size(5px);
        position: relative;
        box-sizing: border-box;
        background: #ffffff;

        .custom-checkbox-fill {
          display: none;
          position: absolute;
          @include absolute-center;
          font-size: multiply-size(16px);
          pointer-events: none;
        }
      }
    }
  }

  @import "custom/styles";
}
