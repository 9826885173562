@import "src/styles/functions";
@import "src/styles/variables/core/variables";
@import "../../mixins";
@import "mixins";

.global-dialog {
  position: relative;

  &.hide-dialog {
    opacity: 0;
  }
}

//  OW-DIALOG
//  ****
.ow-dialog {
  position: relative;
  box-sizing: border-box;

  //  OW-DIALOG BASE
  @import "layout/window-a";

  //  MISSION DIALOG BOX
  @import "layout/window-b";

  //  BUILDING-LIST DIALOG BOX
  @import "layout/window-c";

  //  ALERT DIALOG
  @import "layout/window-d";

  // CUSTOM DIALOG CORE
  @import "layout/window-custom";

  // CUSTOM DIALOG
  @import "../custom/layout/dialog-layouts";

  //  PRIMARY COLOR
  @import "colors/dialog-primary";

  //  SECONDARY COLOR
  @import "colors/dialog-secondary";

  // CUSTOM COLORS
  @import "../custom/colors/dialog-colors";

  //  NAVIGATION BAR
  //  ****
  .navigation-bar {
    @include absolute-center-vertical;
    z-index: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;

    // Button previous
    &.prev {
      left: multiply-size(-22px);
      position: absolute;
    }

    // Button next
    &.next {
      right: multiply-size(-22px);
      position: absolute;
    }
  }

  //  TOP BAR
  //  ****
  .top-bar {
    @include absolute-center-horizontal;
    width: 100%;
    position: absolute;
    top: multiply-size(-20px);
    color: $color-I;
    z-index: 0;
    box-sizing: border-box;
    display: flex;
    justify-content: center;

    .title {
      @include myriad;
      @include text-stroke;
      font-size: multiply-size(14px);
      text-transform: uppercase;
      font-weight: bold;
      text-align: center;
    }

    .absolute-left, .absolute-right {
      position: absolute;
      display: flex;
      align-items: baseline;
      justify-content: center;
      font-size: multiply-size(14px);
      @include myriad;
      @include text-stroke;
      font-weight: bold;

      i {
        margin-right: multiply-size(5px);
      }
    }

    .absolute-left {
      left: multiply-size(12px);
    }

    .absolute-right {
      right: multiply-size(12px);
    }
  }

  //  EXIT BAR
  //  ****
  .exit-bar {
    position: absolute;
    top: multiply-size(3px);
    right: 0;
    width: multiply-size(40px);
    height: multiply-size(31px);
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;

    .exit {
      border: none;
    }
  }

  //  BACK BAR
  //  ****
  .back-bar {
    position: absolute;
    left: multiply-size(-22px);
    padding: multiply-size(7px);
    box-sizing: border-box;
    top: multiply-size(15px);
  }

  //  BOTTOM BAR
  //  ****
  .bottom-bar {
    box-sizing: border-box;
    width: 100%;
    position: absolute;
    height: multiply-size(55px);
    bottom: multiply-size(-28px);
    display: flex;
    justify-content: center;
    align-items: center;
    left: 0;
    padding: 0 multiply-size(10px);

    .bar-button {
      position: relative;
      box-sizing: border-box;
      height: 100%;
      display: flex;
      align-items: center;
      margin: 0 multiply-size(3px);
    }

    .pagination {
      display: flex;
      justify-content: space-between;
      align-items: center;
      box-sizing: border-box;
      height: 100%;
      width: 100%;

      .buttons {
        display: flex;

        button {
          &:not(:last-child) {
            margin-right: multiply-size(7px);
          }
        }
      }

      .pages {
        @include myriad;
        font-size: multiply-size(10px);
        font-weight: bold;
        color: $color-K;
        margin: 0 multiply-size(18px);
      }
    }
  }

  .inner-html-description {
    p:first-child {
      margin-top: 0;
    }

    a, a:active, a:hover {
      color: inherit;
    }
  }

  //  MENU
  //  ****
  .menu-right,
  .menu-left {
    position: absolute;
    top: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 2;

    button {
      &:not(:last-child) {
        margin-bottom: multiply-size(6px);
      }
    }
  }

  .menu-right {
    right: multiply-size(-40px);
  }

  .menu-left {
    left: multiply-size(-40px);
  }
}

.is-loading {
  .loading-opacity {
    opacity: 0.5;
    filter: blur(multiply-size(1px));
  }
}

// ****
// OVERWRITE MATERIAL DIALOG STYLE
// ****
.cdk-global-overlay-wrapper {
  .cdk-overlay-pane {
    max-width: 100% !important
  }
}

.cdk-overlay-container {
  .cdk-overlay-backdrop {
    transition: initial !important;
    background: rgba(0, 51, 102, 0.6) !important;

    &.hide-background {
      opacity: 0 !important;
      pointer-events: none;
    }
  }
}

mat-dialog-container {
  padding: 0 !important;
  background-color: transparent !important;
  max-width: 100% !important;
  max-height: 100% !important;
  box-shadow: none !important;
  overflow: visible !important;
}

.mat-mdc-dialog-surface {
  box-shadow: none !important;
  overflow: visible !important;
}

.mdc-dialog__surface {
  overflow: visible !important;
  background: transparent !important;
}
@import "../custom/base";
