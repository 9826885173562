@import "src/styles/functions";

app-gallery-header {
  @import "../../styles/style";

  .gallery-header {
    background: rgba(0, 163, 204, 1) var(--asset--ui-background-photo-gallery-png);
    background-blend-mode: soft-light;
    background-size: cover;
    background-position: center;
    width: 100%;
    height: multiply-size(524px);

    .gallery-container {
      text-align: center;

      .logo {
        margin-top: multiply-size(65px);
        background: var(--asset--ui-logo-png) center no-repeat;
        background-size: contain;
        width: multiply-size(190px);
        height: multiply-size(210px);
        display: inline-block;
      }
    }

    .exit-button,
    .back-button {
      color: #ffffff;
      position: absolute;
      top: multiply-size(24px);

      &.exit-button {
        font-size: multiply-size(24px);
        right: multiply-size(24px);

        @media only screen and (min-width: $media-md) {
          right: multiply-size(40px);
        }

        @media only screen and (min-width: $media-lg) {
          right: multiply-size(42px);
        }
      }

      &.back-button {
        font-size: multiply-size(28px);
        left: multiply-size(24px);

        @media only screen and (min-width: $media-md) {
          left: multiply-size(40px);
        }

        @media only screen and (min-width: $media-lg) {
          left: multiply-size(42px);
        }
      }
    }
  }
}
