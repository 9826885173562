app-game-debug {
  display: block;
  position: fixed;
  right: 0;
  top: 0;
  max-width: 500px;
  width: 500px;
  height: 100%;
  background: #fffa;
  z-index: 5;
  padding: 15px;
  transform: translateX(100%);
  transition: transform cubic-bezier(0.25, 0.46, 0, 1) 300ms;
  pointer-events: none;
  font-family: "Archivo Narrow";

  &.isOpen {
    transform: translateX(0);

    .debug-container {
      overflow: auto;
    }
  }

  .debug-item {
    pointer-events: all;
    background: #fff;
    padding: 5px;
    margin-bottom: 10px;
    box-shadow: 0 0 3px #aaa;
    border-radius: 5px;

    .debug-item-header {
      border-bottom: solid 1px #ccc;
      padding: 5px;
      font-size: 16px;
      font-weight: bold;
    }

    .debug-item-content {
      display: none;
      padding: 5px;
      font-size: 12px;

      .info {
        display: flex;
        justify-content: space-between;
        align-items: center;

        & > p:last-child {
          font-weight: bold;
        }
      }

      .action-element {
        display: flex;
        flex-direction: column;

        label {
          width: 100%;
          justify-content: flex-start;

          &.green {
            color: #0f8274;
          }

          &.red {
            color: #4C0008;
          }
        }

        & > div {
          display: flex;

          select {
            width: 30%;
          }

          input {
            width: 75%;

            /* Chrome, Safari, Edge, Opera */
            &::-webkit-outer-spin-button,
            &::-webkit-inner-spin-button {
              -webkit-appearance: none;
              margin: 0;
            }

            /* Firefox */
            &[type=number] {
              -moz-appearance: textfield;
              width: 13%;
            }
          }

          button {
            margin-left: auto;
            width: 10%;
          }
        }
      }

      label {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;

        input {
          margin-left: 5px;
          margin-right: 5px;

          &.short {
            width: 70px;
          }
        }
      }

      .other {
      }
    }

    &.expanded {
      .debug-item-content {
        display: block;
      }
    }
  }

  .debug-container {
    position: relative;
    height: 100%;

    &,
    * {
      pointer-events: all;
    }
  }

  button {
    pointer-events: all;
    background: #ffffff !important;
    border: 1px solid black;
    color: #000;

    &.button-toggle {
      position: absolute;
      top: 50vh;
      font-size: 20px;
      border: none;
      padding: 10px;
      left: -38px;
      border-radius: 3px 0 0 3px;
      color: #000;
      box-shadow: 0 0 3px #aaa;
    }
  }

  .ml {
    margin-left: 10px;
  }
}
