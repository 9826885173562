@import "src/styles/mixins";
@import "src/styles/variables/core/variables";

app-card-tasks {
  .branch-name {
    @include absolute-center-horizontal;
    @include archivo-narrow();
    font-size: 16px;
    min-width: 250px;
    padding: 0 10px;
    height: 31px;
    border: 3px solid #000;
    border-top: 1px solid #000;
    background: #FFD500;
    bottom: -3px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
  }

  perfect-scrollbar {
    max-width: 100% !important;

    .ps-content {
      padding-right: 22px;
    }
  }

  m-ui-currency,
  m-ui-product {
    margin-right: 10px;
  }

  .stock-h {
    flex-direction: row-reverse !important;

    .value {
      @include archivo-narrow();
      font-size: 14px !important;
      text-shadow: none !important;
      color: #000000;
      margin-left: 0 !important;
    }

    .icon {
      width: 20px !important;
      height: 20px !important;
      margin-right: 0 !important;
      margin-left: 2px;
    }
  }

  .c-window-long {
    @include archivo-narrow();
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 105px 72px 52px 72px;
    flex-direction: column;

    .content {
      height: 400px;
      width: 100%;
    }

    .container-card {
      width: 393px;
      height: 84px;
      padding-right: 2px;
      padding-top: 10px;
      box-sizing: border-box;
      position: relative;
      margin-bottom: 12px;
      float: left;

      .animal-icon {
        position: absolute;
        display: none;
        width: 30px;
        height: 30px;
        background-position: center;
        top: 0;
        right: -10px;
        z-index: 1000;
      }

      &:not(:nth-child(3n)) {
        margin-right: 10px;
      }

      .card {
        width: 391px;
        height: 80px;
        box-sizing: border-box;
        font-size: 14px;
        letter-spacing: 0;
        font-weight: bold;
        padding: 6px 29px 6px 13px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        position: relative;
        background-color: #F9F4E4;
        border-radius: 4px;
        border: 2px solid #878273;
        cursor: pointer;

        .card-prizes {
          display: flex;
          align-items: center;
          justify-content: flex-start;
        }

        .tab {
          width: 12px;
          height: 100%;
          position: absolute;
          top: 0;
          right: 0;
          border-radius: 0 2px 2px 0;

          &.red {
            background-color: #F12424;
          }

          &.green {
            background-color: #01E436;
          }
        }
      }
    }

    .navigation {
      display: flex;
      justify-content: center;
      align-items: flex-start !important;
      width: 100%;
      margin-top: 12px;

      .nav-arrow {
        text-shadow: none !important;
        color: #000000;
        font-size: 20px;
      }

      .value {
        font-size: 16px;
        font-weight: bold;
        margin: 0 20px;
        cursor: default;
      }
    }
  }

  .progressbar-container {
    width: 100%;
    display: flex;
    align-items: center;

    .progressbar {
      height: 14px;
      width: 320px;
      border-radius: 6px;
      border: 2px solid #878273;
      background-color: #DED8C6;
      padding: 0 !important;

      .fill {
        border-radius: 2px 0 0 2px;
        border-right: 2px solid #878273;

        &.red {
          background-color: #F12424;
        }

        &.green {
          background-color: #01E436;
        }
      }
    }

    .progressbar-value {
      @include archivo-narrow();
      font-size: 14px;
      margin: 0 6px;
    }
  }

  .no-item {
    @include archivo-narrow();
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: bold;
    margin-top: 30px;
  }
}
