app-contract-sale {
  @import "../styles/styles";

  .branch-name {
    @include absolute-center-horizontal;
    @include archivo-narrow();
    font-size: 16px;
    min-width: 250px;
    padding: 0 10px;
    height: 31px;
    border: 3px solid #000;
    border-top: 1px solid #000;
    background: #FFD500;
    bottom: -3px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
  }

  .ow-dialog {
    padding: 56px 88px 84px 105px;
    display: flex;
    flex-direction: column;
  }

  .body {
    height: 484px;
  }

  .contract-list-wrapper {
    @include archivo-narrow();

    .header {
      display: flex;
      width: 100%;
      border-bottom: 1px solid #CEC7B4;
      padding-bottom: 6px;

      .contract-label {
        font-size: 12px;
        line-height: 15px;
        font-weight: 400;

        &.date {
          margin-left: 76px;
          width: 155px;
        }

        &.contract {
          width: 603px;
        }

        &.expected-value {
          width: 140px;
        }
      }
    }

    .contract-wrapper {
      display: flex;
      flex-direction: column;

      .contract-content-wrapper {
        display: flex;

        &.is-met {
          .date,
          .name .name-wrapper,
          .name .condition-wrapper,
          .expected-value,
          .realization {
            opacity: 0.3;
            font-style: italic;
          }
        }

        &.package {
          &.is-met {
            &:not(.is-chosen) {
              .info-wrapper {
                opacity: 0.3;
                font-style: italic;
              }
              .trophy, .clock {
                opacity: 0.3;
              }
            }
          }
        }
      }

      .contract-item {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 22px;
        justify-content: flex-start;
        font-size: 14px;
        line-height: 15px;
        font-weight: 700;
        padding: 12px 0;

        &:nth-child(odd) {
          background: #F9F4E4;
        }

        &.item-centered {
          .contract-content-wrapper {
            align-items: center;
          }
        }

        &.package-border {
          border-top: 1px solid #CEC7B4;
        }

        .contract-package-description {
          span {
            margin: 0 8px 0 10px;
          }
          i {
            cursor: pointer;
          }
        }

        i {
          font-size: 20px;

          &.fa-check-square.green {
            color: $green;
          }

          &.fa-minus-square.red {
            color: $red;
          }
        }

        .squares {
          position: relative;
          width: 20px;
          height: 20px;
          box-sizing: border-box;

          i {
            position: absolute;

            &.contour {
              top: 0;
              left: 0;
              z-index: 1;
            }

            &.color {
              top: 2px;
              left: 2px;
              font-size: 16px;
            }
          }
        }

        .info-wrapper {
          display: flex;
          margin: -3px 0 0 10px;
          width: 66px;
          gap: 6px;

          .info {
            cursor: pointer;
          }
        }

        .date {
          width: 154px;
        }

        .name {
          width: 606px;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
          gap: 20px;

          .condition-wrapper {
            display: flex;
            gap: 10px;

            .condition {
              width: 8px;
              height: 8px;
              border: 2px solid #000;
              border-radius: 50%;
              background: $red;

              &.done {
                background: $green;
              }
            }
          }

          .accept-rules {
            display: flex;
            align-items: center;
            font-size: 14px;
            cursor: pointer;
            font-weight: 400;

            .box {
              box-sizing: border-box;
              display: flex;
              align-items: center;
              justify-content: center;
              min-width: 20px;
              height: 20px;
              border: solid 2px #000000;
              border-radius: 4px;
              font-size: 14px;
              margin-right: 9px;
              position: relative;
            }

            &.cursor-default {
              cursor: default;
            }

            &.box-active {

              .box {
                &:after {
                  content: '';
                  background-image: var(--asset--ui-check_small-png);
                  width: 26px;
                  height: 26px;
                  position: absolute;
                }
              }
            }
          }
        }

        .expected-value {
          width: 91px;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-end;
          gap: 20px;
          text-align: right;
        }

        .realization {
          width: 97px;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-end;
          gap: 20px;
          text-align: right;
        }

        .prize-wrapper {
          margin: -6px 0 -6px 20px;
          display: flex;
          gap: 6px;

          .trophy, .chest, .clock {
            cursor: pointer;

            img {
              width: 30px;
              height: 30px;
              object-fit: contain;
            }

            &.inactive {
              opacity: 0.3;
            }
          }

          .chest {
            background: #FFD500;
            width: 34px;
            display: flex;
            height: 42px;
            margin-top: -6px;
            justify-content: center;
            align-items: center;
          }


        }
      }
    }
  }

  ow-perfect-scrollbar .ps-content {
    padding-right: 24px;
  }
}
