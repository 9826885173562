&.c-base {
  min-width: 187px;
  width: auto;
  height: 40px;
  @include mikado;
  font-weight: 900;
  font-size: 18px;
  padding: 0 10px;
  border: 2px solid;
  position: relative;
  text-transform: initial;
  padding: 0 10px;
}

