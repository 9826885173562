@import "src/styles/mixins";

app-hud-player-preview {
  .preview-container {
    background: #0E1107B3;
    border: 2px solid #000000;
    border-radius: 25px;
    height: 50px;
    box-sizing: border-box;
    padding: 0 30px 0 20px;
    @include mikado;
    letter-spacing: 0.4px;
    color: #fff;
    font-size: 14px;
    line-height: 1.4;
    display: flex;
    flex-flow: column;
    justify-content: center;
    width: 450px;
    margin-right: 20px;
    position: relative;

    .player-name {
      font-weight: bold;
    }

    button {
      position: absolute;
      right: -10px;
    }
  }
}
