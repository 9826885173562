@import "src/styles/mixins";

app-branches {
  .ow-dialog {
    padding: 62px 84px 45px 82px;
    display: flex;
    flex-flow: column;

    perfect-scrollbar {
      .ps-content {
        padding-right: 42px !important;
      }
    }

    .frame {
      padding: 0 12px 0 6px;
      flex: 1;
      overflow: hidden;

      .branches-list {

        .item {
          @include archivo-narrow;
          font-size: 14px;
          display: flex;
          height: 38px;
          align-items: center;
          padding-left: 15px;
          cursor: pointer;

          &:nth-child(odd) {
            background: #F9F4E4;
          }

          .city {
            font-weight: bold;
            margin-right: 10px;
          }
        }
      }
    }

    .navigate-pages {
      @include mikado;
      display: flex;
      align-items: center;
      position: absolute;
      text-transform: uppercase;
      font-weight: 900;
      font-size: 14px;
      top: 20px;
      right: 96px;

      button {
        border: none !important;
      }

      i {
        font-size: 22px;
      }

      .pages {
        text-transform: lowercase;
      }
    }
  }
}


