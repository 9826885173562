m-ui-navigation-center {
  .transparent {
    opacity: 0;

    button {
      pointer-events: none;
    }
  }

  @import "custom/style";
}
