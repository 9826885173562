@import "src/styles/functions";
@import "src/styles/variables/core/variables";
@import "src/styles/mixins";

app-core-alert-confirm {
  .window-d {
    position: relative;
    // MAX SIZE DEFAULT IN ALERT SERVICE
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    p {
      &.title {
        margin: 0 0 multiply-size(6px) 0;
        font-size: multiply-size(12px);
        @include myriad;
        font-weight: bold;
        @include text-stroke;
        color: $color-denary;
        text-align: center;
        text-transform: uppercase;
      }

      &.description {
        @include archivo-narrow;
        margin: 0;
        text-align: center;
        font-size: multiply-size(12px);
        padding: 0 multiply-size(20px) multiply-size(10px) multiply-size(20px);

        & + m-ui-separator {
          margin-top: multiply-size(-10px);
        }
      }
    }
  }

  m-ui-separator {
    width: 100%;
  }

  m-ui-costs {
    margin: 0 auto multiply-size(5px) auto;
  }
}
