.card-tasks-cc {
  perfect-scrollbar {
    max-width: 100% !important;

    .ps-content {
      padding-right: 22px;
    }
  }

  m-ui-currency,
  m-ui-product {
    margin-right: 10px;
  }

  .stock-h {
    flex-direction: row-reverse !important;

    .value {
      @include archivo-narrow();
      font-size: 14px !important;
      text-shadow: none !important;
      color: #000000;
      margin-left: 0 !important;
    }

    .icon {
      width: 20px !important;
      height: 20px !important;
      margin-right: 0 !important;
      margin-left: 2px;
    }
  }

  .content {
    height: 400px;
    width: 100%;
  }

  .container-card {
    width: 393px;
    height: 84px;
    padding-top: 10px;
    padding-right: 2px;
    box-sizing: border-box;
    position: relative;
    margin-bottom: 12px;
    float: left;

    .animal-icon {
      position: absolute;
      display: none;
      width: 30px;
      height: 30px;
      background-position: center;
      top: 0;
      right: -10px;
      z-index: 1000;
    }

    &:not(:nth-child(3n)) {
      margin-right: 10px;
    }

    .card {
      width: 391px;
      height: 80px;
      box-sizing: border-box;
      font-size: 14px;
      letter-spacing: 0;
      font-weight: bold;
      padding: 6px 29px 6px 13px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
      position: relative;
      background-color: #F9F4E4;
      border-radius: 4px;
      border: 2px solid #878273;
      cursor: pointer;

      .cc-chi {
        position: absolute;
        right: -8px;
        top: -8px;
        z-index: 1;
      }

      &.is-choosable {
        opacity: 0.6 !important;
      }

      &.is-choosable-active {
        background-color: #D8D3C2;

        .is-choosable-icon {
          display: flex;
        }
      }

      .is-choosable-icon {
        display: none;
        font-size: 14px;
        margin-left: 5px;
      }

      .card-name {
        display: flex;
        align-items: center;
      }

      .card-prizes {
        display: flex;
        align-items: center;
        justify-content: flex-start;
      }

      .tab {
        width: 12px;
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;
        border-radius: 0 2px 2px 0;

        &.red {
          background-color: #F12424;
        }

        &.green {
          background-color: #01E436;
        }
      }
    }
  }

  .progressbar-container {
    width: 100%;
    display: flex;
    align-items: center;

    .progressbar {
      height: 14px;
      width: 320px;
      border-radius: 6px;
      border: 2px solid #878273;
      background-color: #DED8C6;
      padding: 0 !important;

      .fill {
        border-radius: 2px 0 0 2px;
        border-right: 2px solid #878273;

        &.red {
          background-color: #F12424;
        }

        &.green {
          background-color: #01E436;
        }
      }
    }

    .progressbar-value {
      @include archivo-narrow();
      font-size: 14px;
      margin: 0 6px;
      white-space: nowrap;
    }
  }
}
