app-to-iso {
  .screentoiso {
    background: #fff;
    border-radius: 5px;
  }

  .screentoiso.open {
    bottom: 200px;
  }

  p {
    margin: 5px;
  }

  .container {
    position: relative;
  }

  button {
    position: absolute;
    right: 0;
    top: -120px;
  }
}
