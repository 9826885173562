@import "src/styles/variables/core/variables";
@import "src/styles/mixins";

app-message-event {
  .c-window-short {
    padding: 50px 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .title {
    @include mikado();
    font-size: 20px;
    font-weight: bold;
  }

  .description {
    @include archivo-narrow();
    font-size: 20px;
    text-align: center;
    max-width: 600px;
    margin: 13px 0 40px 0;
  }

  .icon-window-right {
    @include absolute-center-vertical();
    right: -116px;
    width: 280px;
    height: 500px;
  }

  button.c-base {
    text-transform: capitalize;
  }
}
