@import "src/styles/variables/core/variables";
@import "src/styles/mixins";

// no-multiply-size-file

app-mock-store-data {
  z-index: 99999;

  .window-d {
    max-width: 840px;
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    // MAX SIZE DEFAULT IN ALERT SERVICE
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: $color-K;

    .json-editor {
      display: flex;
      width: 100%;
      height: 400px;

      textarea {
        flex: 1;
        resize: none;
      }
    }
  }

  p {
    &.title {
      margin: 0 0 6px 0;
      font-size: 24px;
      @include myriad;
      @include text-stroke;
      font-weight: 900;
      text-align: center;
      text-transform: uppercase;
    }
  }

  .buttons {
    margin-top: 20px;
    display: flex;
    justify-content: center;

    button {
      margin: 0 10px;
    }
  }

  &::ng-deep {
    .exit-bar {
      top: 14px !important;
      right: 8px !important;
    }

    .base {
      width: unset;
      min-width: 204px;
      padding-left: 16px;
      padding-right: 16px;
    }
  }

  m-ui-separator {
    width: 100%;
  }

  m-ui-costs {
    margin: 0 auto 5px auto;
  }
}
