@import "src/styles/functions";

app-add-photo {
  @import "../../styles/style";

  .image-dialog {
    box-sizing: border-box;
    background: #ffffff;
    position: relative;
    width: 100%;
    height: 100%;
    padding: multiply-size(20px);

    @media only screen and (min-width: $media-md) {
      height: 95vh;
      width: multiply-size(720px);
      padding: multiply-size(40px);
      padding-right: multiply-size(32px);
      border-radius: multiply-size(15px);
    }

    @media only screen and (min-width: $media-lg) {
      width: multiply-size(647px);
    }

    @media only screen and (min-width: $media-xxlg) {
      width: multiply-size(791px);
    }

    .exit-button {
      width: multiply-size(46px);
      height: multiply-size(46px);
      position: absolute;
      top: 0;
      right: 0;
      font-size: multiply-size(18px);
      color: $color-dark;
      z-index: 1;
    }

    .upload-photo-input {
      display: none;
    }

    form {
      display: flex;
      flex-direction: column;
      height: 100%;

      textarea,
      input {
        @include roboto-cond;
        width: 100%;
        box-sizing: border-box;
        border: none;
        border-bottom: multiply-size(1px) solid $color-light;
        text-align: left;
        color: $color-dark;
        padding: multiply-size(8px) 0;
        height: auto;

        &::placeholder {
          color: $color-light;
        }
      }

      input {
        font-size: multiply-size(22px);
        margin: multiply-size(30px) 0;
      }

      ow-perfect-scrollbar {
        margin-bottom: multiply-size(30px);
      }

      textarea {
        font-size: multiply-size(16px);
        margin: 0;
        height: 100%;
      }
    }

    .textarea-container {
      box-sizing: border-box;
      width: 100%;
      max-height: multiply-size(500px);
    }

    .gallery-normal {
      margin: 0 auto;
    }
  }
}
