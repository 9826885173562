@import "src/styles/mixins";

app-antyminer-start-requirements {
  @include mikado;

  m-ui-costs {
    display: block;
  }

  m-ui-separator {
    .separator {
      margin: 0;
    }
  }

  & > .flex-center {
    margin-top: 20px;
  }

  .margin-20 {
    margin-bottom: 20px;
  }

  .req-building {
    cursor: pointer;
    position: relative;

    .icon-lack {
      width: 16px;
      height: 32px;
      background: var(--asset--ui-notification_small-png);
      background-size: contain;
      background-repeat: no-repeat;
      position: absolute;
      bottom: 8px;
      right: 3px;

      &:before {
        content: '' !important;
      }
    }
  }

  .hint-title {
    text-align: center;
    font-size: 18px;
    margin-bottom: 0;
  }

  .hint-description {
    font-weight: normal;
    text-align: center;
  }
}
