@import "src/styles/mixins";
@import "src/styles/variables/core/variables";

app-building-upgrade-diff-parameter, .other-container-diff {
  .upgrade-container {
    display: flex;

    .upgrade-label {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .value {
        padding-top: 4px;
      }

      span {
        @include mikado;
        font-size: 14px;
        color: #000000;
        font-weight: bold;

        &.quantity-diff {
          color: #18AE29;
        }

        &.quantity-diff-minus {
          color: $color-octonary;
        }
      }
    }

    .upgrade-value-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .upgrade-arrow {
        width: 18px;
        height: 16px;
        background: var(--asset--ui-arrow_small-png);
        transform: rotate(90deg);
      }
    }
  }
}
