@import "src/styles/mixins";
@import "src/styles/variables/core/variables";

a {
  color: #000000 !important;
  text-decoration: none !important;

  &:hover, &:visited {
    color: #000000 !important;
    text-decoration: none !important;
  }

  &:hover {
    text-decoration: underline !important;
  }
}

button {
  display: inline-flex;

  // *****
  // BASE
  // *****
  &.shop-base {
    width: 160px;
    height: 36px;
    text-transform: uppercase;
    @include mplus1c-rounded;
    font-size: 14px;
    padding-bottom: 1px;

    &:disabled {
      opacity: 1 !important;
      color: $color-button-base-primary-d !important;
    }

    &.back {
      &:before {
        content: "\f104";
        @include font-awesome;
        font-size: 18px;
        font-weight: 900;
        @include absolute-center-vertical;
        left: 30px;
      }
    }

    // -----
    // PRIMARY
    // -----
    &.primary {
      &:hover {
        color: $color-button-base-primary-h;
      }

      &:active {
        color: $color-button-base-primary;
        padding-top: 2px;
      }

      &, &:disabled {
        color: $color-button-base-primary;
      }

    }

    // -----
    // SECONDARY
    // -----
    &.secondary {
      &:hover {
        color: $color-button-base-secondary-h;
      }

      &:active {
        color: $color-button-base-secondary;
        padding-top: 2px;
      }

      &, &:disabled {
        color: $color-button-base-secondary;
      }


      //
      // DISCOUNT
      //
      &.discount {
        height: 39px;
        padding-right: 20px;

        &:hover {
        }

        &:active {
          padding-top: 0px;
        }

        &.disabled {
        }

      }
    }

    // -----
    // TERTIARY
    // -----
    &.tertiary {
      &:hover {
        color: $color-button-base-tertiary-h;
      }

      &:active {
        color: $color-button-base-tertiary;
        padding-top: 2px;
      }

      &, &:disabled {
        color: $color-button-base-tertiary;
      }

    }
  }
}

select {
  background: #ffffff;
  border: 2px solid #2b0c0a;
  width: 216px;
  height: 24px;
  color: #3C3430;
  text-transform: uppercase;
  padding: 2px 5px;
  font-size: 12px;
  margin-left: 10px;
  @include archivo-narrow;
}

input {
  border: 2px solid #4E4E4E;
  text-transform: uppercase;
  padding: 4px 9px;
  border-radius: 0;
  width: 150px;
  font-size: 12px;
  height: 30px;
  font-weight: bold;
  box-sizing: border-box;
  background: #ffffff;
}

table {
  border-collapse: collapse;
  width: 100%;
  margin: 8px 0;

  thead {
    tr {
      th {
        font-size: 16px;
        padding: 20px;
        text-transform: uppercase;
        font-weight: bold;
        text-align: left;
        letter-spacing: 0;
      }
    }
  }

  tbody {
    tr {
      td {
        padding: 5px 20px;

        .flex-center {
          .icon {
            margin-right: 0;
            margin-left: 10px;
          }
        }
      }
    }
  }
}

.shop-container {
  max-width: 1300px;
  margin: 0 auto;
  color: #000000;
}

.logo-market {
  width: 190px;
  height: 127px;
  background-repeat: no-repeat;
  background-size: contain;
}

ow-perfect-scrollbar {
  max-height: 100%;
}

.button-center-bottom {
  width: 100%;
  display: flex;
  justify-content: center;
}

.cart-length {
  display: inline-block;

  &.animation-change-cart {
    animation: changeCartAnimation linear 1s;
    animation-iteration-count: 1;
    transform-origin: 50% 50%;

    @keyframes changeCartAnimation {
      0% {
        transform: rotate(0deg) scaleX(1.00) scaleY(1.00);
      }
      10% {
        transform: rotate(-3deg) scaleX(0.80) scaleY(0.80);
      }
      20% {
        transform: rotate(-3deg) scaleX(0.80) scaleY(0.80);
      }
      30% {
        transform: rotate(3deg) scaleX(1.40) scaleY(1.40);
      }
      40% {
        transform: rotate(-3deg) scaleX(1.40) scaleY(1.40);
      }
      50% {
        transform: rotate(3deg) scaleX(1.40) scaleY(1.40);
      }
      60% {
        transform: rotate(-3deg) scaleX(1.40) scaleY(1.40);
      }
      70% {
        transform: rotate(3deg) scaleX(1.40) scaleY(1.40);
      }
      80% {
        transform: rotate(-3deg) scaleX(1.40) scaleY(1.40);
      }
      90% {
        transform: rotate(3deg) scaleX(1.40) scaleY(1.40);
      }
      100% {
        transform: rotate(0deg) scaleX(1.40) scaleY(1.40);
      }
    }
  }
}

button {
  &.shop {
    box-sizing: border-box;

    &.square {
      min-width: 39px;
      width: 39px;
      min-height: 39px;
      height: 39px;
      padding-top: 3px;
      display: inline;

      //&.green {
      //    background-image: url($img-shop-button-green-small);
      //    &:hover {background-image: url($img-shop-button-green-small-h)}
      //    &:active {background-image: url($img-shop-button-green-small-h)}
      //    @include preload-image($img-shop-button-green-small-h);
      //}
    }
  }
}

.product-ribbon {
  background-repeat: no-repeat;
  text-align: center;

  &.label {
    width: 111px;
    height: 33px;
    //background-image: url($img-shop-produkt-ribbon);
  }

  &.label-long {
    width: 120px;
    height: 30px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;

    &.label1 {
      background: #2f4df8;
    }

    &.label2 {
      background: #66b5ac;
    }

    &.label3 {
      background: #262727;
    }

    span {
      width: 90%;
    }
  }

  &.sparkle {
    width: 36px;
    height: 30px;
  }

  .label-name {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 12px;
    color: #ffffff;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
}

// SHOP GRID
/*  SECTIONS  */
.section {
  clear: both;
  padding: 0;
  margin: 0;
}

/*  COLUMN SETUP  */
.col {
  display: block;
  float: left;
  margin: 1% 0 1% 0.45%;
}

.col:first-child {
  margin-left: 0;
}

/*  GROUPING  */
.group:before,
.group:after {
  content: "";
  display: table;
}

.group:after {
  clear: both;
}

.group {
  zoom: 1; /* For IE 6/7 */
}

/*  GRID OF TWELVE  */
.span_12_of_12 {
  width: 100%;
}

.span_11_of_12 {
  width: 91.54%;
}

.span_10_of_12 {
  width: 83.08%;
}

.span_9_of_12 {
  width: 74.62%;
}

.span_8_of_12 {
  width: 66.16%;
}

.span_7_of_12 {
  width: 57.70%;
}

.span_6_of_12 {
  width: 49.25%;
}

.span_5_of_12 {
  width: 40.79%;
}

.span_4_of_12 {
  width: 32.33%;
}

.span_3_of_12 {
  width: 23.87%;
}

.span_2_of_12 {
  width: 15.41%;
}

.span_1_of_12 {
  width: 6.958%;
}

/*  GO FULL WIDTH BELOW 480 PIXELS */
@media only screen and (max-width: 480px) {
  .col {
    margin: 1% 0 1% 0%;
  }

  .span_1_of_12, .span_2_of_12, .span_3_of_12, .span_4_of_12, .span_5_of_12, .span_6_of_12, .span_7_of_12, .span_8_of_12, .span_9_of_12, .span_10_of_12, .span_11_of_12, .span_12_of_12 {
    width: 100%;
  }
}
