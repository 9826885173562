@import "src/styles/functions";
@import "src/styles/variables/core/variables";
@import "src/styles/mixins";

m-ui-separator {
  @import "variables";

  display: flex;

  .separator {
    width: 100%;
    min-height: multiply-size(1px);
    display: flex;
    justify-content: center;
    overflow: hidden;
    position: relative;
    margin: multiply-size(5px) 0;

    span {
      font-size: multiply-size(10px);
      font-family: MyriadPro, sans-serif;
      font-weight: 700;
      position: relative;
      text-align: center;
      color: $color-span;

      &.padding {
        padding: 0 multiply-size(8px);
      }

      &:before, &:after {
        content: '';
        position: absolute;
        width: multiply-size(500px);
        height: multiply-size(1px);
        background: $bgc-separator;
        display: block;
        @include absolute-center-vertical;
      }

      &:before {
        right: 100%;
      }

      &:after {
        left: 100%;
      }
    }
  }

  @import "custom/style";
}
