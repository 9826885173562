@import "src/styles/variables/core/variables";

.border-dashed {
  border: 2px dashed;

  &.border-yellow {
    border-color: $yellow;
  }

  &.border-gray {
    border-color: $gray;
  }

  &.border-orange-light {
    border-color: $orange-light;
  }

  &.border-blue-light {
    border-color: $blue-light;
  }

  &.border-red {
    border-color: $red;
  }

  &.border-green {
    border-color: $green;
  }

  &.border-orange {
    border-color: $orange;
  }

  &.border-green-light {
    border-color: $green-light;
  }

  &.border-blue-pearl {
    border-color: $blue-pearl;
  }

  &.border-purple-strong {
    border-color: $purple-strong;
  }
}
