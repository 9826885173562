@import "../variables";
@import "../mixins";

$colors: (
  $font-stroke: transparent,
  $border: (
    $normal: #000000,
    $hover: #000000,
  ),
  $background: (
    $normal: #FF9914,
    $active: #DE7D00,
    $hover: #FFBC64,
  ),
  $box-shadow: (
    $normal: #EB5003,
    $active: #D94B05,
    $hover: #FF6B21,
  ),
  $drop-shadow: (
    $normal: transparent,
    $hover: transparent,
  ),
  $font: (
    $normal: #000000,
    $active: #000000,
    $hover: #000000,
  )
);

&.c-quaternary {
  @include generate-schema-button-color($colors)
}
