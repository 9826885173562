@import "src/styles/mixins";
@import "src/styles/variables/core/variables";

app-building-thumbnail {
  .building-thumbnail-container {
    width: 110px;
    height: 110px;
    position: relative;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }

    .title {
      @include mikado;
      font-weight: bold;
      font-size: 14px;
      line-height: 1;
      text-align: center;
    }
  }
}
