@import "../variables";
@import "../mixins";

$colors: (
  $font-stroke: #4C0008,
  $border: #4C0008,
  $background: (
    $normal: #E33932,
    $active: #A92822,
  ),
  $box-shadow: (
    $normal: #99231E,
    $active: #6f0808,
  ),
  $font: (
    $normal: #ffffff,
    $active: #DBADA9,
  )
);

&.tertiary {
  @include generate-schema-button-color($colors)
}
