event-shop-product-details {
  @import "src/styles/mixins";

  .ow-dialog {
    padding: 55px 115px 96px 115px;
    display: flex;
    flex-direction: column;
    position: relative;
  }

  .limit-container {
    position: absolute;
    top: -18px;
    right: 110px;
    color: #000000;
    padding: 0 19px;
    background: #EBE5D5;
    border: 2px solid #3B3326;
    height: 30px;
    display: flex;
    align-items: center;
    font-size: 14px;
    @include mikado;
  }

  .currency-balance-container {
    @include mikado;
    position: absolute;
    left: 100px;
    top: 50px;
    font-size: 20px;
    font-weight: bold;
    display: flex;
    align-items: center;
    letter-spacing: 0;

    .icon-currency {
      width: 28px;
      height: 28px;
      margin-right: 6px;
    }

    .title {
      margin-bottom: 10px
    }
  }

  .frame {
    @include mikado;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 0;
    border-radius: 15px;
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 100%;

    &.gadget {
      .available-amount {
        display: block;
      }
    }

    .available-amount {
      display: none;
      font-size: 14px;
      font-weight: bold;
      text-align: center;
      margin-top: 13px;

      .fa {
        font-size: 9px;
      }
    }

    .to-low-lvl-info, .to-low-star-info {
      text-transform: lowercase;
      font-weight: bold;
      @include mikado;
      font-size: 16px;
    }

    .start, .end {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .icon-product {
      width: 76px;
      height: 76px;
      margin-bottom: 12px;
      pointer-events: none;

      &.allow-image-zoom {
        cursor: pointer;
        pointer-events: all;
      }
    }

    .product-price-container {
      margin-bottom: 20px;
    }

    .product-name {
      margin-bottom: 9px;
    }

    .product-price-container {
      display: flex;
      align-items: center;
    }

    .icon-currency {
      width: 20px;
      height: 20px;
      margin: 0 2px;
    }

    .description {
      @include archivo-narrow;
      font-size: 18px;
      text-align: center;
      line-height: 1.5;
      margin: 16px 0;
    }

    .summary {
      display: flex;
      align-items: center;
      margin: 15px 0;
    }

    .default-field {
      margin: 0;

      input {
        padding: 8px;

        &:focus {
          font-weight: normal;
        }
      }
    }

    .deactivated {
      & > * {
        opacity: 0.7;
        pointer-events: none;
      }
    }
  }
}
