app-rank-league-type {
  @import "../rank-league-details.component";
  @import "../../styles/style";

  .my-position-link {
    position: absolute;
    top: 58px;
    right: 380px;
  }

  .left {
    gap: 10px;

    .role-select {
      width: 200px;
    }
  }
}
