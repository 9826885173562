@import "src/styles/mixins";

app-san-warehouse {
  .ow-dialog {
    padding: 62px 70px 57px 103px;
    display: flex;
    flex-direction: column;
  }

  .warehouse-container {
    display: flex;
    flex-flow: column;

    .title {
      margin-bottom: 20px;
    }

    .frame {
      flex: 1 0 auto;
    }

    .products-container {
      box-sizing: border-box;
      height: 417px;

      .products-list {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        height: 375px;

        .product {
          background: #F9F4E4;
          width: 180px;
          height: 200px;
          margin-bottom: 20px;
          padding: 15px;
          box-sizing: border-box;
          font-size: 14px;
          transition: box-shadow 0.3s ease-in-out;
          @include mikado;
          text-align: center;
          margin-right: 16px;
          cursor: pointer;

          &:hover {
            box-shadow: 0 10px 16px rgb(191, 183, 164);
          }

          &:nth-child(6n) {
            margin-right: 0;
          }

          .icon {
            width: 76px;
            height: 76px;
            margin: 0 auto;
            margin-bottom: 10px;

            img {
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
          }

          .name {
            font-weight: 900;
            height: 35px;
            text-transform: uppercase;
            line-height: 1.3;
            margin-bottom: 3px;
          }
        }
      }
    }
  }

  .fallback-screen {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: 33px;

    img {
      display: block;
      margin: 0 auto 22px;
    }

    h3 {
      @include archivo-narrow;
      text-align: center;
      font-size: 22px;
      font-weight: bold;
      margin: 0 auto 22px;
    }

    p {
      @include archivo-narrow;
      max-width: 700px;
      font-size: 18px;
      text-align: center;
      margin: 0 auto;
    }
  }

  perfect-scrollbar {
    width: 100% !important;
  }
}
