@import "src/styles/mixins";

app-warehouse-details {
  .capacity-container {
    position: absolute;
    top: -18px;
    right: 110px;
    color: #000000;
    padding: 0 19px;
    background: #EBE5D5;
    border: 2px solid #3B3326;
    height: 30px;
    display: flex;
    align-items: center;
    font-size: 14px;
    @include mikado;
  }

  .ow-dialog {
    padding: 56px 114px 78px 114px;
    display: flex;
    flex-direction: column;
  }

  .warehouse-details-container {
    display: flex;
    flex-flow: column;

    .frame {
      flex: 1 0 auto;
      display: flex;
      flex-flow: column;

      .box-icon {
        width: 76px;
        height: 76px;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        margin: 0 auto;
        margin-bottom: 5px;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }

      .title-product {
        @include mikado();
        font-weight: bold;
        font-size: 20px;
        margin: 12px 0 10px 0;
        text-align: center;
      }

      .balance {
        @include mikado;
        text-align: center;
        font-size: 20px;
        font-weight: 900;
        margin-bottom: 20px;
      }

      .text {
        @include archivo-narrow();
        font-size: 18px;
        text-align: center;
        height: 90px;
        line-height: 1.5;
        width: 650px;
        margin: 0 auto 18px auto;
      }

      .transaction-container {
        display: flex;
        flex-flow: column;
        flex: 1 0 auto;

        .transaction-inner {
          flex: 1 0 auto;
          display: flex;
          justify-content: center;

          .column {
            width: 400px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            box-sizing: border-box;

            .error-capacity {
              @include archivo-narrow;
              font-weight: bold;
              font-size: 14px;
            }

            &:first-child:not(:last-child) {
              border-right: 1px solid #9C9583;
            }

            .operation {
              font-size: 18px;
              @include mikado;
              font-weight: 900;
              text-align: center;
              display: flex;
              justify-content: center;
              align-items: center;

              m-ui-product, m-ui-currency {
                margin-left: 6px;
                margin-right: 4px;
              }
            }
          }
        }
      }
    }
  }

  form {
    width: 194px;
    height: 36px;
  }

  .default-field {
    margin-bottom: 0;
  }

  .default-field input {
    padding: 7px;
    @include archivo-narrow();
    font-size: 16px;
  }

  .default-field input:focus + label {
    background: #fff;
  }
}
