@import "src/styles/functions";
@import "src/styles/mixins";
@import "variables";


app-gallery,app-photos {
  overflow-y: auto;
  width: 100%;
  max-height: 100dvh;
}

.gallery-container {
  width: 100%;
  margin: 0 auto;
  @include roboto;
  color: $color-light;

  @media only screen and (min-width: $media-s) {
    width: multiply-size(360px);
  }

  @media only screen and (min-width: $media-md) {
    width: multiply-size(768px);
  }

  @media only screen and (min-width: $media-lg) {
    width: multiply-size(1024px);
  }

  @media only screen and (min-width: $media-xlg) {
    width: multiply-size(1184px);
  }

  @media only screen and (min-width: $media-xxlg) {
    width: multiply-size(1470px);
  }
}

.gallery-background-container {
  background: #ffffff;
  border-radius: multiply-size(30px);
  margin-top: multiply-size(-210px);
  min-height: multiply-size(170px);
  padding: 0 multiply-size(24px) multiply-size(24px) multiply-size(24px);
  box-sizing: border-box;

  @media only screen and (min-width: $media-lg) {
    padding: 0 multiply-size(26px) multiply-size(26px) multiply-size(26px);
  }

  @media only screen and (min-width: $media-xlg) {
    padding: 0 multiply-size(46px) multiply-size(46px) multiply-size(46px);
  }

  @media only screen and (min-width: $media-xxlg) {
    padding: 0 multiply-size(135px) multiply-size(50px) multiply-size(135px);
  }

  .gallery-info {
    display: flex;
    flex-direction: column;
    align-items: center;

    .gallery-title {
      margin: multiply-size(55px) 0 multiply-size(30px) 0;
      font-size: multiply-size(24px);
      font-weight: 900;
      line-height: multiply-size(36px);
      text-align: center;
      color: $color-dark;
    }

    .toggle-description {
      @include roboto-cond;
      font-size: multiply-size(20px);
      color: $color-dark;
      display: flex;
      align-items: center;
      cursor: pointer;

      &.show {
        margin-bottom: multiply-size(36px);
      }

      @media only screen and (min-width: $media-md) {
        display: none;
      }

      i {
        margin-left: multiply-size(15px);
        font-size: multiply-size(16px);
      }
    }

    .gallery-description {
      margin: 0;
      font-size: multiply-size(18px);
      line-height: multiply-size(30px);
      @include roboto-cond;
      text-align: center;
      display: none;

      &.show {
        display: block;
      }

      @media only screen and (min-width: $media-md) {
        display: block;
      }
    }

    @media only screen and (min-width: $media-md) {
      .gallery-title,
      .gallery-description {
        width: multiply-size(534px);
      }
    }

    @media only screen and (min-width: $media-lg) {
      .gallery-title,
      .gallery-description {
        width: multiply-size(640px);
      }
    }

    @media only screen and (min-width: $media-xlg) {
      .gallery-title,
      .gallery-description {
        width: multiply-size(720px);
      }
    }

    @media only screen and (min-width: $media-xxlg) {
      .gallery-title,
      .gallery-description {
        width: multiply-size(792px);
      }
    }
  }
}

.images-list-container {
  display: flex;
  flex-wrap: wrap;
  margin-top: multiply-size(20px);

  @media only screen and (min-width: $media-xlg) {
    margin-top: multiply-size(30px);
  }

  .each-image {
    margin-top: multiply-size(30px);
    width: multiply-size(312px);
    display: flex;
    flex-direction: column;
    cursor: pointer;
    border-radius: multiply-size(15px);
    box-shadow: 0 0 0 multiply-size(1px) #dae1e7;
    overflow: hidden;

    @media only screen and (min-width: $media-md) {
      width: multiply-size(348px);

      &:not(:nth-child(2n + 2)) {
        margin-right: multiply-size(24px);
      }
    }

    @media only screen and (min-width: $media-lg) {
      width: multiply-size(308px);

      &:not(:nth-child(2n + 2)) {
        margin-right: 0;
      }

      &:not(:nth-child(3n + 3)) {
        margin-right: multiply-size(24px);
      }
    }

    @media only screen and (min-width: $media-xlg) {
      width: multiply-size(348px);
    }

    @media only screen and (min-width: $media-xxlg) {
      width: multiply-size(384px);
    }

    img {
      width: 100%;
    }

    .details-border {
      padding: multiply-size(18px) multiply-size(17px) multiply-size(26px) multiply-size(17px);
      box-sizing: border-box;
      border-top: multiply-size(1px) solid #dae1e7;

      .each-image-title {
        font-size: multiply-size(22px);
        color: $color-dark;
      }
    }

    &.without-margin-side {
      margin-left: 0;
      margin-right: 0;
    }
  }
}

.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  min-height: multiply-size(60px);

  .gradient {
    top: 0;
    left: 0;
    width: 100%;
    height: multiply-size(72px);
    background: transparent linear-gradient(180deg, #00000066 0%, #00000000 100%) 0 0 no-repeat padding-box;
    opacity: 1;
    position: absolute;

    @media only screen and (min-width: $media-md) {
      display: none;
    }
  }

  .big-photo,
  .delete-photo,
  .rotate-photo {
    position: absolute;
    border-radius: multiply-size(50px);
    width: multiply-size(50px);
    height: multiply-size(50px);
    background: rgba(0, 0, 0, 0.3);
    color: #ffffff;
    font-size: multiply-size(20px);
    cursor: pointer;

    &.rotate-photo-left {
      left: multiply-size(10px);
      top: 50%;
      transform: translate(0, -50%);
      z-index: 100;
    }

    &.rotate-photo-right {
      right: multiply-size(10px);
      top: 50%;
      transform: translate(0, -50%);
      z-index: 100;
    }
  }

  .big-photo {
    bottom: multiply-size(10px);
    right: multiply-size(10px);
    @media only screen and (min-width: $media-md) {
      top: multiply-size(10px);
      bottom: unset;
    }
  }

  .delete-photo {
    bottom: multiply-size(10px);
    right: multiply-size(10px);
  }

  img {
    max-height: multiply-size(474px);
    min-height: multiply-size(177px);
    max-width: 100%;
  }
}

@media screen and (max-width: $media-md) {
  .image-container {
    flex-direction: column;
  }
}

button {
  i {
    text-shadow: none;
  }

  &.gallery-normal {
    border-radius: multiply-size(12px);
    min-width: multiply-size(197px);
    height: multiply-size(50px);
    border: multiply-size(2px) solid $color-dark;
    background: #ffffff;
    font-size: multiply-size(18px);
    color: $color-dark;
    @include roboto;
    text-transform: uppercase;
  }

  &.rounded-white {
    border-radius: multiply-size(50px);
    width: multiply-size(50px);
    height: multiply-size(50px);
    border: multiply-size(1px) solid #dae1e7;
    background: #ffffff;
    font-size: multiply-size(22px);
    color: $color-dark;
  }

  &.is-shadow {
    box-shadow: 0 multiply-size(12px) multiply-size(12px) rgba(0, 0, 0, 0.24);
  }

  &.add {
    position: fixed;
    bottom: multiply-size(20px);
    right: multiply-size(20px);
  }
}

& {
  perfect-scrollbar {
    .ps-content {
      padding-right: multiply-size(24px);

      @media only screen and (min-width: $media-md) {
        padding-right: multiply-size(30px);
      }
    }

    .ps__rail-y,
    .ps__thumb-y {
      width: multiply-size(4px) !important;
      display: none !important;

      @media only screen and (min-width: $media-md) {
        display: block !important;
      }
    }

    .ps__rail-y {
      background: #dadada !important;
    }

    .ps__thumb-y {
      background-color: #a9a9a9 !important;
    }
  }

  .cropper-container {
    display: none !important;
  }
}

@import "custom/style";
