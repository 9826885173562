@import "src/styles/functions";
@import "src/styles/variables/core/variables";
@import "src/styles/mixins";

app-splash-screen {
  overflow: hidden;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 800;
  pointer-events: none;

  .splash-bg {
    width: 100%;
    height: 100%;
    background-image: var(--asset--ui-background-png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    //background: #ffffff var(--asset--ui-logo-png) center no-repeat;
    opacity: 0;
    transform: scale(1.5);
    transition: 0.3s;

    .logo {
      left: 50%;
      top: 50%;
      height: 350px;
      width: 460px;
      transform: translate(-50%, -50%);
      position: absolute;
      background-image: var(--asset--ui-logo-png);
    }
  }

  &.active {
    pointer-events: all;
    background: #ffffff;

    .splash-bg {
      opacity: 1;
      transform: scale(1);
    }
  }

  .percentage {
    position: absolute;
    top: calc(50% - 260px);
    left: 50%;
    transform: translate(-50%, -50%);

    font-size: multiply-size(36px);
    @include futura-heavy;
    //@include absolute-center-horizontal;
    color: white;
    text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black
  }

  loading {
    position: absolute;
    top: calc(50% - 300px);
    left: 50%;
    transform: translate(-50%, -50%);

    .loading-content {
      .relative {
        i {
          font-size: 24px;
          color: white;
          text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
          position: absolute !important;
        }
      }
    }
  }

  @import "custom/style";
}

//.percentage {
//  font-size: multiply-size(36px);
//  @include futura-heavy;
//  position: absolute;
//  @include absolute-center-horizontal;
//  bottom: multiply-size(40px);
//  color: white;
//  text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black
//}
//
//loading {
//  .loading-content {
//    .relative {
//      i {
//        font-size: 24px;
//        color: white;
//        text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
//        position: absolute !important;
//        bottom: multiply-size(90px);
//      }
//    }
//  }
//}

