button {
  &.exit {
    border: none;

    &.normal {
      width: 40px;
      height: 60px;
      background: var(--asset--ui-exit-svg);
      border-left: 3px solid black;
      background-size: 40px 40px;
      background-repeat: no-repeat;
      background-position: top 5px left -3px;

      &:hover {
        filter: var(--filter-hover);
      }
    }

    i {
      display: none;
    }
  }

  &.custom-exit {
    background: none !important;

    i {
      display: block !important;
      font-size: 30px;
      color: #FFFFFF;
      @include text-stroke();
    }
  }
}
