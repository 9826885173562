@import "src/styles/functions";
@import "src/styles/variables/core/variables";
@import "src/styles/mixins";

app-m-buildings-list {
  .global-dialog {
    width: multiply-size(512px);
    height: multiply-size(322px);
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  m-ui-close-button {
    button {
      &.exit {
        &.normal {
          position: absolute;
          top: 0;
          right: multiply-size(-48px);
        }

        i {
          @include text-stroke();
          color: white;
        }
      }
    }
  }

  swiper-container {
    height: 500px;
    margin-top: 35px;
  }

  .navigation-bar {
    position: absolute;
    @include absolute-center-vertical;

    &.prev {
      left: multiply-size(-40px);
    }

    &.next {
      right: multiply-size(-40px);
    }
  }

  .buildings-list-container {
    flex: none;
    position: relative;
  }

  .window-c {
    margin: multiply-size(21px) multiply-size(6px) multiply-size(12px) multiply-size(6px);
    padding: multiply-size(10px) multiply-size(8px) multiply-size(7px) multiply-size(8px);
    border-radius: multiply-size(10px) multiply-size(10px) multiply-size(6px) multiply-size(6px);
    display: flex;
    align-items: center;
    flex-direction: column;
    transition: 0.3s;
    position: relative;
    opacity: 0.8;

    &.active {
      opacity: 1;
      @include drop-shadow($color-I, 1, 2);
    }

    .icon-container {
      display: flex;
      align-items: flex-end;
      width: multiply-size(100px);
      height: multiply-size(100px);

      img {
        width: 100%;
        object-fit: contain;
      }
    }

    .name {
      margin-top: multiply-size(14px);
      text-align: center;
      font-size: multiply-size(12px);
      text-transform: uppercase;
      line-height: multiply-size(14px);
      @include myriad;
      font-weight: bold;
      color: $color-K;
      min-height: multiply-size(28px);
      margin-bottom: 0;
    }
  }

  m-ui-group-count,
  .building-lock {
    opacity: 0;
    transition: 0.3s;
    position: absolute;
    @include absolute-center-horizontal;
    top: multiply-size(20px);

    &.active {
      top: 0;
      opacity: 1;
    }
  }

  .building-lock {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    @include text-stroke;
    color: $color-I;
    padding: multiply-size(2px);

    i {
      font-size: multiply-size(12px);
      margin-right: multiply-size(4px);
    }

    span {
      font-size: multiply-size(10px);
      line-height: multiply-size(10px);
      @include mikado-black;
    }
  }

  .categories {
    display: flex;
    justify-content: center;
    width: 100%;

    .category {
      margin: multiply-size(14px) multiply-size(10px);
      width: multiply-size(50px);
      height: multiply-size(46px);
      cursor: pointer;
      transition: 0.3s;

      &.active {
        cursor: initial;
        @include drop-shadow($color-T);
      }
    }
  }

  .active-category-name {
    @include text-stroke;
    @include mikado;
    font-size: multiply-size(12px);
    text-align: center;
    margin: 0;
    text-transform: uppercase;
    color: $color-I;
  }

  .one-category {
    .categories,
    .active-category-name {
      display: none;
    }

    .buildings-list-container {
      flex: none;
    }
  }

  @import "custom/style";
}


.mdc-dialog-surface {
  overflow-y: unset;
}
