@import "src/styles/functions";

app-hud-menu-more {
  .global-dialog {
    width: 600px;
    display: flex;
    justify-content: center;
    align-items: center;

    .exit-bar {
      right: -50px !important;
      top: 20px !important;
    }
  }

  app-custom-hud-menu {
    width: 100%;

    .main-buttons-container {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 20px;

      .main-button {
        height: 100px;
        margin: 4px 25px;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: flex-start;

        ow-object-svg {
          height: auto;
        }

        .button-name {
          margin-top: 12px;
          @include mikado();
          font-size: 14px;
          font-weight: bold;
          color: #FFFFFF !important;
          text-transform: none !important;
        }
      }
    }
  }
}
