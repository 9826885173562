.tabs {
  display: flex;
  line-height: 16px;
  height: 44px;
  z-index: 1;
  top: -1px;
  position: absolute;

  .tab {
    position: relative;
    margin: 0 3px;

    &.active {
      z-index: 1 !important;

      &::after {
        position: absolute;
        content: "";
        width: 26px;
        height: 26px;
        background-color: #7CDF4F;
        border-left: solid 3px #000000;
        border-bottom: solid 3px #000000;
        left: 50%;
        transform: rotate(-45deg) translateX(-50%);
        bottom: 16px;
        z-index: -5;
      }

      &.green {
        &::after {
          background-color: #7CDF4F;
        }
      }

      &.yellow {
        &::after {
          background-color: #F8E53A;
        }
      }

      &.blue-light {
        &::after {
          background-color: #0F98FA;
        }
      }

      &.red {
        &::after {
          background-color: #FF3D3D;
        }
      }

      &.purple {
        &::after {
          background-color: #DF50C5;
        }
      }

      &.orange {
        &::after {
          background-color: #FF8B26;
        }
      }
    }

    .bookmarks {
      position: relative;
      padding: 0 14px;
      width: auto;
      cursor: pointer;
      top: -11px;

      .bookmark {
        white-space: nowrap;
        min-width: 156px;
      }
    }
  }
}
