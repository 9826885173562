@import "../variables";
@import "../mixins";

$colors: (
  $font-stroke: transparent,
  $border: (
    $normal: #000000,
    $hover: #000000,
  ),
  $background: (
    $normal: #7EB6FF,
    $active: #4588DF,
    $hover: #9EC8FF,
  ),
  $box-shadow: (
    $normal: #2D5994,
    $active: #7EB6FF,
    $hover: #164788,
  ),
  $drop-shadow: (
    $normal: transparent,
    $hover: transparent,
  ),
  $font: (
    $normal: #000000,
    $active: #000000,
    $hover: #000000,
  )
);

&.c-secondary {
  @include generate-schema-button-color($colors)
}
