@import "src/styles/mixins";
@import "src/styles/variables/core/variables";

app-custom-qa-list {
  .title {
    text-align: center;
    font-weight: bold;
    font-size: 16px;
    @include archivo-narrow;
  }

  .c-window-long {
    padding: 100px 103px 102px 103px;
    box-sizing: border-box;
  }

  .quiz {
    margin-bottom: 20px;
    @include mikado();
    font-size: 20px;
    text-align: center;
  }

  .qa {
    .frame {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      position: relative;

      .duration {
        position: absolute;
        top: -34px;
        right: 24px;
        font-weight: 900;
        font-size: 16px;

        p {
          margin: 0 !important;
        }
      }

      .description {
        @include archivo-narrow();
        font-size: 16px;
        text-align: center;
        padding: 0 100px;
        max-height: 94px;
      }

      .finish-survey-info {
        @include archivo-narrow();
        font-size: 18px;
        font-weight: 900;
      }
    }

    .bottom {
      @include absolute-center-horizontal;
      bottom: 50px;
      display: flex;
      justify-content: center;
    }
  }

  .width {
    width: 224px !important;
  }

  .back-list {
    margin-left: 22px;
  }

  .expired {
    color: #EB2F5B;
  }

  .rank {
    .default-field {
      position: absolute;
      top: -46px;
      right: 20px;

      input {
        width: 210px;
        height: 36px;
      }
    }

    .frame {
      height: 417px;
      border-radius: 0 15px 15px 15px;
      width: 100%;
      box-sizing: border-box;
      position: relative;
      display: flex;
      flex-direction: column;
    }

    .my-position-link {
      text-align: right;
      margin-bottom: 10px;

      a {
        color: #000000;
        text-transform: uppercase;
        font-weight: 900;
      }
    }

    table {
      border-collapse: collapse;
      width: 100%;

      thead {
        tr {
          height: 40px;

          th {
            @include archivo-narrow();
            font-size: 14px;

            &:first-child {
              padding-left: 24px;
              padding-right: 20px;
            }

            &:last-child {
              padding-right: 34px;
              padding-left: 34px;
            }

            &.td-time {
              text-align: center;
            }

            &.td-score {
              text-align: center;
            }
          }
        }
      }

      tbody {
        tr {
          border-bottom: 1px solid rgba(1, 72, 89, 0.3);
          height: 34px;

          &:nth-child(odd) {
            background-color: $color-I;
          }

          td {
            @include mikado;
            font-weight: 900;
            font-size: 14px;

            &:first-child {
              padding-left: 24px;
              padding-right: 20px;
            }

            &:last-child {
              padding-right: 34px;
              padding-left: 34px;
            }

            &.td-name {
              width: 100%;
            }

            &.td-time {
              text-align: center;
            }

            &.td-score {
              text-align: center;
            }
          }
        }
      }
    }
  }

  app-ranking-details-game {
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  .navigation-bar {
    &.next {
      right: -70px;
    }

    &.prev {
      left: -70px;
    }
  }

  .hide {
    opacity: 0;
  }

  .bar-button {
    margin: 0 10px;
  }
}
