@import "src/styles/mixins";

app-building-requirements {
  @include mikado;

  .texts-container {
    margin-top: 6px;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
  }

  m-ui-costs {
    display: block;
  }

  .parameters-employee-costs {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    & > * {
      margin: 0 10px;
    }
  }

  & > .flex-center {
    margin-top: 13px;
  }
}
