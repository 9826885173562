@import "src/styles/functions";
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/mixins/reset-text";
@import "bootstrap/scss/mixins/border-radius";
@import "bootstrap/scss/tooltip";
@import "src/styles/variables/core/variables";
@import "src/styles/mixins";
@import "variables";


.tooltip {
  pointer-events: none;

  .arrow {
    display: none;
  }

  &.show {
    opacity: 1;
  }

  .tooltip-inner {
    max-width: none;
    color: $tooltip-color;
    border: $tooltip-border;
    background: $tooltip-bg !important;
    @include archivo-narrow;
    border-radius: $tooltip-border-radius;
    font-size: multiply-size(12px);
    font-weight: bold;
    @include text-ellipsis;
  }

  &.delayed {
    animation: delayedFadeIn 0.4s;

    @keyframes delayedFadeIn {
      0% {
        opacity: 0;
      }
      99% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
  }

  &.no-wrap {
    .tooltip-inner {
      max-width: multiply-size(220px);
      text-overflow: initial;
      overflow: initial;
      white-space: initial;
    }
  }

  &.pre-line {
    .tooltip-inner {
      text-overflow: initial;
      overflow: initial;
      white-space: pre-line;
    }
  }
}


.mat-mdc-tooltip-panel {
  pointer-events: none;
}

.mdc-tooltip {
  margin: 10px;
  color: $tooltip-color !important;
  border: $tooltip-border !important;
  background: $tooltip-bg !important;
  @include archivo-narrow('!important');
  border-radius: $tooltip-border-radius !important;
  font-size: multiply-size(12px) !important;
  font-weight: bold !important;
  white-space: pre-line;
  text-align: center;
  line-height: multiply-size(16px);
  padding-left: multiply-size(8px);
  padding-right: multiply-size(8px);
}

@import "../custom/tooltip";
