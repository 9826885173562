@import "src/styles/functions";
@import "variables/core/variables";
@import "mixins";

table {
  &.rank {
    border-spacing: 0;

    &.first-three {
      margin-top: multiply-size(15px);

      tr {
        td {
          color: #ffffff;
          background: $color-base !important;
        }
      }
    }

    tr {
      td {
        height: multiply-size(24px);
        font-weight: bold;
        border: solid $color-secondary;
        border-width: multiply-size(1px) multiply-size(2px);
        font-size: multiply-size(14px);
        text-align: center;

        .flex-center {
          .icon {
            margin-right: multiply-size(4px);
          }
        }

        &.td-position {
          min-width: multiply-size(32px);
        }

        &.td-superbadge {
          min-width: multiply-size(32px);
        }

        &.td-name {
          padding-left: multiply-size(20px);
          width: 100%;
          text-align: left;
        }

        &.td-region-name {
          padding: 0 multiply-size(12px);
          @include text-ellipsis;
        }

        &.td-value {
          min-width: multiply-size(82px);
        }

        &.td-min-valid {
          min-width: multiply-size(117px);
        }

        &.td-league-name {
          min-width: multiply-size(120px);
          @include text-ellipsis;

          .icon {
            width: multiply-size(20px);
            height: multiply-size(20px);
          }
        }

        &.td-quality {
          min-width: multiply-size(32px);

          &.fill {
            @include mikado-black;
            color: #ffff1a;
            background: #72af29 !important;

            &:after {
              content: 'Q';
            }
          }
        }

        &.td-prize-1 {
          min-width: multiply-size(70px);
          @include text-ellipsis;
        }

        &.td-prize-2 {
          min-width: multiply-size(65px);
          @include text-ellipsis;
        }

        &.td-prize-3 {
          min-width: multiply-size(54px);
          @include text-ellipsis;
        }

        &.td-prize-4 {
          min-width: multiply-size(70px);
          @include text-ellipsis;
        }

        &.td-prize-array {
          padding-left: multiply-size(5px);
          padding-right: multiply-size(5px);
          display: flex;
          justify-content: center;
          @include text-ellipsis;

          .flex-center {
            margin-left: multiply-size(10px);

            &:first-child {
              margin-left: 0;
            }
          }
        }
      }

      &:nth-child(odd) {
        td {
          background: #ffffff;
        }
      }

      &.my-position {
        td {
          box-shadow: inset 0 0 0 multiply-size(1px) $color-base;
        }
      }
    }
  }

  tr {
    td {
      &.no-wrap {
        white-space: nowrap;
      }
    }
  }
}
