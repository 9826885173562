.progressbar-container {
  @include mikado;
  font-weight: 900;

  .box {
    .progressbar-title {
    }

    .progressbar-value {
    }
  }
}

.progressbar {
  // SET HEIGHT
  // SET BORDER-RADIUS
  width: 100%;
  background-color: #466A00;
  overflow: hidden;
  padding: 2px;
  box-sizing: border-box;

  .fill {
    height: 100%;
    max-width: 100%;
    box-sizing: border-box;
    // SET BORDER-RADIUS

    &.fill-color-6 {
      background-color: #FFBB00;
    }

    &.fill-color-5 {
      background-color: #F12424;
    }

    &.fill-color-4 {
      background-color: #01E436;
    }

    &.fill-color-3 {
      background-color: #88CB03;
    }

    &.fill-color-2 {
      background-color: #01E436;
    }

    &.fill-color-1 {
      background-color: #FFBB00;
    }

    &.green {
      background-color: #95ED42;
    }
  }
}
