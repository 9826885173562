@import "src/styles/mixins";

app-message-details-comics {
  @import "../../styles/style";

  .ow-dialog {
    width: 1152px;
    height: 582px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #EBE5D5;
    border: 2px solid #000000;
    min-width: 400px;

    &:after {
      content: '';
      position: absolute;
      top: -10px;
      left: -10px;
      bottom: -10px;
      right: -10px;
      background: $custom-dialog-outline;
      border: 2px solid #000000;
      z-index: -1;
    }

    .gallery-page {
      box-sizing: border-box;
      width: 1092px;
      height: 522px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .counter {
      position: absolute;
      @include absolute-center-horizontal;
      @include mikado;
      @include text-stroke;
      font-weight: 900;
      color: $color-I;
      font-size: 20px;
      bottom: -34px;

      &.move-right {
        left: unset !important;
        transform: unset !important;
        right: 24px;
      }
    }

    .exit-bar {
      position: absolute;
      top: 20px;
      right: -50px;

      i {
        color: $color-I;
        @include text-stroke;
      }
    }

    .back-bar {
      left: -41px;
      top: 54px;
    }

    .navigation-bar {
      &.next {
        right: -85px !important;
      }

      &.prev {
        left: -85px !important;
      }

      i {
        color: $color-I;
        @include text-stroke;
      }
    }

    .bottom-bar {
      bottom: -42px;
    }

    button {
      border: none;
    }
  }
}
