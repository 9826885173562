@import "src/styles/functions";
&.long {
  width: multiply-size(134px);
  height: multiply-size(24px);
  text-transform: uppercase;
  @include myriad;
  font-weight: bold;
  font-size: multiply-size(16px);
  border-radius: multiply-size(6px);
  border: multiply-size(2px) solid;
}
