app-wf-play {
  @import "../../../../../san-ranks/basic/custom/dialogs/rank-details/styles/style";

  .main-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    box-sizing: border-box;

    &.tpl-1 {
      padding: 78px 95px 112px;
      align-items: flex-start !important;
    }

    &.tpl-2 {
      flex-direction: column;
      padding: 80px 247px 60px;
      justify-content: flex-start;

      .description {
        padding: 0 80px;
      }
    }

    &.tpl-3 {
      padding: 74px 82px 76px 102px;
      flex-direction: column;
      justify-content: flex-start;

      .title {
        margin-bottom: 30px;
      }
    }
  }

  .center {
    width: 568px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: -4px 40px 0;

    .details {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;

      .row {
        display: flex;
        align-items: center;
        width: 100%;
        white-space: nowrap;
        height: 36px;
        padding: 0 7px 0 11px;

        &:nth-child(odd) {
          background: #F9F4E4;
        }

        .name {
          @include mikado();
          font-size: 14px;
          text-align: left;
          width: 25%;
          cursor: default;
        }

        .date {
          @include archivo-narrow();
          font-size: 16px;
          letter-spacing: 0;
          line-height: 18px;
          text-align: center;
          width: 35%;
          cursor: default;
        }

        .prize {
          @include mikado();
          font-size: 14px;
          text-align: right;
          width: 15%;
          cursor: default;
        }

        .detail-btn {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          width: 25%;
        }
      }
    }
  }

  .description {
    @include archivo-narrow();
    font-size: 16px;
    letter-spacing: 0;
    line-height: 18px;
    text-align: center;
    margin-bottom: 20px;
  }

  button.det {
    width: 95px !important;
    height: 26px !important;
    min-width: 95px !important;
    min-height: 26px !important;
    box-shadow: none !important;
    padding-bottom: 0 !important;
    font-size: 16px !important;
  }

  .left, .right {
    background: var(--asset--ui-lemur1-png);
    background-repeat: no-repeat;
    background-size: contain;
    width: 261px;
    height: 383px;
    box-sizing: border-box;
    @include mikado();
    position: relative;
    margin-top: 36px;

    .white-tab {
      @include absolute-center-horizontal;
      top: 30px;
    }
  }

  .white-tab {
    width: 250px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }

  .archivo {
    @include archivo-narrow('!important');
  }

  .title {
    font-size: 20px;
    margin-bottom: 4px;
    line-height: 27px;
    letter-spacing: 0;
    white-space: nowrap;
    @include mikado();
  }

  .summary {
    font-size: 30px;
    line-height: 40px;
    letter-spacing: 0;
    @include mikado();
  }

  .right {
    transform: scaleX(-1);

    .title, .summary {
      transform: scaleX(-1);
    }
  }

  .my-score {
    margin: 10px 0 30px;
    text-align: center;
  }

  .separator {
    margin: 52px 0 38px;

    span {
      color: #000000;
      font-size: 14px;

      &:after, &:before {
        background: #000000;
      }
    }
  }

  .white-tabs {
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 0;

    &.v2 {
      .white-tab {
        background: var(--asset--ui-tab_white_down-png);
        background-repeat: no-repeat;
        background-size: contain;
        width: 250px;
        height: 160px;
        box-sizing: border-box;
        padding-top: 30px;
      }
    }

    .vs {
      margin: 0 28px;
      padding-bottom: 30px;
    }
  }

  .dodo-logo {
    background: var(--asset--ui-dodo_logo-png);
    background-repeat: no-repeat;
    background-size: contain;
    width: 250px;
    height: 112px;
    box-sizing: border-box;
    @include absolute-center-horizontal;
    bottom: 0;
  }

  // TABLE
  perfect-scrollbar {
    height: 408px;
  }

  table {
    @include archivo-narrow;
    border-collapse: collapse;
    font-size: 14px;
    width: 100%;

    &.results-table {
      tr {
        td.premium {
          &::before {
            display: block;
            position: absolute;
            content: "";
            width: 6px;
            height: 38px;
            background-color: #77CC4F;
            z-index: 2;
            top: 0;
            left: 0;
          }
        }

        &:nth-child(odd) {
          td {
            &::before {
              background-color: #8DE662 !important;
            }
          }
        }
      }
    }

    tbody {
      tr {
        height: 38px;

        &:nth-child(odd) {
          background-color: #F9F4E4;
        }
      }
    }

    td {
      font-weight: bold;
      position: relative;
      white-space: nowrap;

      &:first-child {
        padding-left: 30px;
      }

      &:last-child {
        padding-right: 30px;
      }

      &.position {
        width: 6%;
        text-align: left;
      }

      &.name {
        width: 84%;
        text-align: left;
      }

      &.value {
        width: 10%;
        text-align: right;
      }
    }
  }

  perfect-scrollbar {
    .ps-content {
      padding-right: 44px !important;
    }
  }
}
