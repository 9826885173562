@import "src/styles/mixins";

app-contract-max-prizes {
  .ow-dialog {
    max-width: 806px;
    background: #EBE5D5;
    border: 3px solid #000000;
    min-width: 906px;
    min-height: 438px;
    padding: 40px 35px;
    display: flex !important;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    m-ui-product {
      justify-self: start;

      .stock-j {
        flex-direction: row-reverse;
        gap: 4px;

        .value {
          font-size: 22px;
          @include mikado();
        }

        .icon {
          width: 50px;
          height: 50px;
        }
      }
    }

    m-ui-currency {
      justify-self: start;

      .stock-j {
        flex-direction: row-reverse;
        gap: 4px;

        .value {
          font-size: 22px;
          @include mikado();
        }

        .icon {
          width: 50px;
          height: 50px;
        }
      }
    }

    m-ui-close-button {
      position: absolute;
      top: 5px;
      right: -42px;
    }

    .description {
      @include archivo-narrow();
      font-size: 16px;
      text-align: center;
      width: 100%;
    }

    .title {
      margin: 40px 0;
      font-size: 20px;
      @include mikado();
      font-weight: bold;
      text-align: center;
      width: 100%;
    }
  }
  .prizes {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(2, 50px);
    gap: 20px;
    row-gap: 40px;
    justify-content: start;
    align-self: start;
    position: relative;
    padding: 0 25px;

    &::after {
      content: '';
      position: absolute;
      top: calc(50px + 40px / 2);
      left: 0;
      width: 100%;
      height: 1px;
      background: #9C9583;
    }

    .item {
      text-align: left;
      display: flex;
      align-items: center;

      &.final-prizes {
        grid-row: 2;
      }

      &.label {
        @include archivo-narrow();
        font-size: 16px;
        width: 150px;
      }
    }
  }

  button {
    &.c-base {
      margin-top: 20px;
    }
  }
}
