@import "src/styles/functions";
@import "src/styles/variables/core/variables";
@import "src/styles/mixins";

app-core-transaction-history {
  @import "variables";

  .window-a {
    padding: multiply-size(30px) multiply-size(38px) multiply-size(22px) multiply-size(38px);
  }

  .table {
    width: 100%;
    display: flex;
    flex-direction: column;
    @include archivo-narrow;

    .tr {
      height: multiply-size(20px);
      display: flex;
      align-items: center;
      border-bottom: multiply-size(1px) solid $color-K;

      .td {
        padding-right: multiply-size(9px);
        font-size: multiply-size(12px);
        display: flex;

        &.td-reason {
          flex: 1;
          height: multiply-size(15px);
        }

        &.td-value {
          text-align: right;
          @include myriad;
          font-weight: bold;
          color: $color-K;
        }

        &:last-child {
          padding-right: multiply-size(1px);
        }
      }
    }
  }

  ul,
  .description {
    margin: multiply-size(8px) 0;
    font-size: multiply-size(12px);
  }

  .description {
    &:first-of-type {
      margin-top: 0;
    }
  }

  ul {
    padding: 0;
    list-style-position: inside;
  }

  .no-item {
    text-align: center;
    font-size: multiply-size(12px);
    @include mikado;
    color: rgba($color-K, 0.9);
  }

  @import "../../../custom/dialogs/transaction-history/override-transaction-history.component";
}
