@import "src/styles/functions";
@import "src/styles/mixins";
@import "src/styles/variables/core/variables";

app-m-info-message-screen {
  $color-stroke: #011e59;
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  pointer-events: none;

  #{$context-desktop} & {
    --multiply: 1.5;
  }

  #{$context-tablet} & {
    --multiply: 1.2;
  }

  .background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(23, 41, 57, 0.8);
    opacity: 1;
    z-index: -1;
  }

  .message {
    @include mikado;
    font-size: multiply-size(20px);
    text-align: center;
    padding: 0 multiply-size(20px);
    color: #ffffff;
    margin: multiply-size(5px) 0;
    text-shadow: multiply-size(1px) multiply-size(-1px) multiply-size(2px) $color-stroke,
      multiply-size(-1px) multiply-size(-1px) multiply-size(2px) $color-stroke,
      multiply-size(-1px) multiply-size(1px) multiply-size(2px) $color-stroke,
      multiply-size(1px) multiply-size(1px) multiply-size(2px) $color-stroke;
  }

  .message-top {
    position: absolute;
    bottom: 0;
    margin: 0;
    padding: multiply-size(4px) 0;
    width: 100%;
    font-size: multiply-size(14px);
    text-align: center;
    color: #ffffff;
  }

  .background,
  button {
    pointer-events: all;
  }

  button {
    margin: multiply-size(5px) 0;
  }
}
