@import "src/styles/functions";
@import "src/styles/mixins";
@import "variables";

ow-perfect-scrollbar {
  width: multiply-size(10px, "100% + ") !important;

  .ps-content {
    padding-right: multiply-size(17px);
    @include archivo-narrow;
    font-size: multiply-size(10px);
    text-align: justify;
  }
}

.window-a {
  padding: multiply-size(30px) multiply-size(38px) multiply-size(23px) multiply-size(38px);
}

.title {
  font-size: multiply-size(16px);
  text-align: center;
}

.description {
  font-size: multiply-size(10px);
  text-align: center;
}

.message-date {
  position: absolute;
  left: multiply-size(4px);
  top: multiply-size(90px);
  transform: rotate(-90deg);
  font-size: multiply-size(10px);
  @include futura-medium;
}

.flex-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.trash-icon {
  position: absolute;
  bottom: multiply-size(-10px);
  right: multiply-size(-28px);
  width: multiply-size(30px);
  height: multiply-size(30px);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: multiply-size(10px);
  cursor: pointer;
  color: $color-K;
}

@import "custom/style";
