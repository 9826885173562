@import "src/styles/variables/core/variables";
@import "src/styles/mixins";
@import "../../styles/style";

app-custom-message-details-normal {
  .ow-dialog {
    padding: 50px 114px 50px 114px;
    display: flex;
    flex-direction: column;
    @include archivo-narrow;

    .title {
      margin-bottom: 26px;
      font-size: 24px;
      font-weight: 900;
    }

    .frame {
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: space-between;
      border-radius: 15px;

      .frame-white {
        @include archivo-narrow;
        padding: 6px 0px 0px 40px;
        font-size: 16px;
        display: flex;
        flex: 1;
        flex-direction: column;
        box-sizing: border-box;
        border-radius: 12px;

        &.alternative {
          align-items: center;
          justify-content: center;
          padding-left: 0;

          .message-title {
            @include mikado;
            font-size: 30px;
            line-height: 40px;
            margin-bottom: 20px;
          }

          .description {
            text-align: center;
            margin-bottom: 40px;
          }
        }

        .sender-and-date {
          display: flex;
          justify-content: space-between;
          margin-bottom: 20px;

          .senderdate {
            font-weight: bold;
            font-size: 14px;
          }
        }

        .message-title {
          font-weight: bold;
          font-size: 22px;
          margin: 0 0 12px 0;
        }

        .description {
          font-size: 18px;
        }

        .right {
          display: flex;
          font-size: 14px;
          align-items: center;

          button {
            cursor: pointer;
            border: none !important;
            width: auto;
            height: auto;
            margin-left: 24px;
          }
        }
      }

      .img {
        width: 248px !important;
        height: 422px;
      }

      .accept-reading {
        display: flex;
        align-items: center;
        font-size: 14px;
        cursor: pointer;

        .box {
          box-sizing: border-box;
          display: flex;
          align-items: center;
          justify-content: center;
          min-width: 20px;
          height: 20px;
          border: solid 2px #000000;
          border-radius: 4px;
          font-size: 14px;
          margin-right: 10px;
        }

        &.box-active {
          pointer-events: none;

          .box {
            &:after {
              content: '';
              background-image: var(--asset--ui-check_small-png);
              width: 26px;
              height: 26px;
              position: absolute;
            }
          }
        }
      }
    }
  }

  perfect-scrollbar {
    height: auto;
    flex: 1;
  }

  perfect-scrollbar.scroll-indicator::after {
    height: 30px;
    background: linear-gradient(0deg, rgba(236, 255, 198, 1) 0%, rgba(236, 255, 198, 0.35) 100%);
  }

  .bottom-bar {
    bottom: 84px;
    width: 50%;
    left: unset;
    right: 130px;
  }

  .parameters {
    display: flex;
    justify-content: start;
    gap: 14px;
  }
}
