@import "src/styles/variables/core/variables";
@import "src/styles/mixins";

app-cl-rank-list {
  .ow-dialog {
    padding: 17px 40px 40px 40px;

    .frame {
      width: 100%;
      border-radius: 15px;
      padding: 28px 30px 28px 40px;
      height: 415px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
    }
  }

  .choose-wrapper input:checked ~ .checkmark:after {
    top: 7px;
  }

  .ow-radio-container {
    display: flex;
    align-items: center;

    &:not(:last-child) {
      margin-right: 20px;
    }

    .text-wrapper {
      @include mikado;
      font-weight: 900;
      font-size: 14px;
      text-transform: uppercase;
    }
  }

  .options {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .filters {
    display: flex;
    align-items: center;
    margin: 16px 0 16px 14px;
    box-sizing: border-box;
  }

  .navigate-container {
    @include mikado;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    position: absolute;
    top: 26px;
    right: 64px;

    .navigate-date {
      display: flex;

      .current {
        margin: 0 20px;
        font-size: 14px;
        font-weight: 900;
        text-transform: uppercase;
        width: 170px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      button {
        width: 30px;

        i {
          font-size: 22px;
        }
      }
    }
  }

  table {
    @include archivo-narrow;
    border-collapse: collapse;
    font-size: 14px;
    width: 100%;

    tbody {
      tr:nth-child(odd) {
        background-color: #F9F4E4;
      }

      td {
        font-weight: bold;
      }
    }

    tr {
      height: 38px;
    }

    td {
      cursor: pointer;
      position: relative;

      &.max-width {
        width: 100%;
      }

      &:first-child {
        padding-left: 15px;
      }

      &:last-child {
        padding-right: 15px;
      }
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    height: 100%;
  }
}


:host::ng-deep {
  perfect-scrollbar.scroll-indicator::after {
    height: 36px;
  }

  .ng-dropdown-wrapper {
    width: 400px;
    height: 36px;
    color: #000000;
    background: transparent;
    border: 2px solid #000000;
    border-radius: 15px;
    box-shadow: none !important;
    margin-right: 10px;
    display: flex !important;
    align-items: center !important;

    &.active {
      border-radius: 15px 15px 0 0;
      background-color: #E1F7FD;
    }

    input {
      @include roboto-cond;
      font-weight: bold;
      font-size: 16px;
      color: #000000;
    }

    .ng-dropdown-menu {
      max-height: 350px;
      border: 2px solid #000000;
      border-top: none;
      top: 114% !important;
      left: -2px !important;
      right: -2px !important;

      li {
        border-top: 1px dashed #0000003d;
      }
    }

    .ng-dropdown-menu > * {
      background-color: #E1F7FD;
    }
  }

  .default-field {
    @include roboto-cond;
    font-weight: bold;
    margin: 0 auto;

    input {
      font-weight: normal;

      &:focus + label {
        background-color: #C6F4FF;
      }
    }
  }
}
