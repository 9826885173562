@import "src/styles/variables/core/variables";
@import "src/styles/mixins";

rank-buttons {
  display: inherit;

  .my-position-link {
    font-size: 16px;
    @include mikado;
    text-transform: unset;
  }
}
