@import "src/styles/variables/core/variables";
@import "src/styles/mixins";

$font-color: #000000;

//  TABLE CONDITIONS
//  ***
.conditions {
  border-collapse: collapse;

  tr:nth-child(odd) {
    background-color: $color-I;
  }

  tr {
    height: 40px;

    td {
      border-top: 1px solid #588500;
      box-sizing: border-box;
      font-size: 20px;
      font-weight: bold;
      color: $font-color;

      //  Condition check icon
      &.td-check {
        width: 20px;
        padding: 0 30px 0 15px;
      }

      //  Condition name
      &.td-name {
        @include roboto;
        width: 80%;
        padding-top: 1px;
        padding-right: 10px;
        box-sizing: border-box;
        font-size: 14px;
        font-weight: 600;
      }

      //  Condition target
      &.td-condition {
        width: 10%;
        text-align: right;
        white-space: nowrap;
        font-size: 15px;
        padding: 0 15px;
      }

      //  Condition button hand-over
      &.td-transfer {
        width: 10%;

        button {
          &.hand-over {
            text-decoration: underline;
            font-size: 14px;
            font-weight: 900;
            color: $font-color;
          }
        }
      }
    }
  }
}
