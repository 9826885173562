product {
  @import "../../shop-assets";

  & {
    button {
      &.c-base {
        width: 186px !important;
        height: 46px !important;
        font-size: 14px !important;
      }

      &.c-primary.c-square.small {
        padding-bottom: 4px !important;
      }
    }

    width: 100%;
    @include roboto-cond;
    color: #000000;

    .shop-container {
      padding-bottom: 50px;
    }

    .product-image {
      width: 100%;
      height: 0;
      padding-bottom: 100%;
      position: relative;

      img {
        &.product-img {
          width: 100%;
        }

        &.sparkle {
          width: 36px;
          height: 30px;
          position: absolute;
          top: 0;
          right: 0;
        }
      }
    }

    .product-name, .product-description, .product-delivery-time, .product-price-cash {
      padding: 0 20px;
      box-sizing: border-box;
      margin: 0;
    }

    .product-name {
      margin: 0;
      font-size: 18px;
      font-weight: bold;
    }

    .product-description {
      font-size: 14px;
      padding-top: 11px;
      box-sizing: border-box;
      min-height: 220px;

      p {
        margin: 0;
        padding: 0;
      }
    }

    .product-delivery-time, .product-price-cash {
      font-size: 16px;
      padding-top: 22px;
      padding-bottom: 22px;
    }

    .product-price-cash {
      background: #E5F2FF;
      margin-bottom: 18px;

      .card-number {
        float: right;
        display: inline-block;
        margin-top: -9px;

        input {
          margin-left: 8px;
        }
      }

      @media screen and (max-width: 1300px) {
        .card-number {
          float: none;
          display: block;
          margin-top: 10px;

          input {
            width: 100%;
            margin-top: 10px;
            margin-left: 0;
          }
        }
      }
    }

    .sparkle-description {
      display: inline-block;
      font-size: 11px;
      padding: 5px;
    }

    .buttons {
      display: flex;
      justify-content: flex-end;

      .flex-end {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        margin-left: 40px;
      }

      button {
        margin: 0 4px;
      }
    }

    button {
      &.back {
        @include mplus1c-rounded;
        font-weight: 900;
        display: flex;
        align-items: baseline;
        padding-top: 12px;

        i {
          margin-right: 10px;
          text-decoration: none !important;
        }

        span {
          text-decoration: underline;
        }
      }
    }
  }
}
