@import "src/styles/mixins";

app-building-row-production {
  width: 100%;

  .recipe {
    display: flex;
    position: relative;
    box-sizing: border-box;
    width: 100%;
    height: 76px;

    &.blur > *:not(m-ui-multi-stock) {
      filter: blur(4px);
    }
  }

  .production-container {
    display: flex;
    background-color: #C2BBA4;
    position: absolute;
    z-index: -1;

    .arrow-tip {
      width: 28px;
      height: 28px;
      @include absolute-center-vertical;
      left: 118px;

      &:after {
        background-color: #F2EDDF;
        height: 30px;
        content: "";
        display: block;
        box-sizing: border-box;
        position: absolute;
        z-index: -1;
        top: -1px;
        left: -40px;
        transform: translateX(100%);
        width: 50px;
        clip-path: polygon(50% 0, 80% 50%, 50% 100%, 0% 100%, 30% 50%, 0% 0%);
      }
    }

    &.arrow {
      position: absolute;
      z-index: 0;
      height: 28px;
      transform: translateY(100%);
      left: 0;
      top: -4px;
      right: 28px;

      &:after {
        background-color: #C2BBA4;
        clip-path: polygon(42% 0%, 100% 50%, 42% 100%, 0% 100%, 0% 0%);
        width: 24px;
        height: 28px;
        content: "";
        display: block;
        box-sizing: border-box;
        position: absolute;
        z-index: -1;
        top: 0;
        right: 6px;
        transform: translateX(100%);
      }
    }
  }

  .ingredient {
    margin-top: 12px;
    margin-right: 60px;
    position: relative;

    &:before {
      content: "+";
      position: absolute;
      font-size: 20px;
      right: -26px;
      @include mikado();
      color: $color-H;
      margin-top: 14px;
    }

    &:last-child {
      margin-right: 0;

      &:before {
        content: none;
      }
    }
  }

  .stock-layout-c {
    justify-content: flex-start !important;
    width: 170px;
    flex-direction: unset !important;

    i {
      &.icon {
        width: 76px !important;
        height: 76px !important;
      }
    }

    .value {
      @include mikado();
      font-size: 20px !important;
      padding-top: 4px !important;
      margin-left: 10px;
    }
  }

  .stock-b {
    flex-direction: row-reverse !important;
    width: 104px;

    i {
      &.icon {
        width: 76px !important;
        height: 76px !important;
      }
    }

    span {
      @include mikado();
      font-size: 20px !important;
    }
  }

  .stock-a {
    i {
      &.icon {
        width: 50px !important;
        height: 50px !important;
      }
    }

    span {
      @include mikado();
      font-size: 14px !important;
    }
  }

  m-ui-multi-stock,
  m-ui-product,
  m-ui-currency {
    z-index: 1;
  }
}


