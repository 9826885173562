event-shop-list {
  @import "src/styles/mixins";
  @import "src/styles/variables/core/variables";

  .ow-dialog {
    padding: 42px 83px 60px 96px;
    display: flex;
    flex-direction: column;

    .title {
      display: flex;
      align-items: center;
      justify-content: center;
      color: #9C9583;
      margin-bottom: 13px;

      .title-separator {
        height: 1px;
        background-color: #9C9583;
        width: 405px;
      }

      span {
        letter-spacing: 0;
        @include archivo-narrow;
        font-size: 14px;
        padding: 0 10px;
      }
    }
  }

  .event-shops-container {
    display: flex;
    flex-wrap: wrap;

    .event-shop-container {
      @import "../styles";
      @include mikado;
      display: flex;
      flex-direction: column;
      border: 2px solid black;
      font-size: 18px;
      font-weight: bold;
      width: 376px;
      height: 172px;
      box-sizing: border-box;
      transition: border 0.3s;
      overflow: hidden;
      margin: 6px 7px;
      padding: 4px;
      background-color: $default-background;

      &:hover {
        cursor: pointer;
        --fill-color: #ECFFC6;
      }

      &.deactivated {
        opacity: 0.5;
        order: 2;
      }

      &.cursor-none {
        cursor: auto;
      }

      .shop-title {
        @include mikado;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        width: 100%;
        height: 30px;
        letter-spacing: 0;
        background-color: $yellow;
        padding-top: 4px;
        box-sizing: border-box;
      }

      .shop-details {
        background-color: #F9F4E4;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1;
        transition: background .3s;
        overflow: hidden;
        border-top: none;

        .shop-currency {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 114px;

          .icon {
            width: 76px;
            height: 76px;
          }
        }

        .shop-info {
          display: flex;
          flex: 1;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          border-left: 2px solid #EBE5D5;
          height: 92px;

          .item {
            display: flex;
            align-items: center;
            margin: 4px 0;
            position: relative;

            .icon {
              width: 30px;
              height: 30px;
              margin-right: 4px;
            }

            .not-repeatable {
              position: absolute;
              left: -15px;
            }
          }
        }
      }
    }
  }
}
