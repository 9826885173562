@import "src/styles/variables/core/variables";
@import "src/styles/mixins";
@import "../../styles/style";

app-message-details-reward-board-selection {
  .ow-dialog {
    padding: 50px 114px 50px 114px;
    display: flex;
    flex-direction: column;
    @include archivo-narrow;

    .title {
      margin-bottom: 26px;
      font-size: 24px;
      font-weight: 900;
    }

    .role-select {
      width: 244px;
      margin-top: 10px
    }

    .player-select {
      display: flex;
      flex-direction: column;
      align-content: center;
      margin-top: 40px;
      font-weight: bold;
    }

    .resource-from-string-icon {
      margin: 1px 0;

      &.small {
        height: 26px;
        width: 26px;
      }
    }

    .description {
      line-height: 22px;
    }

    .img-box .mdc-tooltip.resource-from-string {
      top: -42px;
    }

    .disable-txt {
      position: absolute;
      top: -28px;
      color: red;
    }

    .frame {
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: space-between;
      border-radius: 15px;

      .frame-white {
        @include archivo-narrow;
        padding: 6px 0 0 40px;
        font-size: 16px;
        display: flex;
        flex: 1;
        flex-direction: column;
        box-sizing: border-box;
        border-radius: 12px;

        .sender-and-date {
          display: flex;
          justify-content: space-between;
          margin-bottom: 20px;

          .senderdate {
            font-weight: bold;
            font-size: 14px;
          }
        }

        .message-title {
          font-weight: bold;
          font-size: 22px;
          margin: 0 0 12px 0;
        }

        .description {
          font-size: 18px;
        }

        .right {
          display: flex;
          font-size: 14px;
          align-items: center;

          button {
            cursor: pointer;
            border: none !important;
            width: auto;
            height: auto;
            margin-left: 24px;
          }
        }
      }

      .img {
        width: 248px !important;
        height: 422px;
      }
    }
  }

  .icon-desc {
    display: flex;
    align-items: center;
    margin: 4px 0 !important;

    img {
      margin: 0 4px !important;
    }
  }

  .bottom-bar {
    height: 50px !important;
    bottom: 50px !important;
  }
}
