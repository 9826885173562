@import "src/styles/mixins";
@import "src/styles/variables/core/variables";

app-business-preview-dashboard {

  .next-button,
  .prev-button {
    @include absolute-center-vertical;
    width: 76px;
    height: 66px;
    background: var(--asset--ui-chevron_big-svg);
    cursor: pointer;
    z-index: 20;
    border-left: 3px solid black;

    &.hide {
      visibility: hidden;
    }
  }

  .branch-name {
    @include absolute-center-horizontal;
    min-width: 250px;
    padding: 0 10px;
    height: 31px;
    border: 3px solid #000;
    border-top: 1px solid #000;
    background: #FFD500;
    bottom: -3px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
  }

  .next-button {
    right: -26px;
  }

  .prev-button {
    left: -26px;
    transform: scaleX(-1);
    -webkit-transform: scaleX(-1);
    top: 45%;
  }

  @mixin display-center {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .business-buttons {
    position: absolute;
    bottom: 37px;
    display: flex;
    gap: 20px;
    right: 84px;
  }

  .business-calendar-button {
    width: 50px;
    height: 54px;
    background: var(--asset--ui-calendar-png) center no-repeat;
    background-size: contain;
    position: absolute;
    bottom: 46px;
    right: -2px;
    cursor: pointer;
  }

  perfect-scrollbar {
    max-width: 100% !important;

    .ps-content {
      padding-right: 30px;
    }
  }

  .fa-clipboard-check {
    font-size: 18px !important;
  }

  m-ui-currency, m-ui-product {
    margin: 0 5px;
  }

  .card-prizes > :first-child {
    margin-left: 0;
  }

  .stock-h {
    flex-direction: row-reverse !important;

    .value {
      @include archivo-narrow();
      font-size: 14px !important;
      text-shadow: none !important;
      color: #000000;
      margin-left: 0 !important;
    }

    .icon {
      width: 20px !important;
      height: 20px !important;
      margin-right: 0 !important;
      margin-left: 2px;
    }
  }

  .c-window-long {
    @include archivo-narrow();
    @include display-center();
    padding: 50px 70px;
    flex-direction: column;

    .top-menu {
      width: 100%;
      flex: 1;
      display: flex;
      align-items: flex-start;
      justify-content: center;

      .option {
        display: flex;
        align-items: center;
        position: relative;

        &:not(:last-child) {
          margin-right: 14px;
        }

        .icon-league {
          width: 40px;
          height: 40px;
          box-sizing: border-box;
        }

        .circle {
          width: 40px;
          height: 40px;
          border-radius: 30px;
          box-sizing: border-box;
          @include display-center;
          background-color: #F9F4E4;
          border: 2px solid #000000;
          @include mikado();
          font-size: 16px;
          font-weight: bold;
        }

        button {
          i {
            text-shadow: none !important;
            font-size: 16px;
            color: #000000;
          }
        }

        span {
          font-size: 14px;
          font-weight: bold;
          margin-left: 4px;

          &.no-wrap {
            white-space: nowrap;
          }
        }
      }
    }

    .main {
      width: 100%;
      height: 418px;
      box-sizing: border-box;
    }

    .navigation {
      @include display-center();
      align-items: flex-start !important;
      width: 100%;
      margin-top: 12px;

      .nav-arrow {
        text-shadow: none !important;
        color: #000000;
        font-size: 20px;
      }

      .value {
        font-size: 16px;
        font-weight: bold;
        margin: 0 20px;
      }
    }

    .table {
      @include display-center();
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      flex-direction: column;

      &.is-dor {
        width: 90%;
        margin: 0 auto;

        tr {

        }
      }

      .header,
      .body {
        @include display-center();
        width: 100%;
        box-sizing: border-box;
        flex-direction: column;

        .tr {
          display: flex;
          align-items: baseline;
          justify-content: space-between;
          width: 100%;
          box-sizing: border-box;

          .td:first-child {
            width: 146px;
          }

          .td:last-child {
            width: 76px;
          }

          .td:nth-child(2),
          .td:nth-child(3),
          .td:nth-child(4) {
            width: 304px;
          }
        }
      }

      .header {
        padding-bottom: 10px;

        .tr {
          .td {
            @include display-center();
            flex-direction: column;

            .header-title {
              letter-spacing: 0;
              font-size: 12px;
              font-weight: bold;
            }

            .header-prizes {
              display: flex;
              align-items: center;
              justify-content: center;
              margin-top: 5px;

              m-ui-currency,
              m-ui-product {
                &:not(:last-child) {
                  margin-right: 2px;
                }
              }

              .stock-h {
                .icon {
                  margin-left: 0;
                }
              }
            }
          }
        }
      }

      .body {
        .tr {
          height: 124px;
          display: flex;
          align-items: flex-start;
          position: relative;

          &.expanded {
            height: auto !important;
            padding-bottom: 16px;

            .cards {
              .card {
                &.tasks,
                &.ranks {
                  height: 80px !important;
                }
              }
            }
          }

          .separator {
            position: absolute;
            margin: 0;
            bottom: -12px;
            width: 100%;
            min-height: multiply-size(1px);
            display: flex;
            justify-content: center;
            overflow: hidden;

            span {
              position: relative;
              text-align: center;
              color: #878273;

              i {
                font-size: 14px;
                cursor: pointer;
              }

              &.padding {
                padding: 0 16px;
              }

              &:before, &:after {
                content: '';
                position: absolute;
                width: 1000px;
                height: 2px;
                background: #959185;
                display: block;
                @include absolute-center-vertical;
              }

              &:before {
                right: 100%;
              }

              &:after {
                left: 100%;
              }
            }
          }

          &:not(:last-child) {
            margin-bottom: 20px;
          }

          &:nth-child(odd) {
            .td > div {
              background-color: #F9F4E4;
            }
          }

          &:nth-child(even) {
            .td > div {
              background-color: #DED8C6;
            }
          }

          .td {
            height: 100%;

            & > div {
              border: 2px solid #959185;
              border-radius: 4px;
            }

            &:first-child {
              display: flex;
              align-items: flex-start;

              div {
                @include display-center();
                @include mikado();
                height: 110px;
                width: 150px;
                box-sizing: border-box;
                text-align: center;
                padding: 0 6px;
                font-size: 16px;
              }
            }

            &:last-child {
              div {
                @include display-center();
                height: 110px;
                width: 80px;
                text-align: center;
                font-size: 12px;
                font-weight: bold;
                box-sizing: border-box;
              }
            }

            &.cards {
              display: flex;
              flex-direction: column !important;
              align-items: center;
              justify-content: flex-start;
              box-sizing: border-box;
              position: relative;


              .animal-icon {
                position: absolute;
                display: none;
                width: 30px;
                height: 30px;
                background-position: center;
                top: -10px;
                right: -10px;
                z-index: 1000;
              }

              .card {
                width: 100%;
                height: 52px;
                box-sizing: border-box;
                font-size: 14px;
                letter-spacing: 0;
                font-weight: bold;
                padding: 4px 18px 4px 10px;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: space-between;
                position: relative;

                &:not(:last-child) {
                  margin-bottom: 6px;
                }

                .card-prizes {
                  @include display-center();
                  justify-content: flex-start !important;
                }

                .tab {
                  width: 12px;
                  height: 100%;
                  position: absolute;
                  top: 0;
                  right: 0;
                  border-radius: 0 2px 2px 0;
                }
              }
            }
          }
        }
      }
    }
  }

  .no-item {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: bold;
    margin-top: 30px;
  }

  .under-construction {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    font-weight: bold;
    margin-top: 40px;
    line-height: 24px;
    text-align: center;
    @include mikado;
    flex-direction: column;

    img {
      margin-bottom: 20px;
    }
  }

  .progressbar-container {
    width: 100%;
    display: flex;
    align-items: center;

    .progressbar {
      height: 14px;
      width: 250px;
      border-radius: 6px;
      border: 2px solid #878273;
      background-color: #DED8C6;
      padding: 0 !important;
      box-sizing: border-box;

      .fill {
        border-radius: 2px 0 0 2px;
        border-right: 2px solid #878273;
      }
    }

    .progressbar-value {
      @include archivo-narrow();
      font-size: 14px;
      margin: 0 6px;
    }
  }

  .tab, .fill {
    &.red {
      background-color: #F12424;
    }

    &.green {
      background-color: #01E436;
    }
  }

  .cursor {
    cursor: pointer;
  }

  .pointer-events {
    pointer-events: none;
  }
}
