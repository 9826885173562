@import "bookmark";

.ow-dialog {
  //  EXIT BAR
  //  ****
  .exit-bar {
    width: 40px;
    height: 46px;

    .exit {
      border: none;
    }
  }

  //  BACK BAR
  //  ****
  .back-bar {
    padding: 0;

    button {
      background: var(--asset--ui-back-svg);
      width: 30px;
      height: 40px;

      &:hover {
        filter: var(--filter-hover);
      }
    }

    i {
      display: none;
    }
  }
}

.ow-dialog {
  &.c-window-short {
    //  EXIT BAR
    //  ****
    .exit-bar {
      top: 32px;
      right: 22px;
    }

    //  BACK BAR
    //  ****
    .back-bar {
      left: 30px;
      top: 74px;
    }
  }

  //  NAVIGATION BAR
  //  ****
  .navigation-bar {
    &.prev {
      left: -14px;
    }

    &.next {
      right: -14px;
    }
  }
}

.ow-dialog {
  &.c-window-long, &.c-window-long-wf {
    //  EXIT BAR
    //  ****
    .exit-bar {
      top: 40px;
      right: 10px;
    }

    //  BACK BAR
    //  ****
    .back-bar {
      left: 20px;
      top: 88px;
    }
  }
}

.global-dialog {
  user-select: text;

  .exit-bar {
    position: absolute;
  }
}

.cdk-overlay-container {
  .cdk-overlay-backdrop {
    background: rgba(0, 0, 0, 0.6) !important;
  }
}
