$yellow: #FFD916;
$gray: #A8A9A8;
$orange-light: #FAAB7B;
$blue-light: #00C2FF;
$red: #FE432F;
$green: #56CF42;
$orange: #FFBE0B;
$green-light: #67E589;
$blue-pearl: #4BC4C4;
$purple-strong: #C462CC;

$custom-dialog-outline: #E13636;
$color-K: #000000;

html {
  --filter-hover: drop-shadow(0px 0px 2px rgba(255, 255, 255, 0.8));
}

$color-X: #EBE5D5;
