@import "src/styles/variables/core/variables";
@import "src/styles/mixins";

wf-media {
  @include archivo-narrow;

  ng-select {
    width: 200px;
  }

  .main-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    &.media {
      .media-row {
        height: 124px !important;

        .left {
          margin-right: 20px !important;

          .icon {
            height: 104px !important;
            width: 104px !important;
            border: 2px solid #000000;
            box-sizing: border-box;
          }
        }

        .right {
          position: relative;
          height: 100%;
          box-sizing: border-box;

          .heading {
            padding-top: 36px;

            .title {
              @include mikado();
              font-size: 20px !important;
              margin-bottom: 0;
            }

            .description {
              font-size: 16px !important;
            }
          }

          .terms {
            text-align: left !important;
            margin-left: 0 !important;
            white-space: nowrap;
            font-size: 16px !important;
            position: absolute;
            top: 8px;
            left: 0;
            display: flex;
            align-items: center;
          }
        }
      }
    }
  }

  .type-select {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
  }

  .media-list {
    height: 431px;

    .media-row {
      height: 80px;
      display: flex;
      box-sizing: border-box;
      padding: 0 18px 0 16px;
      align-items: center;

      &:nth-child(odd) {
        background: #F9F4E4;
      }

      .left {
        margin-right: 18px;

        .icon {
          height: 60px;
          width: 60px;
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
        }
      }

      .right {
        flex: 1 0 0;
        display: flex;

        .heading {
          flex: 1;

          .title {
            font-weight: bold;
            font-size: 16px;
            text-align: left;
            margin-bottom: 3px;
            width: auto;

            a {
              color: #000000;
              text-decoration: none;
              text-underline: none;
              display: inline;
            }
          }

          .description {
            font-size: 14px;
            line-height: 1.4;
          }
        }

        .terms {
          text-align: right;
          margin-left: 40px;
          white-space: nowrap;
          line-height: 1.3;
          font-weight: bold;
          font-size: 14px;
        }
      }
    }
  }

  .ng-dropdown-wrapper {
    width: 260px;
    padding: 4px 8px;
  }

  .pagination {
    display: flex;
    align-items: center;
    justify-content: center;

    button {
      display: inline-block;
      padding: 3px 5px;
      cursor: pointer;
      font-size: 18px;
      margin: 0 20px;
    }

    .value {
      @include archivo-narrow;
      font-weight: 900;
      font-size: 16px;
    }
  }

  .no-item {
    @include archivo-narrow();
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: bold;
  }
}
