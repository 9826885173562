@import "src/styles/mixins";

app-quality-tasks {
  .branch-name {
    @include absolute-center-horizontal;
    @include archivo-narrow();
    font-size: 16px;
    min-width: 250px;
    padding: 0 10px;
    height: 31px;
    border: 3px solid #000;
    border-top: 1px solid #000;
    background: #FFD500;
    bottom: -3px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
  }

  .quality-task-container {
    padding: 42px 104px 112px 108px;
    display: flex;
    flex-flow: column;

    .title {
      margin-bottom: 20px;
    }

    .filters {
      display: flex;
      align-items: center;
      margin: 28px 0 26px 20px;
      box-sizing: border-box;
    }

    .ow-radio-container {
      display: flex;
      align-items: center;

      &:not(:last-child) {
        margin-right: 16px;
      }

      .text-wrapper {
        @include mikado;
        font-weight: 900;
        font-size: 14px;
      }
    }

    .frame {
      flex: 1 0 auto;

      table {
        width: 100%;
        border-spacing: 2px;
        border-collapse: collapse;

        thead {
          th {
            font-size: 12px;
            @include archivo-narrow();
            text-align: center;
            padding: 0 5px 9px 5px;
            letter-spacing: 0.24px;
            font-weight: 400;

            &:first-child {
              text-align: left;
              padding-left: 22px;
            }
          }
        }

        tbody {
          tr {
            height: 38px;

            &.row-sums {
              td {
                padding-top: 18px;
                @include mikado;
                font-weight: 900;
              }
            }

            &:first-child {
              border-top: none;
            }

            &:nth-child(odd) {
              background: #F9F4E4;
            }

            td {
              font-size: 14px;
              font-weight: bold;
              @include archivo-narrow();
              text-align: center;

              button {
                display: initial;
                width: 100%;

                i {
                  font-size: 16px;
                }
              }

              &:not(:first-child) {
                width: 7%;
              }

              &:first-child {
                text-align: left;
                padding-left: 16px;
              }

              &:last-child {
                width: 4%;
              }

              &.sum {
                white-space: nowrap;
              }
            }
          }
        }
      }
    }

    .navigate-container {
      position: absolute;
      z-index: 1;
      top: 66px;
      right: 104px;
      display: flex;
      align-items: center;
    }

    .pagination {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      button {
        display: inline-block;
        padding: 3px 5px;
        cursor: pointer;
        font-size: 20px;
      }

      .value {
        @include archivo-narrow();
        font-weight: 900;
        font-size: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 120px;

        &.quarter {
          width: 100px !important;
        }

        .month {
          position: relative;
        }

        .date {
          position: relative;
          margin-left: 20px;

          &:before {
            content: '';
            position: absolute;
            left: -11px;
            height: 80%;
            background: #000000;
            display: block;
            top: 0;
            width: 2px;
            border-radius: 10px;
            @include absolute-center-vertical;
          }
        }
      }
    }
  }

  .no-data {
    font-size: 16px;
    margin-top: 30px;
    font-weight: 700;
    @include archivo-narrow();
  }
}
