@import "src/styles/functions";
@import "variables/core/variables";


perfect-scrollbar {
  & > .ps {
    max-height: inherit !important;
    max-width: inherit !important;
    width: inherit !important;
    height: inherit !important;
  }

  &.scroll-indicator {
    &:after {
      position: absolute;
      width: multiply-size(12px, '100% - ');
      bottom: 0;
      left: 0;
      height: multiply-size(22px);
      content: ' ';
      z-index: 2;
      pointer-events: none;
      background: rgb(255, 255, 255);
      background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0.35476197314863445) 100%);
    }
  }

  max-width: initial !important;
  width: multiply-size(12px, '100% + ') !important;

  .ps-content {
    padding-right: multiply-size(24px);
  }

  .ps__rail-y, .ps__thumb-y {
    width: multiply-size(1px) !important;
    right: 0 !important;
    border-radius: 0 !important;
  }

  .ps__rail-y {
    background: rgba($color-base, 0.5) !important;
  }

  .ps__thumb-y {
    background-color: $color-base !important;
    cursor: pointer;
  }
}

@import "custom/scrollbars";
