@import "src/styles/mixins";
@import "src/styles/variables/core/variables";
@import "../../../../styles-csp/custom/mixins.scss";
//@import "../../../../../employees/styles-csp/custom/mixins.scss";

app-tracking-begin {
  @import "../../../../../buildings/base/custom/dialogs/styles/style";

  * > div {
    box-sizing: border-box;
  }

  .ow-dialog {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 50px 105px 56px;

    .buttons {
      position: unset;
      transform: unset;
      left: unset;
      bottom: unset;
    }

    .bottom {
      margin: 0 0 10px;

      .texts-container {
        margin-top: 10px;
      }
    }
  }

  /* top section */
  .details {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    margin: 0 0 20px;
    padding: 0;
    height: 200px;

    .picture {
      display: flex;
      align-items: center;
      position: relative;
      width: 200px;
      height: 200px;
      box-sizing: border-box;

      img {
        width: 100%;
        object-fit: contain;
      }
    }

    .information {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-left: 20px;

      .name {
        font-size: 18px;
        line-height: 24px;
        margin-bottom: 16px;
        @include mikado;
      }

      .tracking-details {
        display: flex;
      }
    }
  }

  .materials-box {
    @include material-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;

    &:not(:first-child) {
      padding-left: 20px;
    }

    &:not(:last-child) {
      &:after {
        content: "";
        width: 1px;
        height: 100%;
        background-color: #9c9583;
        right: 0;
        display: block;
        position: absolute;
        bottom: 0;
      }
    }

    .wrapper {
      gap: 14px;
    }
  }

  .item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    img {
      width: 50px;
      height: 50px;
      object-fit: contain;
    }

    span {
      @include mikado();
      font-weight: bold;
      font-size: 14px;
      margin-top: 4px;
    }
  }
}
