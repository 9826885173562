app-prizes-alert {
  .ow-dialog {
    max-width: 840px;
    background: #EBE5D5;
    border: 2px solid #000000;
    min-width: 400px;
    padding: 25px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &:after {
      content: '';
      position: absolute;
      top: -10px;
      left: -10px;
      bottom: -10px;
      right: -10px;
      background: $custom-dialog-outline;
      border: 2px solid #000000;
      z-index: -1;
    }

    perfect-scrollbar {
      max-height: 500px;
    }

    m-ui-close-button {
      position: absolute;
      top: 5px;
      right: -42px;
    }

    p {
      &.title {
        margin: 0 0 10px 0;
        font-size: 22px;
        @include mikado;
        font-weight: bold;
        text-align: center;
        text-transform: uppercase;
      }

      &.description {
        @include archivo-narrow;
        margin: 0;
        text-align: center;
        font-size: 20px;
        max-height: 220px;

        ul, ol {
          padding-left: 0;
          margin-left: 1em;
        }
      }
    }

    button {
      &.c-base {
        margin-top: 20px;
      }
    }
  }

  .stock-layout-b {
    flex-direction: row-reverse;

    .icon {
      margin-left: 8px !important;
      margin-right: 0 !important;
    }
  }
}
