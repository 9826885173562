app-custom-login {
  @import "../../../styles/custom/style.scss";

  .content {
    height: 100%;
    box-sizing: border-box;

    form {
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
    }
  }

  .content,
  .main-content,
  .bottom-links {
    width: 100%;
  }

  .padding {
    padding: 10px;
  }
}
