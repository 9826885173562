@import "src/styles/variables/core/variables";
@import "src/styles/mixins";

app-message-details-info-problem {
  .ow-dialog {
    padding: 28px 114px 35px 114px;
    display: flex;
    flex-direction: column;
    @include archivo-narrow;

    .experience {
      position: absolute;
      width: 71px;
      height: 40px;
      top: -16px;
      right: 134px;
      margin: auto;

      .inner-wrapper {
        position: relative;

        img {
          width: 40px;
          height: 40px;
          left: 70px;
          object-fit: cover;
          position: absolute;
          z-index: 2;
        }

        p {
          text-align: right;
          position: absolute;
          right: -20px;
          top: 6px;
          box-sizing: border-box;

          //width: 71px;
          height: 27px;
          font-size: 16px;
          line-height: 21px;
          margin: 0;
          padding: 3px 25px 3px 8px;
          border: 3px solid black;
          color: black;
          z-index: 1;
          background-color: #eae4d4;
          @include mikado;
        }
      }
    }

    .animal-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100%;

      .animal-title {
        @include mikado;
        font-size: 22px;
        line-height: 30px;
        margin: 0 0 12px;
        text-align: center;
      }

      .animal-image {
        display: flex;
        justify-content: center;
        height: 264px;
        margin-bottom: 12px;

        img {
          max-width: 100%;
          object-fit: contain;
        }
      }

      .teaser {
        @include mikado;
        font-size: 18px;
        line-height: 24px;
        font-weight: bold;
        margin-bottom: 2px;
      }

      .inner-html-description {
        color: black;
        @include archivo-narrow;
        font-weight: normal;
        font-size: 16px;
        line-height: 18px;
        text-align: center;
        max-width: 700px;
      }

      .parameters {
        display: flex;
        justify-content: center;
        margin-top: 20px;

        m-ui-product, m-ui-currency {
          margin: 0 10px;
        }
      }
    }
  }
}
