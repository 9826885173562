@import "src/styles/variables/core/variables";
@import "src/styles/mixins";

app-ranking-details-game {
  @import "../styles/style";

  .ow-dialog {
    padding: 52px 100px;
    position: relative;
  }

  .c-window-long-wf {
    .bookmarks {
      left: 128px;
      top: -12px;
      width: 86%;
    }

    .top {
      .left {
        width: 82%;
        padding-right: 150px;
      }
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    overflow: hidden;

    .frame {
      padding-right: 24px;
      width: 100%;
      height: 395px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;

      &.sparkle-frame {
        height: 354px;
      }
    }
  }

  .top {
    display: flex;
    margin-bottom: 16px;
    padding-left: 10px;

    .left {
      width: 78%;

      p {
        margin: 0;
        font-size: 16px;
        max-height: 24px;
        @include archivo-narrow;

        &.sub-title {
          font-weight: 900;
          margin-bottom: 10px;
        }

        &.description {
          @include roboto-cond;
          font-weight: 400;
        }
      }
    }

    .right {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;

      .default-field {
        @include mikado;
        margin: 0;

        input {
          text-align: center;
          font-weight: normal;
          font-size: 15px;
          width: 210px;
          height: 36px;

          &:focus + label {
            background-color: #C6F4FF;
          }
        }
      }
    }
  }

  .box {
    display: flex;
    justify-content: space-between;
  }

  .filters {
    display: flex;
    align-items: center;
    margin: 16px 0 16px 14px;
    box-sizing: border-box;
  }

  .ow-radio-container {
    display: flex;
    align-items: center;

    &:not(:last-child) {
      margin-right: 20px;
    }

    .text-wrapper {
      @include mikado;
      font-weight: 900;
      font-size: 14px;
      text-transform: uppercase;
    }
  }

  .show-position {
    text-decoration: underline;
    font-size: 14px;
    font-weight: 900;
    display: flex;
    align-items: center;
    padding-right: 14px;

    a {
      color: #000000;
    }
  }

  table {
    @include archivo-narrow;
    border-collapse: collapse;
    font-size: 14px;
    width: 100%;

    &.custom {
      tbody {
        tr {
          &:nth-child(even) {
            background-color: #F9F4E4;
          }
        }
      }
    }

    tr {
      height: 34px;

      &.player-inactive {
        td:not(:first-of-type){
          opacity: 0.3;
          font-style: italic;
        }
      }
    }

    td {
      padding-left: 5px;
      padding-right: 5px;
      font-weight: bold;
      position: relative;
      white-space: nowrap;

      &.fw-normal {
        font-weight: normal !important;
      }

      .allow-change-player {
        &:hover {
          cursor: pointer;
          text-decoration: underline;
        }
      }

      &.rid-27_group-employee_pid-459 {
        opacity: 0;
        pointer-events: none;
        user-select: none;
      }

      .prizes {
        display: flex;
        margin-left: 4px;

        & > * {
          margin: 0 4px;
        }
      }

      .icon-prize {
        img {
          height: 21px;
        }
      }
    }
  }

  .navigate-info {
    @include mikado;
    position: absolute;
    font-weight: 900;
    font-size: 14px;
    top: -11px;
    right: 116px;
    background: #EBE5D5;
    padding: 6px 20px;
    border: solid 3px #000000;
    text-transform: capitalize;
    text-decoration: underline;
  }

  perfect-scrollbar {
    //.ps__rail-y {
    //  width: 3px !important;
    //  border: none !important;
    //}
    //
    //.ps__thumb-y{
    //  height: 50px !important;
    //  width: 3px !important;
    //  right: 0 !important;
    //}
  }

  .sparkle-bar {
    font-size: 16px;
    text-transform: uppercase;
    @include mikado;
    font-weight: bold;
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;

    .sparkle-ribbon {
      width: 38px;
      height: 22px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 2px solid #520000;
      border-radius: 16px 16px 0 0;
      box-shadow: inset 0 -4px 0px 0 #CE0124;
      padding-bottom: 4px;
      background-color: #FF4D6B;
      margin-left: 15px;

      img {
        width: 22px;
        height: 17px;
      }
    }
  }
}
