@import "src/styles/functions";

app-m-qa-summary {
  .score-and-prizes {
    display: flex;
    justify-content: center;
    align-items: center;

    & > * {
      margin: 0 multiply-size(10px);
    }
  }

  .container-prizes {
    display: flex;
    align-items: flex-start;
    justify-content: space-evenly;
    width: 100%;
    box-sizing: border-box;

    .box {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 50%;
      box-sizing: border-box;

      .prizes {
        display: flex;
        align-items: center;
        justify-content: center;

        m-ui-currency,
        m-ui-product {
          margin: 0 multiply-size(2px);
        }
      }
    }
  }

  .stock-b {
    .value {
      @include mikado('!important');
    }
  }

  span {
    text-shadow: none !important;
    color: #000000 !important;
    text-align: center;
    letter-spacing: 0;
    line-height: 12px;

    &.title {
      @include archivo-narrow();
      font-size: multiply-size(10px);
      margin: multiply-size(4px) 0 multiply-size(8px) 0;
      white-space: nowrap;
    }

    &.timer {
      @include mikado();
      font-size: multiply-size(12px);
      color: #FF0000 !important;
    }
  }

  .valid-time {
    margin-top: multiply-size(12px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .title {
      margin-bottom: multiply-size(2px);
    }
  }

  .prize-reduction {
    margin: multiply-size(12px);
  }
}
