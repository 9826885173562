@import "src/styles/mixins";

app-san-transaction-history {
  .ow-dialog {
    padding: 44px 114px 40px 114px;
    display: flex;
    flex-direction: column;
  }

  .history-transaction-container {
    display: flex;
    flex-flow: column;

    .frame {
      flex: 1 0 auto;

      table {
        width: 100%;
        border-spacing: 2px;
        border-collapse: collapse;

        tbody {
          tr {
            height: 40px;

            &:nth-child(odd) {
              background: #F9F4E4;
            }

            td {
              font-size: 14px;
              font-weight: bold;
              @include archivo-narrow();
              white-space: nowrap;

              &.date {
                padding-left: 14px;
              }

              &.name {
                width: 100%;
                padding-left: 60px;
                white-space: break-spaces;
                padding-right: 10px;
              }

              &.more {
                padding-right: 20px;

                button {
                  &.x-small {
                    width: 30px;
                    height: 30px;
                  }

                  i {
                    font-size: 20px;
                  }
                }
              }

              &.currency {
                @include archivo-narrow();
                font-weight: bold;
                padding-right: 14px;

                .flex-center {
                  justify-content: flex-end;

                  & > * {
                    margin-left: 5px;
                  }
                }
              }
            }
          }
        }
      }
    }

    .pagination {
      display: flex;
      align-items: center;
      justify-content: center;

      button {
        display: inline-block;
        padding: 3px 5px;
        cursor: pointer;
        font-size: 20px;
      }

      .value {
        @include archivo-narrow();
        font-weight: 900;
        font-size: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 70px;
        padding-bottom: 2px;

        .separator {
          margin: 0 5px;
        }
      }
    }
  }
}
