@import "src/styles/functions";
@import "src/styles/variables/core/variables";
@import "src/styles/mixins";

app-business-calendar {
  $microleague-color: #6699FF;
  $contract-color: #2BD690;
  $event-color: #FAA81E;
  $other-color: #FD4954;

  .branch-name {
    @include absolute-center-horizontal;
    @include archivo-narrow();
    font-size: 16px;
    min-width: 250px;
    padding: 0 10px;
    height: 31px;
    border: 3px solid #000;
    border-top: 1px solid #000;
    background: #FFD500;
    bottom: -3px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
  }

  .ow-dialog.c-window-long {
    padding: 62px 83px 50px 100px
  }

  m-ui-product {
    .stock-j {
      .icon {
        width: 20px;
        height: 20px;
      }
    }
  }

  m-ui-currency {
    .stock-j {
      .icon {
        width: 20px;
        height: 20px;
      }
    }
  }

  .main-container {
    height: 400px;
  }

  .previous {
    position: absolute;
    top: 85px;
    left: 265px;

    i {
      font-size: 20px;
    }
  }

  .next {
    position: absolute;
    top: 85px;
    right: 86px;

    i {
      font-size: 20px;
    }
  }

  .arrow-new {
    background: var(--asset--ui-business-calendar-new-arrow-png) no-repeat;
    background-size: contain;
    position: absolute;
    width: 79px;
    height: 35px;
    right: 113px;
    top: 77px;

    span {
      @include mikado();
      font-weight: 700;
      top: 9px;
      left: 7px;
      position: absolute;
      font-size: 14px;
    }
  }

  .calendar-wrapper {
    display: grid;
    grid-gap: 0;
    grid-template-columns: 182px repeat(7, 140px);
    grid-template-rows: repeat(5, [row] auto  );
    color: #000;
    @include archivo-narrow;
    font-size: 16px;

    .box {
      border-left: 1px solid #9C9583;

      &.header {
        height: 55px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        gap: 4px;
        grid-column: col 1;
        grid-row: row ;
        border-left: 0;
        color: #3E3E3E;
        padding-bottom: 20px;

        &.current {
          gap: 0;
          font-size: 20px;
          font-weight: 700;
          color: #000;
        }
      }
      .type-name {
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 700;
        border: 2px solid #000;
        border-radius: 10px;
        padding: 13px 0;
        flex: 1;
        margin: 5px 0;
      }

      &.current {
        background: #FFFBF1;
        border-left: 2px solid #000;
        border-right: 2px solid #000;
      }

      &.label {
        grid-column: 1;
        margin-right: 17px;
        border-left: 0
      }

      @for $i from 2 through 8 {
        &.grid-column-#{$i} {
          grid-column: $i;
        }
      }

      &.microleague {
        //display: none;

        &.visible {
          display: flex;
        }
        &.hidden {
          display: none !important;
        }
        grid-row: row 2;

        .type-name {
          background: $microleague-color;
        }
      }
      &.contract {
        //display: none;

        &.visible {
          display: flex;
        }
        &.hidden {
          display: none !important;
        }
        grid-row: row 3;

        .type-name {
          background: $contract-color;
        }
      }
      &.event {
        //display: none;

        &.visible {
          display: flex;
        }
        grid-row: row 4;

        .type-name {
          background: $event-color;
        }
      }
      &.other {
        //display: none;

        &.visible {
          display: flex;
        }
        grid-row: row 5;

        .type-name {
          background: $other-color;
        }
      }
    }

    .label + .box,
    .current + .box, {
      border-left: 0;
    }

    .events {
      grid-column: 2 / span 7;
      align-items: center;

      .item {
        display: flex;
        margin: 15px 0;
        height: 24px;
        border: 2px solid #000;
        font-weight: 700;
        padding: 0 10px 0 10px;
        align-items: center;
        gap: 12px;
        justify-content: space-between;
        position: relative;

        .info-tooltip {
          position: absolute;
          min-height: 40px;
          background: #fff;
          border: 2px solid #000;
          display: none;
          justify-content: center;
          align-items: center;
          padding: 0 20px;
          border-radius: 20px;
          max-width: 270px;
          width: max-content;
          gap: 12px;
          right: 0;
          bottom: 32px;
          z-index: 40;

          @for $i from 1 through 7 {
            &.to-#{$i} {
              max-width: calc(270px + (#{$i} - 1) * 140px);
            }
          }
        }

        .new-event {
          position: absolute;
          height: 22px;
          background: #E8FF00;
          @include mikado();
          border: 2px solid #000;
          font-weight: 700;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 56px;
          left: 10px;
          bottom: 15px;
          z-index: 39;
        }

        &.tooltip-on-bottom {
          .info-tooltip {
            bottom: initial;
            top: 32px;
          }
        }

        &:hover {
          .info-tooltip {
            display: flex;
          }
        }

        .label {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 100%;
        }

        .info {
          button {
            cursor: pointer;
          }
        }

        &.event-complete {
          border: 2px solid rgba(0, 0, 0, 0.3);

          .label {
            font-style: italic;
            opacity: 0.3;
          }

          .info {
            opacity: 0.3;
          }
        }

        @for $i from 2 through 7 {
          &.from-#{$i} {
            margin-left: 140px * ($i - 1);
          }
        }

        @for $i from 1 through 7 {
          &.to-#{$i} {
            margin-right: 140px * (7 - $i);
          }
        }

        &.event-start-visible {
          border-top-left-radius: 20px;
          border-bottom-left-radius: 20px;
        }
        &.event-end-visible {
          border-top-right-radius: 20px;
          border-bottom-right-radius: 20px;
        }

      }

      &.microleague {
        grid-row: row 2;

        .item {
          background: $microleague-color;

          &.event-complete {
            background-color: rgba($microleague-color, 0.3);
          }
        }
      }
      &.contract {
        grid-row: row 3;

        .item {
          background: $contract-color;

          &.event-complete {
            background-color: rgba($contract-color, 0.3);
          }
        }
      }
      &.event {
        grid-row: row 4;

        .item {
          background: $event-color;

          &.event-complete {
            background-color: rgba($event-color, 0.3);
          }
        }
      }
      &.other {
        grid-row: row 5;

        .item {
          background: $other-color;

          &.event-complete {
            background-color: rgba($other-color, 0.3);
          }
        }
      }
    }


  }
}
