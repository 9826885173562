@import "src/styles/variables/core/variables";
@import "src/styles/mixins";

app-message-details-chest-success {
  .ow-dialog {
    padding: 30px 114px 54px 114px;
    display: flex;
    align-items: center;
    justify-content: center;

    m-ui-close-button {
      .exit-bar {
        top: 36px !important;
        right: 10px !important;
      }
    }
  }

  .chest-success-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .message-image {
      display: flex;
      width: 572px;
      height: 264px;
      margin-bottom: 20px;
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;

      &.default {
        background: var(--asset--ilustration-generic_chest_full-png);
      }

      &.chest-blue {
        background: var(--asset--ilustration-generic_chest_blue_full-png);
      }
    }

    .teaser {
      @include mikado;
      font-size: 22px;
      line-height: 30px;
      font-weight: 900;
      margin-bottom: 12px;
    }

    .inner-html-description {
      color: #000000;
      margin: 0 0 20px;
      @include archivo-narrow;
      font-weight: normal;
      font-size: 16px;
      line-height: 18px;
      text-align: center;
      padding: 0 100px;
    }

    .parameters {
      display: flex;
      justify-content: center;
      gap: 14px;
    }
  }

  button {
    position: absolute;
    bottom: 12px;
    right: 12px;
    border: none !important;
  }

  .stock-production {
    flex-direction: unset !important;

    .icon {
      margin-right: 8px;
    }
  }

  .trash {
    position: absolute;
    bottom: 28px;
    right: 70px;
    border: none;

    i {
      font-size: 16px;
      position: unset !important;
    }
  }
}
