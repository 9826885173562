@import "src/styles/mixins";
@import "src/styles/variables/core/variables";

app-antyminer-start {
  * > div {
    box-sizing: border-box;
  }

  .ow-dialog {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    background-color: #D1A86B;
    border: 2px solid black;
    width: 508px;
    padding: 10px;
    height: 524px;

    .exit-bar {
      right: -30px;
      top: 30px;
    }
  }

  app-antyminer-start-requirements {
    width: 100%;
  }

  .details {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 2px solid black;
    background-color: #EBE5D5;
    padding: 30px 42px;
    width: 100%;
    height: 100%;

    h3 {
      @include mikado;
      font-size: 16px;
      line-height: 21px;
      margin: 0 0 44px;
    }

    .c-base {
      height: 40px;
      margin-top: auto;
    }
  }
}
