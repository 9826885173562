@import "src/styles/variables/core/variables";
@import "src/styles/mixins";

app-ranks {
  .branch-name {
    @include absolute-center-horizontal;
    @include archivo-narrow();
    font-size: 16px;
    min-width: 250px;
    padding: 0 10px;
    height: 31px;
    border: 3px solid #000;
    border-top: 1px solid #000;
    background: #FFD500;
    bottom: -3px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
  }

  .ow-dialog {
    padding: 44px 106px;

    .title {
      margin-bottom: 24px;
    }

    .frame {
      width: 100%;
      height: 475px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
    }

    .navigate-container {
      @include archivo-narrow;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: bold;
      position: absolute;
      top: 72px;
      right: 104px;
      z-index: 1;

      .navigate-date {
        display: flex;

        .current {
          font-size: 16px;
          font-weight: 900;
          width: 100px;
          text-align: center;
          cursor: default;
        }

        button {
          width: 20px;
          height: 20px;
          border: 1px solid #000000; // no-multiply-size
          i {
            font-size: 16px;
          }
        }
      }
    }
  }

  .box {
    display: flex;
    justify-content: space-between;
  }

  .show-rank {
    text-decoration: underline;
    font-size: 14px;
    font-weight: 900;
    display: flex;
    align-items: center;
    padding-right: 14px;

    a {
      color: #000000;
    }
  }

  .options {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .filters {
    display: flex;
    align-items: center;
    margin: 28px 0 36px 20px;
    box-sizing: border-box;
  }

  .report-container {
    display: flex;
    align-items: center;
    margin-right: 200px;
    margin-bottom: 12px;

    button {
      margin-left: 10px;

      i {
        font-size: 24px;
      }
    }

    .role-select {
      width: 300px;
    }
  }

  .ow-radio-container {
    display: flex;
    align-items: center;

    &:not(:last-child) {
      margin-right: 16px;
    }

    .text-wrapper {
      @include mikado;
      font-weight: 900;
      font-size: 14px;
    }
  }

  .insurance-competition-icon {
    width: 38px;
    height: 38px;
    box-sizing: border-box;
    @include absolute-center-vertical;
    right: 80px;
    display: none;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .insurance-competition-border {
    width: 9px;
    height: 38px;
    position: absolute;
    left: 0;
    top: 0;
    background: #37AF00;
    display: none;
  }

  .insurance-competition {
    .insurance-competition-border, .insurance-competition-icon {
      display: block;
    }
  }



  .favourite-cell {
    width: 29px;
    height: 20px;
    right: 0;
    border-left: 3px solid #f1ecde;
    // overwrite general styles(used in many places - one case specific)
    padding-right: 11px !important;
  }

  .favourite {
    float: right;
    position: relative;
    font-size: 20px;

    i {
      position: absolute;
      top: -10px;
      left: -20px;
      color: #000000;
      width: 23px;

      &.fas.fa-star {
        color: #000000;
        opacity: 0;
        transition: .5s;
      }
    }

    &.is-favourite {
      i {
        &.fas.fa-star {
          opacity: 1;
        }
      }
    }
  }

  .sparkle-ribbon {
    position: absolute;
    right: 20px;
    bottom: -1px;
    width: 38px;
    height: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid #520000;
    border-radius: 16px 16px 0 0;
    box-shadow: inset 0 -4px 0px 0 #CE0124;
    padding-bottom: 4px;
    background-color: #FF4D6B;

    img {
      width: 22px;
      height: 17px;
    }
  }

  table {
    @include archivo-narrow;
    border-collapse: collapse;
    font-size: 14px;
    width: 100%;

    &.league {
      .cleared {
        right: 6px;
      }
    }

    tbody {
      tr:nth-child(odd) {
        background-color: #F9F4E4;
      }

      td {
        font-weight: bold;
      }
    }

    tr {
      height: 38px;

      &.insurance-competition {
        td {
          &:first-child {
            color: #37AF00;
          }
        }
      }
    }

    td {
      cursor: pointer;
      position: relative;

      &.max-width {
        width: 100%;
      }

      &:first-child {
        padding-left: 22px;
      }

      &:last-child {
        padding-right: 15px;
      }
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    height: 100%;
  }

  .ng-dropdown-wrapper {
    width: 400px;
    height: 36px;
    color: #000000;
    background: transparent;
    border: 2px solid #000000;
    border-radius: 15px;
    box-shadow: none !important;
    margin-right: 10px;
    display: flex !important;
    align-items: center !important;

    &.active {
      border-radius: 15px 15px 0 0;
    }

    input {
      @include roboto-cond;
      font-weight: bold;
      font-size: 16px;
      color: #000000;
    }

    .ng-dropdown-menu {
      max-height: 350px;
      top: 114% !important;
      left: -2px !important;
      right: -2px !important;
    }
  }

  .default-field {
    @include roboto-cond;
    font-weight: bold;
    margin: 0 auto;

    input {
      font-weight: normal;

      &:focus + label {
        background-color: #C6F4FF;
      }
    }
  }

  .hide-rank-info {
    font-size: 16px;
    @include roboto;
    font-weight: bold;
    margin-top: 120px;
    text-align: center;
  }

  .half-league-rank {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    flex: 1;
    margin-top: 40px;

    .desc {
      @include mikado();
      font-size: 20px;
      font-weight: bold;

      span {
        text-transform: lowercase;
      }
    }

    .logo-league {
      width: 300px;
      height: 284px;
      background: var(--asset--ui-league_logo_big-png) center no-repeat;
      background-size: contain;
    }
  }


  perfect-scrollbar .ps-content {
    padding-right: 34px;
  }
}
