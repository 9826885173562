@import "src/styles/mixins";

app-san-hud-parameters {
  .parameters-container {
    position: absolute;
    right: 20px;
    top: 10px;
    display: flex;
    align-items: center;
    background: #0E1107B3;
    border: 2px solid #000000;
    border-left: none;
    border-radius: 25px;
    height: 40px;
    box-sizing: border-box;
    padding: 0 14px;

    .parameter-block {
      display: flex;
      align-items: center;
      position: relative;

      &:not(:last-child) {
        margin-right: 10px;
      }

      .icon-parameter {
        width: 26px;
        height: 26px;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        margin-right: 3px;
      }

      .number {
        @include mikado;
        font-weight: 900;
        color: #fff;
        font-size: 16px;
        white-space: nowrap;
      }
    }
  }
}
