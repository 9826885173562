.ow-dialog {
  padding: 17px 40px 40px 40px;

  .title {
    margin-bottom: 24px;
  }
}

.icon-league {
  width: 40px;
  height: 40px;
  margin: 0 6px 0 10px;
  background-size: cover;

  &.league-1 {
    background-image: var(--asset--league-basic-league_silver-png);
  }

  &.league-2 {
    background-image: var(--asset--league-basic-league_gold-png);
  }

  &.league-3 {
    background-image: var(--asset--league-basic-league_diamond-png);
  }

  &.league-4 {
    background-image: var(--asset--league-basic-league_brilliance-png);
  }

  &.league-5 {
    background-image: var(--asset--league-basic-league_pearl-png);
  }
}

& {
  .navigate-info {
    @include archivo-narrow();
    position: absolute;
    top: -11px;
    right: 72px;
    font-weight: bold;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 18px;
    height: 36px;
    border: 3px solid black;
    background-color: #B8F2FF;
    padding: 0 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
  }

  perfect-scrollbar {
    .ps-content {
      padding-right: 44px;
      padding-left: 1px;
    }
  }

  table {
    tbody {
      tr {
        &.my-position {
          border: solid 2px #D0413B !important;
        }
      }
    }
  }

  .my-position-link {
    @include mikado;
    text-decoration: underline;
    font-size: 14px;
    font-weight: 900;
    display: flex;
    align-items: center;
    padding-right: 14px;
    margin: 0 0 16px auto;

    a {
      color: #000000;
    }
  }
}

.wf-tooltip {
  white-space: pre-line;
  line-height: 9px;
}
