@import "src/styles/functions";

app-progressbar {
  .segments-container {
    display: flex;
  }

  .segment {
    transform: skew(-28deg);
    margin-right: multiply-size(1px);
    background: rgba(0, 0, 0, 0.3);
    overflow: hidden;

    .fill {
      width: 0;
      height: 100%;
    }
  }
}
