@import "src/styles/functions";

ow-perfect-scrollbar {
  display: block;
  height: 100%;
  max-height: 100%;
  overflow: hidden;
  position: relative;
  max-width: initial !important;
  width: multiply-size(12px, "100% + ") !important;

  &.ps-show-always {
    .ps {
      &.ps--active-x > .ps__rail-x,
      &.ps--active-y > .ps__rail-y {
        opacity: 0.6;
      }
    }
  }

  &.ps-bottom-border {
    &:before {
      content: '';
      width: calc(100% - calc(12px * var(--multiply)));
      height: multiply-size(2px);
      background-color: black;
      left: 0;
      bottom: 0;
      position: absolute;
      display: block;
      z-index: 3;
    }
  }

  // probably redundant, but i'm keeping it for compatibility reasons
  &.ps-show-active {
    .ps {
      &.ps--active-x,
      &.ps--active-y {
        .ps-overlay {
          .ps-indicator-show {
            opacity: 1;
          }

          &.ps-at-top {
            .ps-indicator-top {
              background: linear-gradient(180deg, hsla(0, 0%, 66.7%, 0.5) 0, hsla(0, 0%, 66.7%, 0));
            }
          }

          &.ps-at-bottom {
            .ps-indicator-bottom {
              background: linear-gradient(0deg, hsla(0, 0%, 66.7%, 0.5) 0, hsla(0, 0%, 66.7%, 0));
            }
          }

          &.ps-at-left {
            .ps-indicator-left {
              background: linear-gradient(90deg, hsla(0, 0%, 66.7%, 0.5) 0, hsla(0, 0%, 66.7%, 0));
            }
          }

          &.ps-at-right {
            .ps-indicator-right {
              background: linear-gradient(270deg, hsla(0, 0%, 66.7%, 0.5) 0, hsla(0, 0%, 66.7%, 0));
            }
          }

          &:not(.ps-at-top) {
            .ps-indicator-top {
              background: linear-gradient(180deg, hsla(0, 0%, 100%, 0.5) 0, hsla(0, 0%, 100%, 0));
              opacity: 1;
            }
          }

          &:not(.ps-at-bottom) {
            .ps-indicator-bottom {
              background: linear-gradient(0deg, hsla(0, 0%, 100%, 0.5) 0, hsla(0, 0%, 100%, 0));
              opacity: 1;
            }
          }

          &:not(.ps-at-left) {
            .ps-indicator-left {
              background: linear-gradient(90deg, hsla(0, 0%, 100%, 0.5) 0, hsla(0, 0%, 100%, 0));
              opacity: 1;
            }
          }

          &:not(.ps-at-right) {
            .ps-indicator-right {
              background: linear-gradient(270deg, hsla(0, 0%, 100%, 0.5) 0, hsla(0, 0%, 100%, 0));
              opacity: 1;
            }
          }
        }
      }

      &.ps--active-x {
        .ps__rail-x {

        }
      }

      &.ps--active-y {
        .ps__rail-y {

        }
      }
    }
  }

  // scrollbar indicators
  &.scroll-indicator {
    &:after {
      position: absolute;
      width: multiply-size(12px, "100% - ");
      bottom: 0;
      left: 0;
      height: multiply-size(22px);
      content: " ";
      z-index: 2;
      pointer-events: none;
      background: rgb(255, 255, 255);
      //background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0.35476197314863445) 100%);
      background: linear-gradient(0deg, rgb(255, 255, 255) 0%, rgba(255, 255, 255, 0.3547619731) 80%, rgba(255, 255, 255, 0) 100%);
    }
  }

  // container for content and scrollbars
  & > .ps {
    max-height: inherit !important;
    max-width: inherit !important;
    width: inherit !important;
    height: inherit !important;
    position: static;
    display: block;
    overflow: hidden;
    overflow-anchor: none;
    touch-action: auto;

    // overlay which is applied on the top of the items container
    &.overlay {
      bottom: 0;
      display: block;
      left: 0;
      overflow: hidden;
      pointer-events: none;
      position: absolute;
      right: 0;
      top: 0;

      .ps-indicator-bottom,
      .ps-indicator-left,
      .ps-indicator-right,
      .ps-indicator-top {
        opacity: 0;
        position: absolute;
        transition: opacity 0.3s ease-in-out;
      }

      .ps-indicator-bottom,
      .ps-indicator-top {
        left: 0;
        min-height: 24px;
        min-width: 100%;
      }

      .ps-indicator-left,
      .ps-indicator-right {
        min-height: 100%;
        min-width: 24px;
        top: 0;
      }
      .ps-indicator-top {
        top: 0;
      }

      .ps-indicator-bottom {
        bottom: 0;
      }

      .ps-indicator-left {
        left: 0;
      }

      .ps-indicator-right {
        right: 0;
      }
    }

    // these styles are applied meanwhile/after(1-2s) scroll
    &.ps--active-x > .ps__rail-x,
    &.ps--active-y > .ps__rail-y {
      cursor: default;
      transition: height 0.2s linear, opacity 0.2s linear, background-color 0.2s linear;
      background-color: transparent;
      display: block;

      &:hover,
      &.ps--clicking {
        width: 15px;
      }
    }

    &.ps--active-y > .ps__rail-y {
      left: auto !important;
      right: 0 !important;
      top: 0 !important;
      width: 10px;

      &.ps--clicking,
      &:hover {
        width: 15px;
      }
    }

    &.ps--active-x > .ps__rail-x {
      bottom: 0 !important;
      height: 10px;
      left: 0 !important;
      top: auto !important;

      &.ps--clicking,
      &:hover {
        height: 15px;
      }
    }

    // while scroll in both directions
    &.ps--active-x.ps--active-y {
      .ps__rail_y {
        margin: 0 0 10px;
      }

      .ps__rail-x {
        margin: 0 10px 0 0;
      }
    }

    // these styles are applied while scrolling
    &.ps--scrolling-x > .ps__rail-x,
    &.ps--scrolling-y > .ps__rail-y {
      background-color: #eee;
      opacity: 0.9;
    }

    // these styles are applied on any interaction
    &.ps--focus,
    &.ps--scrolling-x,
    &.ps-scrolling-y,
    &:hover {
      .ps__rail-x,
      .ps__rail-y {
        opacity: 0.6;
      }
    }
  }

  // wrapper with the items
  .ps-content {
    padding-right: multiply-size(24px);

    @media only screen and (min-width: 768px) {
      padding-right: multiply-size(30px);
    }
  }

  // scrollbars - set basic size of rail(not thumb and rail)
  .ps__rail-x,
  .ps__rail-y {
    -webkit-transition: background-color 0.2s linear, opacity 0.2s linear;
    display: none;
    opacity: 0;
    position: absolute;
    transition: background-color 0.2s linear, opacity 0.2s linear;
    border-radius: 0;
    background: rgba($color-base, 0.5) !important;

    &.ps--clicking,
    &:focus,
    &:hover {
      background-color: #eee;
      opacity: 0.9;
    }
  }

  .ps__rail-x {
    bottom: 2px;
    height: multiply-size(1px);

    // highlight thumb on action
    &.ps--clicking > .ps__thumb-x,
    &:focus > .ps__thumb-x,
    &:hover > .ps__thumb-x {
      background-color: #999;
      height: 11px;
    }
  }

  .ps__rail-y {
    width: multiply-size(1px);
    right: 0;

    // highlight thumb on action
    &.ps--clicking .ps__thumb-y,
    &:focus > .ps__thumb-y,
    &:hover > .ps__thumb-y {
      background-color: #999;
      width: 11px;
    }
  }

  // size of vertical scrollbar
  .ps__rail-y,
  .ps__thumb-y {
    width: multiply-size(1px) !important;
    right: 0 !important;
    border-radius: 0 !important;
  }

  // size of horizontal scrollbar
  .ps__rail-x,
  .ps__thumb-x {}

  // styles of thumb(button which is used to scroll)
  .ps__thumb-x,
  .ps__thumb-y {
    border-radius: 6px;
    position: absolute;
    cursor: pointer;
    -webkit-transition: background-color 0.2s linear, width 0.2s ease-in-out;
    transition: background-color 0.2s linear, width 0.2s ease-in-out;
    background-color: $color-base !important;
  }

  .ps__thumb-y {
    width: multiply-size(1px);
    right: 0;
  }

  .ps__thumb-x {
    bottom: 2px;
    height: multiply-size(1px);
  }
}
